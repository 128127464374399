import React from 'react';
import {View, Image} from 'react-native';

import styles from './HeaderAStyles';
import Colors from '../../theme/colors';
import NotificationSVG from '../svgs/NotificationSVG';
import headerBg from '../../../assets/images/headerBg.png';
import logo from '../../../assets/images/LOGO_INSTADRINK_LOGO.png';
import LogoTextSVG from '../svgs/LogoTextSVG';

const HeaderA = ({scroll}) => (
  <View style={[styles.header, scroll ? styles.headerScroll : styles.headerNoScroll]}>
    <Image
      source={{uri: headerBg}}
      style={styles.headerBg}
      resizeMode="stretch"
    />
    <View style={styles.container}>
      <Image source={{uri: logo}} style={styles.logo} resizeMode="contain" />
      <LogoTextSVG style={styles.logoText} />
      <NotificationSVG fill="white" style={styles.notificationBtn} />
    </View>
  </View>
);

export default HeaderA;
