import React, {useState, useContext, useEffect} from 'react';
import styles from './GroupOrderPageStyles';
import Colors from '../../theme/colors';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  View,
  Share,
  ActivityIndicator,
  TextInput,
} from 'react-native';
// Import Firebase
import ButtonA from '../../components/buttonA/ButtonA';
import SubheadingA from '../../components/subheadingA/SubheadingA';
import BodyA from '../../components/bodyA/BodyA';
import TitleA from '../../components/titleA/TitleA';
import GroupOrderSVG from '../../components/svgs/GroupOrderSVG';
import TitleBigA from '../../components/titleBigA/TitleBigA';
import DetailsSVG from '../../components/svgs/DetailsSVG';
import PenSVG from '../../components/svgs/PenSVG';
import ClockSVG from '../../components/svgs/ClockSVG';
import DollarSVG from '../../components/svgs/DollarSVG';
import AddUserSVG from '../../components/svgs/AddUserSVG';
import ModalA from '../../components/modalA/ModalA';
import TouchableItem from '../../components/TouchableItem';
import DateTimePicker from '../../components/datePicker/DatePicker';
import {RadioButton} from 'react-native-paper';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {httpsCallable} from 'firebase/functions';
import firebase from 'firebase/compat/app';
import {
  addProduct,
  addPromoCode,
  remPromoCode,
  addCommandeGroupe,
} from '../../reducers/cartactions';
import {AuthContext} from '../../contexts/context';
import getEnvVars from '../../config';
import {db, FUNCTIONS} from '../../services/firebase';
import FavoritesEventsContext from '../../contexts/favoritesEventsContext.js';
import {useNavigation} from '@react-navigation/native';
import {useTranslation} from 'react-i18next';

const {links} = getEnvVars();

const formatDate = (dateString) => {
  const options = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'UTC', // Adjust this according to your timezone
  };

  const date = new Date(dateString);
  const formattedDate = new Intl.DateTimeFormat('fr-FR', options).format(date);

  return formattedDate;
};

const PAYMENTTYPE = {
  covered: 'Payer pour tout le monde',
  shared: 'A chacun son panier',
  third: 'Payez le montant de votre choix',
};

const NameSection = ({handleModalDismiss, setClientName, clientName}) => {
  const {t} = useTranslation();
  const {user} = useContext(AuthContext);
  const [name, setName] = useState(clientName);

  const ChangeClientName = async () => {
    await db.collection('users').doc(user.uid).set(
      {
        displayName: name,
      },
      {merge: true},
    );
  };

  return (
    <View>
      <TitleA style={{marginBottom: 10, paddingHorizontal: 10}}>
        {/* Libellé de la commande */}
        {t('GroupOrderPage.Libellé de la commande')}
      </TitleA>
      <SubheadingA style={{marginBottom: 10, paddingHorizontal: 10}}>
        {t('GroupOrderPage.Changer le nom de la commande groupée')}
      </SubheadingA>
      <TextInput
        onChange={(e) => setName(e.target.value)}
        value={name}
        placeholder={t('GroupOrderPage.Indiquez le nom...')}
        maxLength={50}
        style={styles.textInput}
      />
      <ButtonA
        onPress={() => {
          setClientName(name);
          ChangeClientName();
          handleModalDismiss();
        }}
        disabled={!name}
        style={{marginVertical: 20}}>
        {t('GroupOrderPage.Changer')}
      </ButtonA>

      <ButtonA
        style={styles.cancelButton}
        color={Colors.darkGreen}
        onPress={handleModalDismiss}>
        {t('GroupOrderPage.Annuler')}
      </ButtonA>
    </View>
  );
};

const DateSection = ({handleModalDismiss, setDateTime, dateTime}) => {
  const {t} = useTranslation();
  const [time, setDate] = useState(dateTime);
  return (
    <View>
      <TitleA style={{marginBottom: 10, paddingHorizontal: 10}}>
        {/* Date limite de commande{' '} */}
        {t('GroupOrderPage.Date limite de commande')}
      </TitleA>
      <SubheadingA style={{marginBottom: 10, paddingHorizontal: 10}}>
        {/* Modifiez votre date/heure limite pour finaliser la commande groupée{' '} */}
        {t(
          'GroupOrderPage.Modifiez votre date/heure limite pour finaliser la commande groupée',
        )}
      </SubheadingA>
      <DateTimePicker dateTime={time} setDateTime={setDate} />
      <ButtonA
        onPress={() => {
          setDateTime(time);
          handleModalDismiss();
        }}
        style={{marginVertical: 20}}>
        {t('GroupOrderPage.Fixer')}
      </ButtonA>

      <ButtonA
        style={styles.cancelButton}
        color={Colors.darkGreen}
        onPress={handleModalDismiss}>
        {/* Pas de date limite */}
        {t('GroupOrderPage.Pas de date limite')}
      </ButtonA>
    </View>
  );
};

const PriceSection = ({handleModalDismiss, setChecked, checked}) => {
  const {t} = useTranslation();
  const [checkedValue, setCheckedValue] = useState(checked);
  return (
    <View>
      <TitleA style={{marginBottom: 10, paddingHorizontal: 10}}>
        {/* Choisissez comment payer */}
        {t('GroupOrderPage.Choisissez comment payer')}
      </TitleA>
      <SubheadingA style={{marginBottom: 10, paddingHorizontal: 10}}>
        {/* Vous pouvez payer la totalité de la commande ou laisser d'autres
        personnes payer les articles qu'ils ont achetés choisir */}
        {t(
          'GroupOrderPage.Vous pouvez payer la totalité de la commande ou laisser d autres personnes payer les articles qu ils ont achetés choisir',
        )}
      </SubheadingA>
      <View style={styles.checkBox}>
        <RadioButton
          value="covered"
          color={Colors.darkGreen}
          status={checkedValue === 'covered' ? 'checked' : 'unchecked'}
          onPress={() => setCheckedValue('covered')}
        />
        <BodyA
          onPress={() => setCheckedValue('covered')}
          style={{color: Colors.normalGray}}>
          {/* Payer pour tout le monde */}
          {t('GroupOrderPage.Payer pour tout le monde')}
        </BodyA>
      </View>
      <View style={styles.checkBox}>
        <RadioButton
          value="shared"
          color={Colors.darkGreen}
          status={checkedValue === 'shared' ? 'checked' : 'unchecked'}
          onPress={() => setCheckedValue('shared')}
        />
        <BodyA
          onPress={() => setCheckedValue('shared')}
          style={{color: Colors.normalGray}}>
          {/* Currency in equal parts */}
          {t('GroupOrderPage.Monnaie à parts égales')}
        </BodyA>
      </View>

      {/* <View style={styles.checkBox}>
      <RadioButton
        value="shared"
        color={Colors.darkGreen}
        status={checked === 'third' ? 'checked' : 'unchecked'}
        onPress={() => toggleState('third')}
      />
      <BodyA style={{color: Colors.normalGray}}>
        Payer le montant de votre choix
      </BodyA>
    </View>
    <View>
      <SubheadingA style={styles.label}>Montant à payer</SubheadingA>
      <TextInput
        placeholder="•••••••••••••••••••••••••••••••••••••••••"
        placeholderTextColor={Colors.lightGray}
        maxLength={60}
        value={montant}
        onChange={(event) => setMontant(event.target.value)}
        style={styles.textInput}
      />
      <DollarSVG
        style={styles.inputRightIcon}
        stroke={Colors.normalGray}
      />
    </View> */}
      <ButtonA
        onPress={() => {
          setChecked(checkedValue);
          handleModalDismiss();
        }}
        style={{marginVertical: 20}}>
        Ok
      </ButtonA>
      <ButtonA
        style={styles.cancelButton}
        color={Colors.darkGreen}
        onPress={handleModalDismiss}>
        {t('GroupOrderPage.Annuler')}
      </ButtonA>
    </View>
  );
};

const GroupOrderPage = ({route, cart, addCommandeGroupe}) => {
  const navigation = useNavigation();
  const {t} = useTranslation();
  const favoritesContext = useContext(FavoritesEventsContext);
  const {user} = useContext(AuthContext);
  //
  const [modalVisible, setModalVisible] = useState(false);
  const [checked, setChecked] = useState('covered');
  const [modalContent, setModalContent] = useState('');
  // const [montant, setMontant] = useState('');
  const [dateTime, setDateTime] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [clientName, setClientName] = useState('');
  const [currentUser, setCurrentUser] = useState();
  const params = route?.params;

  useEffect(() => {
    const getUserDiplayName = async (id) => {
      await db
        .collection('users')
        .doc(user.uid)
        .get()
        .then(async (docuser) => {
          const userData = docuser.data();
          setCurrentUser(userData);
          if (userData && 'displayName' in userData) {
            setClientName(userData.displayName);
          }
        });
    };
    getUserDiplayName();
  }, [params]);

  const shareGroupedOrder = async (
    user,
    link,
    paymentType,
    docGO,
    directLink,
  ) => {
    try {
      let message =
        // 'Vous avez été invité à partager une commande avec'
        `${t(
          'GroupOrderPage.Vous avez été invité à partager une commande avec',
        )}`;

      +clientName + '.';
      message = message.concat(' ', 'Lien pour procéder : ' + link);
      await Share?.share({
        title: message,
        message: message,
      });
      navigation.navigate('Event', {
        s: params.eventId,
        t: 'event',
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleCreateGroupedOrder = async (paymentType) => {
    setIsLoading(true);
    const products = JSON.parse(JSON.stringify(cart.products)); // Deep clone needed
    // TODO: The following checks should be handled in backend
    // Add the Grouped Order in groupedorders collection in Firebase
    const groupedOrderParams = {
      owner: {
        userId: user.uid,
        products,
      },
      reservationId: '',
      eventId: params.eventId,
      paymentType,
      status: 'draft',
      users: [],
      createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
    };
    // Push it to Firestore
    await db
      .collection('groupedorders')
      .add(groupedOrderParams)
      .then(async (docGO) => {
        // const docSnapshot = await docGO.get();
        const groupedordersID = docGO.id;
        if (groupedordersID)
          await db.collection('users').doc(user.uid).set(
            {
              activeGroupedOrder: docGO.id,
            },
            {merge: true},
          );
        // Access the data from the snapshot
        // const data = docSnapshot.data();
        addCommandeGroupe({
          id: docGO.id,
          data: groupedOrderParams,
          isOwner: true,
        });
        // TODO: Generate the dynamic link

        let dataDeep = {
          id: params.eventId,
          tier: 'event',
          go: docGO.id,
        };

        const createDeepLink = httpsCallable(FUNCTIONS, 'createDeepLink');
        const result = await createDeepLink(dataDeep);

        const deeplink = result?.data;
        const link = deeplink?.newdoc;
        const directLink =
          links.webappurl +
          'shop?go=' +
          docGO.id +
          '&s=' +
          params.eventId +
          '&t=event';
        // Trigger the native share
        shareGroupedOrder(
          user.displayName,
          link,
          paymentType,
          docGO,
          directLink,
        );
        setIsLoading(false);
      });
  };

  const handleModalOpen = (content) => {
    setModalVisible(true);
    setModalContent(content);
  };

  const handleModalDismiss = () => {
    setModalVisible(false);
  };

  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.imageContainer}>
          <GroupOrderSVG />
          <Text
            style={{
              marginTop: 10,
              color: Colors.darkGreen,
              textAlign: 'center',
              fontFamily: 'Bold',
              fontSize: 24,
              lineHeight: 36,
              letterSpacing: 0.96,
            }}>
            {/* Commande en vrac */}
            {t('GroupOrderPage.Commande en vrac')}
          </Text>

          <Text
            style={{
              color: Colors.darkGray,
              textAlign: 'center',
              fontFamily: 'SemiBold',
              fontSize: 16,
              // lineHeight: 36,
              letterSpacing: 0.64,
            }}>
            {params?.title}
          </Text>

          <SubheadingA
            style={{
              color: Colors.normalGray,
              marginTop: 10,
            }}>
            {/* Invitez les utilisateurs à ajouter des articles à l'aide d'un lien à partager. Payez pour tout le monde ou demandez aux invités de payer leur part. */}
            {t(
              'GroupOrderPage.Invitez les utilisateurs à ajouter des articles à l aide d un lien à partager. Payez pour tout le monde ou demandez aux invités de payer leur part.',
            )}
          </SubheadingA>
        </View>

        <View style={styles.container}>
          <TitleA style={{marginVertical: 5}}>{event?.title}</TitleA>
          <View>
            <TitleBigA>{t('GroupOrderPage.détails de la commande')}</TitleBigA>

            <View style={styles.bodyRow}>
              <View style={styles.left}>
                <DetailsSVG style={{width: 14, height: 14}} />
              </View>
              <View style={{flexGrow: 1}}>
                <BodyA style={{color: Colors.normalGray}}>
                  {t('GroupOrderPage.Libellé de la commande')}
                </BodyA>
                <SubheadingA style={{color: Colors.darkGray}}>
                  {t('GroupOrderPage.Commande de')} {clientName}
                </SubheadingA>
              </View>
              <TouchableItem
                style={styles.right}
                onPress={() => handleModalOpen('name')}>
                <PenSVG />
              </TouchableItem>
            </View>
            <View style={styles.bodyRow}>
              <View style={styles.left}>
                <ClockSVG style={{width: 20, height: 20}} />
              </View>
              <View style={{flexGrow: 1}}>
                <BodyA style={{color: Colors.normalGray}}>
                  {t('GroupOrderPage.Date limite')}
                </BodyA>
                <SubheadingA style={{color: Colors.darkGray}}>
                  {formatDate(dateTime)}
                </SubheadingA>
              </View>
              <TouchableItem
                style={styles.right}
                onPress={() => handleModalOpen('date')}>
                <PenSVG />
              </TouchableItem>
            </View>
            <View style={styles.bodyRow}>
              <View style={styles.left}>
                <DollarSVG style={{width: 20, height: 20}} />
              </View>
              <View style={{flexGrow: 1}}>
                <BodyA style={{color: Colors.normalGray}}>
                  {t('GroupOrderPage.Payer')} {PAYMENTTYPE[checked]}
                </BodyA>
                {/* <SubheadingA style={{color: Colors.darkGray}}>
                  Sans limite de dépenses
                </SubheadingA> */}
              </View>
              <TouchableItem
                style={styles.right}
                onPress={() => handleModalOpen('price')}>
                <PenSVG />
              </TouchableItem>
            </View>
          </View>

          <View style={{marginVertical: 10, marginTop: 30}}>
            <ButtonA
              onPress={async () => {
                await handleCreateGroupedOrder(checked);
              }}
              disabled={isLoading || !clientName}
              style={{
                backgroundColor: Colors.blue,
                width: 182,
                height: 54,
                alignSelf: 'center',
              }}>
              {isLoading ? (
                <ActivityIndicator size="small" color="green" />
              ) : (
                <>
                  <AddUserSVG
                    style={{
                      width: 18,
                      height: 18,
                      marginBottom: -2,
                      marginRight: 10,
                    }}
                  />
                  {t('GroupOrderPage.Invitateur')}
                </>
              )}
            </ButtonA>
          </View>
        </View>
        <ModalA visible={modalVisible} onDismiss={handleModalDismiss}>
          {modalContent === 'name' && (
            <NameSection
              clientName={clientName}
              handleModalDismiss={handleModalDismiss}
              setClientName={setClientName}
            />
          )}
          {modalContent === 'date' && (
            <DateSection
              handleModalDismiss={handleModalDismiss}
              setDateTime={setDateTime}
              dateTime={dateTime}
            />
          )}

          {modalContent === 'price' && (
            <PriceSection
              handleModalDismiss={handleModalDismiss}
              setChecked={setChecked}
              checked={checked}
            />
          )}
        </ModalA>
      </ScrollView>
    </SafeAreaView>
  );
};

const mapStateToProps = (state) => {
  const {cart, store, event} = state;
  return {cart, store, event};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addProduct,
      addPromoCode,
      remPromoCode,
      addCommandeGroupe,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(GroupOrderPage);
