import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const DollarSVG = (props) => {
  const {style, stroke = '#2FBF86', ...restProps} = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      style={style}
      {...restProps}>
      <Path
        stroke={stroke}
        strokeWidth={0.75}
        d="M10.5 16.5a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13Z"
      />
      <Path
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth={0.75}
        d="M11.77 7.435a3.113 3.113 0 0 0-3.046 0c-.463.26-.848.634-1.115 1.085a2.898 2.898 0 0 0 0 2.96c.267.45.652.825 1.115 1.084a3.112 3.112 0 0 0 3.045.001M6.692 9.26h2.539M6.692 10.74h2.539"
      />
    </Svg>
  );
};
export default DollarSVG;
