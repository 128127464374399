import React, {createContext, useState} from 'react';

const FavoritesEventsContext = createContext({
  favorites: [],
  totalFavorites: 0,
  addFavorite: (favoriteEvent) => {},
  removeFavorite: (eventId) => {},
  eventIsFavorite: (eventId) => {},
});

export function FavoritesEventsContextProvider({children}) {
  const [favoritesEvents, setFavoritesEvents] = useState([]);

  function addFavoriteHandler(favoriteEvent) {
    setFavoritesEvents((prevUserFavorites) => {
      return prevUserFavorites.concat(favoriteEvent);
    });
  }

  function removeFavoriteHandler(eventId) {
    setFavoritesEvents((prevUserFavorites) => {
      return prevUserFavorites.filter((event) => event.id !== eventId);
    });
  }

  function eventIsFavoriteHandler(eventId) {
    return favoritesEvents.some((event) => event.id === eventId);
  }

  const context = {
    favorites: favoritesEvents,
    totalFavorites: favoritesEvents.length,
    addFavorite: addFavoriteHandler,
    removeFavorite: removeFavoriteHandler,
    eventIsFavorite: eventIsFavoriteHandler,
  };

  return (
    <FavoritesEventsContext.Provider value={context}>
      {children}
    </FavoritesEventsContext.Provider>
  );
}

export default FavoritesEventsContext;
