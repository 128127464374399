import React, {useEffect, useMemo, useState} from 'react';
// import ScreenTitleA from '../../components/screenTitleA/ScreenTitleA';
import Colors from '../../theme/colors';
import {StatusBar, Text, View, ScrollView, SafeAreaView} from 'react-native';

import styles from './CommandStyle';
import {SvgCommande, DetailsCommande, DetailsCommandeB} from './config';
import CartSVG from '../../components/svgs/CartSVG';
import TicketSVG from '../../components/svgs/TicketSVG';
import LocationSVG from '../../components/svgs/LocationSVG';
import TitleBigA from '../../components/titleBigA/TitleBigA';
import MapLocationSvg from '../../components/svgs/MapLocationSvg';
import MenuBookSvg from '../../components/svgs/MenuBookSVG';
import LineSvg from '../../components/svgs/LineSvg';
import {db} from '../../services/firebase';

const SuivieCommande = ({route}) => {
  // const [status, setStatus] = React.useState('livraison');
  const [reservation, setReservation] = useState();
  const [loading, setLoading] = useState(false);
  const id = route.params.id;
  const queryFn = async (id) => {
    console.log('id', id);
    setLoading(true);
    db.collection('orders')
      .doc(id)
      // .get()
      .onSnapshot(async (reservationData) => {
        const reservation = reservationData.data();
        setReservation(reservation);
      });
    setLoading(false);
  };

  useEffect(() => {
    if (id) queryFn(id);
  }, [id]);

  const foodListName = useMemo(() => {
    if (reservation)
      return reservation?.products?.reduce(
        (acc, item) => acc + item.quantity + ' ' + item.name,
        '',
      );
    else return '';
  }, [reservation]);

  console.log('reservation', reservation);

  return (
    <ScrollView
      horizontal={false}
      showsHorizontalScrollIndicator={false}
      vertical={true}>
      <SafeAreaView style={styles.containerSceen}>
        <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
        {/* <ScreenTitleA style={{height: 60}} title={'Suivie de commande'} /> */}

        <View
          style={[styles.suivieCommandeContainer, {backgroundColor: '#FFF'}]}>
          <View style={{flexDirection: 'row', paddingVertical: 8, gap: 16}}>
            <MapLocationSvg />
            <Text style={styles.TextCommandeA}>Suivi de la commande</Text>
          </View>
          <View style={{paddingTop: 8}}>
            {SvgCommande.map(({id, svg, phrases, name}, index) => (
              <>
                <View
                  key={id}
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignSelf: 'stretch',
                    alignItems: 'center',
                    opacity: reservation?.status === name ? 1 : 0.5,
                  }}>
                  {svg}
                  <View style={{justifyContent: 'center'}}>
                    {phrases.map(({text, style}, index) => (
                      <Text key={index} style={style}>
                        {text}
                      </Text>
                    ))}
                  </View>
                  <View />
                </View>
                {index < SvgCommande.length - 1 && (
                  <View
                    key={id}
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignSelf: 'stretch',
                      alignItems: 'center',
                      opacity: reservation?.status === name ? 1 : 0.5,
                    }}>
                    <LineSvg style={{width: 14, height: 14}} />
                    <View />
                  </View>
                )}
              </>
            ))}
          </View>
        </View>

        <View style={styles.suivieCommandeContainer2}>
          <View style={styles.commandeA}>
            <View style={styles.title}>
              <View>
                <CartSVG stroke={Colors.darkGray} style={styles.cartIcon} />
              </View>
              <View>
                <TitleBigA>{reservation?.store?.name}</TitleBigA>
              </View>
            </View>

            <View style={styles.detail}>
              <View style={[styles.icon, {width: 16, height: 16}]}>
                <TicketSVG stroke={Colors.normalGray} />
              </View>
              <View style={{flexWrap: 'wrap', flexShrink: 1}}>
                <Text>{foodListName}</Text>
              </View>
            </View>
            <View style={styles.detail}>
              <View style={styles.icon}>
                <LocationSVG />
              </View>
              <View style={{flexWrap: 'wrap', flexShrink: 1}}>
                <Text style={{color: Colors.normalGray}}>
                  Livrer à l’adresse 1 Bd de Bonne Nouvelle, 75002 Paris
                </Text>
              </View>
            </View>
          </View>

          <View style={[styles.title, {gap: 3}]}>
            <View>
              <MenuBookSvg stroke={Colors.darkGray} style={styles.cartIcon} />
            </View>
            <View>
              <TitleBigA>Détailles</TitleBigA>
            </View>
          </View>
          {reservation &&
            reservation?.products?.map((product) => {
              const groupedComposition = product.composition.reduce(
                (grouped, item) => {
                  if (!grouped[item.category]) {
                    grouped[item.category] = [];
                  }
                  grouped[item.category].push(item);
                  return grouped;
                },
                {},
              );
              return (
                <View style={{paddingHorizontal: 15, paddingBottom: 8}}>
                  <View style={styles.detailA}>
                    <View>
                      <Text style={styles.textDetailA}> {product.name}</Text>
                    </View>
                    <Text>{product.quantity}</Text>
                    <Text>{product.price}$</Text>
                  </View>

                  <View style={styles.allDetailesA}>
                    <View>
                      {Object.keys(groupedComposition).map((category) => {
                        const items = groupedComposition[category];
                        if (items && items.length > 0) {
                          return (
                            <View key={DetailsCommande.id}>
                              <Text style={styles.titleTextDetaileContainer}>
                                {category}
                              </Text>
                              {items?.map((composition, phraseIndex) => (
                                <View style={styles.textMapingCommande}>
                                  <Text
                                    key={phraseIndex}
                                    style={{
                                      color: 'var(--Normal-Grey, #77838F)',
                                      fontFamily: 'Bai Jamjuree',
                                      fontSize: '14px',
                                      fontStyle: 'normal',
                                      fontWeight: 300,
                                    }}>
                                    {composition.quantity
                                      ? composition.quantity
                                      : 1}{' '}
                                    {composition.name}
                                  </Text>
                                </View>
                              ))}
                            </View>
                          );
                        }
                      })}
                    </View>
                  </View>
                </View>
              );
            })}
        </View>
      </SafeAreaView>
    </ScrollView>
  );
};

export default SuivieCommande;
