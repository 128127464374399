
const authReducer = (
    (prevState, action) => {
    switch( action.type ) {
      case 'GOOGLE_LAUNCH': 
        return {
          ...prevState,
          isLoading: true,
        };
      case 'RETRIEVE_TOKEN': 
        return {
          ...prevState,
          //userToken: action.token,
          isLoading: false,
        };
      case 'LOGIN': 
        return {
          ...prevState,
          userName: action.id,
          //userToken: action.token,
          isLoading: false,
        };
      case 'LOGOUT': 
        return {
          ...prevState,
          userName: null,
          //userToken: null,
          isLoading: false,
        };
      case 'REGISTER': 
        return {
          ...prevState,
          userName: action.id,
          //userToken: action.token,
          isLoading: false,
        };
      case 'RESET': 
        return {
          ...prevState,
          userName: action.id,
          //userToken: action.token,
          isLoading: false,
        };
    }
  });

export default authReducer;