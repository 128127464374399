/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

// import dependencies
import React, {Component} from 'react';
import {FlatList, SafeAreaView, StatusBar, StyleSheet} from 'react-native';

// import components
import ActionProductCardHorizontal from '../../components/cards/ActionProductCardHorizontal';

// import colors
import Colors from '../../theme/colors';

// CategoryA Config
import {db} from '../../services/firebase';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {addProduct} from '../../reducers/cartactions';
import {collectionGroup} from 'firebase/firestore';
//import Toast, {DURATION} from 'react-native-easy-toast';
// CategoryA Styles
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  productList: {
    // spacing = padding + ActionProductCardHorizontal margin = 12 + 4 = 16
    padding: 12,
  },
});

class CategoryA extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    props.navigation.setOptions({
      title: props.route.params.title,
    });

    this.state = {
      isLoading: false,
      products: [],
    };
  }

  componentDidMount = () => {
    this.setState({isLoading: true});
    let storeid = this.props.store.id;
    console.log(this.props.route.params);
    // let doc = db.collection('products')
    //             .where('storeid', '==', storeid)
    //             .where('categoryid', '==', this.props.route.params.categoryid);
    let docRef = collectionGroup(db, 'products');
    // .where('storeid', '==', storeid)
    // .where('categoryid', '==', this.props.route.params.categoryid);
    const doc = query(
      docRef,
      where('orgId', '==', storeid),
      where('categoryid', '==', this.props.route.params.categoryid),
    );
    this.observer = doc.onSnapshot(
      (querySnapshot) => {
        //let worker = docSnapshot.data();
        const data = querySnapshot.docs.map(function (doc) {
          var docdata = doc.data();
          return {
            id: doc.id,
            name: docdata.name,
            photo: docdata.photo,
            price: docdata.price,
          };
        });

        //console.log("dataorders");
        //console.log(data);
        this.setState({products: data, isLoading: false});
      },
      (err) => {
        //console.log(`Encountered error: ${err}`);
      },
    );
  };

  goBack = () => {
    const {navigation} = this.props;
    navigation.goBack();
  };

  navigateTo = (screen) => () => {
    const {navigation} = this.props;
    navigation.navigate(screen);
  };

  navigateToProduct = (screen, id) => () => {
    const {navigation} = this.props;
    navigation.navigate(screen, {id: id});
  };

  onPressRemove = (item) => () => {
    let {quantity} = item;
    quantity -= 1;

    const {products} = this.state;
    const index = products.indexOf(item);

    if (quantity < 0) {
      return;
    }
    products[index].quantity = quantity;

    this.setState({
      products: [...products],
    });
  };

  onPressAdd = (item) => () => {
    //console.log("item adding");
    //console.log(item);

    let productToAdd = {
      id: item.id,
      name: item.name,
      description: '',
      price: item.price,
      photo: item.photo,
      quantity: 1,
      total: item.price,
    };
    this.props.addProduct(productToAdd);

    this.toast.show('Produit ajouté au panier');
  };

  keyExtractor = (item, index) => index.toString();

  renderProductItem = ({item, index}) => {
    //console.log("product");
    //console.log(item);
    return (
      <ActionProductCardHorizontal
        //onPress={this.navigateToProduct('Product', item.id)}
        onPressRemove={this.onPressRemove(item)}
        onPressAdd={this.onPressAdd(item)}
        swipeoutDisabled
        key={index}
        imageUri={item.photo}
        title={item.name}
        description={item.description}
        rating={item.rating}
        price={item.price}
        quantity={item.quantity}
        discountPercentage={item.discountPercentage}
        label={item.label}
      />
    );
  };

  render() {
    const {products} = this.state;

    return (
      <SafeAreaView style={styles.container}>
        <StatusBar
          backgroundColor={Colors.statusBarColor}
          barStyle="dark-content"
        />

        <FlatList
          data={products}
          keyExtractor={this.keyExtractor}
          renderItem={this.renderProductItem}
          contentContainerStyle={styles.productList}
        />
        <Toast
          ref={(toast) => (this.toast = toast)}
          style={{backgroundColor: '#00b970'}}
          textStyle={{fontSize: 20}}
        />
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state) => {
  const {cart, store} = state;
  return {cart, store};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addProduct,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CategoryA);
