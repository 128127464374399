import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import styles from './ReastaurantPageStyles.js';
import Colors from '../../theme/colors.js';
import {
  FlatList,
  SafeAreaView,
  StatusBar,
  Text,
  View,
  Animated,
  Easing,
  ScrollView,
  ActivityIndicator,
  TouchableOpacity,
  Image,
  Share,
  TextInput,
} from 'react-native';
// Import redux

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  addProduct,
  setStore,
  setLastId,
  setTierMode,
  emptyCart,
} from '../../reducers/cartactions.js';
import getImgSource from '../../utils/getImgSource.js';
import TitleA from '../../components/titleA/TitleA.js';
import FavoriteSVG from '../../components/svgs/FavoriteSVG.js';
import StarSVG from '../../components/svgs/StarSVG.js';
import OutlineStarSVG from '../../components/svgs/OutlineStarSVG.js';
import TimeSVG from '../../components/svgs/TimeSVG.js';
import CreditCardSVG from '../../components/svgs/CreditCardSVG.js';
import BackArrowSVG from '../../components/svgs/BackArrowSVG.js';
import HandDeliverySVG from '../../components/svgs/HandDeliverySVG.js';
import SubheadingA from '../../components/subheadingA/SubheadingA.js';
import ShopSVG from '../../components/svgs/ShopSVG.js';
import {query, where, collectionGroup, collection} from 'firebase/firestore';

import ButtonA from '../../components/buttonA/ButtonA.js';
import AddUserSVG from '../../components/svgs/AddUserSVG.js';
import TitleBigA from '../../components/titleBigA/TitleBigA.js';
import FoodCard from '../../components/foodCard/FoodCard.js';
import HorizontalFoodCard from '../../components/foodCard/HorizontalFoodCard.js';
import {db, FUNCTIONS} from '../../services/firebase.js';
import {AuthContext} from '../../contexts/context.js';
import ScreenTitleA from '../../components/screenTitleA/ScreenTitleA';
import firebase from 'firebase/compat/app';

import getEnvVars from '../../config';
import {collectionData, docData} from 'rxfire/firestore';
import {map, tap} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import ModalA from '../../components/modalA/ModalA';
import {httpsCallable} from 'firebase/functions';
import {useTranslation} from 'react-i18next';

// Events
const {links} = getEnvVars();

const NameSection = ({handleModalDismiss, setClientName, clientName}) => {
  const {t} = useTranslation();
  const {user} = useContext(AuthContext);
  const [name, setName] = useState(clientName);

  const ChangeClientName = async () => {
    await db
      .collection('users')
      .doc(user.uid)
      .set(
        {
          displayName: name,
        },
        {merge: true},
      )
      .then(() => {
        setClientName(name);
        handleModalDismiss();
      });
  };
  return (
    <View>
      <TitleA style={{marginBottom: 10, paddingHorizontal: 10}}>
        {/* Compte de nom d'utilisateur */}
        {t('RestaurantPage.Compte de nom d utilisateur')}
      </TitleA>
      <SubheadingA style={{marginBottom: 10, paddingHorizontal: 10}}>
        {t('RestaurantPage.Définissez le nom d utilisateur sur votre compte')}
      </SubheadingA>
      <TextInput
        onChange={(e) => setName(e.target.value)}
        value={name}
        placeholder={t('RestaurantPage.Indiquez le nom...')}
        maxLength={50}
        style={styles.textInput}
      />
      <ButtonA
        onPress={() => {
          ChangeClientName();
        }}
        disabled={!name}
        style={{marginVertical: 20}}>
        {t('RestaurantPage.Changer')}
      </ButtonA>

      <ButtonA
        style={styles.cancelButton}
        color={Colors.darkGreen}
        onPress={handleModalDismiss}>
        {t('RestaurantPage.Annuler')}
      </ButtonA>
    </View>
  );
};

const RestaurantPage = ({navigation, route, setStore, store, emptyCart}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [activeGroupeTable, setActiveGroupeTable] = useState();
  const [storeData, setStoreData] = useState();
  const [groupeTableData, setGroupeTableData] = React.useState();
  const [loadingGroupTable, setLoadingGroupTable] = useState(false);
  const {user} = useContext(AuthContext);
  const {params} = route;
  const [isVisible, setIsVisible] = useState(false);
  // Client Name
  const [clientName, setClientName] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  // Tabs state
  const [isTabClicked, setIsTabClicked] = useState('');

  const [livraisonSelected, setLivraisonSelected] = useState(true);

  const handleEmporterPress = () => {
    setLivraisonSelected(!livraisonSelected);
    setStore({...store, livraison: livraisonSelected});
  };
  const scrollViewRef = useRef();
  const flatListRef = useRef();

  const scrollToSection = (sectionName) => {
    storeData?.categories?.map((category) => {
      if (sectionName === category.name) {
        category?.ref.current?.measureLayout(
          scrollViewRef.current?.getInnerViewNode(),
          (x, y) => {
            scrollViewRef.current.scrollTo({y, animated: true});
            setIsTabClicked('Les Hamburgers');
          },
        );
      }
    });
    // switch (sectionName) {
    //   case 'Les Hamburgers':
    //     hamburgersListRef?.current?.measureLayout(
    //       scrollViewRef.current?.getInnerViewNode(),
    //       (x, y) => {
    //         scrollViewRef.current.scrollTo({y, animated: true});
    //         setIsTabClicked('Les Hamburgers');
    //       },
    //     );
    //     break;
    //   case 'Les Salades':
    //     saladesListRef?.current?.measureLayout(
    //       scrollViewRef.current?.getInnerViewNode(),
    //       (x, y) => {
    //         scrollViewRef.current.scrollTo({y, animated: true});
    //         setIsTabClicked('Les Salades');
    //       },
    //     );
    //     break;
    //   case 'Les Pizzas':
    //     pizzasListRef?.current?.measureLayout(
    //       scrollViewRef.current?.getInnerViewNode(),
    //       (x, y) => {
    //         scrollViewRef.current.scrollTo({y, animated: true});
    //         setIsTabClicked('Les Pizzas');
    //       },
    //     );
    //     break;
    //   default:
    //     break;
    // }
  };
  const handleScroll = (event) => {
    // const flatListCoordinates = flatListRef.current?.measure();
    const scrollViewOffset = event?.nativeEvent?.contentOffset?.y;
    const scrollViewOffsetX = event?.nativeEvent?.contentOffset?.x;

    if (scrollViewOffset > 350) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    handleScroll();
  }, []);

  const dataNavItemFood = useMemo(() => {
    return storeData?.categories?.length > 0
      ? storeData?.categories?.map((c, index) => ({
          id: index,
          name: c.name,
          image: c.image,
        }))
      : [];
  }, [storeData]);

  const renderScrollItemFood = ({item, index}) => {
    return (
      <TouchableOpacity onPress={() => scrollToSection(item.name)}>
        <View style={{flexDirection: 'row'}}>
          <View style={{flexDirection: 'row', alignItems: 'center', gap: 9}}>
            <Image
              source={item.image}
              style={{borderRadius: 28, width: 28, height: 27}}
            />
            <Text
              style={[
                styles.itemName,
                {
                  color: item.name === isTabClicked ? '#2FBF86' : '',
                },
              ]}>
              {item.name}
            </Text>
          </View>
          {index < dataNavItemFood.length - 1 && (
            <View
              style={{
                width: 1,
                height: 27,
                marginHorizontal: 24,
                backgroundColor: '#CAD2CC',
              }}
            />
          )}
        </View>
      </TouchableOpacity>
    );
  };

  const checkUserTableGroupeData = useCallback(async () => {
    return db
      .collection('users')
      .doc(user.uid)
      .get()
      .then(async (data) => {
        const userData = data.data();
        if (userData?.displayName) {
          setClientName(userData?.displayName);
        }
        return userData;
      });
  }, []);
  const getTableGroupeData = useCallback(
    async (uid) => {
      return db
        .collection('groupedorders')
        .doc(uid)
        .get()
        .then(async (data) => {
          const gtDAta = data.data();
          if (gtDAta?.newid) navigation.navigate('Bill');
          if (gtDAta?.newid) navigation.navigate('Cart');
          setGroupeTableData({id: data.id, ...gtDAta});
          return {id: data.id, ...gtDAta};
        });
    },
    [user],
  );

  const checkTableGroupeDataExist = async (id, storeid) => {
    return db
      .collection('groupedorders')
      .doc(id)
      .get()
      .then(async (doc) => {
        if (!doc.exists) {
          return null;
        } else {
          const gtDAta = doc.data();
          console.log('groupedorders', doc.data());
          return {id: doc.id, ...gtDAta};
        }
      });
  };

  const queryFunctionRestauItem = async () => {
    setLoading(true);
    try {
      let storeDoc = db.collection('organisers').doc(params.id);
      // let storeDoc;

      let catDoc = db
        .collectionGroup('categories')
        .where('orgId', '==', params.id);

      let docProds = db
        .collectionGroup('products')
        .where('orgId', '==', params.id);

      const store$ = docData(storeDoc, 'id');

      const categories$ = collectionData(catDoc, 'id');

      const products$ = collectionData(docProds, 'id');

      combineLatest([store$, products$, categories$])
        .pipe(
          map(([store, products, categories]) => ({
            products,
            categories,
            ...store,
          })),
        )
        .subscribe((store) => {
          // if (store.type === 'Restaurant') {
          store.categories.map((category) => {
            category.ref = React.createRef();

            category.data = store.products
              .filter((obj) => {
                return obj.categoryid === category.id;
              })
              .map((data) => ({
                storeid: store.id,
                id: data.id,
                title: data?.name,
                image: data?.image,
                price: data?.price,
                review: data?.review,
                rate: data?.rate || '',
                categoryid: data?.categoryid,
                iscomposite: data?.iscomposite,
                composition: data?.composition,
                description: data?.description,
                isalcoholic: data?.isalcoholic || false,
              }));
          });
          if (__DEV__) {
            console.log('store');
            console.log(store);
          }
          store.categories = store.categories
            .filter((cat) => cat.data.length > 0)
            .sort((a, b) =>
              a.order < b.order ? -1 : b.order < a.order ? 1 : 0,
            );

          setStoreData(store);
        });
    } catch (err) {
      console.log('queryFunctionRestauItem', err);
    }
    setLoading(false);
  };

  const queryFunction = async () => {
    setLoading(true);
    await db
      .collection('organisers')
      .doc(params.id)
      .get()
      .then(async (docGO) => {
        try {
          const storesData = docGO.data();
          setStore(storesData);
          const activeGroupedTable = await checkUserTableGroupeData();
          if (activeGroupedTable.activeGroupedTable) {
            setActiveGroupeTable(activeGroupedTable.activeGroupedTable);

            getTableGroupeData(activeGroupedTable.activeGroupedTable);
          } else if (params.t === 'food' && params.tab) {
            emptyCart();

            const groupStoreTab = storesData?.groupeTable?.find(
              (gt) => gt.tab == params.tab,
            );

            let dataTableGroupe;
            if (groupStoreTab) {
              dataTableGroupe = await checkTableGroupeDataExist(
                groupStoreTab.activeGroupedTable,
                params.id,
              );
            }
            console.log('dataTableGroupe', dataTableGroupe);
            // Groupe table already exist
            if (dataTableGroupe) {
              setActiveGroupeTable(dataTableGroupe.id);
              let usersData = dataTableGroupe?.users;
              usersData.push({
                userId: user.uid,
                products: [],
              });

              const doGroupedOrdersRef = db
                .collection('groupedorders')
                .doc(dataTableGroupe?.id);

              await doGroupedOrdersRef
                .set(
                  {users: JSON.parse(JSON.stringify(usersData))},
                  {merge: true},
                )
                .then(() => {
                  return doGroupedOrdersRef.get();
                })
                .then((doc) => {
                  if (!doc.exists) {
                  } else {
                    setGroupeTableData({id: doc.id, ...doc.data()});
                  }
                })
                .catch((err) => console.log('Error', err));

              await db.collection('users').doc(user.uid).set(
                {
                  activeGroupedTable: dataTableGroupe?.id,
                },
                {merge: true},
              );
            } else {
              // Groupe table doesn't exist
              const groupedOrderParams = {
                users: [
                  {
                    userId: user.uid,
                    products: [],
                  },
                ],
                payementType: null,
                type: 'food',
                storeid: params.id,
                tab: params.tab,
                name: store?.name,
                createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
              };
              // Push it to Firestore
              await db
                .collection('groupedorders')
                .add(groupedOrderParams)
                .then(async (docGt) => {
                  setActiveGroupeTable(docGt.id);
                  setGroupeTableData({id: docGt.id, ...groupedOrderParams});

                  await db.collection('users').doc(user.uid).set(
                    {
                      activeGroupedTable: docGt.id,
                    },
                    {merge: true},
                  );

                  let groupeTableStore = [];

                  if (storesData?.groupeTable?.length > 0) {
                    groupeTableStore = storesData?.groupeTable;
                    groupeTableStore.push({
                      activeGroupedTable: docGt.id,
                      tab: params.tab,
                    });
                  } else
                    groupeTableStore = [
                      {
                        activeGroupedTable: docGt.id,
                        tab: params.tab,
                      },
                    ];

                  await db.collection('organisers').doc(params.id).set(
                    {
                      groupeTable: groupeTableStore,
                    },
                    {merge: true},
                  );
                });
              // setModalVisible(true);
            }
          }
          // test this

          //
        } catch (e) {
          console.log('error', e);
        }
      });
  };

  useEffect(() => {
    if (params?.id) queryFunctionRestauItem();
  }, [params]);

  useEffect(() => {
    if (params?.id) queryFunction();
  }, [params]);

  const navigateFunc = (product) => {
    navigation.navigate('RestaurantItemPage', {product});
  };

  const renderPizzasCard = ({item, index}) => {
    return (
      <HorizontalFoodCard
        key={index}
        imageUri={item.image}
        description={item.description}
        title={item.title}
        review={item.review}
        price={item.price}
        rate={item.rate}
        category={item.category}
        item={item}
        navigateFunc={navigateFunc}
      />
    );
  };

  const recycleGroupedOrder = () => {
    setActiveGroupeTable();
    setGroupeTableData();
    setLoadingGroupTable(false);
  };

  const shareGroupedOrder = async () => {
    try {
      if (!clientName) {
        setModalVisible(true);
        return;
      }
      let link =
        links.webappurl + `RestaurantPage?id=${storeData.id}&t=food&tab=1`;
      let message =
        // `${t('cartBPage.En attente de validation')}`
        // 'Vous avez été invité à partager une commande avec '
        `${t(
          'RestaurantPage.Vous avez été invité à partager une commande avec',
        )}` +
        clientName +
        '.';
      message = message.concat(' ', 'Lien pour procéder : ' + link);
      await Share.share({
        title: message,
        message: message,
      });

      navigation.navigate('RestaurantPage', {
        id: storeData.id,
        t: 'food',
        tab: 1,
      });
    } catch (error) {
      console.log(error.message);
    }
    setLoadingGroupTable(false);
  };

  const removeUSerfromGroupeTable = async () => {
    const callDeleteUSerFromGP = httpsCallable(
      FUNCTIONS,
      'removeGroupOrderFromUser',
    );
    const result = await callDeleteUSerFromGP({userid: user.uid});
    if (result) {
      recycleGroupedOrder();
    }
  };

  const handleLeaveTableGouped = async () => {
    let users = groupeTableData.users;
    users = users.filter((usr) => usr.userId != user.uid);

    const RefGroupOrder = db
      .collection('groupedorders')
      .doc(groupeTableData.id);

    // TODO: Must check that the Grouped Order still exists
    if (users.length > 0) {
      await RefGroupOrder.set({users}, {merge: true}).then(async () => {
        removeUSerfromGroupeTable();
      });
    } else {
      await RefGroupOrder.delete().then(async () => {
        removeUSerfromGroupeTable();
      });
    }
    return;
  };

  const handleGroupTable = async () => {
    setLoadingGroupTable(true);
    if (activeGroupeTable) {
      await handleLeaveTableGouped();
    } else {
      await shareGroupedOrder();
    }
  };

  const handleModalDismiss = () => {
    setModalVisible(false);
  };

  return (
    <>
      {isVisible && (
        <View
          style={{
            backgroundColor: '#FFF',
            boxShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.16)',
            position: 'sticky',
            top: 0,
            zIndex: 1,
            paddingHorizontal: 5,
            alignItems: 'center',
            height: 60,

            paddingTop: 13,
            width: '100%',
          }}>
          <FlatList
            horizontal
            ref={flatListRef}
            style={{marginHorizontal: 0, width: '100%'}}
            data={dataNavItemFood}
            renderItem={renderScrollItemFood}
            thumbTintColor={'#2FBF86'}
            keyExtractor={(item) => item.id}
          />
        </View>
      )}
      <SafeAreaView style={styles.screenContainer}>
        <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
        {activeGroupeTable && (
          <ScreenTitleA
            disableBack={true}
            style={{
              backgroundColor: Colors.blue,
              height: 20,
            }}
            // title={`Tableau des groupes actifs ${params.tab}`}
            title={`${t('RestaurantPage.Tableau des groupes actifs')} ${
              params.tab
            }`}
          />
        )}
        <ScrollView
          ref={scrollViewRef}
          onScroll={handleScroll}
          scrollEventThrottle={16}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}>
          <View>
            <Image
              source={getImgSource(store?.coverImage)}
              resizeMode="cover"
              style={styles.restaurantImage}
            />
          </View>
          <View style={styles.wrapper}>
            <View style={styles.descriptionContainer}>
              <View style={styles.title}>
                <View>
                  <TitleA>{store?.name}</TitleA>
                  <Text style={{color: Colors.darkGray}}>
                    {store?.description}
                  </Text>
                </View>
                <View>
                  <FavoriteSVG
                    fill={Colors.normalGray}
                    style={styles.favIcon}
                  />
                </View>
              </View>
              <View style={styles.description}>
                <View style={{flexShrink: 1}}>
                  <View style={styles.review}>
                    <Text style={{marginRight: 10, color: Colors.normalGray}}>
                      3.7
                    </Text>
                    <StarSVG /> <StarSVG /> <StarSVG />
                    <OutlineStarSVG /> <OutlineStarSVG />
                    <Text style={{marginLeft: 4, color: Colors.normalGray}}>
                      {t('RestaurantPage.(500 avis)')}
                    </Text>
                  </View>
                  <View style={styles.detail}>
                    <View style={styles.icon}>
                      <TimeSVG />
                    </View>
                    <View>
                      <Text style={{color: Colors.normalGray}}>
                        {/* Ouvert jusqu'à 23h00{' '} */}
                        {t('RestaurantPage.Ouvert jusqu à 23h00')}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.detail}>
                    <View style={[styles.icon, {alignSelf: 'flex-start'}]}>
                      <CreditCardSVG />
                    </View>
                    <View
                      style={{
                        flexWrap: 'wrap',
                        flexShrink: 1,
                      }}>
                      <Text
                        style={{
                          color: Colors.normalGray,
                        }}>
                        {t(
                          'RestaurantPage.Paiement par Swille, PassSodexo, Ticket Restaurant, et Bimpli a accepté',
                        )}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={{flexShrink: 0}}>
                  <BackArrowSVG
                    style={styles.backIcon}
                    fill={Colors.normalGray}
                  />
                </View>
              </View>
            </View>
            {!activeGroupeTable && (
              <View style={styles.elipse}>
                <TouchableOpacity onPress={handleEmporterPress}>
                  <View
                    style={[
                      styles.deliveryElipseBack,
                      {
                        backgroundColor: livraisonSelected ? Colors.white : '',
                        transition: 'background-color 0.5s ease-in-out',
                      },
                    ]}>
                    <View style={styles.deliveryElipse}>
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}>
                        <HandDeliverySVG
                          style={{width: 16, height: 16, marginRight: 8}}
                        />
                        <SubheadingA
                          style={{
                            color: Colors.normalGray,
                            fontFamily: 'Bold',
                          }}>
                          {t('RestaurantPage.Livraison')}
                        </SubheadingA>
                      </View>
                      {/* <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}>
                        <Text
                          style={{color: Colors.normalGray, marginRight: 8}}>
                          10-15 mins
                        </Text>
                        <Text style={{color: Colors.normalGray}}>3,50 €</Text>
                      </View> */}
                    </View>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={handleEmporterPress}>
                  <View
                    style={[
                      styles.deliveryElipseBack,
                      {
                        backgroundColor: !livraisonSelected ? Colors.white : '',
                        transition: 'background-color 0.5s ease-in-out',
                      },
                    ]}>
                    <View style={styles.deliveryElipse}>
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}>
                        <ShopSVG
                          style={{width: 20, height: 20, marginRight: 8}}
                        />
                        <SubheadingA
                          style={{
                            color: Colors.normalGray,
                            fontFamily: 'Bold',
                          }}>
                          {t('RestaurantPage.Emporter')}
                        </SubheadingA>
                      </View>
                      {/* <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}>
                        <Text
                          style={{color: Colors.normalGray, marginRight: 8}}>
                          30-40 min
                        </Text>
                        <Text style={{color: Colors.normalGray}}>31,5 km</Text>
                      </View> */}
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
            )}
            <View style={{marginVertical: 10}}>
              <ButtonA onPress={handleGroupTable}>
                {/* <AddUserSVG
                  style={{
                    width: 18,
                    height: 18,
                    marginBottom: -2,
                    marginRight: 10,
                  }}
                />
                {activeGroupeTable
                  ? t('RestaurantPage.Supprimer la table de groupe')
                  : t('RestaurantPage.Tableau de groupe')} */}
                {loadingGroupTable ? (
                  <ActivityIndicator size="small" color="green" />
                ) : (
                  <>
                    <AddUserSVG
                      style={{
                        width: 18,
                        height: 18,
                        marginBottom: -2,
                        marginRight: 10,
                      }}
                    />
                    {activeGroupeTable
                      ? t('RestaurantPage.Supprimer la table de groupe')
                      : t('RestaurantPage.Tableau de groupe')}
                  </>
                )}
              </ButtonA>
            </View>
            {storeData?.categories?.map((category) => {
              return (
                <>
                  <View ref={category.ref} style={{marginTop: 16}}>
                    <TitleBigA>{category.name}</TitleBigA>
                  </View>
                  <FlatList
                    style={{marginBottom: 20}}
                    data={category?.data}
                    showsHorizontalScrollIndicator={false}
                    keyExtractor={(item) => item.id}
                    renderItem={renderPizzasCard}
                  />
                </>
              );
            })}
          </View>
          <ModalA visible={modalVisible} onDismiss={handleModalDismiss}>
            <NameSection
              clientName={clientName}
              handleModalDismiss={handleModalDismiss}
              setClientName={setClientName}
            />
          </ModalA>
          {/* <ModalA visible={modalVisible} onDismiss={handleModalDismiss}>
          <PriceSection
            handleModalDismiss={handleModalDismiss}
            setChecked={setChecked}
            checked={checked}
          />
        </ModalA> */}
        </ScrollView>
      </SafeAreaView>
    </>
  );
};

const mapStateToProps = (state) => {
  const {carts, store, prefs} = state;
  return {carts, store, prefs};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addProduct,
      setStore,
      setLastId,
      setTierMode,
      emptyCart,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantPage);
