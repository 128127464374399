export default {
    "financial": {
        "currency":{
            "symbol": "€",
            "name": ""
        }        
    },
    "general": {
        "status": "Statut",
        "order": "Commande"
    }    
}