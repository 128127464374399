import Svg, {Path} from 'react-native-svg';
import React from 'react';
const CtaSVG = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none">
      <Path
        d="M4.40226 7.95846C4.43283 7.57755 4.60576 7.22213 4.88659 6.96299C5.16743 6.70384 5.53558 6.55998 5.91771 6.56006H15.0818C15.464 6.55998 15.8321 6.70384 16.1129 6.96299C16.3938 7.22213 16.5667 7.57755 16.5973 7.95846L17.2076 15.5585C17.2243 15.7676 17.1976 15.978 17.1291 16.1763C17.0606 16.3746 16.9518 16.5566 16.8095 16.7109C16.6673 16.8651 16.4946 16.9881 16.3024 17.0723C16.1102 17.1565 15.9027 17.2 15.6929 17.2001H5.30667C5.09685 17.2 4.88931 17.1565 4.69713 17.0723C4.50495 16.9881 4.33228 16.8651 4.18999 16.7109C4.04771 16.5566 3.93889 16.3746 3.87039 16.1763C3.80189 15.978 3.7752 15.7676 3.79198 15.5585L4.40226 7.95846Z"
        stroke="#F7F6F0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M13.54 8.84003V5.04001C13.54 4.23375 13.2197 3.46051 12.6496 2.8904C12.0795 2.32029 11.3062 2 10.5 2C9.69371 2 8.92047 2.32029 8.35036 2.8904C7.78025 3.46051 7.45996 4.23375 7.45996 5.04001V8.84003"
        stroke="#F7F6F0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
export default CtaSVG;
