/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

// import dependencies
import React, {Component} from 'react';
import {
  I18nManager,
  ScrollView,
  Linking,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import Color from 'color';
import {FontAwesome as FAIcon} from '@expo/vector-icons';
//import Swiper from 'react-native-swiper';
import Swiper from 'react-native-web-swiper';
// import components
import Avatar from '../../components/avatar/Avatar';
import GradientContainer from '../../components/gradientcontainer/GradientContainer';
import {
  Caption,
  Heading5,
  Subtitle1,
  Subtitle2,
} from '../../components/text/CustomText';
import TouchableItem from '../../components/TouchableItem';

// import colors
import Colors from '../../theme/colors';

// AboutUsA Config
const isRTL = I18nManager.isRTL;
const FACEBOOK_ICON = 'facebook';
const INSTAGRAM_ICON = 'instagram';
const YELP_ICON = 'yelp';
const OVERLAY_COLOR = 'rgba(185, 0, 57, 0.4)';
const AVATAR_SIZE = 54;

// AboutUsA Styles
const styles = StyleSheet.create({
  pb6: {paddingBottom: 6},
  pl8: {paddingLeft: 8},
  screenContainer: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  content: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: 16,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  swiperContainer: {
    width: '100%',
    height: 252,
  },
  paginationStyle: {
    bottom: 14,
    transform: [{scaleX: isRTL ? -1 : 1}],
  },
  dot: {backgroundColor: `${Color(Colors.white).alpha(0.48)}`},
  activeDot: {backgroundColor: Colors.white},
  bgImg: {
    flex: 1,
    resizeMode: 'cover',
  },
  swiperContent: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 16,
  },
  avatarWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    width: AVATAR_SIZE + 2,
    height: AVATAR_SIZE + 2,
    borderRadius: (AVATAR_SIZE + 4) / 2,
    backgroundColor: Colors.white,
  },
  info: {
    fontWeight: '500',
  },
  infoText: {
    color: Colors.white,
    textShadowColor: 'rgba(0, 0, 0, 0.75)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 4,
    textAlign: 'left',
  },
  caption: {
    color: `${Color(Colors.white).alpha(0.87)}`,
    textShadowColor: 'rgba(0, 0, 0, 0.75)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 4,
    textAlign: 'left',
  },
  description: {
    maxWidth: '80%',
  },
  phone: {
    marginTop: 8,
    color: Colors.primaryColor,
  },
  social: {
    flexDirection: 'row',
    marginTop: 8,
    fontWeight: '500',
    marginBottom: 20,
  },
  socialButton: {
    margin: 8,
    borderRadius: 22,
    backgroundColor: Colors.primaryColor,
  },
  socialIconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 44,
    height: 44,
  },
  footer: {
    width: '100%',
    backgroundColor: Colors.background,
  },
  footerButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 52,
  },
  footerButtonText: {
    fontWeight: '500',
    color: Colors.primaryColor,
  },
});

// AboutUsA
export default class HelpA extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  goBack = () => {
    const {navigation} = this.props;
    navigation.goBack();
  };

  callPhone = () => {
    Linking.openURL(`tel:${1601234567}`);
  };

  render() {
    return (
      <SafeAreaView style={styles.screenContainer}>
        <StatusBar
          backgroundColor={Colors.statusBarColor}
          barStyle="dark-content"
        />
        <ScrollView>

        
          <View style={styles.content}>          
            <View  style={{marginHorizontal: 16, paddingTop:50}}>
              
              <Text
                style={{
                  textAlign: 'center',
                  color: '#000000',
                  fontSize: 20,
                  alignSelf: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlignVertical: 'center',
                  alignContent: 'center',
                }}>
                Pour tout problème, pensez à nous contacter.
              </Text>

            </View>

            

            <View style={styles.center}>
              
              <Subtitle2 style={{paddingTop: 50}}>ENVOYEZ NOUS UN MAIL</Subtitle2>
              <Heading5 style={styles.phone} onPress={this.callPhone}>
                instadrinkpro@gmail.com
              </Heading5>
            </View>   
            

            <View style={styles.center}>
              
              <Subtitle2>APPELEZ NOUS</Subtitle2>
              <Heading5 style={styles.phone} onPress={this.callPhone}>
                06 26 90 25 96
              </Heading5>
            </View>            
          </View>

          <View style={styles.footer}>            
            <TouchableItem>
              <View style={styles.footerButton}>
                <Text style={styles.footerButtonText}>www.instadrink.io</Text>                
              </View>
            </TouchableItem>
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
