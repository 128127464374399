import React, {useState, useEffect, useContext} from 'react';
import {
  FlatList,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  View,
  ActivityIndicator,
} from 'react-native';
import OrderItem from '../../components/cards/OrderItemA';
import Colors from '../../theme/colors';
import {db} from '../../services/firebase';
import {AuthContext} from '../../contexts/context';
import firebase from 'firebase/compat/app';
import OrderItemC from '../../components/cards/OrderItemC';
import NoOrdersSVG from '../../components/svgs/NoOrdersSVG';
import TitleA from '../../components/titleA/TitleA';
import BodyA from '../../components/bodyA/BodyA';
import NoTicketsSVG from '../../components/svgs/NoTicketsSVG';
import {useTranslation} from 'react-i18next';

const styles = StyleSheet.create({
  separator: {
    // width: '100%',
    marginRight: 35,
    height: 1,
    backgroundColor: Colors.lightGray,
  },
  screenContainer: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  container: {
    flex: 1,
    backgroundColor: '#efefef',
  },
  productsContainer: {
    paddingVertical: 8,
  },
});

const Separator = () => {
  return <View style={styles.separator} />;
};

const OrdersDList = (props) => {
  const {t} = useTranslation();
  // console.log('props.tabtype ===>', props.tabtype);

  const {user} = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const loadOrders = async () => {
      setIsLoading(true);
      console.log('user', user.uid);
      try {
        let doc = db.collection('orders').where('clientid', '==', user.id);

        if (props.tabtype === 'old') {
          doc = doc.where('status', 'in', ['Delivered', 'canceled']);
        } else {
          doc = doc.where('status', 'not-in', ['Delivered', 'canceled']);
        }
        const querySnapshot = await doc.get();

        const data = querySnapshot.docs.map((doc) => {
          const updatedAt = doc.data().updatedAt?.toDate();
          const formattedDate = `${updatedAt
            .getDate()
            .toString()
            .padStart(2, '0')}/${(updatedAt.getMonth() + 1)
            .toString()
            .padStart(2, '0')}/${updatedAt.getFullYear()}`;
          console.log('Here is the data', doc.data());

          return {
            id: doc.id,
            orderId: doc.id,
            orderNumber: doc.data().orderNumber,
            orderDate: formattedDate,
            orderStatus: doc.data().status,
            orderItems: doc.data().products,
          };
        });
        data.sort((a, b) =>
          a.orderDate > b.orderDate ? -1 : b.orderDate > a.orderDate ? 1 : 0,
        );
        setOrders(data);
      } catch (error) {
        console.log(`Encountered error: ${error}`);
      } finally {
        setIsLoading(false);
      }
    };

    loadOrders();
  }, [user, props.tabtype]);

  console.log('orders ====>', orders);
  // Function to get the month name
  const getMonthName = (monthIndex) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return months[monthIndex];
  };

  const navigateTo = (screen, id) => () => {
    try {
      const {navigation} = props;
      navigation.navigate(screen, {id: id});
    } catch (error) {
      db.collection('errors')
        .add({
          source: 'selecting order',
          message: `${error.message}`,
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        })
        .then((docRef) => {
          console.log('Document written with ID: ', docRef.id);
        })
        .catch((error) => {
          console.error('Error adding document: ', error);
        });
    }
  };

  const keyExtractor = (item) => item.orderNumber.toString();

  const renderItem = ({item, index}) => {
    console.log('item', item);
    return (
      <OrderItemC
        key={index}
        orderId={item.orderId}
        orderNumber={item.orderNumber}
        orderDate={item.orderDate}
        orderItems={item.orderItems}
        orderStatus={item.orderStatus}
        onPress={navigateTo('Order', item.id)}
      />
    );
  };

  console.log('orders', orders);

  if (isLoading) {
    return (
      <View style={{paddingTop: 150}}>
        <ActivityIndicator size="large" color="green" />
      </View>
    );
  }

  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar
        backgroundColor={Colors.statusBarColor}
        barStyle="dark-content"
      />

      <View style={styles.container}>
        {orders.length === 0 ? (
          <View
            style={{
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 80,
            }}>
            <NoTicketsSVG />
            <TitleA
              style={{
                color: Colors.darkGray,
                marginVertical: 26,
              }}>
              {/* No tickets */}
              {t('OrderDList.Pas de billets')}
            </TitleA>
            <BodyA
              style={{
                color: Colors.normalGray,
                fontFamily: 'SemiBold',
              }}>
              {/* You don't have any tickets yet */}
              {t('OrderDList.Vous n avez pas encore de billets')}
            </BodyA>
          </View>
        ) : (
          <FlatList
            data={orders}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
            contentContainerStyle={styles.productsContainer}
            ItemSeparatorComponent={Separator}
            showsVerticalScrollIndicator={false}
          />
        )}
      </View>
    </SafeAreaView>
  );
};

export default OrdersDList;
