import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const ProfileSVG = (props) => {
  const {style, stroke, ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 26 25"
      style={style}
      {...restProps}>
      <Path
        stroke={stroke}
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M4.167 18.75a4.167 4.167 0 0 1 4.166-4.167h8.334a4.167 4.167 0 0 1 4.166 4.167 2.083 2.083 0 0 1-2.083 2.083H6.25a2.083 2.083 0 0 1-2.083-2.083Z"
      />
      <Path
        stroke={stroke}
        strokeWidth={1.5}
        d="M12.5 10.417a3.125 3.125 0 1 0 0-6.25 3.125 3.125 0 0 0 0 6.25Z"
      />
    </Svg>
  );
};
export default ProfileSVG;
