import {StyleSheet} from 'react-native';

import Colors from '../../theme/colors';

const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  contentContainerStyle: {
    flex: 1,
  },
  description: {
    fontFamily: 'Regular',
    fontSize: 14,
    lineHeight: 21,
    letterSpacing: 0.56,
    color: Colors.black,
  },
  buttonText: {
    color: Colors.background,
    textAlign: 'center',
    fontFamily: 'SemiBold',
    fontSize: 16,
    letterSpacing: 0.64,
    textTransform: 'capitalize',
  },
  textInput: {
    width: '100%',
    height: 46,
    borderRadius: 8,
    backgroundColor: Colors.white,
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderWidth: 1,
    borderColor: '#D2D5DA',
    shadowColor: 'black',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.05,
    shadowRadius: 2,
    elevation: 1,
    marginVertical: 10,
  },
  coverImage: {
    width: '100%',
    height: 180,
  },
  container: {
    paddingVertical: 16,
    paddingHorizontal: 25,
  },
  eventNameContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  favoriteTouchableItem: {
    marginLeft: 'auto',
  },

  favorite: {
    width: 30,
    height: 27,
  },
  cardContent: {
    flexDirection: 'column',
    // paddingTop: 15,
    // paddingBottom: 20,
    // paddingHorizontal: 20,
  },
  TitleRow: {},
  contentContainer: {
    marginTop: 10,
  },
  bodyRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  rowIcon: {
    width: 21,
    height: 21,
    marginRight: 10,
  },
  scrollView: {
    width: '80%',
  },
  eventName: {
    color: Colors.darkGreen,
  },
  bodyA: {
    color: Colors.normalGray,
  },
  ownerDetails: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    // height: 64,
    // width: 64,
    // borderRadius: 32,
  },
  ownerImage: {
    height: 64,
    width: 64,
    borderRadius: 32,
  },
  button: {
    backgroundColor: 'rgba(0,0,0, 0.0)',
    borderWidth: 1,
    height: 46,
    width: 123,
    borderColor: Colors.normalGray,
  },
  cartContainer: {
    marginVertical: 10,
    paddingVertical: 20,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: Colors.lightGray,
  },
  cartInput: {},
  title: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 7,
  },
  input: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: Colors.white,
    height: 60,
    borderRadius: 15,
    shadowColor: 'black',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 2,
    paddingLeft: 20,
    paddingRight: 10,
    paddingVertical: 13,
  },
  adderContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '40%',
  },
  vibe: {
    backgroundColor: 'rgba(0,0,0, 0.0)',
    borderWidth: 1,
    height: 46,
    borderColor: Colors.normalGray,
    borderRadius: 20,
    paddingHorizontal: 16,
    marginRight: 16,
  },
  total: {
    marginVertical: 10,
    paddingVertical: 20,
    paddingHorizontal: 16,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: Colors.lightGray,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
});

export default styles;
