import React, {useMemo, useState} from 'react';
import styles from './GroupOrderStyles';
import Colors from '../../theme/colors';
import {View, FlatList} from 'react-native';
import TitleBigA from '../titleBigA/TitleBigA';
import SubheadingA from '../subheadingA/SubheadingA';
import TitleA from '../titleA/TitleA';
import BodyA from '../bodyA/BodyA';
import ButtonA from '../buttonA/ButtonA';
import CartSVG from '../svgs/CartSVG';
import TrashSVG from '../svgs/TrashSVG';
import locales from '../../config/locales';

const Separator = () => {
  return <View style={styles.separator} />;
};

const GroupOrder = ({
  groupedOrderData,
  renderConfirmFriendGroupedOrderButton,
  promoCodeDiscount,
}) => {
  const eventKeyExtractor = (item, index) =>
    item && item.id ? item.id : index.toString();

  const renderShowCartItem = ({item}) => {
    let isDiscounted = false;

    if (promoCodeDiscount?.products.some((e) => e == item.id)) {
      isDiscounted = true;
    }

    // let outDated = false;
    // let comming = false;
    // var today = new Date();

    // // Convert the start and end dates to JavaScript Date objects
    // var startDate = new Date(
    //   filterTicket[0]?.SellTimeLimit?.start?.seconds * 1000,
    // );
    // var endDate = new Date(filterTicket[0]?.SellTimeLimit?.end?.seconds * 1000);

    // // Check if today's date is between the start and end dates
    // if (endDate <= today) {
    //   outDated = true;
    // } else if (startDate >= today) {
    //   comming = true;
    // }
    return (
      <>
        <View style={styles.row}>
          <View style={{flexDirection: 'row'}}>
            <BodyA
              style={{
                color: Colors.normalGray,
                marginRight: 20,
              }}>
              {item.quantity}
            </BodyA>
            <BodyA
              style={{
                color: Colors.normalGray,
              }}>
              {item.name}
            </BodyA>
          </View>

          <View>
            {isDiscounted && (
              <BodyA
                style={{
                  color: Colors.red,
                }}>
                {`€ ${
                  item.price.toFixed(2) -
                  item.price.toFixed(2) * promoCodeDiscount?.discount * 0.01
                }`}
              </BodyA>
            )}

            <BodyA
              style={{
                color: Colors.normalGray,
                textDecorationLine: isDiscounted ? 'line-through' : 'none',
              }}>
              {`€ ${item.price.toFixed(2)}`}
            </BodyA>
          </View>
        </View>
      </>
    );
  };

  const listProduct = useMemo(() => {
    return groupedOrderData?.users?.map((user) => user.products).flat(1);
  }, [groupedOrderData]);

  const updateTotalAmount = useMemo(() => {
    let total = 0.0;
    let totalAfterDiscount = 0.0;
    let discount = false;

    listProduct?.forEach((product) => {
      let {price, isFree} = product;
      const {quantity, iscomposite, composition} = product;
      var compositionprice = iscomposite
        ? composition.reduce(function (a, b) {
            return a + b.price;
          }, 0)
        : 0;
      total += ((isFree ? 0 : price) + compositionprice) * quantity;
      const finalPrice = isFree ? 0 : price;
      if (promoCodeDiscount) {
        const discountFound = promoCodeDiscount?.products.some(
          (e) => e == product.id,
        );
        if (discountFound) {
          totalAfterDiscount +=
            (finalPrice -
              price * promoCodeDiscount?.discount * 0.01 +
              compositionprice) *
            quantity;
        } else {
          totalAfterDiscount += (finalPrice + compositionprice) * quantity;
        }
      }
    });
    // // If price with discount is same than without > discounts removed
    if (totalAfterDiscount > 0) discount = true;

    return {
      total,
      totalAfterDiscount,
      discount,
    };
  }, [promoCodeDiscount, listProduct]);

  return (
    <View style={{marginTop: 20}}>
      <TitleBigA>Group order</TitleBigA>

      <View style={styles.container}>
        {groupedOrderData?.users?.map((elt) => (
          <View>
            <View style={styles.row}>
              <SubheadingA>
                {' '}
                {elt.userName} {elt.status}
              </SubheadingA>
              <View>
                {updateTotalAmount.discount && (
                  <TitleA style={{color: Colors.red}}>
                    {locales.financial.currency.symbol}{' '}
                    {updateTotalAmount.totalAfterDiscount}
                  </TitleA>
                )}
                <TitleA
                  style={{
                    color: Colors.darkGray,

                    textDecorationLine: updateTotalAmount.discount
                      ? 'line-through'
                      : 'none',
                  }}>
                  {locales.financial.currency.symbol} {updateTotalAmount.total}
                </TitleA>
              </View>
            </View>
            {elt?.products?.length > 0 && (
              <FlatList
                data={elt?.products}
                showsHorizontalScrollIndicator={false}
                alwaysBounceHorizontal={false}
                keyExtractor={eventKeyExtractor}
                renderItem={renderShowCartItem}
                // contentContainerStyle={styles.contentContainerStyle}
                ItemSeparatorComponent={Separator}
              />
            )}

            <View style={styles.row}>
              {renderConfirmFriendGroupedOrderButton(elt)}
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

export default GroupOrder;
