import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';

const BelgiumFlagSVG = (props) => {
  const {style, ...restProps} = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      style={style}
      {...restProps}>
      <G clipPath="url(#a)">
        <Path
          fill="#ED4C5C"
          d="M23.25 12c0-4.913-3.113-9.075-7.5-10.613v21.226c4.387-1.538 7.5-5.7 7.5-10.613Z"
        />
        <Path
          fill="#3E4347"
          d="M.75 12c0 4.913 3.15 9.075 7.5 10.613V1.387C3.9 2.925.75 7.087.75 12Z"
        />
        <Path
          fill="#FFE62E"
          d="M15.75 1.387A11.248 11.248 0 0 0 12 .75c-1.313 0-2.588.225-3.75.637v21.226c1.162.412 2.438.637 3.75.637 1.313 0 2.588-.225 3.75-.637V1.387Z"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h24v24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
export default BelgiumFlagSVG;
