import * as React from 'react';
import Svg, {Circle, G, Path, Defs, ClipPath} from 'react-native-svg';
const PenSVG = (props) => {
  const {...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      width={32}
      height={32}
      {...restProps}>
      <Circle cx={16} cy={16} r={16} fill="#212E71" />
      <G clipPath="url(#a)">
        <Path
          fill="#fff"
          d="m18.69 9.604-4.976 4.976a19.319 19.319 0 0 0-4.609 7.414.743.743 0 0 0 .18.768l.002.003a.743.743 0 0 0 .768.18 19.317 19.317 0 0 0 7.415-4.61l4.975-4.976a2.655 2.655 0 1 0-3.755-3.755Zm-1.897 8.054a18.368 18.368 0 0 1-6.639 4.237 18.366 18.366 0 0 1 4.237-6.64l2.964-2.963 2.402 2.402-2.964 2.964Zm4.976-4.975-1.335 1.335-2.403-2.402 1.335-1.336a1.7 1.7 0 1 1 2.403 2.403Z"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M8.348 8.348h15.304v15.304H8.348z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
export default PenSVG;
