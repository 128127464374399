import React from 'react';
import TabBadgeIcon from '../components/navigation/TabBadgeIcon';
import { connect } from 'react-redux';
const TabIcon = (props) => {
    return (
      <TabBadgeIcon
        name={`cart${props.focused ? '' : '-outline'}`}
        badgeCount={props.cart.products.length}
        size={props.size}
        {...props}
      />
    )
  }
  const mapStateToProps = (state) => {
    const { cart } = state
    return { cart }
  };
  
export default connect(mapStateToProps, null)(TabIcon)
  