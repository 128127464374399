import React, {useState} from 'react';
import {View} from 'react-native';

import styles from './CalendarAStyles';
import Colors from '../../theme/colors';
import {ScrollView} from 'react-native-gesture-handler';
import SubheadingA from '../subheadingA/SubheadingA';
import TitleA from '../titleA/TitleA';
import TouchableItem from '../TouchableItem';
import {ClipPath} from 'react-native-svg';
import {useTranslation} from 'react-i18next';

function getWeekDatesUntilEndOfMonth() {
  const today = new Date();
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
  endOfMonth.setDate(endOfMonth.getDate());
  const weekDates = [];

  while (today <= endOfMonth) {
    weekDates.push(new Date(today));
    today.setDate(today.getDate() + 1);
  }
  return weekDates;
}

const dayMonth = getWeekDatesUntilEndOfMonth();

function getMonthUntilEndOfYear() {
  const today = new Date();
  const endOfYear = new Date(today.getFullYear() + 1, 0, 0);
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const monthDates = [];

  while (startOfMonth <= endOfYear) {
    const lastDayOfMonth = new Date(
      startOfMonth.getFullYear(),
      startOfMonth.getMonth() + 1,
      0,
    );
    monthDates.push({
      firstDay: new Date(startOfMonth),
      lastDay: new Date(lastDayOfMonth),
    });
    startOfMonth.setMonth(startOfMonth.getMonth() + 1);
  }

  return monthDates;
}

const CalendarA = ({setSelectedDate, setMonthData}) => {
  const {t} = useTranslation();

  const currentDate = new Date();
  const currentDay = currentDate.getDate();

  const weekDates = getWeekDatesUntilEndOfMonth();
  const weekDaysList = weekDates.map((date) => {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    const dateStr = date.toLocaleDateString(undefined, options);
    const dayNumber = date.getDay();
    const day = date.getDate();

    const daysOfWeek = [
      t('calenderA.Dim'),
      t('calenderA.Lun'),
      t('calenderA.Mar'),
      t('calenderA.Mer'),
      t('calenderA.Jeu'),
      t('calenderA.Ven'),
      t('calenderA.Sam'),
    ];
    const dayText = daysOfWeek[dayNumber];

    return {
      date: dateStr,
      dateNumber: day,
      day: dayText,
    };
  });

  function getDaysInMonth(date) {
    const daysInMonth = [];
    const lastDayOfMonth = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0,
    ).getDate();

    for (let i = 1; i <= lastDayOfMonth; i++) {
      daysInMonth.push({
        date: new Date(date.getFullYear(), date.getMonth(), i),
        dateNumber: i,
        day: new Date(
          date.getFullYear(),
          date.getMonth(),
          i,
        ).toLocaleDateString('en-US', {weekday: 'short'}),
      });
    }

    return daysInMonth;
  }

  const monthTest = getMonthUntilEndOfYear();
  const monthListYear = monthTest.map((date) => {
    const monthNumber = date.firstDay.getMonth() + 1;

    const MontName = [
      t('calenderA.Jan'),
      t('calenderA.Fev'),
      t('calenderA.Marc'),
      t('calenderA.Avr'),
      t('calenderA.Mai'),
      t('calenderA.Jui'),
      t('calenderA.Jul'),
      t('calenderA.Aoû'),
      t('calenderA.Sep'),
      t('calenderA.Oct'),
      t('calenderA.Nov'),
      t('calenderA.Dec'),
    ];
    const month = MontName[date.firstDay.getMonth()];
    return {
      date,
      dateNumberMonth: monthNumber,
      month,
      firstDay: date.firstDay,
      lastDay: date.lastDay,
    };
  });

  const [selectedMonth, setSelectedMonth] = useState(null);

  const [selectedDateIndex, setSelectedDateIndex] = useState(currentDay);
  const handleDateSelection = (index, value) => {
    if (typeof index === 'number') {
      const selectedDate = new Date(currentDate); // Clone the current date
      selectedDate.setDate(selectedDate.getDate() + index - currentDay);
      setSelectedDate(selectedDate);
      setSelectedDateIndex(index);
      setSelectedMonth('');
      setMonthData('');
    } else {
      // setSelectedMonth(value.month);
      setSelectedDate(value);
      setMonthData(index);

      setSelectedMonth(index);
      setSelectedDateIndex(currentDate);
    }
  };
  const AllDataDate = weekDaysList.concat(monthListYear);

  return (
    <View style={{height: 60}}>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        <View style={styles.container}>
          {AllDataDate.map((value, index) => (
            <TouchableItem
              onPress={() =>
                handleDateSelection(value.dateNumber || value.month, value)
              }
              style={styles.col}
              key={index}>
              <View style={{...styles.row1, paddingTop: value.month ? 25 : 0}}>
                <SubheadingA
                  style={{
                    textTransform: 'uppercase',
                    color:
                      value.month && value.month === selectedMonth
                        ? Colors.white
                        : Colors.normalGray,
                    textAlign: value.month ? 'center' : null,
                    fontWeight: 'bold',
                    fontSize: value.month ? 17 : 14,
                    marginLeft: value.month ? 14 : 0,
                    backgroundColor:
                      value.month && value.month === selectedMonth
                        ? Colors.darkGreen
                        : 'transparent',
                    padding:
                      value.month && value.month === selectedMonth ? 6 : 0,

                    borderRadius:
                      value.month && value.month === selectedMonth
                        ? '100%'
                        : '',
                  }}>
                  {value.month ? value.month : value.day}
                </SubheadingA>
              </View>

              <View
                style={{
                  backgroundColor:
                    selectedDateIndex && selectedDateIndex === value.dateNumber
                      ? Colors.darkGreen
                      : 'transparent',
                  borderRadius: 50,
                  width: 33,
                  height: 33,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <TitleA
                  style={{
                    color:
                      selectedDateIndex === value.dateNumber
                        ? Colors.white
                        : Colors.normalGray,
                  }}>
                  {value.dateNumber}
                </TitleA>
              </View>
            </TouchableItem>
          ))}
        </View>
      </ScrollView>
    </View>
  );
};

export default CalendarA;
