import * as React from 'react';
import Svg, {G, Defs, ClipPath, Path} from 'react-native-svg';

const StarSVG = (props) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="none"
      width={14}
      height={13}
      viewBox="0 0 14 13"
      {...props}>
      <G clipPath="url(#a)">
        <Path
          fill="#FFB400"
          d="M13.08 5.087a.494.494 0 0 0-.4-.34L9.067 4.22 7.447.947a.52.52 0 0 0-.894 0L4.933 4.22l-3.613.527a.493.493 0 0 0-.4.34.5.5 0 0 0 .12.513l2.627 2.547-.62 3.6a.507.507 0 0 0 .2.493.5.5 0 0 0 .526.033L7 10.573l3.233 1.7c.072.04.152.06.234.06a.527.527 0 0 0 .293-.093.508.508 0 0 0 .2-.493l-.627-3.6 2.62-2.547a.5.5 0 0 0 .127-.513Z"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h14v13H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default StarSVG;
