import React from "react"
import Svg, {Path , Circle} from 'react-native-svg';

const RadioIcon = ({ checked, onClick }) => {
    return (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        onClick={onClick}
        style={{ cursor: 'pointer' }}
      >
        <Circle cx="10.5" cy="10.5" r="9.5" stroke="#77838F" />
        {checked && <circle cx="10.5" cy="10.5" r="6" fill="#2FBF86" />}
      </Svg>
    );
  };
  export default RadioIcon
  