import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  Fragment,
  useRef,
} from 'react';
import styles from './CartBStyles';
import axios from 'axios';
import InfoModal from '../../components/modals/InfoModal';
import Button from '../../components/buttons/Button';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  addProduct,
  // emptyCart,
  setEvent,
  removeProductByID,
  // setReferral,
  addPromoCode,
  remPromoCode,
  syncCart,
  removeCommandeGroupe,
  emptyCart,
} from '../../reducers/cartactions';

import {
  FlatList,
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  TextInput,
  View,
  Platform,
  Share,
  ActivityIndicator,
} from 'react-native';
import Colors from '../../theme/colors';
// import Order from './order/Order';
import {collection, getDocs, query, where} from 'firebase/firestore';
import {db, FUNCTIONS} from '../../services/firebase';
import {httpsCallable} from 'firebase/functions';
import TitleA from '../../components/titleA/TitleA';
import TitleBigA from '../../components/titleBigA/TitleBigA';
import SubheadingA from '../../components/subheadingA/SubheadingA';
import ItemCounterA from '../../components/itemCounterA/ItemCounterA';
import BodyA from '../../components/bodyA/BodyA';
import TimeSVG from '../../components/svgs/TimeSVG';
import TouchableItem from '../../components/TouchableItem';
import TrashSVG from '../../components/svgs/TrashSVG';
import PromoCodeSVG from '../../components/svgs/PromoCodeSVG';
import ButtonA from '../../components/buttonA/ButtonA';
import AddUserSVG from '../../components/svgs/AddUserSVG';
import CartSVG from '../../components/svgs/CartSVG';
import RemoveUserSVG from '../../components/svgs/RemoveUserSVG';
import ModalA from '../../components/modalA/ModalA';
import MinusSVG from '../../components/svgs/MinusSVG';
import PlusSVG from '../../components/svgs/PlusSVG';
import {AuthContext} from '../../contexts/context';
import NoOrdersSVG from '../../components/svgs/NoOrdersSVG';
import locales from '../../config/locales';
import EmptyState from '../../components/emptystate/EmptyState';
import Icon from '../../components/icon/Icon';
import getEnvVars from '../../config';
import {collectionData, docData} from 'rxfire/firestore';
import {map, tap, switchMap, filter} from 'rxjs/operators';
import {Heading6, Subtitle1} from '../../components/text/CustomText';
import GroupOrder from '../../components/groupOrder/GroupOrder';
import RestaurantCart from '../../components/RestaurantCart/RestaurantCart';
import RestaurantUserCommand from '../../components/RestaurantUserCommand/RestaurantUserCommand';
import AppLoading from 'expo-app-loading';
import Constants from 'expo-constants';
import {loadStripe} from '@stripe/stripe-js';
import {useTranslation} from 'react-i18next';
import {letterSpacing} from 'styled-system';

const Separator = () => {
  return <View style={styles.separator} />;
};
const SeparatorFood = () => {
  return <View style={styles.separatorFood} />;
};
const {paiement, api, links} = getEnvVars();
const stripePromise = loadStripe(paiement.publishableKey);

const IOS = Platform.OS === 'ios';
const PLUS_ICON = IOS ? 'ios-person-remove' : 'md-person-remove';
const ADD_ICON = IOS ? 'ios-person-add' : 'md-person-add';

const CHECKMARK_ICON =
  Platform.OS === 'ios'
    ? 'ios-checkmark-circle-outline'
    : 'md-checkmark-circle-outline';

const FAIL_ICON =
  Platform.OS === 'ios'
    ? 'ios-close-circle-outline'
    : 'md-close-circle-outline';

const NameSection = ({handleModalDismiss, setClientName, clientName}) => {
  const {user} = useContext(AuthContext);
  const [name, setName] = useState(clientName);

  const ChangeClientName = async () => {
    await db
      .collection('users')
      .doc(user.uid)
      .set(
        {
          displayName: name,
        },
        {merge: true},
      )
      .then(() => {
        setClientName(name);
        handleModalDismiss();
      });
  };

  return (
    <View>
      <TitleA style={{marginBottom: 10, paddingHorizontal: 10}}>
        Nom d'utilisateur
      </TitleA>
      <SubheadingA style={{marginBottom: 10, paddingHorizontal: 10}}>
        Définissez le nom d'utilisateur sur votre compte
      </SubheadingA>
      <TextInput
        onChange={(e) => setName(e.target.value)}
        value={name}
        placeholder="Indiquez le nom..."
        maxLength={50}
        style={styles.textInput}
      />
      <ButtonA
        onPress={() => {
          ChangeClientName();
        }}
        disabled={!name}
        style={{marginVertical: 20}}>
        Changer
      </ButtonA>

      <ButtonA
        style={styles.cancelButton}
        color={Colors.darkGreen}
        onPress={handleModalDismiss}>
        Annuler
      </ButtonA>
    </View>
  );
};

const CartB = ({
  addProduct,
  removeProductByID,
  cart,
  navigation,
  addPromoCode,
  remPromoCode,
  syncCart,
  store,
  commandeGroupe,
  removeCommandeGroupe,
  emptyCart,
  //setEvent,
}) => {
  const {t, i18n} = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const eventId = useMemo(() => {
    if (cart?.products?.length > 0) {
      return cart.products[0].eventId;
    } else return null;
  }, [cart]);

  const {user} = useContext(AuthContext);

  const [showGroupOrder, setShowGroupOrder] = useState(false);
  const [PromoCodeModalVisible, setPromoCodeModalVisible] = useState(false);
  const [promoCodeValue, setPromoCodeValue] = useState('');
  const [promoCode, setPromoCode] = useState(false);
  const [invalidPromoCode, setInvalidPromoCode] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [promoCodeDiscount, setPromoCodeDiscount] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalAfterDiscount, setTotalAfterDiscount] = useState(0);
  const [event, setEvent] = useState();

  const [infoModal, setInfoModal] = useState({
    infoModalTitle: '',
    infoModalMessage: '',
    infoModalIcon: FAIL_ICON,
    infoModalVisible: false,
  });

  //group order
  const [isInActiveGroupedOrder, setIsInActiveGroupedOrder] = useState(false);
  const [groupedOrderData, setGroupedOrderData] = useState(null);
  const [groupedOrderIsOwner, setGroupedOrderIsOwner] = useState(false);
  const [totalPriceShared, setTotalPriceShared] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  const [friendCartStatus, setFriendCartStatus] = useState('draft');
  //const [shouldCreateOrder, setShouldCreateOrder] = useState(false);
  const [clientName, setClientName] = useState('');
  const [show, setShow] = useState();
  // restaurant state
  const [currentUserData, setCurrentUserData] = React.useState();
  const [groupeTableData, setGroupeTableData] = React.useState();
  const [activeGroupeTable, setActiveGroupeTable] = useState();
  const [listMenuReady, setListMenuReady] = useState([]);
  const [isMinimumPerOrderNotRespect, setIsMinimumPerOrderNotRespect] =
    useState(false);

  let unsubscribeListener = useRef(null);
  let unsubscribeListenerUser = useRef(null);

  let isTicket = useMemo(
    () => cart?.products.some((p) => p.tier == 'ticket'),
    [cart],
  );
  let isFood = useMemo(
    () => cart?.products.some((p) => p.tier == 'food'),
    [cart],
  );
  const calculerRemise = (prixInitial, tauxRemise) => {
    const remise = (prixInitial * tauxRemise) / 100;
    const prixFinal = prixInitial - remise;
    return prixFinal;
  };
  const handleGroupOrder = () => {
    setShowGroupOrder(true);
  };
  const handleCancelGroupOrder = () => {
    setShowGroupOrder(false);
  };

  const handleModalOpen = () => {
    setPromoCodeModalVisible(true);
  };

  const handleModalDismiss = () => {
    setPromoCodeModalVisible(false);
    setInvalidPromoCode(false);
    setPromoCodeValue('');
  };

  const handleNavigation = () => {
    navigation.navigate('GroupOrder', {
      eventId: eventId,
      clientName: clientName ? clientName : '',
      isInActiveGroupedOrder: isInActiveGroupedOrder,
      title: event?.title,
    });
  };

  // Promo Code
  const updatePromoCode = (text) => {
    // Trim leading and trailing spaces from the input text
    setPromoCodeValue(text);
  };

  const updateTotalAmount = (promoCodeDiscount) => {
    let products;
    if (listMenuReady.length > 0) products = listMenuReady;
    //  if(activeGroupeTable && isTicket) tot
    else products = cart.products;
    let total = 0.0;
    let totalAfterDiscount = 0.0;
    products.forEach((product) => {
      let {price, isFree} = product;
      const {quantity, iscomposite, composition} = product;
      let compositionprice = iscomposite
        ? composition.reduce(function (a, b) {
            let price = b.quantity > 0 ? b.quantity * b.price : b.price;
            return a + price;
          }, 0)
        : 0;
      total += ((isFree ? 0 : price) + compositionprice) * quantity;
      const finalPrice = isFree ? 0 : price;
      if (promoCodeDiscount) {
        const discountFound = promoCodeDiscount?.products.some(
          (e) => e == product.id,
        );
        if (discountFound) {
          // totalAfterDiscount +=
          //   (finalPrice -
          //     price * promoCodeDiscount?.discount * 0.01 +
          //     compositionprice) *
          //   quantity;

          if (quantity <= promoCodeDiscount?.maxSoldTicket) {
            totalAfterDiscount +=
              quantity *
                calculerRemise(price.toFixed(2), promoCodeDiscount?.discount) +
              compositionprice;
          } else {
            const quantityTicketSansSold =
              quantity - promoCodeDiscount?.maxSoldTicket;
            const onlyRemise =
              promoCodeDiscount?.maxSoldTicket *
              calculerRemise(price.toFixed(2), promoCodeDiscount?.discount);

            const resultSansRemise = quantityTicketSansSold * price.toFixed(2);
            totalAfterDiscount +=
              onlyRemise + resultSansRemise + compositionprice;
          }
        } else {
          totalAfterDiscount += (finalPrice + compositionprice) * quantity;
        }
      }
    });
    // If price with discount is same than without > discounts removed
    // if (totalAfterDiscount == total) {
    //   //this.setState({promoCodeDiscount: null});
    //   setPromoCodeDiscount(null);
    // }
    setTotalPrice(total);
    setTotalAfterDiscount(totalAfterDiscount);
  };

  const submitPromoCode = async () => {
    // this.popPromoCodeModal(false);
    setCheckoutLoading(true);
    // TODO: The following comparision should be server side
    let promoCodeFound = false;
    let promoProducts = [];
    let promoDiscount = 0;
    let maxSoldTickets = 0;
    try {
      cart.products.forEach((ticket) => {
        ticket?.promoCode?.map((promoCode) => {
          if (promoCode.discount > 0) {
            if (promoCode.code == promoCodeValue) {
              promoCodeFound = true;
              promoProducts.push(ticket.id);
              promoDiscount = promoCode.discount;
              maxSoldTickets = promoCode.maxSoldTicket;
            }
          }
        });
      });
      if (promoCodeFound) {
        setPromoCodeDiscount({
          discount: promoDiscount,
          products: promoProducts,
          promoCodeValue: promoCodeValue,
          maxSoldTicket: maxSoldTickets,
        });
        updateTotalAmount({
          discount: promoDiscount,
          products: promoProducts,
        });
        addPromoCode({
          discount: promoDiscount,
          products: promoProducts,
        });
        setPromoCode(true);
        setPromoCodeModalVisible(false);
      } else {
        setInvalidPromoCode(true);
        setPromoCodeValue('');
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setCheckoutLoading(false);
    }
  };

  const handleRemovePromoCode = () => {
    remPromoCode(promoCodeDiscount);

    setPromoCode(false);
    setPromoCodeDiscount(null);
    setPromoCodeValue('');
    updateTotalAmount();
  };

  const calculateTotalPriceFriends = async (dataGO) => {
    // Get the amount to be added
    let totalPrice = 0;
    dataGO.users &&
      dataGO.users.forEach((user, i) => {
        totalPrice += parseFloat(user.totalPrice);
        // TODO: Handle what to show depending on what will pay
        // if (user.status == "confirmed") totalConfirmedPrice += user.total;
      });

    setTotalPriceShared(totalPrice);
  };

  const checkCartStatusforFriends = (dataGO) => {
    const userData = dataGO?.users
      ? dataGO?.users[dataGO?.users?.findIndex((usr) => usr.userId == user.uid)]
      : null;
    if (userData) {
      setFriendCartStatus(userData.status);
    }
  };

  const recycleGroupedOrder = () => {
    // removeCommandeGroupe();
    setTotalPriceShared(0);
    setIsInActiveGroupedOrder(false);
    setGroupedOrderData(null);
    setFriendCartStatus('draft');
    setShowLoading(false);
  };

  const getLastGroupedOrderInfo = async (goId) => {
    // TODO: This should be handled in backend
    return await db
      .collection('groupedorders')
      .doc(goId)
      .get()
      .then(async (docGO) => {
        const dataGO = docGO.data();
        if (dataGO) {
          if (
            !dataGO?.users?.some((obj) => obj?.userId === user.uid) &&
            dataGO?.owner?.userId !== user.uid
          ) {
            setInfoModal({
              infoModalTitle: 'Guest',
              infoModalMessage: groupedOrderIsOwner
                ? 'Invité assez groupé'
                : 'Vous avez été supprimé de la liste des commandes de groupe',
              infoModalIcon: FAIL_ICON,
              infoModalVisible: true,
            });
          }
          // Save it locally
          setGroupedOrderData(dataGO);
          // Recalculate Total
          await calculateTotalPriceFriends(dataGO);
          // Check status of Cart for Friend
          if (!groupedOrderIsOwner) checkCartStatusforFriends(dataGO);
        } else {
          console.log('The Grouped Order no longer exists');
          recycleGroupedOrder();
        }
        return dataGO;
      });
  };

  const getUserStatusInGroupedOrder = async (goId, userId) => {
    return await getLastGroupedOrderInfo(goId).then((dataGO) => {
      if (dataGO) {
        // Check if user is Owner
        if (dataGO?.owner?.userId == user.uid) {
          setGroupedOrderIsOwner(true);
          return 'owner';
        } else {
          setGroupedOrderIsOwner(false);
          // Check if exists in Friends
          const users = dataGO.users;
          if (users.length > 0 && users.some((obj) => obj.userId === userId)) {
            return 'friend';
          } else {
            return 'none';
          }
        }
      } else return 'nogo';
    });
  };

  // Fix: Setup onSnapshot on the User document to check changes on the Grouped Order field
  const setupSnaphotUser = async () => {
    unsubscribeListenerUser.current = db
      .collection('users')
      .doc(user.uid)
      .onSnapshot(async (doc) => {
        const userData = doc.data();
        if (userData.exists && userData?.activeGroupedOrder) {
        } else {
          await unsubscribeListenerUser.current();
        }
      });
  };

  const setupSnaphotGroupedOrder = async (goId) => {
    if (unsubscribeListener.current) {
      unsubscribeListener.current();
    }
    unsubscribeListener.current = db
      .collection('groupedorders')
      .doc(goId)
      .onSnapshot(async (doc) => {
        if (doc.exists) {
          const activeGroupedOrder = doc.id;
          await getLastGroupedOrderInfo(activeGroupedOrder).then(
            async (dataGO) => {
              if (!dataGO) return;
              // We check the the Grouped Order has not already been paid (for friends)
              if (dataGO.status === 'paid') {
                await db
                  .collection('users')
                  .doc(user.uid)
                  .set(
                    {
                      activeGroupedOrder: null,
                    },
                    {merge: true},
                  )
                  .then(() => {
                    emptyCart();

                    recycleGroupedOrder();
                  });
                if (!groupedOrderIsOwner) {
                  if (dataGO.paymentType === 'shared') {
                    navigation.navigate('CheckoutSuccess', {
                      newid: dataGO.reservationId,
                    });
                    return;
                  }
                  await setupSnaphotUser();
                  setInfoModal({
                    infoModalTitle: 'Payed',
                    infoModalMessage:
                      'This Grouped Order has been paid by owner',
                    infoModalIcon: CHECKMARK_ICON,
                    infoModalVisible: true,
                  });
                }
                unsubscribeListener.current();
              }
              // Handle if the Friend has been removed from Grouped Order
              if (
                dataGO?.owner?.userId != user.uid &&
                !dataGO.users.some((obj) => obj.userId == user.uid) &&
                activeGroupedOrder
              ) {
                recycleGroupedOrder();
                unsubscribeListener.current();
                await setupSnaphotUser();
              }
            },
          );
        } else {
          // we don't need it because groupe order doesn't exist
          // await setupSnaphotUser();
          unsubscribeListener.current();
        }
      });
  };

  const handleGroupedOrderInitialStatus = async () => {
    await db
      .collection('users')
      .doc(user.uid)
      .get()
      .then(async (docuser) => {
        const userData = docuser.data();
        // Check if there is an active Grouped Order in User

        if (userData.activeGroupedOrder) {
          // We verify that the user exists in the Grouped Order, and if so, as Owner or Friend
          await getUserStatusInGroupedOrder(
            userData.activeGroupedOrder,
            user.uid,
          ).then(async (userRole) => {
            if (userRole == 'owner' || userRole == 'friend') {
              // All good, we save the Grouped Order locally
              setIsInActiveGroupedOrder(userData.activeGroupedOrder);
              // Setup a snapshot to follow changes
              setupSnaphotGroupedOrder(userData.activeGroupedOrder);
            } else {
              setIsInActiveGroupedOrder(false);
              console.log('The user does not handle Grouped Orders');
            }
          });
          // Otherwise, nothing happens
        } else {
          setIsInActiveGroupedOrder(false);
          //  await setupSnaphotUser();
          console.log('The user is not present in an active Grouped Order');
        }
      });
  };

  const checkUserTableGroupeData = async () => {
    return db
      .collection('users')
      .doc(user.uid)
      .get()
      .then(async (data) => {
        const userData = data.data();
        setClientName(userData?.displayName);
        return userData;
      });
  };
  const aggregatedProducts = (data) =>
    data.reduce((acc, obj) => {
      obj?.products.forEach((product) => {
        const foundIndex = acc.findIndex((item) => item.id === product.id);
        if (foundIndex !== -1) {
          acc[foundIndex].quantity += product.quantity;
        } else {
          acc.push({...product});
        }
      });
      return acc;
    }, []);

  const queryFnFood = async () => {
    const tableGroupeData = await checkUserTableGroupeData();
    if (tableGroupeData?.activeGroupedTable) {
      db.collection('groupedorders')
        .doc(tableGroupeData.activeGroupedTable)
        .onSnapshot(async (groupedordersDoc) => {
          const groupedordersData = groupedordersDoc.data();
          if (groupedordersData) {
            setActiveGroupeTable(groupedordersDoc.id);
            setGroupeTableData({...groupedordersData, id: groupedordersDoc.id});
            const currrentUserData = groupedordersData?.users?.find(
              (usr) => usr.userId === user.uid,
            );

            const list = aggregatedProducts(groupedordersData?.users);
            setListMenuReady(list);
            setCurrentUserData(currrentUserData);
          }
        });
      // unsubscribeListener.current()
    }
  };

  useEffect(() => {
    queryFnFood();
  }, []);

  useEffect(() => {
    if (eventId) {
      const eventRef = db.collection('events').doc(eventId);
      docData(eventRef, 'id')
        .pipe(
          switchMap((eventdoc) => {
            const ref = db.collection('organisers').doc(eventdoc.organisation);
            const data = docData(ref, 'id');
            // return data.pipe(map((org) => ({org, ...eventdoc})));
            return data.pipe(
              filter((org) => org.type === 'Restaurant'),
              map((org) => ({org, ...eventdoc})),
            );
          }),
          switchMap((eventdoc) => {
            const ref = db
              .collection('events')
              .doc(eventId)
              .collection('tickets');
            return collectionData(ref, 'id').pipe(
              map((tickets) => ({tickets, ...eventdoc})),
            );
          }),
        )
        .subscribe((eventdoc) => {
          let haspromocode = eventdoc.tickets.some(
            (p) => p.promoCode?.length > 0,
          );
          eventdoc.haspromocode = haspromocode;
          setEvent(eventdoc);
          setShow(eventdoc);
        });
    }
  }, [eventId]);

  useEffect(() => {
    if (eventId) {
      let doc = db.collection('events').doc(eventId).collection('tickets');
      const observer = doc.onSnapshot(
        (querySnapshot) => {
          //let worker = docSnapshot.data();
          querySnapshot.docs.forEach(function (doc) {
            let docdata = doc.data();
          });
          const data = querySnapshot.docs.map(function (doc) {
            let docdata = doc.data();
            return {
              id: doc.id,
              name: docdata.name,
              soldQuantity: docdata.soldQuantity || 0,
              initialQuantity: docdata.initialQuantity,
            };
          });

          syncCart(data);
          //this.setState({ stores: data, isLoading: false });
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        },
      );
      return observer;
    }
  }, [eventId]);

  const addProductsToGroupedOrder = async (products) => {
    // TODO: The following should be handled in backend
    if (groupedOrderIsOwner) {
      let ownerData = groupedOrderData.owner;
      if (ownerData?.products) {
        ownerData.products = [...products];
        await db
          .collection('groupedorders')
          .doc(isInActiveGroupedOrder)
          .set({owner: JSON.parse(JSON.stringify(ownerData))}, {merge: true});
      }
    } else {
      if (groupedOrderData?.users) {
        let usersData = groupedOrderData?.users;

        if (usersData?.length > 0) {
          const indexUser = usersData?.findIndex((e) => e.userId == user.uid);
          if (indexUser > -1) {
            usersData[indexUser].products = [...products];
            // Calculate Total price
            let totalPrice = 0;
            products.forEach((product, i) => {
              totalPrice += parseFloat(product.total);
            });
            usersData[indexUser].totalPrice = totalPrice;
            await db
              .collection('groupedorders')
              .doc(isInActiveGroupedOrder)
              .set(
                {users: JSON.parse(JSON.stringify(usersData))},
                {merge: true},
              );
          }
        }
      }
    }
  };

  useEffect(() => {
    if (commandeGroupe?.id) {
      setGroupedOrderData(commandeGroupe.data);
      setIsInActiveGroupedOrder(commandeGroupe.id);
      commandeGroupe.isOwner && setGroupedOrderIsOwner(true);
    } else {
      recycleGroupedOrder();
    }
  }, [commandeGroupe]);

  useEffect(() => {
    handleGroupedOrderInitialStatus();
    return () => {
      if (unsubscribeListener.current) {
        unsubscribeListener.current();
      }
      if (unsubscribeListenerUser.current) {
        unsubscribeListenerUser.current();
      }
    };
  }, [isInActiveGroupedOrder]);

  useEffect(() => {
    if (
      isInActiveGroupedOrder &&
      groupedOrderData &&
      cart?.products?.length > 0
    ) {
      addProductsToGroupedOrder(cart.products);
    }
  }, [cart, isInActiveGroupedOrder, groupedOrderData]);

  useEffect(() => {
    updateTotalAmount(promoCodeDiscount);
  }, [cart.products, promoCodeDiscount, listMenuReady]);

  useEffect(() => {
    if (cart.products.length > 0) {
      let isOne = cart.products.some((p) => p.quantity < p.minQuantityPerOrder);
      setIsMinimumPerOrderNotRespect(isOne);
    } else if (isMinimumPerOrderNotRespect) {
      setIsMinimumPerOrderNotRespect(false);
    }
  }, [cart.products, isMinimumPerOrderNotRespect]);

  const eventKeyExtractor = (item, index) =>
    item && item.id ? item.id : index.toString();

  const renderShowCartItem = ({item}) => {
    const handleIncrease = (item) => {
      if (item.quantity < item.maxQuantityPerOrder) {
        addProduct({id: item.id, quantity: 1, price: item.price});
      }
    };
    item.initialQuantity =
      item?.initialQuantity || event?.capacity || 100000000000;
    if (!promoCodeDiscount) {
      setPromoCode(false);
    }

    const handleDecrease = (item) => {
      addProduct({id: item.id, quantity: -1, price: item.price});
    };

    if (!item || !item.id) {
      return null; // If item or item.id is not defined, return null to skip rendering
    }
    let isDiscounted = false;
    if (promoCodeDiscount?.products.some((e) => e == item.id)) {
      isDiscounted = true;
    }
    let isMinimumPerOrderNotRespect = false;
    if (item.quantity < item.minQuantityPerOrder)
      isMinimumPerOrderNotRespect = true;
    else isMinimumPerOrderNotRespect = false;

    const {quantity, name, price, order} = item;

    return (
      <View style={styles.inputSpacing}>
        <View
          style={
            isDiscounted && quantity > promoCodeDiscount.maxSoldTicket
              ? styles.inputB
              : styles.input
          }>
          <View
            style={{
              flexShrink: 1,
            }}>
            {/* <TitleA
            style={{
              color: Colors.darkGray,
            }}>
            {name}
          </TitleA> */}

            <SubheadingA
              style={{
                color: Colors.darkGray,
              }}>
              {name}
            </SubheadingA>
            <View>
              <View>
                {isDiscounted && quantity > 0 && (
                  <TitleBigA
                    style={{
                      fontSize: 14,
                      color: Colors.red,
                      textDecorationLine:
                        quantity > promoCodeDiscount.maxSoldTicket
                          ? 'line-through'
                          : '',
                    }}>
                    {quantity > promoCodeDiscount.maxSoldTicket ? (
                      <View>
                        <View style={{color: Colors.darkGreen, fontSize: 14}}>
                          {quantity - promoCodeDiscount?.maxSoldTicket}*
                          {`${price}€`}
                        </View>
                        <View style={{color: Colors.red, fontSize: 14}}>
                          {promoCodeDiscount.maxSoldTicket}*
                          {calculerRemise(
                            price.toFixed(2),
                            promoCodeDiscount?.discount,
                          )}
                          {`€`}
                        </View>
                      </View>
                    ) : (
                      <View
                        style={{
                          fontSize: 14,
                          textDecorationLine:
                            isDiscounted &&
                            quantity > promoCodeDiscount.maxSoldTicket
                              ? 'line-through'
                              : 'none',
                          color: Colors.darkGreen,
                        }}>
                        {' '}
                        {calculerRemise(
                          price.toFixed(2),
                          promoCodeDiscount?.discount,
                        )}
                        {`€`}
                      </View>
                    )}
                  </TitleBigA>
                )}
              </View>
              <SubheadingA
                style={{
                  fontSize: 14,
                  color: isDiscounted ? Colors.normalGray : Colors.darkGreen,
                }}>
                {isDiscounted && quantity > promoCodeDiscount?.maxSoldTicket ? (
                  <View style={{textDecorationLine: 'line-through'}}>
                    {' '}
                    {promoCodeDiscount?.maxSoldTicket}*{` ${price.toFixed(2)}€`}
                  </View>
                ) : (
                  <View
                    style={{
                      fontSize: 14,
                      textDecorationLine:
                        isDiscounted &&
                        promoCodeDiscount?.maxSoldTicket >= quantity
                          ? 'line-through'
                          : 'none',
                    }}>
                    {`€ ${price.toFixed(2)}`}
                  </View>
                )}
              </SubheadingA>
            </View>
            {/* <SubheadingA
            style={{
              color: isDiscounted ? Colors.normalGray : Colors.darkGreen,
              textDecorationLine: isDiscounted ? 'line-through' : '',
            }}>
            {`€ ${price.toFixed(2)}`}
          </SubheadingA> */}
          </View>

          <View
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
              width: '35%',
              flexShrink: 0,
            }}>
            <TouchableItem onPress={() => handleDecrease(item)}>
              <MinusSVG style={{width: 33, height: 34}} />
            </TouchableItem>
            <SubheadingA style={{color: '#1E2022'}}>{quantity}</SubheadingA>
            <TouchableItem onPress={() => handleIncrease(item)}>
              {item?.quantity === item?.maxQuantityPerOrder ||
              item.quantity >= item?.initialQuantity - item?.soldQuantity ? (
                <View> {''}</View>
              ) : (
                <PlusSVG style={{width: 33, height: 34}} />
              )}
            </TouchableItem>
          </View>
        </View>

        {isMinimumPerOrderNotRespect && (
          <View
            style={{
              fontSize: 14,
              textDecorationLine:
                isDiscounted && quantity > promoCodeDiscount.maxSoldTicket
                  ? 'line-through'
                  : 'none',
              color: Colors.red,
            }}>
            Minimum par commande: {item.minQuantityPerOrder}
          </View>
        )}
      </View>
    );
  };

  const renderRestaurantCart = ({item}) => {
    return (
      <RestaurantCart
        product={item}
        addProduct={addProduct}
        removeProductByID={removeProductByID}
      />
    );
  };
  const renderUserGroupedTable = ({item}) => {
    return (
      <RestaurantUserCommand
        user={item}
        addProduct={addProduct}
        removeProductByID={removeProductByID}
        display
      />
    );
  };

  const createcheckoutsession = async (
    custid,
    prices,
    tier,
    newid,
    shouldCreateOrder,
  ) => {
    const version = Constants.manifest.version;
    //console.log(api.checkout.create);
    let options = {
      prices: prices,
      tier: tier,
      newid: newid,
      customer: custid,
      // check this
      // savepm: this.state.isSelected,
      savepm: false,
      accountid: store.accountid,
      mode: 'payment',
    };
    if (isInActiveGroupedOrder) {
      options.goid = isInActiveGroupedOrder;
      options.shouldCreateOrder = shouldCreateOrder;
    }
    options.successurl = `Command?id=${newid}`;
    // options.successurl= `CheckoutSuccess?sc_checkout=success&tier=food&newid=${newid}`
    const callCreateCheckoutSession = httpsCallable(
      FUNCTIONS,
      'createCheckoutSession',
    );
    const result = await callCreateCheckoutSession(options);
    return result;
  };

  const pay = async (products, shouldCreateOrder, docUser) => {
    let tier = 'food';
    const storeid = products?.length > 0 ? products[0].storeid : '';
    const promoCodeDiscount = promoCodeDiscount;

    const items = products.map((product) => {
      tier = product.tier === 'ticket' ? 'ticket' : 'food';

      let compositionprice = product.iscomposite
        ? product.composition.reduce(function (a, b) {
            let price = b.quantity > 0 ? b.quantity * b.price : b.price;
            return a + price;
          }, 0)
        : 0;
      let finalPrice = product.isFree
        ? 0
        : promoCodeDiscount && product.hasDiscount
        ? product.priceAfterDiscount
        : product.price;
      let fields = {
        quantity: product.quantity,
        name: product.name,
        photo: product.photo,
        id: product.id,
        isFree: product.isFree || false,
        isalcoholic: product.isalcoholic || false,
        tier: product.tier,
        categoryid: product.categoryid,
        iscomposite: product.iscomposite,
        composition:
          product.composition !== undefined
            ? JSON.stringify(product.composition)
            : '',
        price: (finalPrice + compositionprice).toFixed(0),
        total: ((finalPrice + compositionprice) * product.quantity).toFixed(0),
      };
      if (product.accessTimeThreshold) {
        fields.accessTimeThreshold = product.accessTimeThreshold;
      }
      if (product.accessTimeLimit) {
        fields.accessTimeLimit = product.accessTimeLimit;
      }

      return fields;
    });

    const version = Constants.manifest.version;

    let reducedPrices = products.reduce(function (filtered, product) {
      let compositionprice = product.iscomposite
        ? product.composition.reduce(function (a, b) {
            let price = b.quantity > 0 ? b.quantity * b.price : b.price;
            return a + price;
          }, 0)
        : 0;
      if (!product.isFree) {
        let finalPrice =
          promoCodeDiscount && product.hasDiscount
            ? product.priceAfterDiscount
            : product.price;
        filtered.push({
          quantity: product.quantity,
          price_data: {
            currency: 'eur',
            product_data: {
              name: product.name,
              images: product.photo ? [product.photo] : [],
            },
            unit_amount: ((finalPrice + compositionprice) * 100).toFixed(0),
          },
        });
      }
      return filtered;
    }, []);

    let status = 'ordered';

    if (reducedPrices.length == 0) {
      status = tier === 'ticket' ? 'confirmed' : 'ordered';
    }

    let newid = '';
    if (shouldCreateOrder) {
      setCheckoutLoading(true);
      const options = {
        items: items,
        status: status,
        clientid: user.uid,
        displayName: clientName,
        // email: clientEmail,
        appversion: version,
        pickmode: 'inhouse',
        origin: 'webapp',
      };
      if (isInActiveGroupedOrder) {
        options.goid = isInActiveGroupedOrder;
      }
      if (promoCodeDiscount) {
        options.promocode = {
          promoCodeValue: promoCodeDiscount.promoCodeValue,
          promoDiscount: promoCodeDiscount.discount,
        };
      }
      if (tier === 'ticket') {
        options.event = {
          id: event.id,
          title: event.title,
          coverImage: event.coverImage,
          address: event.address,
          startDate: event.startDate,
          ownerid: event?.ownerid || '',
        };
        const precreateReservtion = httpsCallable(
          FUNCTIONS,
          'precreateReservtion',
        );

        newid = await precreateReservtion(options);
      } else {
        options.storeid = storeid;
        options.livraison = store?.livraison ? true : false;
        const precreateOrder = httpsCallable(FUNCTIONS, 'precreateOrder');
        newid = await precreateOrder(options);
      }
      setCheckoutLoading(true);
    }

    if (reducedPrices.length > 0) {
      const stripe = await stripePromise;
      setCheckoutLoading(true);
      const checkoutsession = await createcheckoutsession(
        docUser.customerId,
        reducedPrices,
        tier,
        newid.data,
        shouldCreateOrder,
      );
      const result = await stripe.redirectToCheckout({
        sessionId: checkoutsession.data.session.id,
      });
    } else {
      //CheckoutSuccess
      setCheckoutLoading(false);
      navigation.navigate('CheckoutSuccess', tier);
    }
  };

  const checkandpay = async (products, shouldCreateOrder) => {
    setShowLoading(true);
    let isTicket = products.some((p) => p.tier === 'ticket');
    const dbuser = await db.collection('users').doc(user.uid).get();
    const docuser = dbuser.data();
    if (isTicket) {
      let options = {
        products: products,
        promoCodeDiscount: promoCodeDiscount,
        shouldCreateOrder: shouldCreateOrder,
        goid: null,
      };
      if (isInActiveGroupedOrder) {
        options.goid = isInActiveGroupedOrder;
      }
      navigation.navigate('TicketCoordinates', {
        options,
        eventId: eventId,
      });
    } else {
      if (docuser && 'displayName' in docuser && docuser.displayName !== '') {
        setClientName(clientName);
        await pay(cart.products, shouldCreateOrder, docuser);
      } else {
        if (isFood) {
          setModalVisible(true);
        } else
          setInfoModal({
            infoModalTitle: /*'Ajouter un nom utilisateur'*/ `${t(
              'cartBPage.Ajouter un nom utilisateur',
            )}`,
            infoModalMessage: /*'Vous devez ajouter un nom utilisateur'*/ `${t(
              'cartBPage.Vous devez ajouter un nom utilisateur',
            )}`,
            infoModalIcon: FAIL_ICON,
            infoModalVisible: true,
          });
        // this.setState({
        //   inputModalVisible: true,
        // });
      }
    }
    setShowLoading(false);
  };

  const shareGroupedOrder = async () => {
    try {
      const dbuser = await db.collection('users').doc(user.uid).get();
      const link =
        links.webappurl +
        'menu?go=' +
        isInActiveGroupedOrder +
        '&s=' +
        store.id;
      console.log('Link to be shared : ' + link);
      let message =
        /*'Vous avez été invité à partager une commande avec'*/
        `${t('cartBPage.Vous avez été invité à partager une commande avec')}` +
        dbuser.displayName +
        '.';
      message = message.concat(' ', 'Lien pour procéder : ' + link);
      console.log(message);
      const result = await Share.share({
        title: message,
        /*message: "Le règlement de cette commande est " 
                  + (paymentType == 'shared') ? ("pris en charge par chaque personne séparément.") : ("pris en charge totalement par " + user + ".")
                  + "Lien pour procéder : " + link*/
        message: message,
      });
      /*if (result.action === Share.sharedAction) {
        if (result.activityType) {
          console.log("shared with activity type of result.activityType")
        } else {
          console.log("shared")
        }
      } else if (result.action === Share.dismissedAction) {
        console.log("dismissed")
      }*/
    } catch (error) {
      alert(error.message);
    }
  };

  const removeOrderFromUser = async () => {
    await db
      .collection('users')
      .doc(user.uid)
      .set(
        {
          activeGroupedOrder: null,
        },
        {merge: true},
      )
      .then(() => {
        console.log('Grouped Order successfully deleted from user');
        recycleGroupedOrder();
      });
  };
  const recycleGroupedTableOrder = () => {
    setGroupeTableData();
    setActiveGroupeTable();
    setListMenuReady([]);
  };

  const removeUSerfromGroupeTable = async () => {
    const callDeleteUSerFromGP = httpsCallable(
      FUNCTIONS,
      'removeGroupOrderFromUser',
    );
    const result = await callDeleteUSerFromGP({userid: user.uid});
    if (result) {
      recycleGroupedTableOrder();
    }
  };

  const handleLeaveTableGouped = async () => {
    let users = groupeTableData?.users;
    users = users?.filter((usr) => usr.userId != user.uid);

    const RefGroupOrder = db
      .collection('groupedorders')
      .doc(groupeTableData.id);

    // TODO: Must check that the Grouped Order still exists
    if (users?.length > 0) {
      await RefGroupOrder.set({users}, {merge: true}).then(async () => {
        removeUSerfromGroupeTable();
      });
    } else
      await RefGroupOrder?.delete().then(async () => {
        removeUSerfromGroupeTable();
      });
    return;
  };

  const handleLeaveGoupedOrder = async () => {
    setShowLoading(true);
    // Remove the Snapshot listener
    if (!groupedOrderIsOwner) {
      // TODO: The following should be handled in backend
      await db
        .collection('groupedorders')
        .doc(isInActiveGroupedOrder)
        .get()
        .then(async (docGO) => {
          const dataGO = docGO.data();
          let users = dataGO.users;
          users = users.filter((usr) => usr.userId != user.uid);
          // TODO: Must check that the Grouped Order still exists
          await db
            .collection('groupedorders')
            .doc(isInActiveGroupedOrder)
            .set({users}, {merge: true})
            .then(async () => {
              // Remove Grouped Order from current user
              await removeOrderFromUser();
            });
        });
      return;
    }
    // If the owner wants to get out
    await getLastGroupedOrderInfo(isInActiveGroupedOrder).then(async () => {
      if (groupedOrderData.users && groupedOrderData.users.length == 0) {
        // Delete the Grouped Order
        await db
          .collection('groupedorders')
          .doc(isInActiveGroupedOrder)
          .delete()
          .then(async () => {
            // Remove Grouped Order from current userr
            await removeOrderFromUser();
            // Remove the Snapshot listener
            removeCommandeGroupe();
            setShowLoading(false);
          })
          .catch((error) => {
            setShowLoading(false);
            console.error('Error removing Grouped Order: ', error);
          });
      } else {
        // TODO: Handle case where Grouped Order has users
        setInfoModal({
          infoModalTitle: /*'Supprimer une commande groupée'*/ `${t(
            'cartBPage.Supprimer une commande groupée',
          )}`,
          infoModalMessage:
            /* 'Vous ne pouvez pas supprimer une commande groupée pendant que les membres sont en'*/
            `${t(
              'cartBPage.Vous ne pouvez pas supprimer une commande groupée pendant que les membres sont en',
            )}`,
          infoModalIcon: FAIL_ICON,
          infoModalVisible: true,
        });
        setShowLoading(false);
        console.log('You cannot remove a Grouped Order while members are in');
      }
    });
  };

  const renderGroupedOrderLeaveButton = () => (
    <ButtonA
      onPress={() => handleLeaveGoupedOrder()}
      color={'#fff'}
      disabled={
        groupedOrderIsOwner &&
        groupedOrderData.users &&
        groupedOrderData.users.length > 0
      }
      titleColor={Colors.overlayColor}
      title={
        <View style={{alignItems: 'center'}}>
          <Text style={{textAlign: 'center', fontWeight: 'bold'}}>
            {groupedOrderIsOwner
              ? /*'Annuler'*/ `${t('cartBPage.Annuler')}`
              : `${t('cartBPage.Quitter')}`}{' '}
            {t('cartBPage.le panier partagé')}
          </Text>
        </View>
      }
    />
  );

  const renderGroupedOrderManageButtons = () => (
    <View style={{flexDirection: 'row'}}>
      <View style={{flex: 1}}>
        <TouchableOpacity onPress={() => shareGroupedOrder()}>
          <View style={{alignItems: 'center', margin: 10}}>
            <Icon name={ADD_ICON} size={22} color={Colors.secondaryText} />
          </View>
        </TouchableOpacity>
      </View>
      <View style={{flex: 2}}>{renderGroupedOrderLeaveButton()}</View>
    </View>
  );

  const removeFriendFromGroupedOrder = async (userId) => {
    setCheckoutLoading(true);
    // TODO: The following should be handled in backend
    // Remove Grouped Order from User
    await db.collection('users').doc(userId).set(
      {
        activeGroupedOrder: null,
      },
      {merge: true},
    );
    // Remove the User from the Grouped Order
    const users = groupedOrderData.users;
    const updatedUsers = users.filter((user) => user.userId != userId);
    db.collection('groupedorders')
      .doc(isInActiveGroupedOrder)
      .set(
        {
          users: [...updatedUsers],
        },
        {merge: true},
      );
    setCheckoutLoading(false);
  };

  const changeStatusFriendGroupedOrder = async (userId, newStatus) => {
    let users = groupedOrderData.users;
    users[users.findIndex((usr) => usr.userId == userId)].status = newStatus;
    db.collection('groupedorders')
      .doc(isInActiveGroupedOrder)
      .set({users}, {merge: true});
  };

  const checkStatusAllFriendsGroupedOrder = async (status) => {
    if (groupedOrderData) {
      return !groupedOrderData.users.some((elt) => elt.status != status);
    }
    return false;
  };

  // Consolidate Owner and Friends Products
  const consolidateAllProductsGroupedOrder = async (cartProducts) => {
    const ownerProducts = JSON.parse(JSON.stringify(cartProducts)); // Deep clone needed
    // TODO: The following should be handled in backend
    if (groupedOrderData) {
      // Get the Friend's products
      const friendsProducts = [];
      groupedOrderData.users.forEach((elt) =>
        friendsProducts.push(...elt.products),
      );
      // Concatenate both arrays and reduce to increment in case same id
      const allProducts = [...ownerProducts, ...friendsProducts].reduce(
        (accu, current) => {
          let index = accu.findIndex((x) => x.id == current.id);
          if (index == -1) accu.push(current);
          else {
            accu[index].quantity += current.quantity;
            accu[index].total += current.total;
          }
          return accu;
        },
        [],
      );
      // this.setState({allProducts});
      return allProducts;
    } else return null;
  };

  const handleConfirmFood = async () => {
    setShowLoading(true);
    const items = listMenuReady.map((product) => {
      let compositionprice = product.iscomposite
        ? product.composition.reduce(function (a, b) {
            let price = b.quantity > 0 ? b.quantity * b.price : b.price;
            return a + price;
          }, 0)
        : 0;
      let finalPrice = product.price;
      let fields = {
        quantity: product.quantity,
        name: product.name,
        photo: product.photo,
        id: product.id,
        isFree: product.isFree || false,
        isalcoholic: product.isalcoholic || false,
        tier: 'food',
        categoryid: product.categoryid || '',
        iscomposite: product.iscomposite,
        composition:
          product.composition !== undefined
            ? JSON.stringify(product.composition)
            : '',
        price: Number((finalPrice + compositionprice).toFixed(0)),
        total: Number(
          ((finalPrice + compositionprice) * product.quantity).toFixed(0),
        ),
      };
      return fields;
    });

    const version = Constants.manifest.version;
    const options = {
      items: items,
      status: 'ordered',
      clientid: user.uid,
      displayName: clientName,
      storeid: groupeTableData.storeid,
      tab: groupeTableData.tab,
      shouldCreate: true,
      goid: groupeTableData.id,
      // email: clientEmail,
      appversion: version,
      pickmode: 'inhouse',
      origin: 'webapp',
    };

    const precreateOrder = httpsCallable(FUNCTIONS, 'precreateOrder');
    const result = await precreateOrder(options);
    if (result.data) {
      db.collection('groupedorders')
        .doc(groupeTableData.id)
        .set({status: 'ordered', newid: result.data}, {merge: true})
        .catch((err) => console.log('Error', err));
      navigation.navigate('Bill', {id: result.data});
    }
    setShowLoading(false);
  };

  const handleCheckout = async (orderType, products) => {
    setCheckoutLoading(false);
    switch (orderType) {
      // User is NOT in Grouped Order > Normal Behavior
      case 0:
        // Check and pay with creating order
        //setShouldCreateOrder(true);
        checkandpay(products, true);
        break;
      // User is Owner and has decided to Share Grouped Order > PAYS FOR ONLY HIM and START ORDER
      case 1:
        // Verify that all status in Grouped Order (users.status) are paid, otherwise, popup to confirm discard of those that are draft
        const paid = await checkStatusAllFriendsGroupedOrder('paid');
        if (!paid) {
          setInfoModal({
            infoModalTitle: /*'Certains paniers non payés'*/ `${t(
              'cartBPage.Certains paniers non payés',
            )}`,
            infoModalMessage:
              /*'Tous les paniers de vos amis doivent être payés pour pouvoir continuer. Vous pouvez sinon annuler les invitations de ceux qui ne sont pas payés afin de pouvoir procéder.'*/
              `${t(
                'cartBPage.Tous les paniers de vos amis doivent être payés pour pouvoir continuer. Vous pouvez sinon annuler les invitations de ceux qui ne sont pas payés afin de pouvoir procéder.',
              )}`,
            infoModalIcon: FAIL_ICON,
            infoModalVisible: true,
          });
          break;
        }
        // Consolidate all products
        const allProducts1 = await consolidateAllProductsGroupedOrder(products);
        // Check and pay with creating order
        //setShouldCreateOrder(true);
        checkandpay(allProducts1, true);
        // TODO after checkout: Add to Order a map "groupedOrder: {paymentType: "shared", groupedOrderId}")
        // TODO after checkout: Callback Stripe: Change status of Order and Grouped Order (status) to Completed and add orderId to GroupedOrder
        break;
      // User is Owner and has decided to cover Grouped Order > PAYS FOR ALL and START ORDER
      case 2:
        // Verify that all status in Grouped Order (users.status) are validated, otherwise, popup to confirm discard of those that are draft
        const valid = await checkStatusAllFriendsGroupedOrder('validated');
        if (!valid) {
          setInfoModal({
            infoModalTitle: `${t('cartBPage.Valider tous les paniers')}`,
            infoModalMessage: `${t(
              'cartBPage.Tous les paniers de vos amis doivent être validés pour continuer. Vous pouvez sinon annuler les invitations de ceux qui ne sont pas validés afin de pouvoir continuer.',
            )}`,
            infoModalIcon: FAIL_ICON,
            infoModalVisible: true,
          });
          break;
        }
        // Consolidate all products
        const allProducts2 = await consolidateAllProductsGroupedOrder(products);
        // Check and pay with creating order
        //setShouldCreateOrder(true);
        checkandpay(allProducts2, true);
        // TODO after checkout: Add to Order a map "groupedOrder: {paymentType: "covered", groupedOrderId}")
        // TODO after checkout: Callback Stripe: Change status of Order and Grouped Order (status) to Completed
        break;
      // User is NOT Owner and Grouped Order is Shared > PAYS FOR ONLY HIM and WAITS
      case 3:
        // Check and pay without creating order
        //setShouldCreateOrder(false);
        checkandpay(products, false);
        // TODO after checkout: Callback that changes status in Grouped Order (users.status) to paid
        // changeStatusFriendGroupedOrder(user.uid, 'paid');
        // TODO: Wait for OnSnapshot to see if Owner completes Order
        break;
      // User is NOT Owner and Grouped Order is Covered > DOES NOT PAY and WAITS
      case 4:
        // Change status in Grouped Order (users.status) to prevalidated
        changeStatusFriendGroupedOrder(user.uid, 'prevalidated');
        // TODO: Wait for OnSnapshot to see if Owner validates and completes Order
        break;
      case 5:
        navigation.navigate('Bill');
        // TODO: Wait for OnSnapshot to see if Owner validates and completes Order
        break;
      case 6:
        await handleConfirmFood();
        break;
      // TODO: Wait for OnSnapshot to see if Owner validates and completes Order
      case 7:
        navigation.navigate('Bill');
        // await handlePaymentFood();
        // navigation.navigate('Command', {id: groupeTableData.newid});
        break;
      default:
        break;
    }
    setCheckoutLoading(false);
  };

  // Grouped Order
  const checkAlcoholAndCheckout = async (orderType) => {
    console.log('orderType', orderType);
    const {products} = cart;
    if (__DEV__) {
    }

    if (products.filter((e) => e.isalcoholic === true).length > 0) {
      Alert.alert(
        "Contrôle d'age",
        'Votre panier contient une boisson alcoolisée. Avez-vous plus de 18 ans?',
        [
          {
            text: 'Non',
            onPress: () => {
              return;
            },
            style: 'cancel',
          },
          {
            text: 'Oui',
            onPress: async () => {
              await handleCheckout(orderType, products);
            },
          },
        ],
        {cancelable: false},
      );
    } else {
      await handleCheckout(orderType, products);
    }
  };

  const renderPaymentButton = () => {
    let action = 0;
    let message = '';

    const {products} = cart;

    const soldoutProducts = cart.products.filter((product) => {
      const filtredProduct = event?.tickets?.filter(
        (ticket) => ticket.id === product.id,
      );
      return (
        filtredProduct?.length > 0 &&
        product.initialQuantity === filtredProduct[0]?.soldQuantity
      );
    });

    const outdatedProducts = cart.products.filter((product) => {
      const filtredProduct = event?.tickets.filter(
        (ticket) => ticket.id === product.id,
      );
      let today = new Date();
      if (filtredProduct?.length > 0) {
        let startDate = new Date(
          filtredProduct[0]?.SellTimeLimit?.start?.seconds * 1000,
        );

        let endDate = new Date(
          filtredProduct[0]?.SellTimeLimit?.end?.seconds * 1000,
        );
        if (!product.isSellTimeLimit) {
          return false;
        } else {
          if (today >= startDate && today <= endDate) {
            return false;
          } else {
            return true;
          }
        }
      }
    });

    if (products.length > 0) {
      if (soldoutProducts.length === 0 && outdatedProducts.length === 0) {
        if (isInActiveGroupedOrder && groupedOrderData) {
          if (groupedOrderIsOwner) {
            if (groupedOrderData.paymentType == 'shared') {
              // User is Owner and has decided to Share Grouped Order > PAYS FOR ONLY HIM and START ORDER
              action = 1;
              message =
                /*'Paiement (de votre panier uniquement) et lancement de la Commande'*/
                `${t(
                  'cartBPage.Paiement (de votre panier uniquement) et lancement de la Commande',
                )}`;
            } else {
              // User is Owner and has decided to cover Grouped Order > PAYS FOR ALL and START ORDER
              action = 2;
              message = `${t(
                'cartBPage.Paiement (de votre panier et de celui de vos amis validés) et lancement de la Commande',
              )}`;
            }
          } else {
            const status = friendCartStatus;
            if (groupedOrderData.paymentType == 'shared') {
              if (status == 'paid') {
                action = -1;
                message = message = `${t(
                  'cartBPage.En attente du paiement effectué par le propriétaire',
                )}`;
              } else {
                // User is NOT Owner and Grouped Order is Shared > PAYS FOR ONLY HIM and WAITS
                action = 3;
                message =
                  /*'Paiement (depuis votre panier uniquement)'*/
                  `${t('cartBPage.Paiement (depuis votre panier uniquement)')}`;
              }
            } else {
              // User is NOT Owner and Grouped Order is Covered > DOES NOT PAY and WAITS
              if (status == 'prevalidated') {
                action = -1;
                message =
                  /*'En attente de validation'*/
                  `${t('cartBPage.En attente de validation')}`;
              } else if (status == 'validated') {
                action = -1;
                message = `${t('cartBPage.Panier validé')}`;
              } else if (status == 'draft' || !status) {
                action = cart.products.length == 0 ? -1 : 4;
                message =
                  /*'Confirmer mon panier'*/
                  `${t('cartBPage.Confirmer mon panier')}`;
              }
            }
          }
        } else {
          // User is NOT in Grouped Order > Normal Behavior
          action = 0;
          message = /*'Paiement';*/ `${t('cartBPage.Paiement')}`;
        }
      } else {
        action = -1;
        message =
          /*'Paiement (Vous avez épuisé les produits)';*/
          `${t('cartBPage.Paiement (Vous avez épuisé les produits)')}`;
      }
    } else if (activeGroupeTable) {
      if (groupeTableData?.isReady) {
        action = 7;
        message = 'Check Bill';
      } else if (groupeTableData?.newid) {
        action = 5;
        message = 'Check Bill';
      } else {
        if (listMenuReady?.length > 0) {
          action = 6;
          message = `${t('cartBPage.Confirmer la commande')}`;
        } else return;
      }
    } else {
      action = -1;
      message = `${t('cartBPage.Paiement')}`;

      return;
    }

    return (
      <View style={{marginVertical: 10}}>
        <ButtonA
          disabled={isMinimumPerOrderNotRespect}
          styleViewText={{height: 'auto', width: '100%'}}
          onPress={async () => await checkAlcoholAndCheckout(action)}>
          <CartSVG
            style={{
              width: 18,
              height: 18,
              marginBottom: -2,
              marginRight: 10,
            }}
            stroke={Colors.background}
          />
          {message}
        </ButtonA>
      </View>
    );
  };

  const renderConfirmFriendGroupedOrderButton = (friendGroupedOrder) => {
    switch (friendGroupedOrder.status) {
      // The friend has not yet confirmed or paid his command
      // > We just remove the friend from the Grouped Order
      case 'draft':
        return (
          <Fragment>
            <View style={{flexDirection: 'row'}}>
              <TouchableItem
                onPress={() =>
                  removeFriendFromGroupedOrder(friendGroupedOrder.userId)
                }
                style={{flexDirection: 'row'}}
                borderless>
                <TrashSVG
                  style={{
                    width: 17,
                    height: 17,
                    marginRight: 7,
                    marginTop: 3,
                  }}
                />
                <BodyA
                  style={{
                    color: Colors.red,
                  }}>
                  {/* Supprimer un invité */}
                  {t('cartBPage.Supprimer un invité')}
                </BodyA>
              </TouchableItem>
            </View>
          </Fragment>
        );
      // The friend has confirmed his command
      // > We can either remove the friend or confirm his command
      case 'prevalidated':
        return (
          <View>
            <View style={{flexDirection: 'row'}}>
              <TouchableItem
                style={{flexDirection: 'row'}}
                onPress={() =>
                  removeFriendFromGroupedOrder(friendGroupedOrder.userId)
                }
                borderless>
                <TrashSVG
                  style={{
                    width: 17,
                    height: 17,
                    marginRight: 7,
                    marginTop: 3,
                  }}
                />
                <BodyA
                  style={{
                    color: Colors.red,
                  }}>
                  {/* Supprimer un invité */}
                  {t('cartBPage.Supprimer un invité')}
                </BodyA>
              </TouchableItem>
            </View>

            <View style={{flexDirection: 'row'}}>
              <TouchableItem
                style={{flexDirection: 'row'}}
                onPress={() =>
                  changeStatusFriendGroupedOrder(
                    friendGroupedOrder.userId,
                    'validated',
                  )
                }
                borderless>
                <Icon
                  style={{
                    width: 17,
                    height: 17,
                    marginRight: 7,
                    marginTop: 3,
                  }}
                  name={CHECKMARK_ICON}
                  size={17}
                  color={Colors.onPrimaryColor}
                />

                <BodyA
                  style={{
                    color: Colors.red,
                  }}>
                  {/* Approuver */}
                  {t('cartBPage.Approuver')}
                </BodyA>
              </TouchableItem>
            </View>
          </View>
        );
      // We have confirmed the user's choice (prevalidated > validated)
      // > we can unconfirm (validated > prevalidated)
      case 'validated':
        return (
          <Fragment>
            <View style={styles.actionContainer}>
              <View style={{flexDirection: 'row'}}>
                <TouchableItem
                  style={{flexDirection: 'row', marginRight: 7}}
                  onPress={() =>
                    changeStatusFriendGroupedOrder(
                      friendGroupedOrder.userId,
                      'prevalidated',
                    )
                  }
                  borderless>
                  <Icon
                    name={CHECKMARK_ICON}
                    size={17}
                    style={{
                      flexDirection: 'row',
                      width: 17,
                      height: 17,
                    }}
                    // color={Colors.onPrimaryColor}
                  />

                  <BodyA
                    style={{
                      color: Colors.red,
                    }}>
                    {/* Prévalidé */}
                    {t('cartBPage.Prévalidé')}
                  </BodyA>
                </TouchableItem>
              </View>
            </View>
          </Fragment>
        );
      // The user has paid
      // No action possible
      case 'paid':
        return (
          <Fragment>
            <Button
              title="Paid Cart"
              outlined
              disabled
              buttonStyle={{width: 150}}
            />
          </Fragment>
        );
      default:
        return null;
    }
  };

  const hasProducts = useMemo(() => {
    return groupedOrderData?.users?.some(
      (user) => user.products && user.products.length > 0,
    );
  }, [groupedOrderData]);

  const commandeGroupeTable = useMemo(() => {
    return groupeTableData && groupeTableData?.users?.length > 0
      ? groupeTableData?.users?.filter((user) => user?.products?.length > 0)
      : [];
  }, [groupeTableData]);

  if (showLoading) {
    return (
      <SafeAreaView style={styles.screenContainer}>
        <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <ActivityIndicator size="large" color="green" />
        </View>
      </SafeAreaView>
    );
  }

  if (
    cart?.products?.length === 0 &&
    !hasProducts &&
    !isInActiveGroupedOrder &&
    !activeGroupeTable
  ) {
    return (
      <SafeAreaView style={styles.screenContainer}>
        <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
        <View
          style={{
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 128,
          }}>
          <NoOrdersSVG />
          <TitleA
            style={{
              color: Colors.darkGray,
              marginVertical: 26,
            }}>
            {/* Aucune commande */}
            {t('cartBPage.Aucune commande')}
          </TitleA>
          <BodyA
            style={{
              color: Colors.normalGray,
              fontFamily: 'SemiBold',
            }}>
            {t('cartBPage.Vous n avez pas encore de commandes')}
          </BodyA>
        </View>
        <InfoModal
          title={infoModal.infoModalTitle.toUpperCase()}
          message={infoModal.infoModalMessage}
          iconName={infoModal.infoModalIcon}
          onButtonPress={() =>
            setInfoModal((prev) => ({...prev, infoModalVisible: false}))
          }
          onRequestClose={() =>
            setInfoModal((prev) => ({...prev, infoModalVisible: false}))
          }
          buttonTitle={'Ok'.toUpperCase()}
          visible={infoModal.infoModalVisible}
        />
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{marginBottom: 20}}>
        <View style={styles.container}>
          <View>
            <TitleBigA>{t('cartBPage.Détails de la commande')}</TitleBigA>
          </View>
          {isTicket ? (
            <FlatList
              data={cart?.products}
              showsHorizontalScrollIndicator={false}
              alwaysBounceHorizontal={false}
              keyExtractor={eventKeyExtractor}
              renderItem={renderShowCartItem}
              // contentContainerStyle={styles.contentContainerStyle}
              ItemSeparatorComponent={Separator}
            />
          ) : (
            <FlatList
              data={cart?.products}
              showsHorizontalScrollIndicator={false}
              alwaysBounceHorizontal={false}
              keyExtractor={eventKeyExtractor}
              renderItem={renderRestaurantCart}
              // contentContainerStyle={styles.contentContainerStyle}
              ItemSeparatorComponent={SeparatorFood}
            />
          )}

          <View style={styles.total}>
            <TitleBigA style={{color: Colors.darkGray}}>Total</TitleBigA>
            <View>
              {promoCode && (
                <TitleBigA style={{color: Colors.red}}>
                  {`€ ${totalAfterDiscount}`}
                </TitleBigA>
              )}
              <TitleBigA
                style={
                  promoCode
                    ? {
                        fontSize: 12,
                        lineHeight: 18,
                        letterSpacing: 0.48,
                        color: Colors.normalGray,
                        textDecorationLine: 'line-through',
                        textAlign: 'right',
                      }
                    : {color: Colors.darkGreen}
                }>
                {`€ ${totalPrice}`}
              </TitleBigA>
            </View>
          </View>

          {/*<View style={styles.bodyRow}>
            <TimeSVG style={styles.rowIcon} />
            <BodyA style={{color: Colors.normalGray}}>
              Heure limite de commande : Vendredi 1 juin, 23:00
            </BodyA>
              </View>*/}
          {(cart.products.length > 0 || hasProducts) && (
            <>
              {promoCode ? (
                <View style={styles.promoCode}>
                  <SubheadingA
                    style={{
                      color: Colors.normalGray,
                    }}>
                    {t('cartBPage.Annuler le code promo')} {'\n'}(
                    {promoCodeDiscount?.discount}%{' '}
                    {t('cartBPage.réduction appliquée')})
                  </SubheadingA>
                  <TouchableItem onPress={handleRemovePromoCode}>
                    <TrashSVG
                      style={{
                        width: 42,
                        height: 42,
                      }}
                    />
                  </TouchableItem>
                </View>
              ) : (
                <TouchableItem onPress={handleModalOpen}>
                  <View style={styles.promoCode}>
                    <SubheadingA>
                      {t('cartBPage.Avez-vous un code de promotion?')}{' '}
                    </SubheadingA>
                    <PromoCodeSVG
                      style={{
                        width: 42,
                        height: 42,
                      }}
                    />
                  </View>
                </TouchableItem>
              )}
            </>
          )}
          <Separator />

          {/* Groupe table list  */}

          {activeGroupeTable && !isTicket && (
            <View style={{marginVertical: 10}}>
              <TitleBigA>
                {/* Commande Table groupée  */}
                {t('cartBPage.Commande Table groupée')}{' '}
              </TitleBigA>
              <FlatList
                data={commandeGroupeTable}
                showsHorizontalScrollIndicator={false}
                alwaysBounceHorizontal={false}
                keyExtractor={eventKeyExtractor}
                renderItem={renderUserGroupedTable}
                // contentContainerStyle={styles.contentContainerStyle}
                ItemSeparatorComponent={SeparatorFood}
              />
            </View>
          )}
          {/* {isInActiveGroupedOrder && groupedOrderIsOwner && (
          <>
            {renderGroupedOrderManageButtons()}
            <View style={styles.secondTitleContainer}>
              <View style={{flexDirection: 'row'}}>
                <Subtitle1 style={styles.titleText}>Mon Panier</Subtitle1>
              </View>
              <View style={styles.promoCodeContainer}>
                <View style={styles.promoCodeContent}>
                  <TitleBigA style={styles.subTotalPriceText}>
                    {`${locales.financial.currency.symbol} ${parseFloat(
                      Math.round(total * 100) / 100,
                    ).toFixed(2)}`}
                  </TitleBigA>
                </View>
              </View>
            </View>
            {cart.products.length === 0 && (
              <View style={{flexDirection: 'row'}}>
                <EmptyState
                  title=""
                  message="Il semble que vous n’avez encore rien ajouté à votre panier"
                />
              </View>
            )}
          </>
        )} */}

          {isInActiveGroupedOrder && groupedOrderIsOwner && (
            <GroupOrder
              groupedOrderData={groupedOrderData}
              renderConfirmFriendGroupedOrderButton={
                renderConfirmFriendGroupedOrderButton
              }
              promoCodeDiscount={promoCodeDiscount}
            />
          )}

          {/*!activeGroupeTable && !isInActiveGroupedOrder && !isFood && (
            <View style={{marginVertical: 10, marginTop: 30}}>
              <ButtonA
                style={{backgroundColor: Colors.blue}}
                onPress={handleNavigation}>
                <AddUserSVG
                  style={{
                    width: 18,
                    height: 18,
                    marginBottom: -2,
                    marginRight: 10,
                  }}
                />
                {/* Commande groupée }
                {t('cartBPage.Commande groupée')}{' '}
              </ButtonA>
            </View>
          )}*/}

          {/* {!showGroupOrder && (
            <View style={{marginVertical: 10}}>
              <ButtonA
                onPress={() => {
                  checkandpay();
                }}>
                <CartSVG
                  style={{
                    width: 18,
                    height: 18,
                    marginBottom: -2,
                    marginRight: 10,
                  }}
                  stroke={Colors.background}
                />
                {`Paiement (${promoCode ? totalAfterDiscount : totalPrice}) €`}
              </ButtonA>
            </View>
          )} */}

          {renderPaymentButton()}

          {isInActiveGroupedOrder ? (
            !(!groupedOrderIsOwner && friendCartStatus === 'paid') && (
              <View style={{marginVertical: 10, marginHorizontal: 7}}>
                <ButtonA
                  style={{
                    backgroundColor: 'rgba(0,0,0, 0.0)',
                    borderWidth: 1,
                    borderColor: Colors.normalGray,
                    borderRadius: 10,
                  }}
                  color={Colors.normalGray}
                  onPress={() => handleLeaveGoupedOrder()}>
                  <RemoveUserSVG
                    style={{
                      width: 18,
                      height: 18,
                      marginBottom: -2,
                      marginRight: 10,
                    }}
                  />
                  {groupedOrderIsOwner ? 'Annuler' : 'Partir'}{' '}
                  {t('cartBPage.Commande groupée')}
                </ButtonA>
              </View>
            )
          ) : groupeTableData?.id && !groupeTableData?.newid ? (
            <View style={{marginVertical: 10, marginHorizontal: 7}}>
              <ButtonA
                style={{
                  backgroundColor: 'rgba(0,0,0, 0.0)',
                  borderWidth: 1,
                  borderColor: Colors.normalGray,
                  borderRadius: 10,
                }}
                color={Colors.normalGray}
                onPress={() => handleLeaveTableGouped()}>
                <RemoveUserSVG
                  style={{
                    width: 18,
                    height: 18,
                    marginBottom: -2,
                    marginRight: 10,
                  }}
                />
                {/* Quitter le tableau groupé */}
                {t('cartBPage.Quitter le tableau groupé')}
              </ButtonA>
            </View>
          ) : null}
        </View>

        {/* ************* MODAL ************** */}
        <ModalA visible={modalVisible} onDismiss={() => setModalVisible(false)}>
          <NameSection
            clientName={clientName}
            handleModalDismiss={() => setModalVisible(false)}
            setClientName={setClientName}
          />
        </ModalA>
        <InfoModal
          title={infoModal.infoModalTitle.toUpperCase()}
          message={infoModal.infoModalMessage}
          iconName={infoModal.infoModalIcon}
          onButtonPress={() =>
            setInfoModal((prev) => ({...prev, infoModalVisible: false}))
          }
          onRequestClose={() =>
            setInfoModal((prev) => ({...prev, infoModalVisible: false}))
          }
          buttonTitle={'Ok'.toUpperCase()}
          visible={infoModal.infoModalVisible}
        />

        <ModalA visible={PromoCodeModalVisible} onDismiss={handleModalDismiss}>
          <View>
            <TitleA style={{marginBottom: 10, paddingHorizontal: 10}}>
              {/* Code promo */}
              {t('cartBPage.Code promo')}
            </TitleA>

            <SubheadingA style={{marginBottom: 10, paddingHorizontal: 10}}>
              {/* Veuillez saisir le code promo que vous avez reçu */}
              {t('cartBPage.Veuillez saisir le code promo que vous avez reçu')}
            </SubheadingA>
            <TextInput
              autoFocus={invalidPromoCode}
              placeholder={
                invalidPromoCode
                  ? /*'Code promo invalide !'*/ `${t(
                      'cartBPage.Code promo invalide !',
                    )}`
                  : '000 000 000 000'
              }
              placeholderTextColor={
                invalidPromoCode ? Colors.red : Colors.lightGray
              }
              // returnKeyType="search"
              maxLength={50}
              onChangeText={updatePromoCode}
              value={promoCodeValue}
              style={styles.textInput}
            />
            <ButtonA style={{marginVertical: 10}} onPress={submitPromoCode}>
              Ok
            </ButtonA>

            <ButtonA
              style={styles.cancelButton}
              color={Colors.darkGreen}
              onPress={handleModalDismiss}>
              {/* Annuler */}
              {t('cartBPage.Annuler')}
            </ButtonA>
          </View>
        </ModalA>
      </ScrollView>
    </SafeAreaView>
  );
};

const mapStateToProps = (state) => {
  const {cart, store, event, commandeGroupe} = state;
  return {cart, store, event, commandeGroupe};
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addProduct,
      removeProductByID,
      addPromoCode,
      remPromoCode,
      syncCart,
      setEvent,
      removeCommandeGroupe,
      emptyCart,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CartB);
