import Colors from "../../theme/colors"
import {StyleSheet, I18nManager} from 'react-native';


const styles = StyleSheet.create({
  screenContainer : {
        flex : 1 ,
        backgroundColor: Colors.background,

    } , 
    container : {
        paddingHorizontal: 24 , 
      paddingTop : 16 , 
    } , 
    separator: {
        height: 1,
        backgroundColor: Colors.lightGray,
      },
    containerText :{
        flexDirection : "row" ,
        alignItems : "center" , 
        justifyContent :"space-between"
    } , 
    titleArticle : {
      /*  color: "var(--Dark-Grey, #1D1F21)",
    fontFamily: "Bai Jamjuree",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "150%", 
    letterSpacing: 0.64 , */
    } , 
    inputContainer : {
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#D2D5DA',
        backgroundColor: '#FFF',
        shadowColor: '#000',
        paddingHorizontal: 10  , 
        paddingVertical : 15 , 
        marginBottom : 16 ,
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.05,
        shadowRadius: 2,
        elevation: 2,
  
      },
      textArticle: {
       /* color: "var(--Dark-Grey, #1D1F21)",
        fontFamily: "Bai Jamjuree",
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "150%", */
        } , 
      textInput:{
        color: "var(--Light-Grey, #CAD2CC)",
       width : '80%' ,
        borderWidth: 0,
        outlineStyle: 'none',
        underlineColorAndroid: 'transparent',
fontFamily: "Bai Jamjuree",
fontSize: 14 , 
fontStyle: "normal",
fontWeight: 300,
lineHeight: "150%", /* 21px */
      } , 
      containerPayement :{
        flexDirection : "row" ,
        paddingVertical :20 ,
         justifyContent : "space-between"
          ,paddingHorizontal : 24 ,
         alignItems : "center"
    } ,
    containerScreenPayment : {
      borderRadius: 15, 
      borderWidth: 1, 
      marginBottom: 16
    }
     
})
export default styles