import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import styles from './ButtonAStyles';
import Colors from '../../theme/colors';

const ButtonA = ({
  children,
  style,
  styleViewText = {},
  onPress,
  color,
  disabled,
  ...props
}) => (
  <TouchableOpacity
    activeOpacity={0.8}
    style={[
      styles.button,
      style,
      disabled ? {backgroundColor: Colors.lightGray} : null,
    ]}
    onPress={onPress}
    disabled={disabled}
    {...props}>
    <View style={[styles.buttonTextContainer, styleViewText]}>
      <Text style={[styles.buttonText, {color: color || Colors.background}]}>
        {children}
      </Text>
    </View>
  </TouchableOpacity>
);

export default ButtonA;
