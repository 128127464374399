import React from 'react';
import {StyleSheet} from 'react-native';

import OrdersList from './OrdersAList';
import {useTranslation} from 'react-i18next';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  addProduct,
  setStore,
  setLastId,
  setTierMode,
} from '../../reducers/cartactions';

// import colors
import Colors from '../../theme/colors';

import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import BackArrowSVG from '../../components/svgs/BackArrowSVG';
import OrdersDList from './OrdersDList';

const Tab = createMaterialTopTabNavigator();

// OrdersB Styles
const styles = StyleSheet.create({
  topArea: {flex: 0, backgroundColor: Colors.primaryColor},
  container: {
    flex: 1,
    backgroundColor: '#efefef',
  },
  productsContainer: {
    paddingVertical: 8,
  },
  activeTab: {
    borderBottomWidth: 3,
    borderBottomColor: Colors.darkGreen,
  },
  tabBarStyle: {
    backgroundColor: Colors.background,
  },
  // labelStyle: {
  //   backgroundColor: Colors.background,
  // },
});

// OrdersB
function OrdersD(props) {
  const {t} = useTranslation();
  if (false) {
    // if (props.prefs.tierMode && props.prefs.tierMode === 'event') {
    return (
      <Tab.Navigator>
        <Tab.Screen name="Billets à venir">
          {(props) => <OrdersDList {...props} tabtype="active" />}
        </Tab.Screen>
        <Tab.Screen name="Billets passés">
          {(props) => <OrdersDList {...props} tabtype="old" />}
        </Tab.Screen>
      </Tab.Navigator>
    );
  } else {
    return (
      <Tab.Navigator
        tabBarOptions={{
          activeTintColor: Colors.darkGreen,
          inactiveTintColor: Colors.normalGray,
          headerTitleStyle: {
            textAlign: 'center',
            fontFamily: 'SemiBold',
            fontSize: 14,
            lineHeight: 21,
            letterSpacing: 0.56,
          },
          headerTitleAlign: 'center',
          // labelStyle: styles.labelStyle,
          tabBarStyle: styles.tabBarStyle,
        }}>
        <Tab.Screen
          // {{title: t('HomeNavigatorATitle.Détails Commande')}}
          name="upcomingTickets"
          /*options={{title: 'My upcoming tickets'}}*/
          options={{title: t('OrderD.Mes prochains billets')}}>
          {(props) => <OrdersDList {...props} tabtype="active" />}
        </Tab.Screen>
        <Tab.Screen
          name="PastTickets"
          // options={{title: 'Past tickets'}}
          options={{title: t('OrderD.Billets antérieurs')}}>
          {(props) => <OrdersDList {...props} tabtype="old" />}
        </Tab.Screen>
      </Tab.Navigator>
    );
  }
}

const mapStateToProps = (state) => {
  const {carts, store, prefs} = state;
  return {carts, store, prefs};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addProduct,
      setStore,
      setLastId,
      setTierMode,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OrdersD);
