import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const LocationSVG = (props) => {
  const {style, fill = '#77838F', ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 21 21"
      style={style}
      {...restProps}>
      <Path
        fill={fill}
        d="M10.813 6.487a2.328 2.328 0 0 0-2.326 2.325 2.326 2.326 0 0 0 4.65.002 2.33 2.33 0 0 0-2.325-2.327Zm0 3.922c-.88 0-1.594-.717-1.594-1.594 0-.877.716-1.594 1.594-1.594.877 0 1.593.717 1.593 1.594 0 .877-.716 1.594-1.594 1.594Z"
      />
      <Path
        fill={fill}
        d="M14.921 4.728A5.729 5.729 0 0 0 10.813 3 5.735 5.735 0 0 0 6.7 4.728 5.909 5.909 0 0 0 5 8.9c0 3.188 2.968 5.84 4.565 7.265.222.2.413.368.566.512a.983.983 0 0 0 1.363 0 23.1 23.1 0 0 1 .566-.512c1.594-1.425 4.565-4.077 4.565-7.265a5.923 5.923 0 0 0-1.704-4.172Zm-3.395 10.827c-.225.201-.421.376-.582.531a.19.19 0 0 1-.263 0c-.161-.152-.357-.327-.582-.531C8.596 14.214 5.805 11.72 5.805 8.9c0-2.802 2.246-5.082 5.005-5.082 2.761 0 5.007 2.28 5.007 5.085 0 2.817-2.79 5.311-4.29 6.652Z"
      />
    </Svg>
  );
};
export default LocationSVG;
