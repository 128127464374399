import {StyleSheet} from 'react-native';
import Colors from '../../theme/colors';

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: 83,
    marginHorizontal: 25,
  },
  shadow: {
    width: '100%',
    height: 38,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  xmlid1: {
    width: '100%',
    height: '100%',
  },
  touchableOpacity: {
    position: 'absolute',
    top: 16,
    left: 14,
    zIndex: 2,
  },
  searchIcon: {
    width: 13,
    height: 13,
  },
  inputContainer: {
    width: '100%',
    height: 46,
  },
  textInput: {
    width: '100%',
    height: '100%',
    borderRadius: 23,
    backgroundColor: Colors.white,
    paddingVertical: 12,
    paddingLeft: 40,
    paddingRight: 24,
    position: 'relative',
    borderWidth: 1,
    borderColor: Colors.background,
  },

  cherchezUnEvenement: {
    color: Colors.lightGray,
    fontFamily: 'Light',
    fontSize: 14,
  },
});

export default styles;
