/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

// import dependencies
import React from 'react';
import {Modal, StatusBar, StyleSheet, Text, View, TouchableOpacity, Platform} from 'react-native';

// import components
import Button from '../buttons/Button';
import Icon from '../icon/Icon';

// import colors, layout
import Colors from '../../theme/colors';
import Layout from '../../theme/layout';

// TwoChoicesModal Config
const IOS = Platform.OS === 'ios';
const CLOSE_ICON = IOS ? 'ios-close' : 'md-close';
// TwoChoicesModal Styles
const styles = StyleSheet.create({
  modalWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.52)',
  },
  modalContainer: {
    //justifyContent: 'center',
    //alignItems: 'center',
    padding: 20,
    width: Layout.SCREEN_WIDTH - 3 * Layout.LARGE_MARGIN,
    borderRadius: 4,
    backgroundColor: Colors.background,
  },
  title: {
    paddingVertical: 8,
    fontWeight: '700',
    fontSize: 18,
    color: Colors.primaryText,
    textAlign: 'center'
  },
  message: {
    marginBottom: 16,
    padding: 8,
    fontWeight: '400',
    color: Colors.secondaryText,
    textAlign: 'center',
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: 12,
    width: '100%',
  },
  modalHeader: {
    flexDirection: "row",
    alignItems: "flex-end",
    borderWidth: 1,
    borderColor: "white"
  },
  /* The header takes up all the vertical space not used by the close button. */
  modalHeaderContent: {
    flexGrow: 1,
  },
  modalHeaderCloseText: {
    textAlign: "right",
    paddingLeft: 5,
    paddingRight: 5
  },
});

// TwoChoicesModal Props
type Props = {
  message: string,
  onRequestClose: () => {},
  iconName: string,
  iconColor: string,
  statusBarColor: string,
  title: string,
  button1Title: string,
  button2Title: string,
  onButton1Press: () => {},
  onButton2Press: () => {},
  visible: boolean,
};

// TwoChoicesModal
const TwoChoicesModal = ({
  message,
  onRequestClose = () => {},
  iconName,
  iconColor,
  statusBarColor = 'rgba(0, 0, 0, 0.52)',
  title,
  button1Title,
  button2Title,
  onButton1Press,
  onButton2Press,
  visible = false,
}: Props) => (
  <Modal
    animationType="none"
    transparent
    visible={visible}
    onRequestClose={onRequestClose}>
    <StatusBar backgroundColor={statusBarColor} />
    
    
    
    <View style={styles.modalWrapper}>
    
      <View style={styles.modalContainer}>
      <View style={{
    position: 'absolute',
    top: 16,
    borderRadius: 18,
    backgroundColor: Colors.background,
  }}>        
          <TouchableOpacity onPress={onRequestClose}>
            <Icon
                    name={CLOSE_ICON}
                    size={22}
                    color={Colors.secondaryText}
                  />
            
          </TouchableOpacity>
        </View>
        <Icon name={iconName} size={72} color={iconColor} />
        <Text style={styles.title}>{title}</Text>

        {message !== '' && message !== undefined && (
          <Text style={styles.message}>{message}</Text>
        )}

        <View style={styles.buttonContainer}>
          <Button buttonStyle={{flex:1, margin: 5}} onPress={onButton1Press} title={button1Title} />
          <Button buttonStyle={{flex:1, margin: 5, textAlign: 'center'}} onPress={onButton2Press} title={button2Title} />
        </View>
      </View>
    </View>
  </Modal>
);

export default TwoChoicesModal;