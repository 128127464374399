import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';

const SmartphoneSVG = (props) => {
  const {style, ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 170 170"
      style={style}
      {...restProps}>
      <G clipPath="url(#a)">
        <Path
          fill="#FDE57F"
          d="M84.35 170c46.228 0 83.702-37.475 83.702-83.702S130.578 2.596 84.351 2.596.649 40.071.649 86.298 38.123 170 84.35 170Z"
        />
        <Path
          fill="#5F6AB9"
          d="M91.463 0H28.025c-9.318 0-16.898 7.58-16.898 16.898v109.966C25.407 152.588 52.843 170 84.35 170a83.683 83.683 0 0 0 23.772-3.431c.154-.914.238-1.852.238-2.809V16.898C108.362 7.581 100.781 0 91.463 0Z"
        />
        <Path
          fill="#524FA1"
          d="M30.556 16.898C30.556 7.581 38.136 0 47.454 0h-19.43c-9.317 0-16.898 7.58-16.898 16.898v109.966a84.092 84.092 0 0 0 19.43 23.553V16.898Z"
        />
        <Path
          fill="#6D85D2"
          d="M70.22 12.089H58.895a2.726 2.726 0 1 1 0-5.453h11.327a2.726 2.726 0 1 1 0 5.453ZM49.32 12.088a2.726 2.726 0 0 1-2.727-2.727 2.731 2.731 0 0 1 3.26-2.671 2.724 2.724 0 0 1-.534 5.398Z"
        />
        <Path
          fill="#F7F6F0"
          d="M84.351 170c4.233 0 8.391-.318 12.454-.926A7.51 7.51 0 0 0 99 163.759V16.899a7.536 7.536 0 0 0-7.536-7.537h-7.906a2.82 2.82 0 0 0-2.82 2.819v1.79c0 2.4-1.963 4.362-4.362 4.362H43.113a4.375 4.375 0 0 1-4.362-4.362v-1.79a2.82 2.82 0 0 0-2.82-2.819h-7.906a7.536 7.536 0 0 0-7.536 7.536V140.4C35.84 158.506 58.752 170 84.35 170Z"
        />
        <Path
          fill="#B2D9ED"
          d="M30.556 16.898c0-2.707.643-5.265 1.78-7.536h-4.311a7.536 7.536 0 0 0-7.536 7.536v123.49a83.644 83.644 0 0 0 10.067 10.023V16.898Z"
        />
        <Path
          fill="#2FBF86"
          d="M91.463 9.362h-7.906a2.82 2.82 0 0 0-2.82 2.819v1.79c0 2.4-1.963 4.362-4.362 4.362H43.113a4.375 4.375 0 0 1-4.362-4.362v-1.79a2.82 2.82 0 0 0-2.82-2.819h-7.906a7.536 7.536 0 0 0-7.536 7.536v20.594h78.51V16.898a7.536 7.536 0 0 0-7.536-7.536Z"
        />
        <Path
          fill="#2FAC7A"
          d="M30.556 16.898c0-2.707.643-5.265 1.78-7.536h-4.311a7.536 7.536 0 0 0-7.536 7.536v20.594h10.067V16.898Z"
        />
        <Path
          fill="#FF5E75"
          d="M146.009 6.07c-22.058-12.681-50.98-4.815-63.585 17.287-11.177 19.596-6.545 43.932 9.925 58.184a3.704 3.704 0 0 1 1.258 3.025l-.733 12.228a.832.832 0 0 0 1.288.744l10.867-7.175a2.174 2.174 0 0 1 1.939-.234c20.813 7.576 44.686-.631 56.144-20.477 12.86-22.273 5.204-50.76-17.103-63.583Z"
        />
        <Path
          fill="#F7F6F0"
          d="M128.889 62.953h-1.151V42.442a4.907 4.907 0 0 0-4.908-4.908h-6.058a4.907 4.907 0 0 0 0 9.815h1.151v15.605h-1.151a4.907 4.907 0 0 0 0 9.815h12.117a4.907 4.907 0 0 0 0-9.816ZM122.006 31.282a5.731 5.731 0 1 0 0-11.462 5.731 5.731 0 0 0 0 11.462Z"
        />
        <Path
          fill="#14C0EB"
          d="M68.51 75.041H50.517a5.682 5.682 0 0 0-5.682 5.682v11.733c0 .756.613 1.369 1.369 1.369H72.93c.697 0 1.263-.566 1.263-1.264V80.724a5.683 5.683 0 0 0-5.682-5.683Z"
        />
        <Path
          fill="#00ADDC"
          d="M56.07 80.724a5.682 5.682 0 0 1 5.681-5.683H50.516c-3.153 0-5.682 2.568-5.682 5.683v11.732c0 .756.613 1.369 1.369 1.369h9.866V80.724Z"
        />
        <Path
          fill="#F4C3B8"
          d="M59.514 70.72a9.504 9.504 0 1 0 0-19.007 9.504 9.504 0 0 0 0 19.007Z"
        />
        <Path
          fill="#EAB5AC"
          d="M59.514 51.714c-7.255-.007-11.905 7.892-8.231 14.255 3.673 6.362 12.84 6.286 16.46-.001-7.316-.008-11.878-7.917-8.229-14.254Z"
        />
        <Path
          fill="#B2D9ED"
          d="M61.499 161.327h-3.97a2.726 2.726 0 0 1 0-5.453h3.97a2.727 2.727 0 0 1 0 5.453Z"
        />
        <Path
          fill="#CAD2CC"
          d="M82.634 108.958H45.18a2.727 2.727 0 0 1 0-5.453h37.455a2.726 2.726 0 1 1 0 5.453ZM35.335 108.958a2.725 2.725 0 1 1-.003-5.453h.003a2.726 2.726 0 0 1 0 5.453ZM82.634 120.243H45.18a2.727 2.727 0 0 1 0-5.453h37.455a2.726 2.726 0 1 1 0 5.453ZM82.634 131.528H45.18a2.726 2.726 0 0 1 0-5.453h37.455a2.727 2.727 0 0 1 0 5.453ZM82.634 142.813H45.18a2.726 2.726 0 0 1 0-5.453h37.455a2.727 2.727 0 0 1 0 5.453ZM35.335 131.528a2.728 2.728 0 0 1-.003-5.453h.003a2.727 2.727 0 0 1 0 5.453Z"
        />
        <Path
          fill="#DC4359"
          d="M141.358 82.002c-24.559 0-44.468-19.908-44.468-44.468 0-15.504 7.937-29.153 19.967-37.111-13.977 1.873-27.024 9.942-34.433 22.933-11.177 19.597-6.545 43.932 9.925 58.184a3.704 3.704 0 0 1 1.258 3.026l-.733 12.228a.832.832 0 0 0 1.288.744l10.867-7.175a2.174 2.174 0 0 1 1.939-.234c16.801 6.116 35.596 1.944 48.244-10.331a44.401 44.401 0 0 1-13.854 2.204Z"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h170v170H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default SmartphoneSVG;
