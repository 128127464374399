import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const ClockSVG = (props) => {
  const {...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      width={20}
      height={20}
      {...restProps}>
      <Path
        fill="#2FBF86"
        d="M10 17c3.307 0 6-2.693 6-6s-2.693-6-6-6-6 2.693-6 6 2.693 6 6 6Zm0-11.2c2.869 0 5.2 2.331 5.2 5.2s-2.331 5.2-5.2 5.2A5.205 5.205 0 0 1 4.8 11c0-2.869 2.331-5.2 5.2-5.2Z"
      />
      <Path
        fill="#2FBF86"
        d="M12.303 13.905a.425.425 0 0 0 .603-.065.43.43 0 0 0-.065-.603l-1.982-1.584V8.429a.43.43 0 0 0-.43-.429.43.43 0 0 0-.429.43v3.426c0 .13.06.254.16.334l2.143 1.715Z"
      />
    </Svg>
  );
};
export default ClockSVG;
