import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
const NewSVG = (props) => {
  const {style, fill, ...restProps} = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 36"
      style={style}
      {...restProps}>
      <Path
        fill="#8D09FE"
        d="M0 12a2 2 0 0 1 2-2h31.599a1 1 0 0 1 .824 1.566L30 18l4.423 6.433A1 1 0 0 1 33.599 26H2a2 2 0 0 1-2-2V12Z"
      />
      <Path
        fill="#fff"
        d="M10.063 14.18V22H8.452L5.31 16.758V22H3.698v-7.82H5.31l3.147 5.247V14.18h1.606Zm6.65 6.563V22H12.55v-1.257h4.162Zm-3.637-6.563V22h-1.611v-7.82h1.611Zm3.094 3.185v1.224h-3.62v-1.224h3.62Zm.537-3.185v1.262H12.55V14.18h4.157Zm2.83 6.58 1.558-6.58h.87l.2 1.095L20.504 22h-.935l-.032-1.24Zm-.8-6.58 1.29 6.58-.108 1.24h-1.042l-1.735-7.82h1.595Zm4.797 6.552 1.273-6.552h1.595L24.672 22H23.63l-.096-1.268Zm-1.08-6.552 1.569 6.606L23.98 22h-.935l-1.67-6.73.214-1.09h.865Z"
      />
    </Svg>
  );
};
export default NewSVG;
