import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';

const AddUserSVG = (props) => {
  const {style, ...restProps} = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 18 18"
      style={style}
      {...restProps}>
      <G clipPath="url(#a)">
        <Path
          fill="#F7F6F0"
          d="M7.27.108c-2.09 0-3.765 1.434-3.765 4.176 0 1.79.713 3.614 1.795 4.737.423 1.124-.339 1.542-.497 1.602-2.185.802-4.739 2.255-4.739 3.7v.54c0 1.968 3.749 2.423 7.226 2.423.758-.002 1.516-.03 2.272-.086a5.365 5.365 0 0 1-1.47-3.7 5.37 5.37 0 0 1 1.123-3.288C9.112 9.968 9.07 9.584 9.26 9c1.076-1.125 1.774-2.933 1.774-4.717C11.033 1.543 9.355.11 7.27.11V.108Zm6.23 9.021a4.37 4.37 0 1 0 0 8.74 4.37 4.37 0 0 0 0-8.74Zm-.606 1.948h1.212v1.796h1.796v1.254h-1.796v1.796h-1.212v-1.796h-1.817v-1.254h1.817v-1.796Z"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h18v18H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
export default AddUserSVG;
