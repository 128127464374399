import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
const CreditCardSVG = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}>
    <Path
      fill="#77838F"
      d="M3.6 3.2a2 2 0 0 0-2 2v5.6a2 2 0 0 0 2 2h8.8a2 2 0 0 0 2-2V5.2a2 2 0 0 0-2-2H3.6Zm-1.2 2A1.2 1.2 0 0 1 3.6 4h8.8a1.2 1.2 0 0 1 1.2 1.2v5.6a1.2 1.2 0 0 1-1.2 1.2H3.6a1.2 1.2 0 0 1-1.2-1.2V5.2Zm1.2-.4a.4.4 0 1 0 0 .8h4a.4.4 0 1 0 0-.8h-4Zm0 1.6a.4.4 0 1 0 0 .8H10a.4.4 0 1 0 0-.8H3.6ZM4 8.8a.8.8 0 0 0-.8.8v.8a.8.8 0 0 0 .8.8h3.2a.8.8 0 0 0 .8-.8v-.8a.8.8 0 0 0-.8-.8H4Z"
    />
  </Svg>
);
export default CreditCardSVG;
