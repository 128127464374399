import React from 'react';
import Svg, {Path} from 'react-native-svg';

const NotificationSVG = (props) => {
  const {style, fill, ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 33"
      fill="none"
      style={style}
      {...restProps}>
      <Path
        fill={fill}
        d="m29.13 20.35-2.785-2.748v-3.64a10.085 10.085 0 0 0-2.663-6.804 10.357 10.357 0 0 0-6.599-3.294v-2.08h-2.058v2.08a10.356 10.356 0 0 0-6.6 3.294 10.085 10.085 0 0 0-2.662 6.805v3.639l-2.786 2.747c-.193.19-.301.449-.301.718v3.044c0 .27.108.528.301.718.193.19.455.297.728.297h7.204v.789a5.186 5.186 0 0 0 1.292 3.512 5.324 5.324 0 0 0 3.338 1.764 5.22 5.22 0 0 0 2.121-.23 5.17 5.17 0 0 0 1.844-1.058 5.083 5.083 0 0 0 1.252-1.704 5.021 5.021 0 0 0 .444-2.058v-1.015h7.203c.273 0 .535-.107.728-.297.193-.19.301-.448.301-.718v-3.044c0-.27-.108-.527-.301-.718Zm-9.989 5.791c0 .808-.325 1.582-.904 2.153a3.11 3.11 0 0 1-2.183.892 3.11 3.11 0 0 1-2.183-.892 3.023 3.023 0 0 1-.904-2.153v-1.015h6.174v1.015Zm8.233-3.044H4.734v-1.61L7.52 18.74c.193-.19.301-.449.301-.718v-4.06c0-2.153.868-4.218 2.412-5.74a8.292 8.292 0 0 1 5.821-2.379c2.184 0 4.278.856 5.822 2.378a8.063 8.063 0 0 1 2.41 5.742v4.06c0 .268.11.526.302.717l2.786 2.747v1.61Z"
      />
    </Svg>
  );
};

export default NotificationSVG;
