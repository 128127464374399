const getMonthName = (monthIndex) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return months[monthIndex];
};

export const formattedDateUtils = (startDate) =>
  `${startDate.getDate().toString().padStart(2, '0')} ${getMonthName(
    startDate.getMonth(),
  )} ${startDate.getFullYear()} at ${startDate
    .getHours()
    .toString()
    .padStart(2, '0')}:${startDate.getMinutes().toString().padStart(2, '0')}`;
