import React, {useState} from 'react';
import styles from './DropDownInputStyles';
import Colors from '../../theme/colors';
import {ScrollView, Text, TextInput, View} from 'react-native';
import SubheadingA from '../subheadingA/SubheadingA';
import TouchableItem from '../TouchableItem';
import DownArrowSVG from '../svgs/DownArrowSVG';

const DropDownInput = ({
  label,
  placeholder,
  icon,
  dataArray,
  value,
  onChangeText,
  isFocused,
  onFocus,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  return (
    <View style={styles.inputRow}>
      <SubheadingA style={styles.label}>
        {label}
        <Text style={{color: Colors.red}}> *</Text>
      </SubheadingA>
      <TextInput
        placeholder={placeholder}
        placeholderTextColor={Colors.lightGray}
        maxLength={60}
        style={[styles.textInput, {paddingLeft: 44}]}
        value={value} // Use the provided value prop
        onChangeText={onChangeText} // Use the provided onChangeText prop
        isFocused={isFocused}
        onFocus={onFocus}
      />

      {icon && <View style={styles.inputLeftIcon}>{icon}</View>}
      <TouchableItem
        onPress={() => setDropdownVisible(!dropdownVisible)}
        style={styles.inputRightIcon}>
        <DownArrowSVG
          fill={'#77838F'}
          style={{transform: dropdownVisible ? [{scaleY: -1}] : []}}
        />
      </TouchableItem>

      {dropdownVisible && (
        <ScrollView style={styles.dropdown}>
          {dataArray.map((value, index) => (
            <TouchableItem
              key={index}
              onPress={() => {
                onChangeText(value); // Update the value directly
                setDropdownVisible(false);
              }}
              style={styles.dropdownItem}>
              <Text>{value}</Text>
            </TouchableItem>
          ))}
        </ScrollView>
      )}
    </View>
  );
};

export default DropDownInput;
