import {StyleSheet, Platform} from 'react-native';
import Colors from '../../theme/colors';

const styles = StyleSheet.create({
  touchableItem: {
    marginTop: 12,
    marginHorizontal: 25,
  },
  cardContainer: {
    width: '100%',
    borderRadius: 15,
    position: 'relative',
  },
  newIcon: {
    width: 36,
    height: 36,
    position: 'absolute',
    top: -12,
    right: -6,
    zIndex: 99,
  },
  // favoriteIconContainer: {
  //   width: 33,
  //   height: 33,
  //   borderRadius: 50,
  //   // backgroundColor: Colors.darkGreen,
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   position: 'absolute',
  //   top: 80,
  //   right: 7,
  //   zIndex: 2,
  // },
  favoriteIcon: {
    width: '50%',
    height: '50%',
  },
  cardImage: {
    width: '100%',
    height: 120,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
  },
  cardContent: {
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    backgroundColor: Colors.white,
    flexDirection: 'column',
    paddingTop: 15,
    paddingBottom: 20,
    paddingHorizontal: 20,
  },
  TitleRow: {},
  subheadingRow: {
    marginTop: 10,
  },
  bodyRow: {
    flexDirection: 'row',
    marginTop: 10,
  },
  rowIcon: {
    width: 21,
    height: 21,
    marginRight: 10,
  },
  scrollView: {
    width: '80%',
  },
  eventName: {
    color: Colors.darkGreen,
  },
});

export default styles;
