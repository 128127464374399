import {StyleSheet} from 'react-native';
import Colors from '../../theme/colors';

const styles = StyleSheet.create({
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: Colors.lightGray,
  },
  screenContainer: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  scrollView: {
    paddingVertical: 10,
    paddingHorizontal: 25,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  scrollView: {
    paddingVertical: 10,
    paddingHorizontal: 25,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 46,
    gap: 24,
    // marginVertical: 10,
    marginBottom: 24,
    marginTop: 24,
  },
  image: {
    width: 74,
    height: 74,
    borderRadius: 74,
  },
  title: {
    flexGrow: 1,
  },
  buttonContainer: {
    width: 10,
    height: 15,
    marginLeft: 23,
  },
  button: {
    transform: [{scaleX: -1}],
  },
});

export default styles;
