import React, {useEffect, useState, useContext, useCallback} from 'react';
import styles from './CartCStyles';
import Colors from '../../theme/colors';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import TitleBigA from '../../components/titleBigA/TitleBigA';
import TouchableItem from '../../components/TouchableItem';
import TimeSVG from '../../components/svgs/TimeSVG';
import CartSVG from '../../components/svgs/CartSVG';
import TicketSVG from '../../components/svgs/TicketSVG';
import PriceSVG from '../../components/svgs/PriceSVG';
import BackArrowSVG from '../../components/svgs/BackArrowSVG';
import LocationSVG from '../../components/svgs/LocationSVG';
import TimeIconSVG from '../../components/svgs/TimeIconSVG';
import {db, FUNCTIONS} from '../../services/firebase';
import {AuthContext} from '../../contexts/context';
import {useNavigation} from '@react-navigation/native';
import {useTranslation} from 'react-i18next';

const CartC = () => {
  const {t} = useTranslation();
  const {user} = useContext(AuthContext);
  const [orderStatus, setOrderStatus] = useState(true);
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation();

  const queryFn = async (orderStatus) => {
    setLoading(true);
    // const status = orderStatus ? 'ordered': 'preordered';
    const qAllReservation = db
      .collection('orders')
      .where('clientid', '==', user.uid);
    if (!orderStatus) {
      qAllReservation.where('status', '==', 'delivered');
    }
    // if (!orderStatus) qAllReservation.where('status', '!=', 'ordered');

    const reservationSnap = await qAllReservation.get();
    let reservationList = [];

    for (const doc of reservationSnap.docs) {
      const docdata = doc.data();
      reservationList.push({...docdata, id: doc.id});
    }
    setReservations(reservationList);
    setLoading(false);
  };

  useEffect(() => {
    queryFn(orderStatus);
  }, [orderStatus]);

  const handleButtonClick = () => {
    setOrderStatus(true);
  };

  const handelButtonClickLivre = () => {
    setOrderStatus(false);
  };

  const handdleRedirection = useCallback(
    (id) => {
      const direction = orderStatus ? 'Command' : 'LivredCommand';
      navigation.navigate(direction, {
        id,
      });
    },
    [orderStatus],
  );

  if (loading) {
    return (
      <SafeAreaView style={styles.screenContainer}>
        <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />

        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <ActivityIndicator size="large" color="green" />
        </View>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}>
        <View style={styles.screenContainerBtn}>
          <TouchableOpacity onPress={handleButtonClick}>
            <View>
              <Text
                style={[
                  styles.textContainer,
                  {
                    color: orderStatus
                      ? 'var(--Dark-Green, #2FBF86)'
                      : 'var(--Normal-Grey, #77838F)',
                  },
                ]}>
                {/* In progress */}
                {t('CartC.En cours')}
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={handelButtonClickLivre}>
            <Text
              style={[
                styles.textContainer,
                {
                  color: orderStatus
                    ? 'var(--Normal-Grey, #77838F)'
                    : 'var(--Dark-Green, #2FBF86)',
                },
              ]}>
              {/* Liveries */}
              {t('CartC.Livrées')}
            </Text>
          </TouchableOpacity>
        </View>
        <View style={orderStatus ? styles.line : styles.lineLivre} />

        <View style={styles.wrapper}>
          {reservations.map((reservations) => {
            const food = reservations.products.reduce((acc, p) => {
              return acc + ' ' + p.quantity + ' ' + p.name;
            }, '');
            return (
              <TouchableOpacity
                onPress={() => handdleRedirection(reservations.id)}>
                <View style={styles.descriptionContainer}>
                  <View style={styles.description}>
                    <View style={styles.title}>
                      <View>
                        <CartSVG
                          stroke={Colors.darkGray}
                          style={styles.cartIcon}
                        />
                      </View>
                      <View>
                        <TitleBigA>{reservations.store.name}</TitleBigA>
                      </View>
                    </View>
                    <View style={styles.detail}>
                      <View style={[styles.icon, {width: 16, height: 16}]}>
                        <TicketSVG stroke={Colors.normalGray} />
                      </View>
                      <View style={{flexWrap: 'wrap', flexShrink: 1}}>
                        <Text>{food}</Text>
                      </View>
                    </View>
                    <View style={styles.detail}>
                      <View style={styles.icon}>
                        <LocationSVG />
                      </View>
                      <View style={{flexWrap: 'wrap', flexShrink: 1}}>
                        <Text style={{color: Colors.normalGray}}>
                          {/* Deliver to the address 1 Bd de Bonne Nouvelle, 75002
                          Paris */}
                          {t(
                            'CartC.Livrer à l adresse 1 Bd de Bonne Nouvelle 75002 Paris',
                          )}
                        </Text>
                      </View>
                    </View>
                    <View>
                      <View style={styles.detail}>
                        <View style={styles.icon}>
                          <TimeSVG />
                        </View>
                        <View>
                          <Text style={{color: Colors.normalGray}}>
                            {/* Open - 10:00 - 23:00 */}
                            {t('CartC.Ouvert - 10h00 - 23h00')}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.detail}>
                        <View style={styles.icon}>
                          <PriceSVG />
                        </View>
                        <View>
                          <Text style={{fontFamily: 'SemiBold'}}>38.99 €</Text>
                        </View>
                      </View>
                    </View>
                    <View style={{flexDirection: 'row', paddingTop: 5, gap: 3}}>
                      <TimeIconSVG />
                      <Text style={styles.textEnCour}>
                        {' '}
                        {orderStatus
                          ? 'In the process of delivery'
                          : 'livred'}{' '}
                      </Text>
                    </View>
                  </View>
                  <View style={{flexShrink: 0}}>
                    <TouchableItem>
                      <BackArrowSVG
                        style={styles.backIcon}
                        fill={Colors.normalGray}
                      />
                    </TouchableItem>
                  </View>
                </View>
                <View style={styles.separator} />
              </TouchableOpacity>
            );
          })}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default CartC;
