/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

// import dependencies
import React, {Component} from 'react';
import {
  I18nManager,
  Platform,
  SafeAreaView,
  StatusBar,
  Text,
  ActivityIndicator,
  View,
} from 'react-native';

import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
//import { Swiper, SwiperSlide } from 'swiper';
//import Swiper from 'react-native-swiper';
import Swiper from 'react-native-web-swiper';
import locales from '../../config/locales';
// import components
import Button from '../../components/buttons/Button';
import CreditCard from '../../components/creditcard/CreditCard';
import InfoModal from '../../components/modals/InfoModal';
import LinkButton from '../../components/buttons/LinkButton';
import {Caption, Subtitle1, Subtitle2} from '../../components/text/CustomText';
import UnderlineTextInput from '../../components/textinputs/UnderlineTextInput';
import axios from 'axios';
import {PaymentsStripe as Stripe} from 'expo-payments-stripe';
// import colors, layout
import Colors from '../../theme/colors';

import EmptyState from '../../components/emptystate/EmptyState';
import {db} from '../../services/firebase';
import {connect} from 'react-redux';
import {AuthContext} from '../../contexts/context';
import {bindActionCreators} from 'redux';
import {emptyCart} from '../../reducers/cartactions';
import styles from './CheckoutAStyles.js';
import firebase from 'firebase/compat/app';
import getEnvVars from '../../config';
const {stripe, api} = getEnvVars();
// CheckoutA Config
const isRTL = I18nManager.isRTL;
const EMPTY_STATE_ICON = 'credit-card';
const INPUT_FOCUSED_BORDER_COLOR = Colors.primaryColor;
const CHECKMARK_ICON =
  Platform.OS === 'ios'
    ? 'ios-checkmark-circle-outline'
    : 'md-checkmark-circle-outline';

const FAIL_ICON =
  Platform.OS === 'ios'
    ? 'ios-close-circle-outline'
    : 'md-close-circle-outline';
const errorMessages = {
  incorrect_number: 'The card number is incorrect.',
  invalid_number: 'The card number is not a valid credit card number.',
  invalid_expiry_month: "The card's expiration month is invalid.",
  invalid_expiry_year: "The card's expiration year is invalid.",
  invalid_cvc: "The card's security code is invalid.",
  expired_card: 'The card has expired.',
  incorrect_cvc: "The card's security code is incorrect.",
  incorrect_zip: "The card's zip code failed validation.",
  card_declined: 'The card was declined.',
  missing: 'There is no card on a customer that is being charged.',
  processing_error: 'An error occurred while processing the card.',
  rate_limit:
    "An error occurred due to requests hitting the API too quickly. Please let us know if you're consistently running into this error.",
};

const declineMessages = {
  generic_decline: 'Cette carte est refusée.',
  insufficient_funds: 'Fonds insuffisants.',
  lost_card: 'Cette carte est perdue.',
  stolen_card: 'Cette carte est volée.',
};
import {LogBox} from 'react-native';

//Ignore all log notifications
LogBox.ignoreAllLogs();

// CheckoutA
class CheckoutA extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      card: null,
      customer: null,
      bytoken: false,
      infoModalVisible: false,
      modalIcon: '',
      modalTitle: '',
      modalMessage: '',
      cardLoading: false,
      token: null,
      dbUser: null,
      hasCustomer: false,
      userLoading: false,
    };
  }

  async componentDidMount() {
    const {user} = this.context;
    //console.log("mount user");
    //console.log(user);
    Stripe.setOptionsAsync({
      publishableKey: stripe.publishableKey,
      //androidPayMode: 'test', // [optional] used to set wallet environment (AndroidPay)
    });
    this.setState({userLoading: true});

    const observer = db
      .collection('users')
      .doc(user.uid)
      .onSnapshot(
        (docSnapshot) => {
          //console.log(`Received doc snapshot: ${docSnapshot.data()}`);
          //console.log(docSnapshot.data());
          var docuser = docSnapshot.data();
          if ('customerId' in docuser) {
            //console.log("hascustomer docuser");
            //console.log(docuser.customerId);
            this.setState({cardLoading: true});
            axios({
              method: 'GET',
              url: `${api.cards.getdefault}?custid=${docuser.customerId}`,
            })
              .then(async (response) => {
                //console.log("stripe response");
                if (response.data != null) {
                  //console.log(response.data);
                  let card = response.data;
                  this.setState({
                    card: card,
                    hasCustomer: true,
                    cardLoading: false,
                  });
                } else {
                }
              })
              .catch((e) => {
                //console.log("api error");
                this.setState({cardLoading: false, userLoading: false});
                //console.log(e)
              });
          } else {
            this.setState({cardLoading: false});
          }
          this.setState({dbUser: docuser, userLoading: false});
        },
        (err) => {
          //console.log(`Encountered error: ${err}`);
          this.setState({userLoading: false, cardLoading: false});
          //console.error("Error adding document: ", error);
        },
      );
  }

  navigateTo = (screen) => () => {
    const {navigation} = this.props;
    navigation.navigate(screen);
  };

  goBack = () => {
    const {navigation} = this.props;
    navigation.goBack();
  };

  clearInputs = () => {
    this.address.clear();
    this.city.clear();
    this.zip.clear();
  };

  focusOn = (nextFiled) => () => {
    if (nextFiled) {
      nextFiled.focus();
    }
  };

  onIndexChanged = (index) => {
    let activeIndex;
    if (isRTL) {
      activeIndex = 2 - index; // 2 = 3 steps - 1
    } else {
      activeIndex = index;
    }
    this.setState({
      activeIndex: activeIndex,
    });
  };

  nextStep = () => {
    this.swiper.scrollBy(1, true);
  };

  previousStep = () => {
    this.swiper.scrollBy(-1, true);
  };

  showInfoModal = (value) => async () => {
    const {user} = this.context;
    const storeRef = db.collection('organisers').doc('hnUwyRENRVnIBH2o25NA');
    const increment = firebase.firestore.FieldValue.increment(1);
    let total = this.props.cart.products.reduce(function (prev, cur) {
      return prev + cur.total;
    }, 0);
    total = total * 100;
    axios({
      method: 'GET',
      url: `${api.charges.create}?total=${total}&token=${this.state.dbUser.customerId}&merchant=hnUwyRENRVnIBH2o25NA`,
    })
      .then(async (response) => {
        // Atomically increment the population of the city by 50.
        await storeRef.update({ordersCount: increment});
        var res = await storeRef.get();
        //console.log("storeref");
        //console.log(res.data());
        //console.log(this.props);
        db.collection('orders')
          .add({
            orderNumber: res.data().ordersCount.toString(),
            clientid: user.uid,
            storeid: 'hnUwyRENRVnIBH2o25NA',
            products: this.props.cart.products,
            status: 'ordered',
            //payment: response.id,
            updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
          })
          .then((docRef) => {
            //console.log("Document written with ID: ", docRef.id);
            this.props.emptyCart();
          })
          .catch((error) => {
            //console.error("Error adding document: ", error);
          });
        this.setState({
          modalIcon: CHECKMARK_ICON,
          modalTitle: 'Success',
          modalMessage:
            'Order placed successfully. For more details check your orders.',
          modalIconColor: Colors.primaryColor,
          infoModalVisible: value,
        });
      })
      .catch((error) => {
        console.error('Error adding document: ', error);

        if (error.response) {
          //console.log("error.reponse");
          //console.log(error.response);
        }

        this.setState({
          modalIcon: FAIL_ICON,
          modalTitle: 'Echec',
          modalMessage: 'Echec de paiement',
          modalIconColor: Colors.secondaryColor,
          infoModalVisible: value,
        });
      });
  };

  getCreditCardInfo = () => {
    console.log('display card');
    console.log(this.state.cardLoading);
    if (this.state.cardLoading) {
      return (
        <View style={{paddingTop: 30}}>
          <ActivityIndicator size="large" color={Colors.primaryColor} />
        </View>
      );
    }
    if (this.state.card) {
      return (
        <CreditCard
          colors={['#784BA0', '#2B86C5']}
          brand={this.state.card.brand.toLowerCase()}
          last4Digits={this.state.card.last4}
          cardHolder={`${this.state.dbUser.firstName} ${this.state.dbUser.lastName}`}
          expiry={`${this.state.card.exp_month} / ${this.state.card.exp_year}`}
        />
      );
    } else {
      console.log('no card found');
      return (
        <View style={{paddingTop: 150, paddingBottom: 50}}>
          <EmptyState
            showIcon={true}
            iconName={EMPTY_STATE_ICON}
            title="Pas de carte bancaire"
            message="Ajouter une carte bancaire pour pouvoir passer votre commande"
          />
        </View>
      );
    }
  };

  closeInfoModal = (value) => () => {
    this.setState(
      {
        infoModalVisible: value,
      },
      () => {
        this.goBack();
      },
    );
  };

  handleCardDetails = async () => {
    const {user} = this.context;
    try {
      this.setState({cardLoading: true});
      const cardOptions = {
        /*requiredBillingAddressFields: 'full',
           prefilledInformation: {
             billingAddress: {
               name: 'Test Name',
               line1: 'Test Line 1',
               line2: '4',
               city: 'Test City',
               state: 'Test State',
               country: 'Test Country',
               postalCode: '31217'
             }
          }*/
      };
      // GETS YOUR TOKEN FROM STRIPE FOR PAYMENT PROCESS
      const token = await Stripe.paymentRequestWithCardFormAsync(cardOptions);
      //console.log("returned token");
      //console.log(token);

      if (!this.state.hasCustomer) {
        var data = {
          uid: user.uid,
          email: this.state.dbUser.email,
          name: `${this.state.dbUser.firstName} ${this.state.dbUser.lastName}`,
          token: token?.tokenId,
        };
        //console.log(config.api.customers.create);
        var options = {
          method: 'post',
          url: api.customers.create,
          headers: {
            'Content-Type': 'application/json',
          },
          data: data,
        };

        axios(options)
          .then((response) => {
            //console.log(JSON.stringify(response.data));
            db.collection('users')
              .doc(user.uid)
              .set(
                {
                  customerId: response.data.id,
                },
                {merge: true},
              )
              .then((resp) => {
                this.setState({
                  card: {
                    brand: token.card.brand,
                    last4: token.card.last4,
                    exp_month: token.card.expMonth,
                    exp_year: token.card.expYear,
                    name: token.card.name,
                  },
                });
                this.setState({
                  cardLoading: false,
                  loading: false,
                  token: null,
                  customerId: response.data.id,
                });
                //this.props.navigation.navigate('Home', {result: true});
              })
              .catch((e) => console.log(e));
          })
          .catch((error) => {
            this.setState({cardLoading: false, loading: false, token: null});
            console.log(error);
          });
      } else {
        this.setState({cardLoading: true});
        var options = {
          method: 'get',
          url: `${api.cards.create}?custid=${this.state.dbUser.customerId}&token=${token?.tokenId}`,
        };

        axios(options)
          .then((response) => {
            //console.log("JSON.stringify(response.data)");
            //console.log(JSON.stringify(response));

            this.setState({
              card: {
                brand: token.card.brand,
                last4: token.card.last4,
                exp_month: token.card.expMonth,
                exp_year: token.card.expYear,
                name: token.card.name,
              },
            });
            this.setState({cardLoading: false});
          })
          .catch((error) => {
            //console.log("error.response");
            //console.log(error);

            if (error.response) {
              //console.log(error.response.data);

              let resperror = error.response.data;
              //console.log(resperror.statusCode);
              if (resperror.statusCode === 402) {
                if (resperror.code === 'incorrect_cvc') {
                  //console.log("resperror.statusCode");
                  this.setState({
                    infoModalVisible: true,
                    modalIcon: FAIL_ICON,
                    modalMessage: 'Le cvc est incorrect',
                    modalTitle: "La carete n'est pas ajoutée",
                    modalIconColor: Colors.secondaryColor,
                  });
                } else if (resperror.code === 'card_declined') {
                  this.setState({
                    infoModalVisible: true,
                    modalIcon: FAIL_ICON,
                    modalMessage: declineMessages[resperror.decline_code],
                    modalTitle: errorMessages[resperror.code],
                    modalIconColor: Colors.secondaryColor,
                  });
                } else {
                  this.setState({
                    infoModalVisible: true,
                    modalIcon: FAIL_ICON,
                    modalMessage: 'les inforamtions de la cartes sont fausses',
                    modalTitle: errorMessages[resperror.code],
                    modalIconColor: Colors.secondaryColor,
                  });
                }
              } else {
                this.setState({
                  infoModalVisible: true,
                  modalIcon: FAIL_ICON,
                  modalMessage: 'les inforamtions de la cartes sont fausses',
                  modalTitle: resperror.code,
                  modalIconColor: Colors.secondaryColor,
                });
              }
            }
          })
          .finally(() => {
            this.setState({cardLoading: false});
          });
      }

      this.setState({token});
    } catch (error) {
      //console.log("error paying");
      //console.log(error);
      this.setState({cardLoading: false});
    }
  };

  render() {
    const {activeIndex, infoModalVisible, modalMessage, modalIconColor} =
      this.state;
    let products = this.props.cart.products;
    //console.log( this.props.cart);
    let total = products.reduce(function (prev, cur) {
      return prev + cur.total;
    }, 0);
    return (
      <SafeAreaView style={styles.screenContainer}>
        <StatusBar
          backgroundColor={Colors.statusBarColor}
          barStyle="dark-content"
        />
        <View style={styles.container}>
          <View style={styles.headerContainer}>
            <View style={styles.stepIndicator}>
              <View style={styles.stepContainer}>
                <Caption
                  style={[
                    styles.stepText,
                    activeIndex === 0 && styles.activeStepText,
                  ]}>
                  Méthode
                </Caption>
                <Caption
                  style={[
                    styles.stepText,
                    activeIndex === 0 && styles.activeStepText,
                  ]}>
                  de paiment
                </Caption>
              </View>

              <View
                style={[styles.line, activeIndex > 0 && styles.activeLine]}
              />

              <View style={styles.stepContainer}>
                <Caption
                  style={[
                    styles.stepText,
                    activeIndex === 1 && styles.activeStepText,
                  ]}>
                  Passer la
                </Caption>
                <Caption
                  style={[
                    styles.stepText,
                    activeIndex === 1 && styles.activeStepText,
                  ]}>
                  commande
                </Caption>
              </View>
            </View>
          </View>

          <KeyboardAwareScrollView
            contentContainerStyle={styles.swiperContainer}>
            <Swiper
              ref={(r) => {
                this.swiper = r;
              }}
              index={isRTL ? 2 : 0}
              onIndexChanged={this.onIndexChanged}
              loop={false}
              showsPagination={false}
              // scrollEnabled={false}
            >
              {/* STEP 1 */}
              <View>
                {this.getCreditCardInfo()}
                {/*<View style={{paddingTop: 120, justifyContent:'space-between'}}>
                  <LinkButton
                    onPress={this.navigateTo('PaymentMethod')}
                    title="Edit details"
                    titleStyle={styles.actionButton}
                  />
                  
            </View>*/}
                <View
                  style={{
                    paddingTop: 40,
                    paddingHorizontal: 24,
                    paddingBottom: 24,
                  }}>
                  <Button
                    onPress={this.handleCardDetails}
                    title="Ajouter une carte"
                  />
                  {/*<CardForm products={products}/>*/}
                </View>
              </View>
              <View style={styles.form}>
                <Subtitle2 style={[styles.overline, styles.pt16]}>
                  Méthode de paiement
                </Subtitle2>
                <Subtitle1 style={styles.orderInfo}>
                  XXXX XXXX XXXX {this.state.card?.last4}
                </Subtitle1>

                <Subtitle2 style={[styles.overline, styles.pt16]}>
                  Votre Commande
                </Subtitle2>
                <View style={styles.row}>
                  <Subtitle1 style={styles.orderInfo}>Total amount</Subtitle1>
                  <Subtitle1 style={styles.amount}>
                    {locales.financial.currency.symbol} {total}
                  </Subtitle1>
                </View>
              </View>
            </Swiper>

            <View style={styles.buttonContainer}>
              {activeIndex < 1 && (
                <Button
                  onPress={isRTL ? this.previousStep : this.nextStep}
                  title="Suivant"
                  disabled={this.state.card === null}
                />
              )}

              {activeIndex === 1 && (
                <Button
                  onPress={this.showInfoModal(true)}
                  title="Passer la commande"
                />
              )}

              {activeIndex === 0 && (
                <View style={styles.linkButtonContainer}>
                  <LinkButton
                    onPress={this.goBack}
                    title="Annuler"
                    titleStyle={styles.linkButton}
                  />
                </View>
              )}

              {activeIndex > 0 && (
                <View style={styles.linkButtonContainer}>
                  <LinkButton
                    onPress={isRTL ? this.nextStep : this.previousStep}
                    title="Back"
                    titleStyle={styles.linkButton}
                  />
                </View>
              )}
            </View>
          </KeyboardAwareScrollView>

          <InfoModal
            iconName={this.state.modalIcon}
            iconColor={modalIconColor}
            title={this.state.modalTitle.toUpperCase()}
            message={modalMessage}
            buttonTitle="Back to shopping"
            onButtonPress={this.closeInfoModal(false)}
            onRequestClose={this.closeInfoModal(false)}
            visible={infoModalVisible}
          />
        </View>
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state) => {
  const {cart} = state;
  return {cart};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      emptyCart,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutA);
