/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

// import dependencies
import React from 'react';
import {Platform, View, Image, Text} from 'react-native';
import {createStackNavigator} from '@react-navigation/stack';

// import components
import HeaderIconButton from '../components/navigation/HeaderIconButton';

// import HomeNavigator
import HomeNavigator from './HomeNavigatorA';

// import Product screen
import Product from '../screens/product/ProductB';

// import Categories screen
import Categories from '../screens/categories/CategoriesA';
import Category from '../screens/categories/CategoryA';
import Cart from '../screens/cart/CartA';

// import Search results screen
import SearchResults from '../screens/search/SearchResultsA';
import QrScanner from '../screens/scanner/QrScanner';

// import Checkout screen
import Checkout from '../screens/checkout/CheckoutA';
import CheckoutC from '../screens/checkout/CheckoutC';
import CheckoutFailed from '../screens/checkout/CheckoutFailedA';
import CheckoutSuccessA from '../screens/checkout/CheckoutSuccessA';

// import EditProfile screen
import EditProfile from '../screens/profile/EditProfileA';

import TicketCoordinates from '../screens/ticketcoordinates/TicketCoordinatesA';

// import DeliveryAddress screen
import DeliveryAddress from '../screens/address/DeliveryAddressA';

// import AddAddress screen
import AddAddress from '../screens/address/AddAddressB';

// import EditAddress screen
import EditAddress from '../screens/address/EditAddressB';

// import Payment screen
import PaymentMethod from '../screens/payment/PaymentMethodA';

import Menu from '../screens/menu/MenuA';
import Events from '../screens/events/Events';
import EventB from '../screens/event/EventB';

// import AddCreditCard screen
import AddCreditCard from '../screens/payment/AddCreditCardA';

// import Notifications screen
import Notifications from '../screens/notifications/NotificationsA';

// import Orders screen
import Orders from '../screens/orders/OrdersD';
// import Order from '../screens/order/OrderA';
import Rating from '../screens/rating/RatingA';
import Receipt from '../screens/receipt/Receipt';
// import AboutUs screen
import AboutUs from '../screens/about/AboutUsA';

// import colors
import Colors from '../theme/colors';
// import OrderAndPayment from '../screens/orderAndPayment/OrderAndPayment';
import ScreenTitleA from '../components/screenTitleA/ScreenTitleA';
import BackArrowSVG from '../components/svgs/BackArrowSVG';
import Parameters from '../screens/parameters/Parameters';
import {useNavigation} from '@react-navigation/native';
import GroupOrderPage from '../screens/groupOrder/GroupOrderPage';
//import CartC from '../screens/cart/CartC';
import Command from '../screens/Command/Command';
import LivredCommand from '../screens/Command/LivredCommand';
import {useTranslation} from 'react-i18next';

// MainNavigatorA Config
const SAVE_ICON = Platform.OS === 'ios' ? 'ios-checkmark' : 'md-checkmark';

// create stack navigator
const Stack = createStackNavigator();

// MainNavigatorA
function MainNavigatorA() {
  const {t} = useTranslation();
  return (
    <Stack.Navigator
      screenOptions={{
        cardOverlayEnabled: false,
        headerStyle: {
          backgroundColor: Colors.darkGreen,
          height: 60,
        },
        headerBackTitleVisible: false,
        headerBackImage: () => (
          <BackArrowSVG
            style={{
              width: 10,
              height: 16,
              position: 'absolute',
              left: 14,
            }}
          />
        ),
        headerTitleStyle: {
          textAlign: 'center',
          fontFamily: 'SemiBold',
          fontSize: 16,
          lineHeight: 24,
          letterSpacing: 0.64,
          color: Colors.background,
        },
        headerTitleAlign: 'center',
      }}>
      <Stack.Screen
        name="HomeNavigator"
        component={HomeNavigator}
        options={{headerShown: false}}
      />
      {/* <Stack.Screen name="Parameters" component={ParametersTabs} /> */}
      {/* <Stack.Screen
        name="Commandes"
        component={CartC}
        options={{
          title: 'Mes commandes',
        }}
      /> */}
      <Stack.Screen
        name="Categories"
        component={Categories}
        options={{
          title: 'toutes catégories',
        }}
      />
      <Stack.Screen
        name="Events"
        component={HomeNavigator}
        options={{headerShown: false}}
        //options={{ headerTitle: () => <HeaderLogo /> }}
      />

      <Stack.Screen
        name="Category"
        component={Category}
        options={
          {
            //title: 'Pizza',
          }
        }
      />
      <Stack.Screen
        name="Panier"
        component={Cart}
        options={
          {
            //title: 'Pizza',
          }
        }
      />
      <Stack.Screen
        name="Menu"
        component={Menu}
        options={
          {
            //title: 'Pizza',
          }
        }
      />
      <Stack.Screen
        name="Rating"
        component={Rating}
        options={
          {
            //title: 'Pizza',
          }
        }
      />
      <Stack.Screen
        name="QrScanner"
        component={QrScanner}
        options={
          {
            //title: 'Pizza',
          }
        }
      />
      <Stack.Screen
        name="Product"
        component={Product}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name="Receipt"
        component={Receipt}
        options={{
          title: 'Reçu',
        }}
      />
      {/* <Stack.Screen
        name="Parameters"
        component={Parameters}
        options={{
          title: 'Parameters',
          headerShown: true,
        }}
      /> */}
      <Stack.Screen
        name="SearchResults"
        component={SearchResults}
        options={{
          title: 'Résultats de recherche',
        }}
      />

      <Stack.Screen
        name="CheckoutFailed"
        component={CheckoutFailed}
        options={{headerShown: false}}
        // options={({ navigation }) => ({
        //   headerLeft: () => (
        //     <HeaderIconButton
        //       onPress={() => navigation.navigate('HomeNavigator')}
        //       name={'md-arrow-back'}
        //       color={Colors.white}
        //     />
        //   ),
        //   title: 'Echec de paiement',
        //   headerStyle: {
        //     backgroundColor: Colors.darkGreen,
        //     height: 60,
        //   },
        // })}
      />
      <Stack.Screen
        options={({navigation}) => ({
          headerLeft: () => (
            <HeaderIconButton
              onPress={() => navigation.goBack()}
              name={'md-arrow-back'}
              color={Colors.white}
            />
          ),
        })}
        name="Event"
        component={EventB}
      />
      <Stack.Screen
        name="CheckoutSuccess"
        component={CheckoutSuccessA}
        options={({navigation}) => ({
          headerLeft: () => (
            <HeaderIconButton
              onPress={() => navigation.navigate('HomeNavigator')}
              name={'md-arrow-back'}
              color={Colors.white}
            />
          ),
          // title: 'Votre paiement est passé',
          title: t('MainNavigatorATitle.Votre paiement est passé'),
          headerStyle: {
            backgroundColor: Colors.darkGreen,
            height: 60,
          },
        })}
      />
      <Stack.Screen
        name="Checkout"
        component={Checkout}
        options={{
          title: 'Paiement',
          headerStyle: {
            elevation: 0,
            shadowOpacity: 0,
          },
        }}
      />
      <Stack.Screen
        name="CheckoutStripe"
        component={CheckoutC}
        options={{
          title: 'Paiement',
          headerStyle: {
            elevation: 0,
            shadowOpacity: 0,
          },
        }}
      />
      <Stack.Screen
        name="EditProfile"
        component={EditProfile}
        options={({navigation}) => ({
          title: 'Edit Profile',
          headerRight: () => (
            <HeaderIconButton
              onPress={() => navigation.goBack()}
              name={SAVE_ICON}
              color={Colors.primaryColor}
            />
          ),
        })}
      />
      <Stack.Screen
        name="TicketCoordinates"
        component={TicketCoordinates}
        options={({navigation}) => ({
          title: 'Your contact details',
          headerRight: () => (
            <HeaderIconButton
              onPress={() => navigation.goBack()}
              name={SAVE_ICON}
              color={Colors.primaryColor}
            />
          ),
        })}
      />
      <Stack.Screen
        name="DeliveryAddress"
        component={DeliveryAddress}
        options={({navigation}) => ({
          title: 'Delivery Address',
          headerRight: () => (
            <HeaderIconButton
              onPress={() => navigation.goBack()}
              name={SAVE_ICON}
              color={Colors.primaryColor}
            />
          ),
        })}
      />
      <Stack.Screen
        name="AddAddress"
        component={AddAddress}
        options={{
          title: 'Add New Address',
        }}
      />
      <Stack.Screen
        name="EditAddress"
        component={EditAddress}
        options={{
          title: 'Edit Address',
        }}
      />
      <Stack.Screen
        name="PaymentMethod"
        component={PaymentMethod}
        options={{
          title: 'Payment Method',
        }}
      />
      <Stack.Screen
        name="AddCreditCard"
        component={AddCreditCard}
        options={{
          title: 'Add Credit Card',
        }}
      />
      <Stack.Screen
        name="Notifications"
        component={Notifications}
        options={{
          title: 'Notifications',
        }}
      />
      <Stack.Screen
        name="Orders"
        component={Orders}
        options={{
          // title: 'Mes commandes',
          title: t('MainNavigatorATitle.Mes commandes'),
        }}
      />

      <Stack.Screen
        name="GroupOrder"
        component={GroupOrderPage}
        // options={{
        //   title: 'Group order',
        // }}
        options={{
          title: t('MainNavigatorATitle.Commande groupée'),
        }}
      />
      {/* <Stack.Screen
        name="Order"
        component={Order}
        options={{
          title: 'Ma commande',
        }}
      /> */}
      <Stack.Screen
        name="AboutUs"
        component={AboutUs}
        options={{
          title: 'A propos de nous',
        }}
      />
      <Stack.Screen
        name="Command"
        component={Command}
        initialParams={{id: ''}} // Pass eventId as initial parameter
        options={{
          title: 'Commande',
        }}
      />
    </Stack.Navigator>
  );
}

function ParametersTabs() {
  return (
    <Stack.Navigator
      initialRouteName="Parameters"
      screenOptions={({navigation}) => ({
        headerStyle: {
          backgroundColor: Colors.darkGreen,
          height: 60,
        },
        headerBackTitleVisible: false,
        headerLeft: () => (
          <HeaderIconButton
            onPress={() => navigation.goBack()}
            name={'md-arrow-back'}
            color={Colors.white}
          />
        ),
        headerTitleStyle: {
          textAlign: 'center',
          fontFamily: 'SemiBold',
          fontSize: 16,
          lineHeight: 24,
          letterSpacing: 0.64,
          color: Colors.background,
        },
        headerTitleAlign: 'center',
      })}>
      <Stack.Screen
        name="Parameters"
        component={Parameters}
        // options={{title: 'Détails Commande'}}
      />
      <Stack.Screen
        name="Command"
        component={Command}
        initialParams={{id: ''}} // Pass eventId as initial parameter
        options={{
          title: 'Commande',
        }}
      />
      <Stack.Screen
        name="LivredCommand"
        component={LivredCommand}
        initialParams={{id: ''}} // Pass eventId as initial parameter
        options={{
          title: 'Commande',
        }}
      />
    </Stack.Navigator>
  );
}

export default MainNavigatorA;
