import React from 'react';
import Colors from '../../theme/colors';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import TitleA from '../titleA/TitleA';
import BodyA from '../bodyA/BodyA';
import TouchableItem from '../TouchableItem';
import BackArrowSVG from '../svgs/BackArrowSVG';
import {useNavigation} from '@react-navigation/native';
import locales from '../../config/locales';

const styles = StyleSheet.create({
  container: {
    paddingLeft: 35,
    marginVertical: 15,
    flexDirection: 'row',
  },
  productsContainer: {
    paddingVertical: 8,
  },
});

const OrderItemC = ({orderDate, orderItems, orderId, orderNumber}) => {
  // console.log('orderItems ====>', orderItems);
  const navigation = useNavigation();

  return (
    <View style={styles.container}>
      <View
        style={{
          flex: '1 1',
        }}>
        <View>
          <TitleA
            style={{
              color: Colors.normalGray,
            }}>{`${locales.general.order} #${orderNumber}`}</TitleA>
        </View>
        <View
          style={{
            marginTop: 15,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <BodyA style={{color: Colors.normalGray}}>{orderDate}</BodyA>
          <BodyA
            style={{
              color: Colors.darkGreen,
              textAlign: 'right',
              fontFamily: 'SemiBold',
            }}>{`${orderItems.length} ${
            orderItems.length < 2 ? 'ticket' : 'tickets'
          }`}</BodyA>
        </View>
      </View>

      <View
        style={{
          width: 35,
          justifyContent: 'center',
          alignItems: 'center',
          flexShrink: 0,
        }}>
        <TouchableItem
          onPress={() => {
            navigation.navigate('Order', {
              orderId: orderId,
              orderItems: orderItems,
            });
          }}>
          <BackArrowSVG
            fill={Colors.lightGray}
            style={{width: 20, height: 20, transform: [{scaleX: -1}]}}
          />
        </TouchableItem>
      </View>
    </View>
  );
};

export default OrderItemC;
