import {StyleSheet} from 'react-native';
import Colors from '../../theme/colors';

const styles = StyleSheet.create({
  body: {
    fontFamily: 'Light',
    fontSize: 14,
    lineHeight: 21,
    letterSpacing: 0,
    color: Colors.black,
  },
});

export default styles;
