import React from 'react';
import {Text} from 'react-native';
import {ButtonText} from '../text/CustomText';
import styles from './TitleAStyles';

const TitleA = ({children, style, ...props}) => (
  <Text style={[styles.title, style]} {...props}>
    {children}
  </Text>
);

export default TitleA;
