/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

// import dependencies
import React, {Component} from 'react';
import {
  I18nManager,
  Image,
  Platform,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import {SwipeRow} from 'react-native-swipe-list-view';
import Color from 'color';
import {Subtitle1, Subtitle2} from '../text/CustomText';
// import utils
import getImgSource from '../../utils/getImgSource.js';

// import components
import Icon from '../icon/Icon';
import TouchableItem from '../TouchableItem';

// import colors
import Colors from '../../theme/colors';
import locales from '../../config/locales';
// ActionProductCardHorizontal Config
const imgHolder = require('../../../assets/images/imgholder.png');

const isRTL = I18nManager.isRTL;
const IOS = Platform.OS === 'ios';
const MINUS_ICON = IOS ? 'ios-remove' : 'md-remove';
const PLUS_ICON = IOS ? 'ios-add' : 'md-add';
const DELETE_ICON = IOS ? 'ios-close' : 'md-close';

console.log('Colors', Colors);

// ActionProductCardHorizontal Styles
const styles = StyleSheet.create({
  swipeRow: {
    borderRadius: 4,
    overflow: 'hidden',
  },
  cardBack: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: 1,
    borderRadius: 4,
    backgroundColor: Color(Colors.error).alpha(0.12).string(),
  },
  deleteButtonContainer: {
    borderBottomRightRadius: 4,
    borderTopRightRadius: 4,
    width: 88,
    overflow: 'hidden',
  },
  deleteButton: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  bg: {
    backgroundColor: Colors.background,
  },
  container: {
    margin: 4,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.08)',
    borderRadius: 4,
  },
  innerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: 4,
    backgroundColor: Colors.surface,
  },
  productImg: {
    width: 116,
    height: 126,
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4,
    resizeMode: 'cover',
  },
  productInfo: {
    flex: 1,
    justifyContent: 'space-between',
  },
  productDetails: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: 12,
  },
  title: {
    flex: 1,
    fontWeight: '500',
    fontSize: 16,
    color: Colors.primaryText,
    letterSpacing: 0.15,
    textAlign: 'left',
  },
  secondLine: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 2,
    paddingLeft: 12,
  },
  priceContainer: {},
  priceContent: {
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  oldPrice: {
    fontSize: 15,
    fontWeight: '500',
    color: '#8e8e8e',
  },
  hr: {
    position: 'absolute',
    top: 10,
    width: '82%',
    height: 1,
    backgroundColor: '#8e8e8e',
  },
  price: {
    fontWeight: '700',
    fontSize: 18,
    color: Colors.primaryText,
  },
  discountPrice: {
    fontWeight: '700',
    fontSize: 18,
    color: 'red',
  },
  actionContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 4,
  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  quantity: {
    top: -1,
    paddingHorizontal: 20,
    fontSize: 18,
    color: Colors.black,
    textAlign: 'center',
  },
  iconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    margin: 8,
    width: 28,
    height: 28,
    borderRadius: 14,
    backgroundColor: Colors.primaryColor,
  },
  newLabelContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    borderBottomRightRadius: 4,
    paddingVertical: 4,
    paddingHorizontal: 8,
    backgroundColor: Colors.secondaryColor,
  },
  warningLabelContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    borderBottomRightRadius: 4,
    paddingVertical: 4,
    paddingHorizontal: 8,
    backgroundColor: Colors.tertiaryColor,
  },
  label: {
    fontSize: 12,
    color: Colors.onPrimaryColor,
  },
  descriptionText: {
    flex: 1,
    lineHeight: 20,
    color: Colors.secondaryText,
    textAlign: 'left',
    textDecorationLine: 'underline',
  },
});
// ActionProductCardHorizontal DeleteButton
const DeleteButton = ({onPress}) => (
  <View style={styles.deleteButtonContainer}>
    <TouchableItem onPress={onPress} style={styles.deleteButton}>
      <Icon name={DELETE_ICON} size={26} color={Colors.error} />
    </TouchableItem>
  </View>
);

// ActionProductCardHorizontal State
type State = {};

// ActionProductCardHorizontal Props
type Props = {
  onPress: () => {},
  onPressRemove: () => void,
  onPressAdd: () => void,
  activeOpacity: number,
  imageUri: string,
  title: string,
  description: string,
  price: number,
  isAddDisabled: boolean,
  quantity: number,
  discountPercentage: number,
  swipeoutDisabled: boolean,
  isFree: boolean,
  swipeoutOnPressRemove: () => {},
  label: 'new',
};

// ActionProductCardHorizontal
export default class ActionProductCardHorizontal extends Component<
  Props,
  State,
> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onPressAdd = () => {
    const {onPressAdd = () => {}} = this.props;
    onPressAdd();
  };

  onPressRemove = () => {
    const {onPressRemove = () => {}} = this.props;
    onPressRemove();
  };

  renderLabel = (label) => {
    if (label === 'new') {
      return (
        <View style={styles.newLabelContainer}>
          <Text style={styles.label}>NEW</Text>
        </View>
      );
    }
    if (label === 'soldout') {
      return (
        <View style={styles.newLabelContainer}>
          <Text style={styles.label}>Sold out</Text>
        </View>
      );
    }
    if (label === 'expired') {
      return (
        <View style={styles.newLabelContainer}>
          <Text style={styles.label}>Outdated</Text>
        </View>
      );
    }
    if (label === 'comming') {
      return (
        <View style={styles.warningLabelContainer}>
          <Text style={styles.label}>Comming Soon</Text>
        </View>
      );
    }
    if (label === 'hs') {
      return (
        <View style={styles.newLabelContainer}>
          <Text style={styles.label}>Indisponible</Text>
        </View>
      );
    }

    return <View />;
  };

  renderImage = (imageUrl) => {
    if (imageUrl) {
      return (
        <Image
          defaultSource={imgHolder}
          source={getImgSource(imageUrl)}
          style={styles.productImg}
        />
      );
    }

    return <View />;
  };

  render() {
    const {
      activeOpacity,
      onPress,
      imageUri,
      title,
      isFree,
      description,
      isAddDisabled,
      price = 0,
      quantity = 0,
      discountPercentage,
      swipeoutDisabled,
      composition,
      swipeoutOnPressRemove,
      label,
    } = this.props;

    console.log('quantity', quantity);
    console.log('isAddDisabled', isAddDisabled);

    return (
      <View style={styles.container}>
        <SwipeRow
          disableLeftSwipe={isRTL ? true : swipeoutDisabled}
          disableRightSwipe={isRTL ? swipeoutDisabled : true}
          directionalDistanceChangeThreshold={16}
          rightOpenValue={isRTL ? 0 : -88}
          leftOpenValue={isRTL ? 88 : 0}
          style={styles.swipeRow}>
          <View style={styles.cardBack}>
            <DeleteButton onPress={swipeoutOnPressRemove} />
          </View>

          {/* FIX ME:
           * extra View was added because of iOS ToucableOpacity bug
           */}
          <View style={styles.bg}>
            <TouchableItem
              activeOpacity={activeOpacity}
              onPress={onPress}
              useForeground>
              <View style={styles.innerContainer}>
                {this.renderImage(imageUri)}

                <View style={styles.productInfo}>
                  <View style={styles.productDetails}>
                    <Text numberOfLines={2} style={styles.title}>
                      {title}
                    </Text>

                    <View style={styles.priceContainer}>
                      {discountPercentage ? (
                        <View style={styles.priceContent}>
                          <Text style={styles.discountPrice}>
                            {price === ''
                              ? ''
                              : `${locales.financial.currency.symbol} ${(
                                  price -
                                  price * discountPercentage * 0.01
                                ).toFixed(2)}`}
                          </Text>
                        </View>
                      ) : (
                        <View style={styles.priceContent}>
                          <Text
                            style={[
                              styles.price,
                              discountPercentage
                                ? {
                                    textDecorationLine: 'line-through',
                                    textDecorationStyle: 'solid',
                                    color: Colors.primaryText,
                                  }
                                : null,
                            ]}>
                            {isFree
                              ? 'Gratuit'
                              : `${
                                  locales.financial.currency.symbol
                                } ${price.toFixed(2)}`}
                          </Text>
                        </View>
                      )}
                      {discountPercentage && (
                        <View style={styles.priceContent}>
                          <Text
                            style={[
                              styles.price,
                              discountPercentage
                                ? {
                                    textDecorationLine: 'line-through',
                                    textDecorationStyle: 'solid',
                                    color: Colors.primaryText,
                                  }
                                : null,
                            ]}>
                            {isFree
                              ? 'Gratuit'
                              : `${
                                  locales.financial.currency.symbol
                                } ${price.toFixed(2)}`}
                          </Text>
                        </View>
                      )}
                    </View>
                  </View>

                  {composition &&
                    composition.map((compo, index) => {
                      return (
                        <>
                          <View style={styles.productDetails} key={index}>
                            <Text numberOfLines={2} style={styles.title}>
                              {compo.name}
                            </Text>
                            <Text style={styles.price}>
                              {compo.price === 0
                                ? ''
                                : `${
                                    locales.financial.currency.symbol
                                  } ${compo.price.toFixed(2)}`}
                            </Text>
                          </View>
                          <View style={styles.priceContainer}></View>
                        </>
                      );
                    })}
                  <View style={styles.secondLine}>
                    <Subtitle2 numberOfLines={2} style={styles.descriptionText}>
                      {description}
                    </Subtitle2>
                  </View>

                  <View style={styles.actionContainer}>
                    {quantity > 0 && (
                      <View style={styles.actions}>
                        <TouchableItem onPress={this.onPressRemove} borderless>
                          <View style={styles.iconContainer}>
                            <Icon
                              name={MINUS_ICON}
                              size={20}
                              color={Colors.onPrimaryColor}
                            />
                          </View>
                        </TouchableItem>

                        <Text style={styles.quantity}>{quantity}</Text>
                      </View>
                    )}
                    {label !== 'hs' &&
                      label !== 'soldout' &&
                      label !== 'comming' &&
                      label !== 'expired' && (
                        <TouchableItem
                          onPress={this.onPressAdd}
                          borderless
                          disabled={isAddDisabled}>
                          <View style={styles.iconContainer}>
                            <Icon
                              name={PLUS_ICON}
                              size={20}
                              color={Colors.onPrimaryColor}
                            />
                          </View>
                        </TouchableItem>
                      )}
                  </View>
                </View>

                {this.renderLabel(label)}
              </View>
            </TouchableItem>
          </View>
        </SwipeRow>
      </View>
    );
  }
}
