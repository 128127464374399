import {StyleSheet} from 'react-native';
import Colors from '../../theme/colors';

const styles = StyleSheet.create({
  title: {
    color: Colors.background,
  },
  container: {
    backgroundColor: Colors.darkGreen,
    height: 60,
    position: 'relative',
    paddingVertical: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconContainer: {
    position: 'absolute',
    left: 24,
  },
  icon: {
    width: 10,
    height: 16,
  },
  titleContainer: {},
  title: {
    textAlign: 'center',
    color: Colors.background,
  },
});

export default styles;
