import React from 'react';
import {Text} from 'react-native';
import styles from './SubheadingAStyles';

const SubheadingA = ({children, style, ...props}) => (
  <Text style={[styles.subheading, style]} {...props}>
    {children}
  </Text>
);

export default SubheadingA;
