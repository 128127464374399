import React, {useState, useEffect, useMemo, useCallback} from 'react';
import styles from './RestaurantsStyles.js';
import Colors from '../../theme/colors.js';
import {
  FlatList,
  SafeAreaView,
  StatusBar,
  Text,
  View,
  ScrollView,
  Image,
} from 'react-native';
// Import redux
import {connect} from 'react-redux';
import {useNavigation} from '@react-navigation/native';
import {bindActionCreators} from 'redux';
import {
  addProduct,
  setStore,
  setLastId,
  setTierMode,
} from '../../reducers/cartactions.js';
// Import Firebase
import {db} from '../../services/firebase.js';
// import cities array
import HeaderA from '../../components/headerA/HeaderA.js';
import TitleA from '../../components/titleA/TitleA.js';
import SearchBarA from '../../components/searchBarA/searchBarA.js';
import EventCardA from '../../components/eventCardA/EventCardA.js';
import TouchableItem from '../../components/TouchableItem.js';
// import SVG
import DownArrowSVG from '../../components/svgs/DownArrowSVG.js';
import FavoriteSVG from '../../components/svgs/FavoriteSVG.js';
import {useContext} from 'react';
import FavoritesEventsContext from '../../contexts/favoritesEventsContext.js';
import SubheadingA from '../../components/subheadingA/SubheadingA.js';
import {getServicesData} from './servicesData.js';
import TitleBigA from '../../components/titleBigA/TitleBigA.js';
import {categoriesData} from './categoriesData.js';
import getImgSource from '../../utils/getImgSource.js';
import FoodCard from '../../components/foodCard/FoodCard.js';
import {promotionCardData} from './promotionCardData.js';
import VerticalFoodCard from '../../components/foodCard/VerticalFoodCard.js';
import AnnounceSVG from '../../components/svgs/AnnounceSVG.js';
import {AuthContext} from '../../contexts/context.js';
import {useTranslation} from 'react-i18next';

// restaurantList
const Restaurants = ({navigation}) => {
  const {t} = useTranslation();
  const servicesData = getServicesData(t);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeliveryAnnounce, setShowDeliveryAnnounce] = useState(true);

  const favoritesContext = useContext(FavoritesEventsContext);
  const [showFavorites, setShowFavorites] = useState(false);

  const [restaurantList, setRestaurantList] = useState([]);

  const [search, setSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const [selectCityDisplay, setSelectCityDisplay] = useState(false);
  const {user} = useContext(AuthContext);

  const [searchCity, setSearchCity] = useState(false);
  const [searchCityQuery, setSearchCityQuery] = useState('');

  const [noMatchingEvents, setNoMatchingEvents] = useState(false);

  const Addresses = useMemo(() => {
    return Array.from(
      new Set(restaurantList?.map((item) => item.adress)),
    ).filter((address) => address !== undefined);
  }, [restaurantList]);

  const checkUserTableGroupeData = useCallback(async () => {
    return db
      .collection('users')
      .doc(user.uid)
      .get()
      .then(async (data) => {
        const userData = data.data();
        return userData;
      });
  }, []);

  const getTableGroupeData = useCallback(async (id) => {
    return db
      .collection('groupedorders')
      .doc(id)
      .get()
      .then(async (data) => {
        const groupedordersData = data.data();
        return groupedordersData;
      });
  }, []);

  const queryFn = async () => {
    try {
      setIsLoading(true);

      const qAllEvents = db
        .collection('organisers')
        .where('type', '==', 'Restaurant');

      const qSearchedEvents = qAllEvents.where(
        'name',
        '==',
        searchQuery.trim(),
      );

      const qSearchedByCityEvents = qAllEvents.where(
        'address',
        '==',
        searchCityQuery.trim(),
      );

      const eventSnapshots =
        searchQuery.trim() !== ''
          ? await qSearchedEvents.get()
          : searchCityQuery.trim() !== ''
          ? await qSearchedByCityEvents.get()
          : await qAllEvents.get();
      const restaurantData = [];

      for (const doc of eventSnapshots.docs) {
        const docdata = doc.data();

        restaurantData.push({...docdata, id: doc.id});
      }

      const userDAta = await checkUserTableGroupeData();
      if (userDAta.activeGroupedTable) {
        const tableData = await getTableGroupeData(userDAta.activeGroupedTable);
        restaurantData.sort((a, b) => {
          if (a.id === tableData.storeid) return -1;
          else if (b.id === tableData.storeid) return 1;
          else return 0;
        });
        const exist = tableData.users.find((d) => d.userId === user.uid);
        if (exist) restaurantData[0] = {...restaurantData[0], exist: true};
      }

      setRestaurantList(restaurantData);
      setIsLoading(false);

      setSearchQuery('');

      setSearchCityQuery('');

      if (
        restaurantData.length === 0 ||
        (searchCity && eventsData.length === 0)
      ) {
        setNoMatchingEvents(true);
      } else {
        setNoMatchingEvents(false);
      }

      if (selectCityDisplay) {
        setSelectCityDisplay(false);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await queryFn();
      } catch (error) {
        console.log('error', error);
      }
    };

    fetchData();
  }, [search, searchCity]);

  const renderServices = ({item}) => {
    return (
      <TouchableItem
        onPress={() => {
          navigation.navigate(item.routeName);
        }}>
        <View style={styles.item}>
          <View style={styles.icon}>{item.icon}</View>
          <View style={styles.title}>
            <SubheadingA style={{color: Colors.darkGray}}>
              {item.title}
            </SubheadingA>
          </View>
        </View>
        <View style={item.title === 'Restaurants' && styles.borderTitle} />
      </TouchableItem>
    );
  };
  const renderCotegorie = ({item}) => {
    return (
      <TouchableItem
        onPress={() => {
          navigation.navigate(item.routeName);
        }}>
        <View style={styles.item}>
          <Image
            source={getImgSource(item.image)}
            style={styles.CategoryImage}
            resizeMode="contain"
          />
          <View style={styles.title}>
            <SubheadingA style={{color: Colors.darkGray, marginTop: 8}}>
              {item.title}
            </SubheadingA>
          </View>
        </View>
      </TouchableItem>
    );
  };

  const renderShowItem = ({item, index}) => {
    if (!item || !item.id) {
      return null; // If item or item.id is not defined, return null to skip rendering
    }
    return (
      <EventCardA
        key={index}
        eventId={item.id}
        imageUri={item.imageUri}
        name={item.title}
        description={item.summary}
        date={item.dateDisplay}
        address={item.address}
        location={item.location}
        price={item.price}
      />
    );
  };

  const renderPromotionCard = ({item, index}) => {
    return (
      <FoodCard
        key={index}
        imageUri={item.image}
        description={item.description}
        title={item.title}
        review={item.review}
        price={item.price}
        rate={item.rate}
      />
    );
  };

  const renderfastfoodCard = ({item, index}) => {
    return (
      <VerticalFoodCard
        key={index}
        id={item.id}
        imageUri={item?.coverImage}
        description={item?.description}
        title={item?.name}
        category={item?.category}
        review={item?.review}
        address={item?.location}
        time={item?.time}
        status={item?.status}
        rate={item?.rate || 'NEW'}
        exist={item?.exist}
      />
    );
  };

  const handleSelectCityDisplay = () => {
    setSelectCityDisplay(true);
  };

  // Function to toggle between displaying all restaurantList and favorite restaurantList
  const handleShowFavorites = () => {
    setShowFavorites((prevState) => !prevState);
  };
  const handleHideDelivery = () => {
    // setShowFavorites((prevState) => !prevState);
    setShowDeliveryAnnounce(false);
  };

  // Function to get only the favorite restaurantList
  const getFavoriteEvents = () => {
    return restaurantList.filter((event) =>
      favoritesContext.eventIsFavorite(event.id),
    );
  };

  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
      {/* {showDeliveryAnnounce && (
        <View style={styles.freeDeliveryContainer}>
          <AnnounceSVG style={styles.announceIcon} />
          <View style={{position: 'relative'}}>
            <TitleA style={{color: Colors.white}}>
              0 € de frais de livraison
            </TitleA>
            <Text style={{color: Colors.white}}>
              Essayer notre abonnement aujourd’hui
            </Text>
            <TouchableItem
              style={styles.closeButton}
              onPress={handleHideDelivery}>
              <Text style={{color: Colors.white, fontSize: 16}}>x</Text>
            </TouchableItem>
          </View>
        </View>
      )} */}
      <View>
        <HeaderA />
        <SearchBarA
          placeholder={t('Restaurant.Rechercher un événement')}
          style={{marginTop: -14}}
          value={searchQuery}
          onChangeText={(text) => {
            setSearchQuery(text);
          }}
          onPress={() => {
            if (searchQuery.trim() !== '') {
              queryFn(!search);
            }
          }}
        />
      </View>

      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{paddingBottom: 40}}
        // style={styles.container}
      >
        <View>
          <FlatList
            horizontal
            data={servicesData}
            showsHorizontalScrollIndicator={false}
            // alwaysBounceHorizontal={false}
            keyExtractor={(item) => item.id}
            renderItem={renderServices}
            ItemSeparatorComponent={() => (
              <View
                style={{
                  marginHorizontal: 20,
                }}
              />
            )}
            contentContainerStyle={{paddingHorizontal: 25, paddingBottom: 25}}
          />
        </View>

        <View style={styles.selectCityBtnContainer}>
          <TouchableItem
            onPress={handleSelectCityDisplay}
            style={styles.selectCityTouchableItem}>
            <TitleA>{t('Restaurant.Sélectionnez une ville')}</TitleA>
            <DownArrowSVG style={styles.downArrow} />
          </TouchableItem>

          <TouchableItem onPress={handleShowFavorites}>
            <FavoriteSVG
              fill={showFavorites ? Colors.darkGreen : Colors.lightGray}
              style={styles.favorite}
            />
          </TouchableItem>
        </View>

        <View style={styles.categoriesContainer}>
          <TitleBigA>{t('Restaurant.Catégories')}</TitleBigA>
          <TouchableItem onPress={() => navigation.navigate('CategoriesTab')}>
            <TitleA style={styles.showAllButton}>
              {t('Restaurant.Afficher tout')}
            </TitleA>
          </TouchableItem>
        </View>

        <View>
          <FlatList
            horizontal
            data={categoriesData}
            showsHorizontalScrollIndicator={false}
            // alwaysBounceHorizontal={false}
            keyExtractor={(item) => item.id}
            renderItem={renderCotegorie}
            // contentContainerStyle={styles.contentContainerStyle}
            ItemSeparatorComponent={() => (
              <View
                style={{
                  marginHorizontal: 20,
                }}
              />
            )}
            contentContainerStyle={{marginHorizontal: 25}}
          />
        </View>

        <View style={{marginTop: 24, paddingHorizontal: 24}}>
          <TitleBigA>Promotions</TitleBigA>
        </View>

        <View>
          <FlatList
            horizontal
            data={promotionCardData}
            showsHorizontalScrollIndicator={false}
            // alwaysBounceHorizontal={false}
            keyExtractor={(item) => item.id}
            renderItem={renderPromotionCard}
            // contentContainerStyle={{backgroundColor: 'yellow'}}
            // ItemSeparatorComponent={() => (
            //   <View
            //     style={{
            //       marginHorizontal: 20,
            //     }}
            //   />
            // )}
            contentContainerStyle={{paddingHorizontal: 24}}
          />
        </View>

        <View style={{marginTop: 24, paddingHorizontal: 24}}>
          <TitleBigA>{Addresses[0]}</TitleBigA>
        </View>
        <View>
          <FlatList
            data={restaurantList}
            showsHorizontalScrollIndicator={false}
            // alwaysBounceHorizontal={false}
            keyExtractor={(item) => item.id}
            renderItem={renderfastfoodCard}
            // contentContainerStyle={{backgroundColor: 'yellow'}}
            // ItemSeparatorComponent={() => (
            //   <View
            //     style={{
            //       marginHorizontal: 20,
            //     }}
            //   />
            // )}
            contentContainerStyle={{paddingHorizontal: 24}}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const mapStateToProps = (state) => {
  const {carts, store, prefs} = state;
  return {carts, store, prefs};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addProduct,
      setStore,
      setLastId,
      setTierMode,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Restaurants);
