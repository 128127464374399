import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const TimeSVG = (props) => {
  const {style, fill = '#77838F', ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 21 21"
      style={style}
      {...restProps}>
      <Path
        fill={fill}
        d="M10.5 16c3.031 0 5.5-2.469 5.5-5.5S13.531 5 10.5 5A5.508 5.508 0 0 0 5 10.5c0 3.031 2.469 5.5 5.5 5.5Zm0-10.267a4.772 4.772 0 0 1 4.767 4.767 4.772 4.772 0 0 1-4.767 4.767A4.772 4.772 0 0 1 5.733 10.5 4.772 4.772 0 0 1 10.5 5.733Z"
      />
      <Path
        fill={fill}
        d="M12.189 12.613c.059.06.127.087.197.087.092 0 .184-.05.245-.147a.464.464 0 0 0-.048-.553l-1.453-1.452V7.593c0-.216-.142-.393-.315-.393-.173 0-.315.177-.315.393v3.142c0 .12.044.232.118.306l1.57 1.572Z"
      />
    </Svg>
  );
};
export default TimeSVG;
