import React from 'react';
import {Text, TouchableOpacity, View , StyleSheet } from 'react-native';
// import styles from './ButtonAStyles';
import Colors from '../../theme/colors';

const styles = StyleSheet.create({
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    //  marginBottom : 16 ,
    paddingVertical: 15,
   
    borderRadius: 10,
  },
  text : {
    backgroundColor: Colors.background, 
    alignItems: 'center',
    fontFamily: 'Bai Jamjuree',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 24, 
    letterSpacing: 0.64,
    textTransform: 'capitalize',
  },
});
const ButtonAddition = ({
  children,
  style,
  styleViewText = {},
  onPress,
  color,
  disabled,
  ...props
}) => (
  <TouchableOpacity
    activeOpacity={0.8}
    style={[
      styles.button,
      style,
      
    ]}
    onPress={onPress}
    disabled={disabled}
    {...props}>
    <View style={[styles.buttonTextContainer, styleViewText]}>
      <Text style={[styles.buttonText, {color: color || Colors.background}]}>
        {children}
      </Text>
    </View>
  </TouchableOpacity>
);

export default ButtonAddition;
