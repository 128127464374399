import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
const BackArrowSVG = (props) => {
  const {style, fill = '#F7F6F0', ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 10 15"
      style={style}
      {...restProps}>
      <Path
        fill={fill}
        d="M8.378 11.452a1.534 1.534 0 0 1-2.17 2.17L1.085 8.5a1.534 1.534 0 1 1 2.17-2.17l5.123 5.123Z"
      />
      <Path
        fill={fill}
        d="M6.208 1.085a1.534 1.534 0 1 1 2.17 2.17L3.255 8.378a1.534 1.534 0 1 1-2.17-2.17l5.123-5.123Z"
      />
      "M16.8 11.55V6.8a3.8 3.8 0 0 0-7.6 0v4.75"
    </Svg>
  );
};
export default BackArrowSVG;
