import React from 'react'
import Svg, {Path} from 'react-native-svg';

const IconShareSVG = () => {
return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="43" height="42" viewBox="0 0 43 42" fill="none">
  <Path d="M29.0896 7C28.4142 6.99973 27.7477 7.16037 27.1415 7.46955C26.5353 7.77874 26.0056 8.22821 25.5932 8.78332C25.1808 9.33844 24.8968 9.98438 24.763 10.6714C24.6292 11.3584 24.6492 12.068 24.8214 12.7458C24.7149 12.7691 24.6123 12.8087 24.517 12.8633L20.462 15.157L15.3055 18.2168C15.2538 18.2473 15.2047 18.282 15.1584 18.3206C14.441 17.8804 13.6181 17.66 12.785 17.685C11.9519 17.7099 11.1427 17.9792 10.451 18.4616C9.75928 18.944 9.21338 19.6198 8.87651 20.4108C8.53963 21.2018 8.42555 22.0756 8.54747 22.9311C8.66939 23.7866 9.02232 24.5887 9.56541 25.2447C10.1085 25.9006 10.8195 26.3836 11.6162 26.6376C12.4129 26.8917 13.2626 26.9064 14.067 26.6801C14.8714 26.4538 15.5976 25.9957 16.1615 25.3589L20.465 27.8419L24.8097 30.3004C24.5466 31.3809 24.6724 32.5237 25.1634 33.5148C25.6544 34.5059 26.4769 35.2771 27.4767 35.684C28.4766 36.0908 29.5851 36.1053 30.5945 35.7248C31.6039 35.3442 32.4448 34.5947 32.9598 33.6168C33.4747 32.6389 33.6282 31.4997 33.3915 30.4127C33.1548 29.3258 32.5442 28.3657 31.6741 27.7125C30.804 27.0593 29.7342 26.7578 28.6651 26.8645C27.5961 26.9712 26.6013 27.4788 25.8672 28.2921L21.5328 25.8381L17.1998 23.3414C17.4635 22.2081 17.3015 21.0132 16.7468 19.9993L21.5386 17.1577L25.573 14.8731C25.669 14.8192 25.7567 14.7508 25.8333 14.6702C26.3288 15.2317 26.9515 15.6561 27.6449 15.905C28.3383 16.1539 29.0806 16.2194 29.8046 16.0957C30.5286 15.972 31.2114 15.6629 31.7912 15.1964C32.3711 14.7298 32.8296 14.1207 33.1254 13.4239C33.4212 12.7272 33.5449 11.9649 33.4852 11.2061C33.4256 10.4473 33.1845 9.71593 32.7838 9.07814C32.3832 8.44035 31.8355 7.91631 31.1905 7.55345C30.5454 7.1906 29.8234 7.00037 29.0896 7Z" fill="#2FBF86"/>
</Svg>
)
}
export default IconShareSVG