import {StyleSheet} from 'react-native';
import Colors from '../../theme/colors';

const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: Colors.background,
  },

  imageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 25,
    borderBottomWidth: 1,
    borderColor: Colors.lightGray,
  },
  image: {
    width: 170,
    height: 170,
  },
  container: {
    paddingHorizontal: 25,
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderColor: Colors.lightGray,
  },
  bodyRow: {
    flexDirection: 'row',
    marginTop: 23,
    paddingBottom: 34,
    borderBottomWidth: 1,
    borderColor: Colors.lightGray,
  },
  left: {
    marginRight: 8,
    flexShrink: 0,
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  right: {
    marginLeft: 8,
    flexShrink: 0,
    alignSelf: 'center',
  },
  rowIcon: {
    width: 20,
    height: 20,
    marginRight: 5,
  },
  textInput: {
    width: '100%',
    height: 46,
    borderRadius: 8,
    backgroundColor: Colors.white,
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderWidth: 1,
    borderColor: '#D2D5DA',
    shadowColor: 'black',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.05,
    shadowRadius: 2,
    elevation: 1,
    marginVertical: 10,
    // position: 'relative',
  },
  cancelButton: {
    backgroundColor: 'rgba(0,0,0, 0.0)',
  },
  label: {
    position: 'absolute',
    top: -2,
    left: 18,
    backgroundColor: Colors.white,
    borderRadius: 14,
    paddingHorizontal: 6,
  },
  inputRightIcon: {
    position: 'absolute',
    top: 25,
    right: 16,
    width: 16,
    height: 16,
  },
  checkBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 15,
    marginBottom: 16,
  },
});

export default styles;
