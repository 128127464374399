import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const EmailSVG = (props) => {
  const {style, ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      fill="none"
      style={style}
      {...restProps}>
      <Path
        fill="#77838F"
        fillRule="evenodd"
        d="M1.835 3.557a.563.563 0 0 1 .795-.034L9 9.362l6.37-5.84a.563.563 0 0 1 .76.83L9.38 10.54a.563.563 0 0 1-.76 0L1.87 4.352a.562.562 0 0 1-.035-.795Z"
        clipRule="evenodd"
      />
      <Path
        fill="#77838F"
        fillRule="evenodd"
        d="M1.688 3.938c0-.311.251-.563.562-.563h13.5c.31 0 .563.252.563.563V13.5a1.125 1.125 0 0 1-1.125 1.125H2.812A1.125 1.125 0 0 1 1.688 13.5V3.937Zm1.125.562v9h12.374v-9H2.813Z"
        clipRule="evenodd"
      />
      <Path
        fill="#77838F"
        fillRule="evenodd"
        d="M8.184 8.62a.562.562 0 0 1-.034.795l-5.344 4.9a.563.563 0 1 1-.76-.829l5.343-4.9a.562.562 0 0 1 .795.034ZM9.816 8.62a.563.563 0 0 1 .795-.035l5.343 4.901a.562.562 0 1 1-.76.83L9.85 9.414a.562.562 0 0 1-.034-.795Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};

export default EmailSVG;
