import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const QRCodeSVG = (props) => {
  const {style, fill = '#5C97E3', ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      style={style}
      {...restProps}>
      <Path
        fill={fill}
        fillRule="evenodd"
        d="M21.813 1.469h5.812a2.906 2.906 0 0 1 2.906 2.906v5.813a.97.97 0 0 1-1.937 0V4.375a.97.97 0 0 0-.969-.969h-5.813a.97.97 0 0 1 0-1.937ZM10.188 3.406H4.375a.97.97 0 0 0-.969.969v5.813a.97.97 0 0 1-1.937 0V4.375a2.906 2.906 0 0 1 2.906-2.906h5.813a.97.97 0 0 1 0 1.937Zm4.843 8.719V6.312a.97.97 0 0 1 1.938 0v5.813a.97.97 0 0 1-1.938 0Zm-1.937 0a.97.97 0 0 1-.969.969H6.312a.97.97 0 0 1-.968-.969V6.312a.97.97 0 0 1 .968-.968h5.813a.97.97 0 0 1 .969.968v5.813Zm13.562 0a.97.97 0 0 1-.968.969h-5.813a.97.97 0 0 1-.969-.969V6.312a.97.97 0 0 1 .969-.968h5.813a.97.97 0 0 1 .968.968v5.813Zm-15.5-.969V7.281H7.281v3.875h3.875Zm13.563 0V7.281h-3.875v3.875h3.875Zm-9.688 3.875H16a.97.97 0 0 1 0 1.938h-.969a.97.97 0 0 1 0-1.938Zm-8.719 0h4.844a.97.97 0 0 1 0 1.938H6.312a.97.97 0 0 1 0-1.938ZM23.75 16.97h.969V16a.97.97 0 0 1 1.937 0v1.938a.97.97 0 0 1-.968.968H23.75a.97.97 0 0 1 0-1.937ZM20.844 16v4.844h.968a.97.97 0 0 1 0 1.937h-1.937a.97.97 0 0 1-.969-.968V16a.97.97 0 0 1 1.938 0Zm-5.813 5.813v-1.938a.97.97 0 0 1 1.938 0v1.938a.97.97 0 0 1-1.938 0Zm-1.937 3.875a.97.97 0 0 1-.969.968H6.312a.97.97 0 0 1-.968-.968v-5.813a.97.97 0 0 1 .968-.969h5.813a.97.97 0 0 1 .969.969v5.813Zm-2.906 4.843H4.375a2.906 2.906 0 0 1-2.906-2.906v-5.813a.97.97 0 0 1 1.937 0v5.813a.97.97 0 0 0 .969.969h5.813a.97.97 0 0 1 0 1.937Zm.968-5.812v-3.875H7.281v3.875h3.875Zm10.656 3.875h5.813a.97.97 0 0 0 .969-.969v-5.813a.97.97 0 0 1 1.937 0v5.813a2.906 2.906 0 0 1-2.906 2.906h-5.813a.97.97 0 0 1 0-1.937ZM16 24.719h8.719v-2.907a.97.97 0 0 1 1.937 0v3.875a.97.97 0 0 1-.968.97H16a.97.97 0 0 1 0-1.938Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
export default QRCodeSVG;
