/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

// import dependencies
import React, {Component, useContext, useState} from 'react';
import {
  Platform,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  View,
  ActivityIndicator,
} from 'react-native';
import Color from 'color';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {NativeBaseProvider, Radio} from 'native-base';
// import components
import {Subtitle2} from '../../components/text/CustomText';
import TouchableItem from '../../components/TouchableItem';
import UnderlineTextInput from '../../components/textinputs/UnderlineTextInput';
import Divider from '../../components/divider/Divider';
import Button from '../../components/buttons/Button';
// import colors
import Colors from '../../theme/colors';
import axios from 'axios';
// contexts
import {AuthContext} from '../../contexts/context';
import Constants from 'expo-constants';
import {loadStripe} from '@stripe/stripe-js';
import {setReferral} from '../../reducers/cartactions';
import {connect, useSelector} from 'react-redux';
import {bindActionCreators} from 'redux';

import {db} from '../../services/firebase';
import firebase from 'firebase/compat/app';

import styles from './TicketCoordinatesAStyles.js';

import getEnvVars from '../../config';
import {useNavigation} from '@react-navigation/core';
const {paiement, api} = getEnvVars();
const stripePromise = loadStripe(paiement.publishableKey);

// TicketCoordinatesA Config
const INPUT_FOCUSED_BORDER_COLOR = Colors.primaryColor;

const TicketCoordinatesA = (props) => {
  const {user} = useContext(AuthContext);
  const [name, setName] = useState('');
  const [nameFocused, setNameFocused] = useState(false);
  const [email, setEmail] = useState('');
  const [emailFocused, setEmailFocused] = useState(false);
  const [phone, setPhone] = useState('');
  const [phoneFocused, setPhoneFocused] = useState(false);
  const [instagram, setInstagram] = useState('');
  const [instagramFocused, setInstagramFocused] = useState(false);
  const [sexe, setSexe] = useState('');
  const [sexeFocused, setSexeFocused] = useState(false);
  const [age, setAge] = useState('');
  const [ageFocused, setAgeFocused] = useState(false);
  const [birthday, setBirthday] = useState('');
  const [birthdayFocused, setBirthdayFocused] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  const navigation = useNavigation();

  const {referralID} = useSelector((state) => state.referralID);

  console.log('referralID', referralID);

  const handleNameChange = (text) => {
    setName(text);
    const isActive = email.length > 0 && text.length > 0;
    setIsDisabled(!isActive);
  };

  const handleNameFocus = () => {
    setNameFocused(true);
    setEmailFocused(false);
    setInstagramFocused(false);
    setSexeFocused(false);
    setPhoneFocused(false);
    setAgeFocused(false);
    setBirthdayFocused(false);
  };

  const handleEmailChange = (text) => {
    setEmail(text);
    const isActive = name.length > 0 && text.length > 0;
    setIsDisabled(!isActive);
  };

  const handleEmailFocus = () => {
    setNameFocused(false);
    setEmailFocused(true);
    setInstagramFocused(false);
    setSexeFocused(false);
    setPhoneFocused(false);
    setAgeFocused(false);
    setBirthdayFocused(false);
  };

  const handlePhoneChange = (text) => {
    setPhone(text);
  };

  const handlePhoneFocus = () => {
    setNameFocused(false);
    setEmailFocused(false);
    setInstagramFocused(false);
    setSexeFocused(false);
    setPhoneFocused(true);
    setAgeFocused(false);
    setBirthdayFocused(false);
  };

  const handleSexeChange = (text) => {
    setSexe(text);
    const isActive = email.length > 0 && name.length > 0 && text.length > 0;
    setIsDisabled(!isActive);
  };

  const handleAgeChange = (text) => {
    setAge(text);
  };

  const handleAgeFocus = () => {
    setNameFocused(false);
    setEmailFocused(false);
    setInstagramFocused(false);
    setSexeFocused(false);
    setAgeFocused(true);
    setBirthdayFocused(false);
    setSexeFocused(true);
    setPhoneFocused(false);
  };

  const handleBirthdayChange = (text) => {
    setBirthday(text);
  };

  const handleBirthdayFocus = () => {
    setNameFocused(false);
    setEmailFocused(false);
    setInstagramFocused(false);
    setSexeFocused(false);
    setAgeFocused(false);
    setBirthdayFocused(true);
    setSexeFocused(true);
    setPhoneFocused(false);
  };

  const handleInstagramChange = (text) => {
    setInstagram(text);
  };

  const handleInstagramFocus = () => {
    setNameFocused(false);
    setEmailFocused(false);
    setInstagramFocused(true);
    setSexeFocused(false);
    setPhoneFocused(false);
    setAgeFocused(false);
    setBirthdayFocused(false);
  };

  const handleFocus = (nextField) => () => {
    if (nextField) {
      nextField.focus();
    }
  };

  const checkIfRequired = (fieldName, requiredFields) => {
    return requiredFields?.some((field) => field.field === fieldName);
  };

  const handleFinalizePress = async () => {
    let userinfos = {
      name: name,
      email: email,
    };
    if (sexe) {
      userinfos.gender = sexe;
    }
    if (instagram) {
      userinfos.instagram = instagram;
    }
    if (phone) {
      userinfos.phone = phone;
    }
    if (age) {
      userinfos.age = age;
    }
    if (birthday) {
      userinfos.birthday = birthday;
    }
    await db.collection('users').doc(user.uid).set(userinfos, {merge: true});
    await db
      .collection('events')
      .doc(event.id)
      .collection('attendees')
      .add(userinfos, {merge: true});
    const {params} = props.route;
    var tier = 'food';

    console.log('params', params);

    const {products, promoCodeDiscount, shouldCreateOrder, goid} = params;

    const items = products.map((product) => {
      tier = product.tier === 'ticket' ? 'ticket' : 'food';

      var compositionprice = product.iscomposite
        ? product.composition.reduce(function (a, b) {
            return a + b.price;
          }, 0)
        : 0;
      let finalPrice = product.isFree
        ? 0
        : promoCodeDiscount && product.hasDiscount
        ? product.priceAfterDiscount
        : product.price;
      let fields = {
        quantity: product.quantity,
        name: product.name,
        photo: product.photo,
        id: product.id,
        isFree: product.isFree,
        isalcoholic: product.isalcoholic,
        tier: product.tier,
        eventid: props.event.id,
        categoryid: product.categoryid,
        iscomposite: product.iscomposite,
        composition:
          product.composition !== undefined
            ? JSON.stringify(product.composition)
            : '',
        price: (finalPrice + compositionprice).toFixed(0),
        total: ((finalPrice + compositionprice) * product.quantity).toFixed(0),
      };
      if (product.accessTimeThreshold) {
        fields.accessTimeThreshold = product.accessTimeThreshold;
      }
      if (product.accessTimeLimit) {
        fields.accessTimeLimit = product.accessTimeLimit;
      }
      return fields;
    });

    const {store} = props;
    const version = Constants.manifest.version;

    var reducedPrices = products.reduce(function (filtered, product) {
      var compositionprice = product.iscomposite
        ? product.composition.reduce(function (a, b) {
            return a + b.price;
          }, 0)
        : 0;
      if (!product.isFree) {
        let finalPrice =
          promoCodeDiscount && product.hasDiscount
            ? product.priceAfterDiscount
            : product.price;
        filtered.push({
          quantity: product.quantity,
          price_data: {
            currency: 'eur',
            product_data: {
              name: product.name,
              images: product.photo ? [product.photo] : [],
            },
            unit_amount: ((finalPrice + compositionprice) * 100).toFixed(0),
          },
        });
      }
      return filtered;
    }, []);

    var status = 'preordered';
    if (reducedPrices.length == 0) {
      status = tier === 'ticket' ? 'confirmed' : 'ordered';
    }
    let newid = '';
    if (shouldCreateOrder) {
      setCheckoutLoading(true);
      const options = {
        email: userinfos.email,
        items: items,
        status: status,
        clientid: user.uid,
        displayName: userinfos.name,
        appversion: version,
        pickmode: 'inhouse',
        origin: 'webapp',
        userinfos: userinfos,
        paymentinfos: '',
      };
      if (goid) {
        options.goid = goid;
      }
      if (referralID !== '') {
        options.referralid = referralID;
        const db = firebase.firestore();
        const eventid = props.event.id;
        await db
          .collection('events')
          .doc(eventid)
          .collection('referrals')
          .doc(referralID)
          .update({
            stats: firebase.firestore.FieldValue.increment(1),
          });

        const referralDoc = await db
          .collection('events')
          .doc(eventid)
          .collection('referrals')
          .doc(referralID)
          .get();

        const stats = referralDoc.data().stats;
        console.log('stats', stats);
      }

      if (tier === 'ticket') {
        options.event = props.event;
        console.log('reservations');
        try {
          newid = await axios({
            method: 'POST',
            url: `${api.reservations.precreate}`,
            data: options,
          });
          if (newid) {
            setReferral('');
          }
        } catch (error) {
          console.log(error.message);
        }
      } else {
        options.storeid = store.id;
        newid = await axios({
          method: 'POST',
          url: `${api.orders.precreate}`,
          data: options,
        });
      }
      setCheckoutLoading(true);
    }

    if (reducedPrices.length > 0) {
      const stripe = await stripePromise;
      setCheckoutLoading(true);
      const checkoutsession = await createcheckoutsession(
        reducedPrices,
        tier,
        newid.data,
      );
      const result = await stripe.redirectToCheckout({
        sessionId: checkoutsession.data.id,
      });
    } else {
      //CheckoutSuccess
      setCheckoutLoading(false);
      navigation.navigate('CheckoutSuccess', tier);
    }
  };

  const createcheckoutsession = async (prices, tier, newid) => {
    const {store} = props;
    const {params} = props.route;
    const dbuser = await db.collection('users').doc(user.uid).get();
    const docuser = dbuser.data();

    let options = {
      prices: prices,
      tier: tier,
      newid: newid,
      customer: docuser.customerId,
      // savepm: this.state.isSelected,
      accountid: store.accountid,
      mode: 'payment',
    };
    if (params.goid) {
      options.goid = params.goid;
      options.shouldCreateOrder = params.shouldCreateOrder;
    }
    const checkoutsession = await axios({
      method: 'POST',
      url: `${api.checkout.create}`,
      data: options,
    });
    return checkoutsession;
  };

  const fields = [
    {field: 'name', isFocused: nameFocused},
    {field: 'email', isFocused: emailFocused},
    {field: 'sexe', isFocused: sexeFocused},
    {field: 'age', isFocused: ageFocused},
    {field: 'birthday', isFocused: birthdayFocused},
    {field: 'instagram', isFocused: instagramFocused},
    {field: 'phone', isFocused: phoneFocused},
  ];

  const {event} = props;

  const requiredFields = event?.participantInfo?.map((fieldObj) => {
    const {field} = fieldObj;
    const isFocused = fields.find((f) => f.field === field)?.isFocused ?? false;
    return {...fieldObj, isFocused};
  });

  if (checkoutLoading) {
    return (
      <View style={{paddingTop: 50}}>
        <ActivityIndicator size="large" color="green" />
      </View>
    );
  }
  return (
    <SafeAreaView style={styles.container}>
      <StatusBar
        backgroundColor={Colors.statusBarColor}
        barStyle="dark-content"
      />

      <KeyboardAwareScrollView enableOnAndroid>
        <View style={styles.avatarSection}>
          <Subtitle2 style={styles.overline}>
            Please enter your details for your ticket
          </Subtitle2>
        </View>

        <View style={styles.editForm}>
          <Subtitle2 style={styles.label}>First and last name *</Subtitle2>
          <UnderlineTextInput
            value={name}
            onChangeText={handleNameChange}
            onFocus={handleNameFocus}
            inputFocused={nameFocused}
            onSubmitEditing={handleEmailFocus}
            returnKeyType="next"
            focusedBorderColor={INPUT_FOCUSED_BORDER_COLOR}
            inputContainerStyle={styles.inputContainerStyle}
          />

          <Subtitle2 style={styles.label}>Adresse E-mail *</Subtitle2>
          <UnderlineTextInput
            value={email}
            onChangeText={handleEmailChange}
            onFocus={handleEmailFocus}
            inputFocused={emailFocused}
            onSubmitEditing={handleAgeFocus}
            returnKeyType="next"
            keyboardType="email-address"
            focusedBorderColor={INPUT_FOCUSED_BORDER_COLOR}
            inputContainerStyle={styles.inputContainerStyle}
          />

          {checkIfRequired('age', requiredFields) && (
            <>
              <Subtitle2 style={styles.label}>Age</Subtitle2>
              <UnderlineTextInput
                value={age}
                keyboardType="numeric"
                onChangeText={handleAgeChange}
                onFocus={handleAgeFocus}
                onSubmitEditing={handleBirthdayFocus}
                inputFocused={ageFocused}
                focusedBorderColor={INPUT_FOCUSED_BORDER_COLOR}
                inputContainerStyle={styles.inputContainerStyle}
              />
            </>
          )}

          {checkIfRequired('birthday', requiredFields) && (
            <>
              <Subtitle2 style={styles.label}>Birthday</Subtitle2>
              <UnderlineTextInput
                value={birthday}
                keyboardType="date"
                onChangeText={handleBirthdayChange}
                onFocus={handleBirthdayFocus}
                onSubmitEditing={handleInstagramFocus}
                inputFocused={birthdayFocused}
                focusedBorderColor={INPUT_FOCUSED_BORDER_COLOR}
                inputContainerStyle={styles.inputContainerStyle}
              />
            </>
          )}

          {checkIfRequired('instagram', requiredFields) && (
            <>
              <Subtitle2 style={styles.label}>Instagram</Subtitle2>
              <UnderlineTextInput
                value={instagram}
                onChangeText={handleInstagramChange}
                onFocus={handleInstagramFocus}
                inputFocused={instagramFocused}
                focusedBorderColor={INPUT_FOCUSED_BORDER_COLOR}
                inputContainerStyle={styles.inputContainerStyle}
              />
            </>
          )}

          {checkIfRequired('gender', requiredFields) && (
            <>
              <Subtitle2 style={styles.label}>Sexe *</Subtitle2>
              <NativeBaseProvider>
                <Radio.Group
                  name="myRadioGroup"
                  value={sexe}
                  onChange={handleSexeChange}
                  style={{flexDirection: 'row'}}>
                  <Radio value="male" my="1">
                    Garçon
                  </Radio>
                  <Radio value="female" my="1">
                    Fille
                  </Radio>
                </Radio.Group>
              </NativeBaseProvider>
            </>
          )}

          {checkIfRequired('phone', requiredFields) && (
            <>
              <Subtitle2 style={styles.label}>Phone number</Subtitle2>
              <UnderlineTextInput
                value={phone}
                keyboardType="phone-pad"
                onChangeText={handlePhoneChange}
                onFocus={handlePhoneFocus}
                inputFocused={phoneFocused}
                focusedBorderColor={INPUT_FOCUSED_BORDER_COLOR}
                inputContainerStyle={styles.inputContainerStyle}
              />
            </>
          )}
        </View>
        <Divider />

        <View>
          <View style={styles.bottomButtonContainer}>
            <Button
              onPress={handleFinalizePress}
              title="Payment Finalization"
              disabled={isDisabled}
            />
          </View>
        </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
};

const mapStateToProps = (state) => {
  const {cart, store, event} = state;
  return {cart, store, event};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setReferral,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TicketCoordinatesA);
