import {StyleSheet} from 'react-native';
import Colors from '../../theme/colors';
import {height} from 'styled-system';

const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  // slideContainer: {
  //   flex: 1,
  //   alignItems: 'center',
  //   justifyContent: 'center',
  // },
  // slide1: {
  //   backgroundColor: 'rgba(20,20,200,0.3)',
  // },
  QRCodeSection: {
    backgroundColor: Colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: Colors.lightGray,
    height: 320,
    position: 'relative',
  },
  ticketIndex: {
    justifyContent: 'space-between',
    width: '100%',
    paddingHorizontal: 25,
    paddingVertical: 15,
    flexDirection: 'row',
  },
  QRCodeContainer: {
    marginBottom: 25,
    marginTop: 15,
    width: 220,
    height: 227,
    position: 'relative',
  },
  QRCodeMask: {
    width: '100%',
    height: '100%',
  },
  QRCode: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: [{translateX: '-50%'}, {translateY: '-50%'}],
  },
  contentContainer: {
    paddingHorizontal: 25,
    paddingVertical: 15,
  },
  detailsContainer: {},
  icon: {
    width: 20,
    height: 20,
    marginRight: 5,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginVertical: 10,
  },
  left: {
    width: '45%',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  right: {
    width: '55%',
  },
  buttonContainer: {
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
});

export default styles;
