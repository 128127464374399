/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

// import dependencies
import 'react-native-gesture-handler';
import React, {useEffect, useState} from 'react';
import {LogBox} from 'react-native';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {enableScreens} from 'react-native-screens';
import {Linking} from 'react-native';
import {db} from './src/services/firebase';

// CUSTOM FONTS
import useCustomFonts from './expo-fonts';

import AppLoading from 'expo-app-loading';
import * as serviceWorkerRegistration from './src/serviceWorkerRegistration';
//enableScreens();

// TODO: Remove when fixed
LogBox.ignoreAllLogs();
/*YellowBox.ignoreWarnings([
  'VirtualizedLists should never be nested',
  'Warning: componentWillReceiveProps has been renamed, and is not recommended',
]);*/

// import MainNavigatorA or MainNavigatorB to preview design differnces
import RoutesA from './src/navigation/RoutesA';
import * as Routes from './src/navigation/RoutesA';

import {AuthContext} from './src/contexts/context';
import {
  loggingOut,
  registration,
  resetPassword,
  signInAnonymouslyAsync,
} from './src/services/firebase';
import {Provider} from 'react-redux';
//import cartReducer from './src/reducers/cartreducer';
import authReducer from './src/reducers/authreducer';
import ErrorBoundary from './src/components/errors/ErrorBoundary';

import {PersistGate} from 'redux-persist/integration/react';
import {store, persistor} from './src/reducers/cartreducer';
import firebase from 'firebase/compat/app';
import {FavoritesEventsContextProvider} from './src/contexts/favoritesEventsContext';
import i18n from './src/config/i18n/i18n';
//const store = createStore(cartReducer);

// APP
const App = (props) => {
  useCustomFonts();

  const initialLoginState = {
    isLoading: false,
    userName: null,
    userToken: null,
  };

  useEffect(async () => {
    console.log('starting app');
    let result = await signInAnonymouslyAsync();
    //console.log(result);
  });

  const [authState, dispatch] = React.useReducer(
    authReducer,
    initialLoginState,
  );

  const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);

    return (
      <AuthContext.Provider
        value={{
          user,
          setUser,
          signInAnonymously: async () => {
            try {
              let result = await signInAnonymouslyAsync();
              console.log(result);
              dispatch({type: 'LOGIN'});
            } catch (e) {
              console.log(e);
            }
          },

          signOut: async () => {
            try {
              console.log('loggin out');
              loggingOut();
              dispatch({type: 'LOGOUT'});
            } catch (e) {
              console.error(e);
            }
          },
          passwordReset: async (email) => {
            try {
              console.log('loggin out');
              resetPassword(email);
              dispatch({type: 'LOGOUT'});
            } catch (e) {
              console.error(e);
            }
          },
        }}>
        {children}
      </AuthContext.Provider>
    );
  };

  if (authState.isLoading) {
    console.log('application is loading');
    return (
      <AppLoading onFinish={() => console.log('')} onError={console.warn} />
    );
  }

  return (
    <SafeAreaProvider>
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <AuthProvider>
              <FavoritesEventsContextProvider>
                <RoutesA />
              </FavoritesEventsContextProvider>
            </AuthProvider>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </SafeAreaProvider>
  );
};

export default App;
serviceWorkerRegistration.register();
