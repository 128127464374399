// import dependencies
import React, {useContext, useEffect, useState} from 'react';
import styles from './EventCardAStyles.js';
import Colors from '../../theme/colors.js';
import {Image, Platform, View} from 'react-native';
import getImgSource from '../../utils/getImgSource.js';

// import components
import TouchableItem from '../TouchableItem.js';
import TitleA from '../titleA/TitleA.js';
import SubheadingA from '../subheadingA/SubheadingA.js';
import BodyA from '../bodyA/BodyA.js';
import NewSVG from '../svgs/NewSVG.js';
import FavoriteSVG from '../svgs/FavoriteSVG.js';
import LocationSVG from '../svgs/LocationSVG.js';
import TimeSVG from '../svgs/TimeSVG.js';
import TicketPriceSVG from '../svgs/TicketPriceSVg.js';
import FavoritesEventsContext from '../../contexts/favoritesEventsContext.js';
import {useNavigation} from '@react-navigation/native';
import TicketSVG from '../svgs/TicketSVG.js';
import ProgressiveImage from '../../utils/progressiveImage.js';
// import colors, layout

// RestaurantCard Config
const imgHolder = require('../../../assets/images/imgholder.png');
const IOS = Platform.OS === 'ios';
const STAR_ICON = IOS ? 'ios-star' : 'md-star';
// RestaurantCard Styles

const EventCardA = ({
  eventId,
  activeOpacity,
  onPress,
  imageUri,
  name,
  rating,
  date,
  description,
  open,
  address,
  price,
  location,
}) => {
  const favoritesContext = useContext(FavoritesEventsContext);
  const [isFavorite, setIsFavorite] = useState(false); // State to track if the event is a favorite or not

  const navigation = useNavigation();

  // Check if the event is already a favorite when the component mounts
  useEffect(() => {
    setIsFavorite(favoritesContext.eventIsFavorite(eventId));
  }, [favoritesContext, eventId]);

  // Function to toggle the event as a favorite or not
  const handleToggleFavorite = () => {
    if (isFavorite) {
      favoritesContext.removeFavorite(eventId);
    } else {
      favoritesContext.addFavorite({
        id: eventId,
        name, // Replace with the name of the event from the props
      });
    }
    setIsFavorite(!isFavorite); // Toggle the state
  };

  // Function to navigate to the event details screen
  const navigateToEventDetails = () => {
    navigation.navigate('Event', {
      s: eventId,
      t: 'event',
      isFavorite: favoritesContext.eventIsFavorite(eventId),
    });
  };

  return (
    <TouchableItem
      activeOpacity={activeOpacity}
      onPress={navigateToEventDetails}
      borderless
      useForeground
      style={styles.touchableItem}>
      <View style={styles.cardContainer}>
        <NewSVG style={styles.newIcon} />
        <TouchableItem
          onPress={handleToggleFavorite}
          style={{
            backgroundColor: isFavorite ? Colors.darkGreen : Colors.background,
            width: 33,
            height: 33,
            borderRadius: 50,
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 80,
            right: 7,
            zIndex: 2,
          }}>
          <FavoriteSVG
            fill={isFavorite ? Colors.background : Colors.normalGray}
            style={styles.favoriteIcon}
          />
        </TouchableItem>

        {/* <Image
          defaultSource={imgHolder}
          source={getImgSource(imageUri)}
          style={styles.cardImage}
          resizeMode="cover"
        /> */}
        <ProgressiveImage
          // thumbnailSource={imgHolder}
          thumbnailSource={getImgSource(imageUri)}
          source={getImgSource(imageUri)}
          style={styles.cardImage}
          resizeMode="cover"
          rounded={false}
        />

        <View style={styles.cardContent}>
          <View style={styles.TitleRow}>
            <TitleA>{name}</TitleA>
          </View>

          <View style={styles.subheadingRow}>
            <SubheadingA style={styles.eventName}>{location}</SubheadingA>
          </View>

          <View style={styles.bodyRow}>
            <LocationSVG style={styles.rowIcon} />
            <BodyA style={{color: Colors.normalGray, width: '85%'}}>
              {address}
            </BodyA>
          </View>

          <View style={styles.bodyRow}>
            <TimeSVG style={styles.rowIcon} />
            <BodyA style={{color: Colors.normalGray}}>{date}</BodyA>
          </View>

          <View style={styles.bodyRow}>
            <TicketSVG
              viewBox="-6 -6 38 38"
              style={styles.rowIcon}
              stroke={Colors.normalGray}
            />
            <BodyA style={{color: Colors.darkGray}}>
              {price}
              {' €'}
            </BodyA>
          </View>
        </View>
      </View>
    </TouchableItem>
  );
};

export default EventCardA;
