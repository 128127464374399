import {StyleSheet} from 'react-native';
import Colors from '../../theme/colors';

const styles = StyleSheet.create({
  container: {
    // paddingVertical: 16,
    // paddingHorizontal: 25,
    marginTop: 10,
  },
  row: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 12,
  },

  separator: {
    width: '100%',
    marginBottom: 12,
    height: 1,
    backgroundColor: Colors.lightGray,
  },
});

export default styles;
