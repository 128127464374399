/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

// import dependencies
import React, {Component, Fragment} from 'react';
import {
  ImageBackground,
  Platform,
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import Color from 'color';

// import utils
import getImgSource from '../../utils/getImgSource.js';
import isEmptyOrUndefined from '../../utils/stringHelper.js';
// import components
import Button from '../../components/buttons/Button';
import {Caption, Heading5, SmallText} from '../../components/text/CustomText';
import GradientContainer from '../../components/gradientcontainer/GradientContainer';
import Icon from '../../components/icon/Icon';
import TouchableItem from '../../components/TouchableItem';
import locales from '../../config/locales';
// import colors
import Colors from '../../theme/colors';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {addProduct} from '../../reducers/cartactions';

import RequiredListPicker from '../../components/pickers/RequiredListPicker';
// ProductB Config
const IOS = Platform.OS === 'ios';
const MINUS_ICON = IOS ? 'ios-remove' : 'md-remove';
const PLUS_ICON = IOS ? 'ios-add' : 'md-add';
const FAVORITE_ICON = IOS ? 'ios-heart' : 'md-heart';
const CLOSE_ICON = IOS ? 'ios-close' : 'md-close';
const imgHolder = require('../../../assets/images/imgholder.png');

import styles from './ProductBStyles.js';

// ProductB
class ProductB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      product: {},
      finalProduct: {},
      sizePicked: false,
      hasSizes: false,
      sizes: [],
      composition: [],
      favorite: false,
    };
  }

  componentDidMount = () => {
    this.setState({loading: true});
    const product = this.props.route.params.product;
    this.setState({product});
    var hasSizes = false;
    let {sizes, composition, ...finalProduct} = product;
    finalProduct.quantity = 0;
    finalProduct.composition = [];
    finalProduct.total = finalProduct.price * finalProduct.quantity;
    let prdcategory = this.props.store.categories.filter((obj) => {
      return obj.id === product.categoryid;
    });
    finalProduct.name =
      (prdcategory.length > 0 ? prdcategory[0].name : '') +
      ' ' +
      finalProduct.name;
    this.setState({finalProduct});
    if (product.sizes) {
      console.log('has sizes');
      hasSizes = true;
      const sizes = product.sizes;
      sizes.forEach((size) => {
        size.picked = false;
      });
      console.log('new sizes');
      console.log(sizes);
      this.setState({sizes, hasSizes});
    }
    if (product.iscomposite) {
      console.log('yes is composite');
      console.log(product.composition);
      console.log(product.id);
      var parts = product.composition;

      parts.forEach((compo) => {
        compo.picked = false;
        console.log(compo);
        compo.options = compo.items.split(',').map(function (element) {
          return {name: element, picked: false};
        });
      });
      this.setState({composition: parts});
    }

    this.setState({loading: false});
  };

  navigateTo = (screen) => () => {
    const {navigation} = this.props;
    navigation.navigate(screen);
  };

  goBack = () => {
    const {navigation} = this.props;
    navigation.goBack();
  };

  onPressAddToFavorites = () => {
    const {favorite} = this.state;

    this.setState({
      favorite: !favorite,
    });
  };

  isReady = () => {
    const {composition, sizes, sizePicked} = this.state;

    if (sizes.length > 0 && sizePicked) {
      console.log('sizes');
      return true;
    }
    if (composition.length > 0) {
      console.log('drinks');
      var picked = true;
      composition.forEach((compo) => {
        console.log(compo.name);
        console.log(compo.picked);
        console.log(compo.required);
        picked = compo.required ? picked && compo.picked : picked;
      });
      return picked;
    }
    //console.log("neither");
    return false;
  };

  setSize = (item) => () => {
    const {sizes, finalProduct} = this.state;
    console.log(item);
    const index = sizes.indexOf(item);
    const picked = sizes[index].picked;
    var sizePicked = false;

    finalProduct.name = finalProduct.name + ' ' + item.name;
    finalProduct.price = item.price;
    sizePicked = true;
    if (finalProduct.quantity === 0) {
      finalProduct.quantity = 1;
    }
    finalProduct.total = finalProduct.price * finalProduct.quantity;
    let updatedState = sizes.map((size) =>
      size.name === item.name
        ? {...size, picked: true}
        : {...size, picked: false},
    );

    sizes[index].picked = !picked;
    this.setState({sizes: updatedState});
    this.setState({
      finalProduct,
      sizePicked,
    });
  };

  onRequiredOptionPicked = (name, item) => () => {
    console.log('option picked', name, item);
    const {composition, finalProduct} = this.state;
    var partindex = composition.findIndex((item) => item.name === name);
    //console.log(composition[partindex]);
    var optionindex = composition[partindex].options.findIndex(
      (option) => option.name === item.name,
    );
    console.log(optionindex);
    console.log(composition[partindex].options[optionindex]);
    var itempicked = composition[partindex].options[optionindex].picked;
    var required = composition[partindex].required;
    //composition[partindex].options[optionindex].picked = true;
    let updatedState = composition[partindex].options.map((option) =>
      option.name === item.name
        ? {...option, picked: required ? true : !itempicked}
        : {...option, picked: false},
    );
    composition[partindex].options = updatedState;
    composition[partindex].picked = composition[partindex].options.some(
      (element) => element.picked,
    );

    console.log(this.state.composition);
    var finalindex = finalProduct.composition.findIndex(
      (option) => option.category === name,
    );
    if (composition[partindex].picked) {
      if (finalindex < 0) {
        finalProduct.composition.push({
          category: name,
          name: item.name,
          price: composition[partindex].supplement,
        });
      } else {
        finalProduct.composition[finalindex].name = item.name;
      }
    } else {
      if (finalindex > -1) {
        finalProduct.composition.splice(finalindex, 1);
      }
    }

    if (finalProduct.quantity === 0) {
      finalProduct.quantity = 1;
    }
    var compositionprice = finalProduct.composition.reduce(function (a, b) {
      return a + b.price;
    }, 0);
    finalProduct.total =
      (this.state.product.price + compositionprice) * finalProduct.quantity;

    this.setState({
      finalProduct,
      composition,
    });
  };

  onPressIncreaseAmount = () => {
    const {finalProduct} = this.state;
    let {quantity} = finalProduct;

    quantity += 1;
    finalProduct.quantity = quantity;

    const total = quantity * finalProduct.price;
    finalProduct.total = total;

    this.setState({
      finalProduct,
    });
  };

  onPressDecreaseAmount = () => {
    const {finalProduct} = this.state;
    let {quantity} = finalProduct;

    quantity -= 1;
    quantity = quantity < 1 ? 1 : quantity;
    finalProduct.quantity = quantity;

    const total = quantity * finalProduct.price;
    finalProduct.total = total;

    this.setState({
      finalProduct,
    });
  };

  addToCart = () => {
    //console.log("adding to cart");
    const {finalProduct} = this.state;
    console.log('finalproduct');
    console.log(finalProduct);
    /*this.setState({
      product,
    });*/
    let productToAdd = {
      id: finalProduct.id,
      name: finalProduct.name,
      description: finalProduct.description,
      price: finalProduct.price,
      photo: finalProduct.photo,
      iscomposite: finalProduct.iscomposite,
      composition: finalProduct.composition,
      quantity: finalProduct.quantity,
      total: finalProduct.total,
    };
    this.props.addProduct(productToAdd);
    this.goBack();
  };

  renderPickerList = (compo, index) => {
    //console.log(compo);

    return (
      <RequiredListPicker
        index={index}
        name={compo.name}
        onPress={this.onRequiredOptionPicked}
        caption={compo.caption}
        items={compo.options}
      />
    );
  };

  render() {
    const {
      favorite,
      finalProduct,
      product,
      hasSizes,
      sizes,
      sizePicked,
      loading,
      composition,
    } = this.state;
    const {price, description, quantity, photo} = product;
    const total = 10;
    if (loading) {
      return <></>;
    }
    //console.log("drinks");
    //console.log(drinks);

    return (
      <Fragment>
        <StatusBar
          backgroundColor={Colors.primaryColor}
          barStyle="light-content"
        />
        <SafeAreaView style={styles.topArea} />
        <View style={styles.screenContainer}>
          <ScrollView>
            {!isEmptyOrUndefined(photo) && (
              <View style={styles.header}>
                <ImageBackground
                  defaultSource={imgHolder}
                  source={getImgSource(photo)}
                  style={styles.productImg}>
                  <GradientContainer
                    colors={[Colors.black, 'transparent']}
                    start={{x: 0, y: 1}}
                    end={{x: 0, y: 0.6}}
                    containerStyle={styles.bottomOverlay}
                  />
                </ImageBackground>

                <View style={[styles.topButton, styles.left]}>
                  <TouchableItem onPress={this.goBack} borderless>
                    <View style={styles.buttonIconContainer}>
                      <Icon
                        name={CLOSE_ICON}
                        size={IOS ? 24 : 22}
                        color={Colors.secondaryText}
                      />
                    </View>
                  </TouchableItem>
                </View>

                {/*<View
                style={[
                  styles.topButton,
                  styles.right,
                  favorite && styles.favorite,
                ]}>
                <TouchableItem onPress={this.onPressAddToFavorites} borderless>
                  <View style={styles.buttonIconContainer}>
                    <Icon
                      name={FAVORITE_ICON}
                      size={IOS ? 18 : 20}
                      color={
                        favorite
                          ? Colors.onSecondaryColor
                          : Colors.secondaryText
                      }
                    />
                  </View>
                </TouchableItem>
                    </View>*/}
              </View>
            )}

            {isEmptyOrUndefined(photo) && (
              <View style={styles.emptyHeader}>
                <View style={[styles.topButtonEmptyImage, styles.left]}>
                  <TouchableItem onPress={this.goBack} borderless>
                    <View style={styles.buttonIconContainer}>
                      <Icon
                        name={CLOSE_ICON}
                        size={IOS ? 24 : 22}
                        color={Colors.secondaryText}
                      />
                    </View>
                  </TouchableItem>
                </View>
              </View>
            )}

            <View style={styles.productDescription}>
              <View style={styles.productTitleContainer}>
                <Heading5 style={styles.productTitle}>{product.name}</Heading5>
                {!hasSizes && (
                  <Text style={styles.priceText}>{`${
                    locales.financial.currency.symbol
                  } ${price.toFixed(2)}`}</Text>
                )}
              </View>

              <SmallText style={styles.shortDescription}>
                {description}
              </SmallText>
            </View>

            {sizes.length > 0 && (
              <View>
                <Caption style={styles.caption}>Choose the size</Caption>
                {sizes.map((item, index) => (
                  <TouchableItem
                    key={index.toString()}
                    onPress={this.setSize(item)}
                    useForeground>
                    <View style={styles.dishContainer}>
                      <View style={styles.indicator}>
                        <View>
                          {item.picked ? (
                            <View style={styles.filledIndicator} />
                          ) : (
                            <View style={styles.emptyIndicator} />
                          )}
                        </View>

                        <Text style={styles.dishName}>{item.name}</Text>
                      </View>

                      <Text style={styles.dishPrice}>
                        {item.price.toFixed(2)}
                      </Text>
                    </View>
                  </TouchableItem>
                ))}
              </View>
            )}

            {composition.length > 0 &&
              composition.map((compo, index) =>
                this.renderPickerList(compo, index),
              )}
          </ScrollView>

          {finalProduct.quantity > 0 && (
            <View style={styles.amountContainer}>
              <View style={styles.amountButtonsContainer}>
                <TouchableItem onPress={this.onPressDecreaseAmount} borderless>
                  <View style={styles.iconContainer}>
                    <Icon
                      name={MINUS_ICON}
                      size={20}
                      color={Colors.onPrimaryColor}
                    />
                  </View>
                </TouchableItem>

                <Text style={styles.quantity}>{finalProduct.quantity}</Text>

                <TouchableItem onPress={this.onPressIncreaseAmount} borderless>
                  <View style={styles.iconContainer}>
                    <Icon
                      name={PLUS_ICON}
                      size={20}
                      color={Colors.onPrimaryColor}
                    />
                  </View>
                </TouchableItem>
              </View>
            </View>
          )}

          <View style={styles.bottomButtonsContainer}>
            <Button
              onPress={this.addToCart}
              title={`Ajouter au panier ${locales.financial.currency.symbol} ${
                isNaN(finalProduct.total) ? '' : finalProduct.total.toFixed(2)
              }`}
              titleColor={Colors.onPrimaryColor}
              height={44}
              disabled={!this.isReady()}
              color={Colors.primaryColor}
            />
          </View>
        </View>

        <SafeAreaView style={styles.bottomArea} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {cart, store} = state;
  return {cart, store};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addProduct,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductB);
