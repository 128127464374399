import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const NoTicketsSVG = (props) => {
  const {style, ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={144}
      height={144}
      fill="none"
      viewBox="0 0 144 144"
      style={style}
      {...restProps}>
      <Path
        fill="#CAD2CC"
        fillRule="evenodd"
        d="M10.8 43.2a10.8 10.8 0 0 1 10.8-10.8h100.8a10.802 10.802 0 0 1 10.8 10.8v19.008a4.774 4.774 0 0 1-2.938 4.414 5.827 5.827 0 0 0-2.603 8.603 5.827 5.827 0 0 0 2.603 2.146 4.78 4.78 0 0 1 2.938 4.414V100.8c0 2.864-1.138 5.611-3.163 7.637a10.804 10.804 0 0 1-7.637 3.163H21.6a10.801 10.801 0 0 1-10.8-10.8V81.792a4.792 4.792 0 0 1 2.937-4.42 5.825 5.825 0 0 0 0-10.75 4.78 4.78 0 0 1-2.937-4.407V43.2Zm10.8-3.6a3.6 3.6 0 0 0-3.6 3.6v17.503c8.697 4.918 8.697 17.676 0 22.594V100.8a3.602 3.602 0 0 0 3.6 3.6h100.8a3.598 3.598 0 0 0 3.6-3.6V83.297c-8.698-4.918-8.698-17.676 0-22.594V43.2a3.597 3.597 0 0 0-3.6-3.6H21.6Z"
        clipRule="evenodd"
      />
      <Path
        fill="#CAD2CC"
        fillRule="evenodd"
        d="M90 95.4a5.4 5.4 0 0 1 5.4 5.4v3.6a5.398 5.398 0 0 1-5.4 5.4 5.4 5.4 0 0 1-5.4-5.4v-3.6a5.4 5.4 0 0 1 5.4-5.4Zm0-61.2a5.4 5.4 0 0 1 5.4 5.4v3.6a5.4 5.4 0 0 1-10.8 0v-3.6a5.4 5.4 0 0 1 5.4-5.4ZM90 54a5.4 5.4 0 0 1 5.4 5.4V63a5.4 5.4 0 0 1-10.8 0v-3.6A5.4 5.4 0 0 1 90 54Zm0 21.6a5.4 5.4 0 0 1 5.4 5.4v3.6a5.4 5.4 0 1 1-10.8 0V81a5.4 5.4 0 0 1 5.4-5.4Z"
        clipRule="evenodd"
      />
      <Path
        fill="#CAD2CC"
        d="M8.28 13.522a3.704 3.704 0 0 1 5.242-5.235l122.191 122.191a3.704 3.704 0 0 1 0 5.235 3.7 3.7 0 0 1-5.234 0L8.287 13.522H8.28Z"
      />
    </Svg>
  );
};

export default NoTicketsSVG;
