/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

// import dependencies
import React, {Component} from 'react';
import {
  Alert,
  I18nManager,
  Platform,
  SafeAreaView,
  ScrollView,
  StatusBar,
  Switch,
  View 
} from 'react-native';
import AwesomeAlert from 'react-native-awesome-alerts';
// import components
import Avatar from '../../components/avatar/Avatar';
import Divider from '../../components/divider/Divider';
import Icon from '../../components/icon/Icon';
import {Heading6, Subtitle1, Subtitle2} from '../../components/text/CustomText';
import TouchableItem from '../../components/TouchableItem';
import {db, FUNCTIONS} from '../../services/firebase';
import { httpsCallable } from 'firebase/functions';
// import colors
import Colors from '../../theme/colors';
import AppLoading from 'expo-app-loading';
import { AuthContext } from '../../contexts/context';
import getEnvVars from "../../config";
const { api } = getEnvVars();
import styles from './SettingsAStyles.js';
import axios from 'axios';
// SettingsA Config
const isRTL = I18nManager.isRTL;
const IOS = Platform.OS === 'ios';
const DIVIDER_MARGIN_LEFT = 60;
const ARROW_ICON = 'ios-arrow-forward';
const ADDRESS_ICON = IOS ? 'ios-pin' : 'md-pin';
const NOTIFICATION_OFF_ICON = IOS
  ? 'ios-notifications-off'
  : 'md-notifications-off';
const NOTIFICATION_ICON = IOS ? 'ios-notifications' : 'md-notifications';
const PAYMENT_ICON = IOS ? 'ios-card' : 'md-card';
const ORDERS_ICON = IOS ? 'ios-list' : 'md-list';
const TERMS_ICON = IOS ? 'ios-paper' : 'md-pencil';
const ABOUT_ICON = IOS
  ? 'ios-information-circle-outline'
  : 'md-information-circle-outline';
const LOGOUT_ICON = IOS ? 'ios-log-out' : 'md-log-out';




// SettingsA Props
type Props = {
  icon: string,
  title: String,
  onPress: () => {},
  extraData: React.Node  
};

// SettingsA Components
const Setting = ({icon, title, onPress, extraData}: Props) => (
  <TouchableItem onPress={onPress}>
    <View>
      <View style={[styles.row, styles.setting]}>
        <View style={styles.leftSide}>
          {icon !== undefined && (
            <View style={styles.iconContainer}>
              <Icon name={icon} size={24} color={Colors.primaryColor} />
            </View>
          )}
          <Subtitle1 style={styles.mediumText}>{title}</Subtitle1>
        </View>

        <View style={isRTL && {transform: [{scaleX: -1}]}}>
          <Icon name={ARROW_ICON} size={16} color="rgba(0, 0, 0, 0.16)" />
        </View>
      </View>

      {extraData ? (
        <View style={styles.extraDataContainer}>{extraData}</View>
      ) : (
        <View />
      )}
    </View>
  </TouchableItem>
);

// SetingsA
export default class SettingsA extends Component {
  static contextType = AuthContext
  constructor(props) {
    super(props);
    this.isMountedVal = 0;
    this.state = {
      notificationsOn: true,
      isLoading: true,
      dbUser: null,
      showAlert: false,
      card: null
    };
  }

  componentWillUnmount(){
		this.isMountedVal = 0;
	}

  componentDidMount = () => {
    this.isMountedVal = 1;
    const { user } = this.context;
    this.setState({isLoading: true});
    //console.log("docuser mounting ");
    
    db.collection('users').doc(user.uid)
      .get()
      .then((doc) => {
        var docuser = doc.data();
        docuser.id = doc.id;      
        //console.log("docuser");
        //console.log(docuser);  
        this.setState({ dbUser: docuser, isLoading: false });   
        if ("customerId" in docuser) {
          axios({
            method: 'GET',
            url: `${api.pms.get}?custid=${docuser.customerId}`
          }).then(async (response) => {   
              //console.log("stripe response");
              if (response.data.length > 0) {
                //console.log(response.data[0]);   
                let card = response.data[0].card;     
                this.setState({card: card});  
              } else {
                
              }              
          }).catch(e => {
              //this.setState({ loading: false, token: null })        
              //console.log(e)
          })  
        }
    }).catch((error) => {
      this.setState({ isLoading: false });    
      //console.error("Error adding document: ", error);
    });
     
  };

  navigateTo = (screen) => () => {
    const {navigation} = this.props;
    navigation.navigate(screen);
  };

  toggleNotifications = (value) => {
    this.setState({
      notificationsOn: value,
    });
  };

  logout = () => {
    const { signOut } = this.context;
    this.setState({
      showAlert: true
    });
    signOut();    
  };


  showLogout = () => {
    const { signOut } = this.context;
    this.setState({
      showAlert: true
    });
    
  };


  handlePaymentMethod = () => {
    const { user } = this.context;
    const {navigation} = this.props;    
    this.setState({ checkoutLoading: true });
     

    db.collection('users').doc(user.uid)
    .get()
    .then(async (doc) => {
      var docuser = doc.data();
      console.log(docuser);
      if ("customerId" in docuser) {
        console.log(JSON.stringify(docuser));   
        const options = {
          customer: docuser.customerId,
          mode: "setup"
        }; 
        const callCreateCheckoutSession = httpsCallable(FUNCTIONS, 'createCheckoutSession');
        const result = await callCreateCheckoutSession(options);
        console.log(result);
        if (!result.data.haserror) {
          console.log(response);
          this.setState({ checkoutLoading: false });
          navigation.navigate('CheckoutStripe', {sessionId: response.data.id, mode: "setup"});
        } else {
          this.setState({ checkoutLoading: false });
          console.error("Error adding document: ", result.data?.error);
          console.error(error.response);
        } 
      } else {
        var options = {
          uid: user.uid,
          email: docuser.email,
          name: `${docuser.firstName} ${docuser.lastName}` ,          
        };        
        
        const createStripeCustomer = httpsCallable(FUNCTIONS, 'createStripeCustomer');
        const result = await createStripeCustomer(options);
        console.log(result);
        if (!result.data.haserror) {
          db.collection('users')
            .doc(user.uid)                
            .set({
                customerId: response.data.id,                    
            }, { merge: true }).then(resp => {
                this.setState({ loading: false, token: null })   
                //this.props.navigation.navigate('Home', {result: true});
            }).catch(e => console.log(e))

            const callCreateCheckoutSession = httpsCallable(FUNCTIONS, 'createCheckoutSession');
            const resultSession = await callCreateCheckoutSession(options);
            console.log(resultSession);
            if (!resultSession.data.haserror) {
              
              this.setState({ checkoutLoading: false });
              navigation.navigate('CheckoutStripe', {sessionId: resultSession.data.session.id, mode: "setup"});
            } else {
              this.setState({ checkoutLoading: false });
              console.error("Error adding document: ", result.data?.error);
              console.error(error.response);
            } 

        } else {
          this.setState({ checkoutLoading: false });
          console.error("Error adding document: ", result.data?.error);
          console.error(error.response);
        }     
      }           
    })
    .catch((error) => {
      this.setState({ checkoutLoading: false });
      console.error("Error adding document: ", error);
    });    
    
  }



  getCreditCardInfo = () => {
    if (this.state.card) {
      return (
        <Setting
              onPress={this.navigateTo('PaymentMethod')}
              icon={PAYMENT_ICON}
              title="Méthode de paiement"
              extraData={
                <View>
                  <Subtitle2 style={styles.extraData}>{this.state.card.brand.toLowerCase()}</Subtitle2>
                  <Subtitle2 style={styles.extraData}>
                    xxxx xxxx xxxx {this.state.card.last4}
                  </Subtitle2>
                </View>
              }
            />
        )      
    } else {
      return (
        <Setting
              onPress={this.handlePaymentMethod}
              icon={ADDRESS_ICON}
              title="Méthode de paiement"
              extraData={
                <View>
                  <Subtitle2 style={styles.extraData}>
                    Vous n'avez pas encore de méthode de paiement
                  </Subtitle2>
                  
                </View>
              }
            />)
    }
  }

  hideAlert = () => {
    this.setState({
      showAlert: false
    });
  };



  render() {
    const {notificationsOn, showAlert} = this.state;
    console.log(this.state.isLoading);
    if (this.state.isLoading) {
      return <AppLoading     
          onFinish={() => console.log("")}
          onError={console.warn}
        />;
    }
    else
    {
      return (
        <SafeAreaView style={styles.container}>
          <StatusBar
            backgroundColor={Colors.statusBarColor}
            barStyle="dark-content"
          />

          <ScrollView contentContainerStyle={styles.contentContainerStyle}>
            <View style={styles.titleContainer}>
              <Heading6 style={styles.titleText}>Paramètres</Heading6>
            </View>

            <TouchableItem useForeground onPress={this.navigateTo('EditProfile')}>
              <View style={[styles.row, styles.profileContainer]}>
                <View style={styles.leftSide}>
                  <Avatar
                    imageUri={this.state.dbUser.photo}
                    rounded
                    size={60}
                  />
                  <View style={styles.profileInfo}>
                    <Subtitle1 style={styles.name}>{this.state.dbUser.displayName}</Subtitle1>
                    <Subtitle2 style={styles.email}>
                      {this.state.dbUser.email}
                    </Subtitle2>
                  </View>
                </View>
              </View>
            </TouchableItem>

            <Divider />
            
            <Divider type="inset" marginLeft={DIVIDER_MARGIN_LEFT} />

            {this.getCreditCardInfo()}
            <Divider type="inset" marginLeft={DIVIDER_MARGIN_LEFT} />

            <TouchableItem onPress={this.navigateTo('Notifications')}>
              <View style={[styles.row, styles.setting]}>
                <View style={styles.leftSide}>
                  <View style={styles.iconContainer}>
                    {notificationsOn ? (
                      <Icon
                        name={NOTIFICATION_ICON}
                        size={24}
                        color={Colors.primaryColor}
                      />
                    ) : (
                      <Icon
                        name={NOTIFICATION_OFF_ICON}
                        size={24}
                        color={Colors.primaryColor}
                      />
                    )}
                  </View>
                  <Subtitle1 style={styles.mediumText}>Notifications</Subtitle1>
                </View>

                {/*
                  FIX: when android:supportsRtl="true" not added to AndroidManifest.xml
                  <View style={isRTL && {transform: [{scaleX: -1}]}}> 
                */}
                <View>
                  <Switch
                    trackColor={{
                      true: IOS && Colors.primaryColor,
                    }}
                    thumbColor={IOS ? Colors.onPrimaryColor : Colors.primaryColor}
                    onValueChange={this.toggleNotifications}
                    value={notificationsOn}
                  />
                </View>
              </View>
            </TouchableItem>
            <Divider type="inset" marginLeft={DIVIDER_MARGIN_LEFT} />

            <Setting
              onPress={this.navigateTo('Orders')}
              icon={ORDERS_ICON}
              title="Mes commandes"
            />
            <Divider type="inset" marginLeft={DIVIDER_MARGIN_LEFT} />

            <Setting
              onPress={this.navigateTo('TermsConditions')}
              icon={TERMS_ICON}
              title="Terms and Conditions"
            />
            <Divider type="inset" marginLeft={DIVIDER_MARGIN_LEFT} />

            <Setting
              onPress={this.navigateTo('AboutUs')}
              icon={ABOUT_ICON}
              title="A propos de nous"
            />
            <Divider type="inset" marginLeft={DIVIDER_MARGIN_LEFT} />

            <TouchableItem onPress={this.showLogout}>
              <View style={[styles.row, styles.setting]}>
                <View style={styles.leftSide}>
                  <View style={styles.iconContainer}>
                    <Icon
                      name={LOGOUT_ICON}
                      size={24}
                      color={Colors.secondaryColor}
                    />
                  </View>
                  <Subtitle1 style={[styles.logout, styles.mediumText]}>
                    Se déconnecter
                  </Subtitle1>
                </View>
              </View>
            </TouchableItem>
          </ScrollView>
          <AwesomeAlert
            show={showAlert}
            showProgress={false}
            title="Se déconnecter"
            message="Êtes-vous sûr de vouloir vous déconnecter?"
            closeOnTouchOutside={true}
            closeOnHardwareBackPress={false}
            showCancelButton={true}
            showConfirmButton={true}
            cancelText="Non"
            confirmText="Oui"
            confirmButtonColor="#DD6B55"
            onCancelPressed={() => {
              this.hideAlert();
            }}
            onConfirmPressed={() => {
              this.logout();
            }}
          />
        </SafeAreaView>
      );
    }
  }
}
