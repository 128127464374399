import Svg, {G, Path, Defs, ClipPath, Rect} from 'react-native-svg';
import React from 'react';
const GroupMontantSVG = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none">
      <Path
        d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
        stroke="#77838F"
        strokeWidth="0.75"
      />
      <Path
        d="M8.17174 4.63199C7.74427 4.3923 7.25944 4.2662 6.76597 4.26636C6.2725 4.26652 5.78776 4.39294 5.36046 4.63291C4.93315 4.87289 4.57833 5.21797 4.33164 5.63349C4.08495 6.049 3.95508 6.52032 3.95508 7.00009C3.95508 7.47985 4.08495 7.95117 4.33164 8.36668C4.57833 8.7822 4.93315 9.12728 5.36046 9.36726C5.78776 9.60723 6.2725 9.73365 6.76597 9.73381C7.25944 9.73398 7.74427 9.60787 8.17174 9.36818M3.48535 6.31643H5.82855M3.48535 7.68329H5.82855"
        stroke="#77838F"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
    </Svg>
  );
};
export default GroupMontantSVG;
