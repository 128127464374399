import {StyleSheet, I18nManager} from 'react-native';
import Color from 'color';
import Colors from '../../theme/colors';
const isRTL = I18nManager.isRTL;
// HomeA Styles
const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  wrapper: {
    paddingHorizontal: 24,
  },
  screenContainerBtn : {
    paddingTop: 7,
    paddingBottom: 13,
    flexDirection : "row",
    justifyContent : "space-around" ,
    alignItems :"center" , 
    alignSelf : "stretch"
   
  } , 
  enCoursColor : {
color :"var(--Dark-Green, #2FBF86)" ,

  } , 
  textContainer : {
   color :  "var(--Normal-Grey, #77838F)" ,

    /* Subheading */
    fontFamily: "Bai Jamjuree",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "150%" ,/* 21px */
    letterSpacing: 0.56,
  } , 
  textEnCour :{
    
    color: "var(--Dark-Green, #2FBF86)",

    fontFamily: "Bai Jamjuree",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "150%" ,/* 21px */
    
  } ,
  line: {
    width: '50%',
    borderBottomWidth: 1,
    borderBottomColor: "#2FBF86", 
    

  },
  lineLivre : {
    width: '50%',
    borderBottomWidth: 1,
    borderBottomColor: "#2FBF86", 
    alignSelf : "flex-end" , 
  } ,
  descriptionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  description: {
    padding: 16,
    flexShrink: 1,
  },
  title: {
    flexDirection: 'row',
    // justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 8,
  },
  cartIcon: {
    width: 28,
    height: 28,
    marginRight: 8,
  },
  detail: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
  },
  icon: {
    width: 21,
    height: 21,
    marginRight: 10,
    alignSelf: 'flex-start',
  },
  backIcon: {
    width: 35,
    height: 21,
    transform: [{scaleX: -1}],
  },
  separator: {
    height: 1,
    backgroundColor: Colors.lightGray,
    marginVertical: 16,
  },
});

export default styles;
