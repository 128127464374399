import {StyleSheet} from 'react-native';
import Colors from '../../theme/colors';

const styles = StyleSheet.create({
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: Colors.lightGray,
  },
  screenContainer: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  scrollView: {
    paddingVertical: 10,
    paddingHorizontal: 25,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  userImageContainer: {
    width: 75,
    height: 75,
    marginRight: 10,
  },
  userImage: {
    width: '100%',
    height: '100%',
    borderRadius: 40,
  },
  userInfoContainer: {
    flexDirection: 'column',
  },
  flatListContainer: {},
  flatList: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: Colors.lightGray,
  },

  item: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 46,
    marginVertical: 10,
  },
  icon: {
    width: 36,
    height: 36,
    marginRight: 15,
  },
  title: {
    flexGrow: 1,
  },
  buttonContainer: {
    width: 10,
    height: 15,
    marginLeft: 23,
  },
  button: {
    transform: [{scaleX: -1}],
  },
});

export default styles;
