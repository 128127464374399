import React from 'react';
import Colors from '../../theme/colors';
import { View, Image, TextInput, TouchableOpacity } from 'react-native';
import styles from './SearchBarAStyles';

import xmlid1 from '../../../assets/images/xmlid1.png';
import SearchIconSVG from '../svgs/SearchIconSVG';

const SearchBarA = ({
  children,
  style,
  onChangeText,
  value,
  placeholder,
  onPress,
  onSubmitEditing,
  stroke,
  fill,
  ...props
}) => (
  <View style={[styles.container, style]} {...props}>
    <View style={styles.inputContainer}>
      <TouchableOpacity
        activeOpacity={0.85}
        onPress={onPress}
        style={styles.touchableOpacity}>
        <SearchIconSVG style={styles.searchIcon} stroke={stroke} fill={fill} />
      </TouchableOpacity>
      <TextInput
        placeholder={placeholder}
        placeholderTextColor={Colors.lightGray}
        returnKeyType="search"
        maxLength={50}
        onSubmitEditing={onSubmitEditing}
        // onChange={onChange}
        value={value}

        style={styles.textInput}
        onChangeText={onChangeText}
      />
    </View>

    <View style={styles.shadow}>
      <View style={styles.xmlid1}>
        <Image
          source={{ uri: xmlid1 }}
          style={styles.xmlid1}
          resizeMode="stretch"
        />
      </View>
    </View>
  </View>
);

export default SearchBarA;
