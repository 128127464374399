import React from 'react';
import Svg, {Path} from 'react-native-svg';

const FavoriteSVG = (props) => {
  const {style, fill, ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 27"
      fill="none"
      style={style}
      {...restProps}>
      <Path
        fill={fill}
        d="M15 27c-.427 0-.839-.157-1.16-.44a347.795 347.795 0 0 0-3.411-2.969l-.005-.005C7.4 20.981 4.79 18.731 2.975 16.514.945 14.037 0 11.687 0 9.12c0-2.493.845-4.794 2.38-6.478A8.025 8.025 0 0 1 8.38 0c1.732 0 3.318.554 4.714 1.646A9.708 9.708 0 0 1 15 3.659a9.709 9.709 0 0 1 1.906-2.013C18.302.554 19.888 0 21.619 0c2.317 0 4.448.938 6.001 2.642C29.155 4.326 30 6.627 30 9.12c0 2.567-.945 4.917-2.975 7.394-1.816 2.217-4.426 4.467-7.448 7.072-1.033.89-2.203 1.9-3.418 2.974-.32.284-.732.44-1.159.44ZM8.38 1.778c-1.82 0-3.492.735-4.708 2.07C2.437 5.201 1.757 7.074 1.757 9.12c0 2.16.793 4.09 2.571 6.26 1.72 2.098 4.275 4.302 7.235 6.854l.006.004c1.035.892 2.208 1.904 3.428 2.984a411.973 411.973 0 0 1 3.44-2.989c2.96-2.552 5.516-4.755 7.235-6.853 1.778-2.17 2.57-4.1 2.57-6.26 0-2.045-.68-3.918-1.914-5.273a6.295 6.295 0 0 0-4.709-2.07c-1.333 0-2.557.43-3.638 1.275-.964.754-1.635 1.707-2.028 2.374a1.098 1.098 0 0 1-.953.547c-.394 0-.75-.204-.953-.547-.393-.667-1.064-1.62-2.028-2.374-1.08-.845-2.305-1.274-3.638-1.274Z"
      />
    </Svg>
  );
};
export default FavoriteSVG;
