import React, {useState, useEffect} from 'react';
import {Text, View, StyleSheet, Button} from 'react-native';
import {BarCodeScanner} from 'expo-barcode-scanner';
import {db} from '../../services/firebase';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {setStore} from '../../reducers/cartactions';
import {AuthContext} from '../../contexts/context.js';

function QrScanner(props) {
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const {user, setUser} = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      const {status} = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  const getTableGroupeData = async (id) => {
    return db
      .collection('groupedorders')
      .doc(id)
      .get()
      .then(async (data) => {
        const groupedordersData = data.data();
        return groupedordersData;
      });
  };

  const handleBarCodeScanned = async () => {
    const regex =
      /^https:\/\/app.instadrink.io\/([a-z0-9]+)\?(?:.*)tab=([0-9]+)/i;
    //const regex = /^exp:\/\/192.168.1.16:19000\/--\/Menu\?store=[a-z0-9]+$/gi;
    try {
      const link = 'https://app.instadrink.io/TFCjBxz21avDya9VRDiX?tab=1';
      const matches = link.match(regex);
      if (matches && matches.length >= 3) {
        const id = matches[1]; // Extract ID
        const tabValue = matches[2];
        await db
          .collection('organisers')
          .doc(id)
          .get()
          .then(async (dataStore) => {
            var data = dataStore.data();
            if (data) {
              if (data.groupeTable) {
                const dataGT = data.groupeTable.find(
                  (groupe) => groupe.tab === tabValue,
                );
                const gTableGroupe = await getTableGroupeData(
                  dataGT.activeGroupedTable,
                );
                if (gTableGroupe) {
                  const userData = gTableGroupe.users.find(
                    (u) => u.userId === user?.uid,
                  );
                  if (userData?.addITProduct?.length > 0) {
                    navigation.navigate('Bill');
                    return;
                  }
                }
              }
              console.log('Redirect to cart');
            }
            console.log(snapshot.docs);
            //Linking.openURL(data);
            //props.setStore(store);
          });
        navigation.navigate('RestaurantPage', {
          id: id,
          tab: tabValue,
          t: 'food',
        });
      }
    } catch (error) {
      console.log('Error', error);
    }
  };

  if (hasPermission === null) {
    return <Text>Demande d'accès au caméra</Text>;
  }
  if (hasPermission === false) {
    return <Text>Pas d'accès au caméra</Text>;
  }

  return (
    <View style={styles.container}>
      <BarCodeScanner
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
        style={StyleSheet.absoluteFillObject}
      />
      {scanned && (
        <Button
          title={'Tapez pour scanner de nouveau'}
          onPress={() => setScanned(false)}
        />
      )}
    </View>
  );
}

const mapStateToProps = (state) => {
  const {store} = state;
  return {store};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setStore,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(QrScanner);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
});
