/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

// import node modules
import React from 'react';
import {StyleSheet, Text, TextStyle, View} from 'react-native';
import Color from 'color';
import {Caption} from '../text/CustomText';
// import components
import TouchableItem from '../TouchableItem';

// import colors
import Colors from '../../theme/colors';

// SizePicker Config
const PICKED_BACKGROUND_COLOR = Color(Colors.primaryColor).alpha(0.24);
const PICKED_TEXT_COLOR = Colors.primaryColorDark;

// SizePicker Styles
const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 6,
    marginRight: 8,
    height: 32,
    borderRadius: 4,
    backgroundColor: 'rgba(35, 47, 52, 0.08)',
    overflow: 'hidden',
  },
  caption: {
    padding: 16,
    fontWeight: '700',
    textAlign: 'left',
  },
  dishContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    height: 56,
  },
  indicator: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  emptyIndicator: {
    marginRight: 24,
    width: 20,
    height: 20,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: Colors.black,
    backgroundColor: Colors.background,
  },
  filledIndicator: {
    marginRight: 24,
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: Colors.black,
  },
  picker: {flex: 1, justifyContent: 'center'},
  title: {
    top: -1,
    color: 'rgb(35, 47, 52)',
    paddingHorizontal: 12,
  },
});

// SizePicker Props
type Props = {
  activeOpacity: number,
  color: StyleProp,
  onPress: () => void,
  picked: boolean,
  title: string,
  titleStyle: TextStyle,
};

// SizePicker
const OptionalListPicker = ({
  caption,
  index,
  items,
  onPress,
  name,
}: Props) => (
  <View  key={name + '-' + index.toString()}>
      <Caption style={styles.caption}>{caption}</Caption>
        {items && items.map((item, i) => (
          <TouchableItem
          key={item.name + '-' + i.toString()}
          onPress={onPress(name, item)}
          useForeground>
          <View style={styles.dishContainer}>
            <View style={styles.indicator}>
              <View>
                {item.picked ? (
                  <View style={styles.filledIndicator} />
                ) : (
                  <View style={styles.emptyIndicator} />
                )}
              </View>

              <Text style={styles.dishName}>{item.name}</Text>
            </View>

            <Text style={styles.dishPrice}>
              
            </Text>
          </View>
        </TouchableItem>
        ))}
      
    </View>
    
  
);

export default OptionalListPicker;