import React from 'react';
import BelgiumFlagSVG from '../components/svgs/BelgiumFlagSVG';
import FranceFlagSVG from '../components/svgs/FranceFlagSVG';

export const countries = [
  {
    name: 'France',
    flagComponent: (
      <FranceFlagSVG style={{width: 24, height: 24, marginRight: 24}} />
    ),
    cities: [
      'Paris',
      'Lyon',
      'Marseille',
      'Nice',
      'Nantes',
      'Toulouse',
      'lille',
    ],
  },
  {
    name: 'Belgium ',
    flagComponent: (
      <BelgiumFlagSVG style={{width: 24, height: 24, marginRight: 24}} />
    ),
    cities: [
      'Brussels',
      'Antwerp',
      'Ghent',
      'Bruges',
      'Liege',
      'Namur',
      'Leuven',
    ],
  },
];
