/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

// import node modules
import React, {Component} from 'react';
import {
  FlatList,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  View, Alert,
  ActivityIndicator
} from 'react-native';

// import components
import OrderItem from '../../components/cards/OrderItemA';

// import colors
import Colors from '../../theme/colors';


import { db } from "../../services/firebase";

import { AuthContext } from '../../contexts/context';
import firebase from "firebase/compat/app";
// OrdersA Styles
const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  container: {
    flex: 1,
    backgroundColor: '#efefef',
  },
  productsContainer: {
    paddingVertical: 8,
  },
});

// OrdersA
export default class OrdersList extends Component {
  static contextType = AuthContext
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      orders: [],
    };
  }

  componentDidMount = () => {
    const { user } = this.context;        
    this.setState({isLoading: true});
      let doc = db.collection('orders').where('clientid', '==', user.uid);
      if (this.props.tabtype == "old") {
        doc = doc.where('status', 'in', ['Delivered', 'canceled']);
      } else {
        doc = doc.where('status', 'not-in', ['Delivered', 'canceled']);
      }
      this.observer = doc.onSnapshot(querySnapshot => {            
          //let worker = docSnapshot.data();
          var data = querySnapshot.docs.map(function(doc) {
            var docdata = doc.data();
            return {id:doc.id, 
              orderNumber: docdata.orderNumber,
              orderDate: docdata.updatedAt,
              orderStatus: docdata.status,
              orderItems: docdata.products
              }
          });
          
          //console.log("dataorders");
          //console.log(data);
          data.sort((a,b) => (a.orderDate > b.orderDate) ? -1 : ((b.orderDate > a.orderDate) ? 1 : 0))
              
          this.setState({ orders: data, isLoading: false });           
          
          
      }, err => {
          console.log(`Encountered error: ${err}`);
      });
  };

  goBack = () => {
    const {navigation} = this.props;
    navigation.goBack();
  };

  navigateTo = (screen, id) => () => {
    try {
      const {navigation} = this.props;
      navigation.navigate(screen, {id: id});
      /*db.collection("logs").add({
        source: 'selecting order',
        screen: screen,
        id: id,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      })
      .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);            
      })
      .catch((error) => {
          console.error("Error adding document: ", error);
      }); */
      
    } catch (error) {
      db.collection("errors").add({
        source: 'selecting order',
        message: `${error.message}`,               
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      })
      .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);            
      })
      .catch((error) => {
          console.error("Error adding document: ", error);
      }); 
      
    }
    
  };

  keyExtractor = item => item.orderNumber.toString();

  renderItem = ({item, index}) => {    
    return (
    <OrderItem
      key={index}
      activeOpacity={0.8}
      orderNumber={item.orderNumber}
      orderDate={item.orderDate}
      orderItems={item.orderItems}
      orderStatus={item.orderStatus}
      onPress={this.navigateTo('Order', item.id)}
    />
  )};

  render() {
    const {orders} = this.state;
    if (this.state.isLoading) {
      //throw new Error('I crashed!');
      return  <View style={{paddingTop: 150}}>
                <ActivityIndicator size="large" color='green' />
              </View>;
    }
    return (
      <SafeAreaView style={styles.screenContainer}>
        <StatusBar
          backgroundColor={Colors.statusBarColor}
          barStyle="dark-content"
        />

        <View style={styles.container}>
          <FlatList
            data={orders}
            renderItem={this.renderItem}
            keyExtractor={this.keyExtractor}
            contentContainerStyle={styles.productsContainer}
          />
        </View>
      </SafeAreaView>
    );
  }
}