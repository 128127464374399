import React, {useState, useRef} from 'react';
import {
  View,
  StyleSheet,
  Animated,
  Image,
  ImageBackground,
  Text,
} from 'react-native';

const ProgressiveImage = ({
  thumbnailSource,
  rounded,
  source,
  handleScrollImage,
  style,
  ...props
}) => {
  // const imageRef = useRef(null);
  // const [imageLoaded, setImageLoaded] = useState(false);
  const thumbnailAnimated = useRef(new Animated.Value(0))?.current;
  const imageAnimated = useRef(new Animated.Value(0))?.current;

  // const thumbnailAnimated = new Animated.Value(0);
  // const imageAnimated = new Animated.Value(0);

  // const blurRadiusAnimated = useRef(new Animated.Value(30))?.current; // Ajout de la gestion du blurRadius

  const handleThumbnailLoad = () => {
    Animated.timing(thumbnailAnimated, {
      toValue: 0,
      // useNativeDriver: false,
      duration: 2000,
    }).start();
  };

  const onImageLoad = () => {
    Animated.timing(imageAnimated, {
      toValue: 1,
      // useNativeDriver: false,
      duration: 4000,
    }).start();
  };

  // Animated.timing(blurRadiusAnimated, {
  //   toValue: 0,
  //   useNativeDriver: false,
  // }).start();
  // React.useEffect(() => {
  //   // const handleVisibilityChange = () => {
  //   //   if (imageRef?.current && imageRef.current?.isInView()) {
  //   //     handleScrollImage();
  //   //   }
  //   // };
  //   const isInView = () => {
  //     const windowHeight =
  //       window?.innerHeight || document.documentElement.clientHeight;
  //     const rect = imageRef.current?.getBoundingClientRect();
  //     if (!rect) return false;

  //     const bottomVisible = rect?.bottom <= windowHeight;
  //     const topVisible = rect?.top <= windowHeight && rect.top >= 0;

  //     return topVisible || bottomVisible;
  //   };

  //   const handleVisibilityChange = () => {
  //     if (imageRef?.current && isInView()) {
  //       handleScrollImage();
  //     }
  //   };
  //   const visibilityObserver = new IntersectionObserver(
  //     handleVisibilityChange,
  //     {
  //       threshold: 0.5, // ajuste selon tes besoins
  //     },
  //   );

  //   if (imageRef.current) {
  //     visibilityObserver.observe(imageRef.current);
  //   }

  //   // Nettoyage de l'observateur lorsque le composant est démonté
  //   return () => {
  //     visibilityObserver.disconnect();
  //   };
  // }, [imageRef, handleScrollImage]);
  return (
    <View style={[styles.container, {borderRadius: rounded ? 32 : 0}]}>
      {/* Image Thumbnail */}

      <Animated.Image
        {...props}
        // ref={imageRef}
        source={thumbnailSource}
        style={[
          style,
          // {opacity: thumbnailAnimated /*0.5*/},

          styles.thumbnailImage,
        ]}
        onLoad={handleThumbnailLoad}
        blurRadius={50}
      />

      {/* Full Size Image */}

      <Animated.Image
        {...props}
        // ref={imageRef}
        source={source}
        style={[
          styles.fullSizeImage,
          {opacity: imageAnimated},
          // {blurRadius: blurRadiusAnimated},
          style,
        ]}
        onLoad={onImageLoad}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#e1e4e8',
    // opacity: 0.5,
    // overflow: 'hidden',
  },
  thumbnailImage: {
    // resizeMode: 'cover',
    // backgroundColor: '#e1e4e8',
    // overflow: 'hidden',
    // borderRadius: 32,
    // blurRadius: 30,
  },
  fullSizeImage: {
    // ...StyleSheet.absoluteFillObject,
    // resizeMode: 'cover',

    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,

    top: 0,
    // backgroundColor: 'transparent',
  },
});

export default ProgressiveImage;
