import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const LocalSVG = (props) => {
  const {style, fill = '#77838F', ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      style={style}
      {...restProps}>
      <Path
        fill={fill}
        fillRule="evenodd"
        d="M8 5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM5.5 6.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Z"
        clipRule="evenodd"
      />
      <Path
        fill={fill}
        fillRule="evenodd"
        d="M8 2a4.5 4.5 0 0 0-4.5 4.5c0 2.07 1.156 3.955 2.377 5.36A15.76 15.76 0 0 0 8 13.874a15.76 15.76 0 0 0 2.123-2.016C11.344 10.455 12.5 8.57 12.5 6.5A4.5 4.5 0 0 0 8 2Zm0 12.5-.287.41-.002-.002-.003-.002-.013-.01a4.954 4.954 0 0 1-.213-.158 16.767 16.767 0 0 1-2.359-2.223C3.844 11.046 2.5 8.93 2.5 6.5a5.5 5.5 0 1 1 11 0c0 2.43-1.344 4.545-2.623 6.016a16.766 16.766 0 0 1-2.359 2.222 9.082 9.082 0 0 1-.213.159l-.013.009-.003.002-.002.001L8 14.5Zm0 0 .287.41a.5.5 0 0 1-.574 0L8 14.5Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
export default LocalSVG;
