import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
const OutlineStarSVG = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={17}
    fill="none"
    viewBox="0 0 16 17"
    {...props}>
    <Path
      fill="#FFB400"
      d="M11.467 14.333a.487.487 0 0 1-.234-.06L8 12.573l-3.233 1.7a.5.5 0 0 1-.527-.033.507.507 0 0 1-.2-.493l.627-3.6L2.04 7.6a.5.5 0 0 1-.12-.513.493.493 0 0 1 .4-.34l3.613-.527 1.62-3.273a.52.52 0 0 1 .894 0l1.62 3.273 3.613.527a.494.494 0 0 1 .4.34.5.5 0 0 1-.12.513l-2.627 2.547.62 3.6a.507.507 0 0 1-.2.493.527.527 0 0 1-.286.093ZM8 11.513c.08.001.16.02.233.054L10.8 12.9l-.487-2.86a.52.52 0 0 1 .14-.447l2.08-2-2.873-.426a.507.507 0 0 1-.373-.274L8 4.293 6.713 6.9a.507.507 0 0 1-.373.273l-2.873.42 2.08 2a.52.52 0 0 1 .14.447L5.2 12.92l2.567-1.333A.568.568 0 0 1 8 11.513Z"
    />
  </Svg>
);
export default OutlineStarSVG;
