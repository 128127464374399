import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';
const GroupOrderSVG = (props) => {
  const {...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 162 171"
      width={162}
      height={171}
      {...restProps}>
      <G clipPath="url(#a)">
        <Path
          fill="#FEF156"
          d="m64.535 157.975 75.975-13.326C153.834 130.054 162 110.582 162 89.174c0-45.188-36.268-81.826-81-81.826S0 43.986 0 89.174c0 11.155 2.215 21.776 6.216 31.428l58.32 37.373Z"
        />
        <Path
          fill="#391A6F"
          d="M75.479 0h-35.21c-10.349 0-18.78 8.55-18.78 19.004v101.564C54.982 154.434 80.01 18.102 80.01 18.102L75.478 0Z"
        />
        <Path
          fill="#5A3F8F"
          d="M113.764 0H75.479c-2.116 2.138-6.81 8.15.892 13.526 13.126 9.786 42.98 3.574 42.98 3.574L113.764 0ZM122.227 38.04s-3.835 89.709 4.926 118.398c4.893-3.406 9.357-7.381 13.39-11.756V40.812l-18.316-2.771Z"
        />
        <Path
          fill="#775BBC"
          d="M121.731 0h-7.967c-2.116 2.772-4.993 8.416 1.52 13.526l11.869 27.287s5.753 7.715 13.39 0v-21.81C140.51 8.55 132.08 0 121.731 0Z"
        />
        <Path
          fill="#ADE9F7"
          d="m57.956 150.594-20.795 7.381c9.356 6.078 20.002 10.286 31.474 12.09l-10.679-19.471ZM88.042 151.295c-17.456 1.336-14.415 19.371-14.415 19.371 2.447.234 4.893.334 7.373.334 5.455 0 10.81-.534 15.968-1.603l-8.926-18.102ZM76.371 13.526H40.27c-2.976 0-5.423 2.438-5.423 5.478v101.564l49.229 13.226s17.753-24.848 17.753-57.746c.034-32.93-25.457-62.522-25.457-62.522Z"
        />
        <Path
          fill="#E8F8FC"
          d="M121.731 13.526h-45.36s-5.355 6.747.992 11.39L81 50.731s-6.844 6.079 0 11.857c0 0 11.571 25.35 5.852 48.194 0 0-5.555 4.508-1.785 10.453l-8.86 16.098 11.207 18.303c.132 4.275 1.752 10.52 9.522 13.76a79.987 79.987 0 0 0 30.184-12.992V19.004c0-3.04-2.413-5.478-5.389-5.478Z"
        />
        <Path
          fill="#6AD9FB"
          d="M118.789 29.057c0-2.372-1.885-4.275-4.232-4.275h-6.976c-2.347 0-4.232 1.903-4.232 4.275 0 2.37 1.885 4.275 4.232 4.275h6.976c2.314 0 4.232-1.904 4.232-4.275Z"
        />
        <Path
          fill="#775BBC"
          d="M81 21.91c-7.869 0-14.282 6.445-14.282 14.428 0 3.974 1.587 7.581 4.198 10.22L81 41.28l10.084 5.276a14.485 14.485 0 0 0 4.198-10.22c0-7.982-6.413-14.461-14.282-14.428Z"
        />
        <Path
          fill="#FFC7AB"
          d="M87.826 31.406c1.276-3.79-.732-7.906-4.483-9.194-3.752-1.288-7.827.74-9.102 4.53-1.275 3.79.733 7.905 4.484 9.193 3.752 1.289 7.827-.74 9.102-4.53Z"
        />
        <Path
          fill="#02C0FC"
          d="M84.637 36.304h-7.274c-3.537 0-6.447 2.94-6.447 6.513v3.707A14.145 14.145 0 0 0 81 50.732c3.934 0 7.505-1.603 10.084-4.208v-3.707c0-3.574-2.91-6.513-6.447-6.513Z"
        />
        <Path
          fill="#6AD9FB"
          d="M118.492 61.52h-38.45c-4.233 0-7.67 3.473-7.67 7.748v21.91c0 4.275 3.438 7.748 7.67 7.748h2.016c1.422 0 2.579 1.169 2.579 2.605v4.743c0 4.976 5.885 7.548 9.488 4.141l10.514-9.953a5.52 5.52 0 0 1 3.802-1.502h10.117l11.637-21.275-11.703-16.165Z"
        />
        <Path
          fill="#2FBF86"
          d="M42.417 120.568H7.01c-.265 0-.53 0-.827.034a81.719 81.719 0 0 0 9.555 17.033l6.05 1.636.43 6.179a82.736 82.736 0 0 0 14.91 12.525h6.282l9.456-19.305-10.448-18.102Z"
        />
        <Path
          fill="#6AD9FB"
          d="M157.239 91.178v-21.91c0-4.275-3.438-7.748-7.67-7.748h-51.08s-6.678 31.561 0 37.406h51.08c4.199 0 7.67-3.473 7.67-7.748Z"
        />
        <Path
          fill="#2FBF86"
          d="M81.959 120.568H42.417c-1.586 1.737-6.116 7.615.496 12.458v12.458s-6.678 6.546.53 12.491h10.182c1.422 0 2.744.534 3.802 1.503l10.514 9.952c.231.234.496.434.76.601a61.55 61.55 0 0 0 4.992.602c2.083-.769 3.736-2.706 3.736-5.344v-4.743c0-1.436 1.157-2.605 2.58-2.605h2.016c4.232 0 7.67-3.473 7.67-7.748v-21.91c-.066-4.241-3.504-7.715-7.736-7.715ZM21.688 133.026c-2.843 0-5.257 1.971-5.95 4.609a80.795 80.795 0 0 0 6.48 7.815c3.173-.267 5.652-2.939 5.652-6.212 0-3.407-2.777-6.212-6.182-6.212Z"
        />
        <Path
          fill="#fff"
          d="M116.409 74.245c-.86-.134-1.62.467-1.753 1.336l-1.454 10.353c-.166 1.17-.662 4.743-6.58 4.743H94.753c-5.918 0-6.413-3.574-6.579-4.743L86.72 75.581c-.133-.869-.893-1.47-1.753-1.336-.86.133-1.421.901-1.322 1.77L85.1 86.368c.66 4.81 4.1 7.448 9.653 7.448h11.869c5.555 0 8.96-2.638 9.654-7.448l1.455-10.353c.099-.835-.496-1.637-1.322-1.77ZM82.653 73.009c-1.587 0-2.876-1.302-2.876-2.906 0-1.603 1.29-2.905 2.876-2.905h3.505c1.223-6.914 7.14-11.99 14.216-11.99h.661c7.075 0 12.993 5.076 14.217 11.99h3.504c1.587 0 2.876 1.302 2.876 2.905 0 1.604-1.289 2.906-2.876 2.906H82.653Zm26.681-5.811c-1.124-3.607-4.464-6.179-8.299-6.179h-.661c-3.835 0-7.174 2.572-8.298 6.179h17.258Z"
        />
        <Path
          fill="#6AD9FB"
          d="M101.035 56.544c6.811 0 12.431 5.277 13.059 11.99h4.662c.86 0 1.554.701 1.554 1.57a1.56 1.56 0 0 1-1.554 1.57H82.653a1.56 1.56 0 0 1-1.554-1.57c0-.869.694-1.57 1.554-1.57h4.662c.628-6.714 6.248-11.99 13.059-11.99h.661Zm-10.58 11.99h20.532c-.629-4.977-4.827-8.851-9.919-8.851h-.661c-5.124 0-9.356 3.874-9.951 8.85Zm10.58-14.662h-.661a15.53 15.53 0 0 0-10.613 4.175c-2.28 2.104-3.9 4.809-4.628 7.815h-2.48c-2.314 0-4.199 1.903-4.199 4.241 0 2.338 1.885 4.242 4.2 4.242h36.102c2.314 0 4.199-1.904 4.199-4.242s-1.885-4.241-4.199-4.241h-2.447a16.182 16.182 0 0 0-4.628-7.815c-2.943-2.672-6.712-4.175-10.646-4.175Zm-6.91 11.99c1.323-2.104 3.637-3.507 6.249-3.507h.661c2.612 0 4.926 1.403 6.249 3.507H94.125Z"
        />
        <Path
          fill="#fff"
          d="M93.795 87.27a1.559 1.559 0 0 1-1.521-1.303l-1.058-6.446c-.132-.868.43-1.67 1.256-1.803.86-.134 1.653.434 1.786 1.269l1.058 6.446c.132.868-.43 1.67-1.257 1.803-.099.034-.165.034-.264.034ZM107.614 87.27c-.099 0-.165 0-.264-.033a1.512 1.512 0 0 1-1.256-1.804l1.058-6.446c.132-.835.925-1.436 1.785-1.269a1.514 1.514 0 0 1 1.256 1.804l-1.058 6.446a1.558 1.558 0 0 1-1.521 1.302ZM100.705 87.27a1.56 1.56 0 0 1-1.554-1.57v-6.446c0-.868.694-1.57 1.554-1.57a1.56 1.56 0 0 1 1.553 1.57V85.7a1.56 1.56 0 0 1-1.553 1.57ZM79.545 133.093c-.86-.134-1.62.467-1.752 1.336l-1.455 10.353c-.165 1.169-.66 4.743-6.579 4.743H57.89c-5.918 0-6.414-3.574-6.579-4.743l-1.455-10.353c-.132-.869-.892-1.47-1.752-1.336-.86.133-1.422.902-1.322 1.77l1.454 10.353c.662 4.81 4.1 7.448 9.654 7.448h11.87c5.553 0 8.959-2.638 9.653-7.448l1.455-10.353c.099-.868-.463-1.637-1.323-1.77ZM45.79 131.824c-1.587 0-2.877-1.303-2.877-2.906 0-1.603 1.29-2.906 2.877-2.906h3.504c1.223-6.913 7.141-11.99 14.217-11.99h.66c7.076 0 12.994 5.077 14.217 11.99h3.504c1.587 0 2.877 1.303 2.877 2.906 0 1.603-1.29 2.906-2.877 2.906H45.79Zm26.68-5.778c-1.124-3.607-4.463-6.179-8.298-6.179h-.661c-3.836 0-7.175 2.572-8.299 6.179H72.47Z"
        />
        <Path
          fill="#2FBF86"
          d="M64.172 115.392c6.81 0 12.43 5.277 13.059 11.99h4.662c.86 0 1.553.701 1.553 1.569a1.56 1.56 0 0 1-1.553 1.57H45.79a1.56 1.56 0 0 1-1.554-1.57 1.56 1.56 0 0 1 1.554-1.569h4.661c.629-6.713 6.249-11.99 13.06-11.99h.66Zm-10.58 11.99h20.531c-.628-4.977-4.827-8.851-9.918-8.851h-.661c-5.125 0-9.324 3.874-9.952 8.851Zm10.58-14.662h-.661a15.526 15.526 0 0 0-10.613 4.175c-2.281 2.104-3.901 4.809-4.629 7.815h-2.446c-2.315 0-4.199 1.903-4.199 4.241 0 2.338 1.884 4.242 4.199 4.242h36.103c2.314 0 4.198-1.904 4.198-4.242s-1.884-4.241-4.198-4.241h-2.447a16.179 16.179 0 0 0-4.628-7.815c-2.976-2.706-6.745-4.175-10.68-4.175Zm-6.91 11.99c1.322-2.104 3.637-3.507 6.249-3.507h.66c2.613 0 4.927 1.403 6.25 3.507H57.261Z"
        />
        <Path
          fill="#fff"
          d="M56.965 146.084a1.558 1.558 0 0 1-1.521-1.302l-1.058-6.446c-.133-.868.43-1.67 1.256-1.804.86-.133 1.653.435 1.785 1.27l1.058 6.445c.133.869-.43 1.67-1.256 1.804-.1.033-.198.033-.264.033ZM70.751 146.085c-.1 0-.165 0-.264-.034a1.513 1.513 0 0 1-1.257-1.803l1.058-6.446c.133-.835.926-1.436 1.785-1.269.86.133 1.422.935 1.257 1.803l-1.058 6.446a1.56 1.56 0 0 1-1.52 1.303ZM63.841 146.085a1.56 1.56 0 0 1-1.554-1.57v-6.446c0-.868.695-1.57 1.554-1.57.86 0 1.554.702 1.554 1.57v6.446a1.54 1.54 0 0 1-1.554 1.57Z"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h162v171H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
export default GroupOrderSVG;
