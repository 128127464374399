import { StyleSheet } from 'react-native';
import Colors from '../../theme/colors';
import Layout from '../../theme/layout';

const styles = StyleSheet.create({
  header: {
    width: '100%',
    // height: 106,
    position: 'relative',
  },


  headerScroll: {
    height: 70
  },
  headerNoScroll: {
    height: 106
  },
  headerBg: {
    width: '100%',
    height: '100%',
  },
  container: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    position: 'absolute',
    top: 4,
    left: 0,
    paddingRight: 22,
  },
  logoText: {
    width: 166,
    height: 22,
  },
  pro: {
    width: 166,
    height: 22,
  },
  logo: {
    width: 77,
    height: 77,
  },
  notificationBtn: {
    marginLeft: 'auto',
    width: 33,
    height: 33,
  },
});

export default styles;
