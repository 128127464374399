import * as React from 'react';
import Svg, {Circle, ClipPath, Defs, G, Path} from 'react-native-svg';
const MinusSVG2 = (props) => {
  const {style, stroke = '#2FBF86', fill = '#2FBF86', ...restProps} = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      style={style}
      {...restProps}>
      <Circle cx={10} cy={10} r={9.5} stroke={stroke} fill="#FFF" />
      <G fill={fill} clipPath="url(#a)">
        <Path d="M6 10.8a.8.8 0 1 1 0-1.6h8a.8.8 0 0 1 0 1.6H6Z" />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M2 2h16v16H2z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
export default MinusSVG2;
