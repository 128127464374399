import {StyleSheet, Platform} from 'react-native';
import Colors from '../../theme/colors';

const styles = StyleSheet.create({
  touchableItem: {
    marginTop: 12,
    // marginRight: 16,
  },
  cardContainer: {
    width: '100%',
    borderRadius: 15,
    position: 'relative',
  },
  // promoFrameContainer: {
  //   position: 'relative',
  //   // top: 205,
  //   // right: -6,
  //   // zIndex: 100,
  // },
  newIcon: {
    width: 36,
    height: 36,
    position: 'absolute',
    top: -15,
    right: -6,
    zIndex: 2,
    // paddingHorizontal: 10,
  },
  rate: {
    position: 'absolute',
    top: -5,
    right: -2,
    zIndex: 2,
    paddingHorizontal: 3,
  },
  // favoriteIconContainer: {
  //   width: 33,
  //   height: 33,
  //   borderRadius: 50,
  //   // backgroundColor: Colors.darkGreen,
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   position: 'absolute',
  //   top: 80,
  //   right: 7,
  //   zIndex: 2,
  // },
  favoriteIcon: {
    width: '50%',
    height: '50%',
  },
  cardImage: {
    width: '100%',
    height: 120,
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
  },
  cardContent: {
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    backgroundColor: Colors.white,
    flexDirection: 'column',
    paddingTop: 15,
    paddingBottom: 20,
    paddingHorizontal: 20,
  },
  TitleRow: {},
  subheadingRow: {
    marginTop: 10,
  },
  category: {
    paddingHorizontal: 10,
    backgroundColor: Colors.darkGreen,
    borderRadius: 8,
    width: 'max-content',
    marginTop: 9,
  },
  categoryType: {
    color: Colors.white,
  },
  bodyRow: {
    flexDirection: 'row',
    marginTop: 10,
  },
  rowIcon: {
    width: 21,
    height: 21,
    marginRight: 10,
  },
  scrollView: {
    width: '80%',
  },
  eventName: {
    color: Colors.darkGray,
  },
  promoFrameContainer: {
    position: 'relative',
  },
  promoFrameContainer: {
    position: 'relative',
  },
});

export default styles;
