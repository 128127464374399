import {StyleSheet} from 'react-native';
import Colors from '../../theme/colors';

const styles = StyleSheet.create({
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: Colors.darkGreen,
    paddingVertical: 15,
    paddingHorizontal: 50,
    borderRadius: 10,
  },
  buttonTextContainer: {
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    position: 'relative',
  },
  buttonText: {
    color: Colors.background,
    textAlign: 'center',
    fontFamily: 'SemiBold',
    fontSize: 16,
    letterSpacing: 0.64,
    textTransform: 'capitalize',
  },
});

export default styles;
