import React from 'react';
// import { useEffect } from 'react';
import {useFonts} from 'expo-font';

const useCustomFonts = () => {
  // useEffect(() => {
  // Load your custom fonts here
  useFonts({
    Light: require('./assets/fonts/BaiJamjuree-Light.ttf'),
    Regular: require('./assets/fonts/BaiJamjuree-Regular.ttf'),
    SemiBold: require('./assets/fonts/BaiJamjuree-SemiBold.ttf'),
    Bold: require('./assets/fonts/BaiJamjuree-Bold.ttf'),
    // Add other font weights and styles as needed
  });
  // }, []);
};

export default useCustomFonts;
