import React from 'react';

import pizzaImage from '../../../assets/images/pizzaImage.png';
import HamburgersImage from '../../../assets/images/hamburgersImage.png';
import Salads from '../../../assets/images/saladsImage.png';
import halal from '../../../assets/images/halaIImage.png';

export const categoriesData = [
  {
    id: '1',
    image: pizzaImage,
    title: 'Pizzas',
    routeName: '',
  },
  {
    id: '2',
    image: HamburgersImage,
    title: 'Hamburgers',
    routeName: '',
  },
  {
    id: '3',
    image: Salads,
    title: 'Salads',
    routeName: '',
  },
  {
    id: '4',
    image: halal,
    title: 'Halal',
    routeName: '',
  },
];
