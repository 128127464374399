/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

// import dependencies
import React, {useEffect, useState, useContext} from 'react';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {MaterialCommunityIcons as Icon} from '@expo/vector-icons';

// SVGs import
import HomeSVG from '../components/svgs/HomeSVG';
import CartSVG from '../components/svgs/CartSVG';
import QRCodeSVG from '../components/svgs/QRCodeSVG';
import TicketSVG from '../components/svgs/TicketSVG';
import ProfileSVG from '../components/svgs/ProfileSVG';

// firestore
import {db} from '../services/firebase';
import Events from '../screens/events/Events';

// import Home screen
import Home from '../screens/home/HomeD';

import Menu from '../screens/menu/MenuA';

// import Search screen
import Search from '../screens/search/SearchR';

// import Favorites screen
import Favorites from '../screens/favorites/FavoritesA';

import Notifications from '../screens/notifications/NotificationsA';

// import Cart screen
import Cart from '../screens/cart/CartA';

// import Orders screen
import Orders from '../screens/orders/OrdersC';

// import Help screen
import Help from '../screens/help/HelpA';

// import Settings screen
import SettingsA from '../screens/settings/SettingsA';

import TabIcon from './TabIcon';
import TabOrdIcon from './TabOrdIcon';

// import colors
import Colors from '../theme/colors';
import {AuthContext} from '../contexts/context';
import {View} from 'react-native';
// import OrderAndPayment from '../screens/orderAndPayment/OrderAndPayment';
// import ContactDetails from '../screens/orderAndPayment/contactDetails/ContactDetails';
import {createStackNavigator} from '@react-navigation/stack';
import BackArrowSVG from '../components/svgs/BackArrowSVG';
import PaymentMethod from '../screens/orderAndPayment/paymentMethod/PaymentMethod';
// import ConfirmedPurchase from '../screens/orderAndPayment/confirmedPurchase/ConfirmedPurchase';
import EventB from '../screens/event/EventB';
import CartB from '../screens/cart/CartB';
import CartC from '../screens/cart/CartC';
import Command from '../screens/Command/Command';
import TicketCoordinatesB from '../screens/ticketcoordinates/TicketCoordinatesB';
import CheckoutSuccessA from '../screens/checkout/CheckoutSuccessA';
import Parameters from '../screens/parameters/Parameters';
import OrdersD from '../screens/orders/OrdersD';
import OrderB from '../screens/order/OrderB';
import HeaderIconButton from '../components/navigation/HeaderIconButton';
import GroupOrderPage from '../screens/groupOrder/GroupOrderPage';
import AllServices from '../screens/allServices/AllServices';
import Restaurants from '../screens/restaurants/Restaurants';
import RestaurantPage from '../screens/restaurantPage/RestaurantPage';
import RestaurantItemPage from '../screens/restaurantItemPage/RestaurantItemPage';
import QrScanner from '../screens/scanner/QrScanner';
import RestaurantOrderDetails from '../screens/restaurantOrderDetails/RestaurantOrderDetails';
import ArticleDiviser from '../screens/ArticleDiviser/ArticleDiviser';
import ArticlesPayer from '../screens/ArticlesPayer/ArticlesPayer';
import restaurantBill from '../screens/restaurantBill/restaurantBill';
import CategoriesTab from '../screens/categoriesTab/categoriesTab';
//
//import Command from '../screens/Command/Command';
import LivredCommand from '../screens/Command/LivredCommand';
import {useTranslation} from 'react-i18next';
import ChangeLangue from '../screens/parameters/changeLangue/changeLangue';
import MontantChoix from '../screens/MontantChoix/MontantChoix';
// HomeNavigator Config

// type Props = {
//   color: string,
//   focused: string,
//   size: number,
// };

// create bottom tab navigator
const Tab = createBottomTabNavigator();

// create nested with stacks tab navigator
const HomeStack = createStackNavigator();

// HomeNavigator
function HomeNavigator(props) {
  const [ordCount, setOrdCount] = useState(0);
  const {user} = useContext(AuthContext);

  useEffect(() => {
    let doc = db.collection('orders').where('clientid', '==', user.uid);
    doc = doc.where('status', 'not-in', ['Delivered', 'canceled', 'validated']);

    //this.setState({isLoading: true});
    const observer = doc.onSnapshot((querySnapshot) => {
      const ordsize = querySnapshot.docs.length;
      setOrdCount(ordsize);
    });
    return observer;
  }, []);

  return (
    <React.Fragment>
      <Tab.Navigator
        initialRouteName="Menu"
        backBehavior="initialRoute"
        screenOptions={({route}) => ({
          tabBarIcon: ({focused}) => {
            let IconComponent;
            let iconColor = focused ? Colors.darkGreen : Colors.normalGray;
            let selectedDot = focused ? Colors.darkGreen : Colors.white;

            if (route.name === 'Home') {
              IconComponent = (
                <View
                  style={{
                    position: 'relative',
                    alignItems: 'center',
                  }}>
                  <HomeSVG
                    stroke={iconColor}
                    style={{
                      width: 25,
                      height: 25,
                    }}
                  />
                  <View
                    style={{
                      backgroundColor: selectedDot,
                      width: 5,
                      height: 5,
                      borderRadius: 50,
                      position: 'absolute',
                      bottom: -7,
                      left: 9.8,
                    }}></View>
                </View>
              );
            } else if (route.name === 'Cart') {
              IconComponent = (
                <View
                  style={{
                    position: 'relative',
                    alignItems: 'center',
                  }}>
                  <CartSVG
                    stroke={iconColor}
                    style={{
                      width: 25,
                      height: 25,
                    }}
                  />
                  <View
                    style={{
                      backgroundColor: selectedDot,
                      width: 5,
                      height: 5,
                      borderRadius: 50,
                      position: 'absolute',
                      bottom: -7,
                      left: 9.8,
                    }}></View>
                </View>
              );
            } else if (route.name === 'QRCodeScan') {
              IconComponent = (
                <View
                  style={{
                    backgroundColor: Colors.white,
                    width: 60,
                    height: 60,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 30,
                    marginTop: -20,
                    shadowColor: '#171717',
                    shadowOffset: {width: 0, height: 10},
                    shadowOpacity: 0.1,
                    shadowRadius: 10,
                  }}>
                  <QRCodeSVG fill={iconColor} style={{width: 31, height: 31}} />
                </View>
              );
            } else if (route.name === 'Orders') {
              IconComponent = (
                <View
                  style={{
                    position: 'relative',
                    alignItems: 'center',
                  }}>
                  <TicketSVG
                    stroke={iconColor}
                    style={{
                      width: 25,
                      height: 25,
                    }}
                  />
                  <View
                    style={{
                      backgroundColor: selectedDot,
                      width: 5,
                      height: 5,
                      borderRadius: 50,
                      position: 'absolute',
                      bottom: -7,
                      left: 9.7,
                    }}></View>
                </View>
              );
            } else if (route.name === 'Parameters') {
              IconComponent = (
                <View
                  style={{
                    position: 'relative',
                    // alignItems: 'center',
                  }}>
                  <ProfileSVG
                    stroke={iconColor}
                    style={{
                      width: 25,
                      height: 25,
                    }}
                  />
                  <View
                    style={{
                      backgroundColor: selectedDot,
                      width: 4.9,
                      height: 5,
                      borderRadius: 50,
                      position: 'absolute',
                      bottom: -7,
                      left: 9.5,
                    }}></View>
                </View>
              );
            }
            // else if (route.name === 'Help') {
            //   IconComponent = <ProfileSVG />;
            // }
            // else if (route.name === 'Search') {
            //   IconComponent = SearchIcon;
            // } else if (route.name === 'Menu') {
            //   IconComponent = MenuIcon;
            // } else if (route.name === 'Favorites') {
            //   IconComponent = FavoritesIcon;
            // } else if (route.name === 'Orders') {
            //   IconComponent = OrdersIcon;
            // } else if (route.name === 'Settings') {
            //   IconComponent = SettingsIcon;
            // } else if (route.name === 'Help') {
            //   IconComponent = HelpIcon;
            // }

            // You can return any component that you like here!
            return IconComponent;
          },
        })}
        tabBarOptions={{
          keyboardHidesTabBar: true,
          scrollEnabled: false,
          activeTintColor: Colors.primaryColor,
          inactiveTintColor: Colors.secondaryText,
          showLabel: false, // hide labels
          style: {
            backgroundColor: Colors.white, // TabBar background
            height: 65,
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
            borderTopWidth: 0,
            position: 'relative',
            zIndex: 999,
          },
          tabStyle: {
            marginHorizontal: 10,
          },
        }}>
        <Tab.Screen name="Home" component={HomeTabs} />
        {/* <Tab.Screen name="AllServices" component={HomeTabs} /> */}
        {/* <Tab.Screen name="Cart" component={CartTabs} /> */}
        <Tab.Screen name="Cart">
          {({route}) => <CartTabs eventId={route.params?.eventId} />}
        </Tab.Screen>

        {/* <Tab.Screen name="QRCodeScan" component={QrScanner} /> */}
        {/*<Tab.Screen
          name="Orders"
          component={OrdersTab}
          // options={{
          //   tabBarIcon: (props) => (
          //     <TabOrdIcon
          //       focused={props.focused}
          //       size={props.size}
          //       count={ordCount}
          //     />
          //   ),
          // }}
      />

        <Tab.Screen name="Parameters" component={ParametersTab} />*/}

        {/* <Tab.Screen name="Help" component={Help} /> */}
        {/*<Tab.Screen name="Favorites" component={Favorites} />*/}
        {/*<Tab.Screen name="Notifications" component={Notifications} />*/}
        {/* <Tab.Screen name="Settings" component={Settings} /> */}
      </Tab.Navigator>
    </React.Fragment>
  );
}

function HomeTabs() {
  const {t} = useTranslation();
  return (
    <HomeStack.Navigator
      initialRouteName="Home"
      screenOptions={({navigation}) => ({
        headerStyle: {
          backgroundColor: Colors.darkGreen,
          height: 60,
        },
        headerBackTitleVisible: false,
        headerLeft: () => (
          <HeaderIconButton
            onPress={() => navigation.goBack()}
            name={'md-arrow-back'}
            color={Colors.white}
          />
        ),
        headerTitleStyle: {
          textAlign: 'center',
          fontFamily: 'SemiBold',
          fontSize: 16,
          lineHeight: 24,
          letterSpacing: 0.64,
          color: Colors.background,
        },
        headerTitleAlign: 'center',
      })}>
      <HomeStack.Screen
        name="AllServices"
        component={AllServices}
        /* options={{title: 'Toutes les prestations'}}*/
        options={{title: t('HomeNavigatorATitle.Toutes les prestations')}}
      />

      <HomeStack.Screen
        name="Home"
        component={Events}
        options={{headerShown: false}}
      />

      <HomeStack.Screen
        name="Restaurants"
        component={Restaurants}
        options={{headerShown: false}}
      />
      <HomeStack.Screen
        name="RestaurantPage"
        component={RestaurantPage}
        options={{title: 'Burger & Fries'}}
      />
      <HomeStack.Screen
        name="RestaurantItemPage"
        component={RestaurantItemPage}
        // options={{title: 'Salade César'}}
        options={{title: t('HomeNavigatorATitle.Salade César')}}
      />
      <HomeStack.Screen
        name="RestaurantOrderDetails"
        component={RestaurantOrderDetails}
        // options={{title: 'Détails Commande'}}
        options={{title: t('HomeNavigatorATitle.Détails Commande')}}
      />
      <HomeStack.Screen
        name="ChangeLangue"
        component={ChangeLangue}
        // options={{title: 'Détails Commande'}}
        options={{title: t('HomeNavigatorATitle.changement de langue')}}
      />
      <HomeStack.Screen
        name="CategoriesTab"
        component={CategoriesTab}
        options={{
          headerShown: false,
        }}
      />
      <HomeStack.Screen
        options={({navigation}) => ({
          headerLeft: () => (
            <HeaderIconButton
              onPress={() => navigation.navigate('Home')}
              name={'md-arrow-back'}
              color={Colors.white}
            />
          ),
        })}
        name="Event"
        component={EventB}
      />
    </HomeStack.Navigator>
  );
}

function CartTabs({eventId}) {
  const {t} = useTranslation();
  return (
    <HomeStack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: Colors.darkGreen,
          height: 60,
        },
        headerBackTitleVisible: false,
        headerBackImage: () => (
          <BackArrowSVG
            style={{
              width: 10,
              height: 16,
              position: 'absolute',
              left: 14,
            }}
          />
        ),
        headerTitleStyle: {
          textAlign: 'center',
          fontFamily: 'SemiBold',
          fontSize: 16,
          lineHeight: 24,
          letterSpacing: 0.64,
          color: Colors.background,
        },
        headerTitleAlign: 'center',
      }}>
      {/* <HomeStack.Screen
        name="Cart"
        component={CartB}
        options={{
          title: 'Order',
        }}
      /> */}

      <HomeStack.Screen
        name="Cart"
        component={CartB}
        // initialParams={{eventId}}
        // options={{
        //   title: 'Paniers',
        // }}
        options={{title: t('HomeNavigatorATitle.Paniers')}}
      />

      <HomeStack.Screen
        name="Bill"
        component={restaurantBill}
        // initialParams={{eventId}}
        options={{
          // title: 'Bill',
          title: t('HomeNavigatorATitle.Facture'),
        }}
      />
      <HomeStack.Screen
        name="amount"
        component={MontantChoix}
        options={{
          title: 'Payer le montant de votre choix',
        }}
      />
      <HomeStack.Screen
        name="shared"
        // name="shared"
        component={ArticleDiviser}
        // options={{
        //   title: 'Diviser en parts égales',
        // }}
        options={{
          title: t('HomeNavigatorATitle.Diviser en parts égales'),
        }}
      />
      <HomeStack.Screen
        name="chooseArticle"
        component={ArticlesPayer}
        // options={{
        //   title: 'Choisir les articles à payer',
        // }}
        options={{
          title: t('HomeNavigatorATitle.Choisir les articles à payer'),
        }}
      />

      {/* <HomeStack.Screen
        name="Cart"
        component={CartB}
        // initialParams={{eventId}}
        options={{
          title: 'Paniers',
        }}
      /> */}

      <HomeStack.Screen
        name="TicketCoordinates"
        component={TicketCoordinatesB}
        // options={{
        //   title: 'Détails de votre contact',
        // }}
        options={{title: t('HomeNavigatorATitle.Détails de votre contact')}}
      />
      <HomeStack.Screen
        name="PaymentMethod"
        component={PaymentMethod}
        options={{
          title: 'Moyen de paiement',
        }}
      />

      {/* <HomeStack.Screen
        name="GroupOrder"
        component={GroupOrderPage}
        options={{
          title: 'Group order',
        }}
      /> */}

      {/* <HomeStack.Screen
        name="CheckoutSuccess"
        component={CheckoutSuccessA}
        // options={{
        //   title: 'Confirmed purchase',
        // }}
      /> */}
    </HomeStack.Navigator>
  );
}

function ParametersTab() {
  const {t} = useTranslation();

  return (
    <HomeStack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: Colors.darkGreen,
          height: 60,
        },
        headerBackTitleVisible: false,
        headerBackImage: () => (
          <BackArrowSVG
            style={{
              width: 10,
              height: 16,
              position: 'absolute',
              left: 14,
            }}
          />
        ),
        headerTitleStyle: {
          textAlign: 'center',
          fontFamily: 'SemiBold',
          fontSize: 16,
          lineHeight: 24,
          letterSpacing: 0.64,
          color: Colors.background,
        },
        headerTitleAlign: 'center',
        headerTintColor: Colors.background,
      }}>
      <HomeStack.Screen
        name="Parameters"
        component={Parameters}
        // options={{
        //   title: 'Paramètres',
        // }}
        options={{title: t('HomeNavigatorATitle.Paramètres')}}
      />
      <HomeStack.Screen
        name="Command"
        component={Command}
        initialParams={{id: ''}} // Pass eventId as initial parameter
        options={{
          title: 'Commande',
        }}
      />
      <HomeStack.Screen
        name="LivredCommand"
        component={LivredCommand}
        initialParams={{id: ''}} // Pass eventId as initial parameter
        options={{
          title: 'Commande',
        }}
      />
      <HomeStack.Screen
        name="Commandes"
        component={CartC}
        options={{
          // title: 'Mes commandes',
          title: t('HomeNavigatorATitle.Mes commandes'),
        }}
      />
    </HomeStack.Navigator>
  );
}

function OrdersTab() {
  return (
    <HomeStack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: Colors.darkGreen,
          height: 60,
        },
        headerBackTitleVisible: false,
        headerBackImage: () => (
          <BackArrowSVG
            style={{
              width: 10,
              height: 16,
              position: 'absolute',
              left: 14,
            }}
          />
        ),
        headerTitleStyle: {
          textAlign: 'center',
          fontFamily: 'SemiBold',
          fontSize: 16,
          lineHeight: 24,
          letterSpacing: 0.64,
          color: Colors.background,
        },
        headerTitleAlign: 'center',
        headerTintColor: Colors.background,
      }}>
      <HomeStack.Screen
        name="Orders"
        component={OrdersD}
        options={{
          title: 'Mes billets',
        }}
      />
      <HomeStack.Screen
        name="Order"
        component={OrderB}
        options={{
          title: 'Mon billet',
        }}
      />
    </HomeStack.Navigator>
  );
}

export default HomeNavigator;
