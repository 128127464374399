import {StyleSheet} from 'react-native';
import Colors from '../../theme/colors';

const styles = StyleSheet.create({
  inputRow: {
    marginBottom: 30,
    position: 'relative',
  },
  label: {
    position: 'absolute',
    top: -10,
    left: 10,
    backgroundColor: Colors.white,
    borderRadius: 14,
    paddingHorizontal: 6,
  },
  textInput: {
    height: 46,
    borderRadius: 8,
    backgroundColor: Colors.white,
    paddingVertical: 12,
    paddingRight: 16,
    paddingLeft: 16,
    borderColor: '#D2D5DA',
    borderWidth: 1,
    shadowColor: 'black',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.05,
    shadowRadius: 2,
    elevation: 1,
  },
  inputLeftIcon: {
    position: 'absolute',
    top: 15,
    left: 16,
    width: 16,
    height: 16,
  },
  inputRightIcon: {
    position: 'absolute',
    top: 14,
    right: 12,
    width: 18,
    height: 18,
  },

  dropdown: {
    maxHeight: 150,
    borderRadius: 8,
    backgroundColor: Colors.white,
    paddingVertical: 12,
    paddingRight: 16,
    paddingLeft: 16,
    borderColor: '#D2D5DA',
    borderWidth: 1,
    shadowColor: 'black',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.05,
    shadowRadius: 2,
    elevation: 1,
  },
  dropdownItem: {
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGray,
  },
});

export default styles;
