import React from 'react';
import {useTranslation} from 'react-i18next';

const DateTimePicker = ({setDateTime, dateTime}) => {
  const {t} = useTranslation();
  const handleDateChange = (event) => {
    setDateTime(
      new Date(event.target.value + 'T' + dateTime.toISOString().split('T')[1]),
    );
  };

  const handleTimeChange = (event) => {
    setDateTime(
      new Date(dateTime.toISOString().split('T')[0] + 'T' + event.target.value),
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 4,
        marginTop: 30,
      }}>
      <div style={{position: 'relative', flex: '1 1'}}>
        <label
          style={{
            position: 'absolute',
            background: 'white',
            top: -10,
            left: 10,
            paddingLeft: 10,
            paddingRight: 10,
          }}
          htmlFor="datePicker">
          {/* Date limite */}
          {t('GroupOrderPage.Date limite')}
        </label>
        <input
          type="date"
          value={dateTime.toISOString().split('T')[0]}
          onChange={handleDateChange}
          style={{
            height: 46,
            border: '1px solid #6C727F',
            borderRadius: 8,
            paddingLeft: 20,
            paddingRight: 10,
            width: '100%',
            boxSizing: 'border-box',
          }}
        />
      </div>

      <div style={{position: 'relative', flex: '1 1'}}>
        <label
          style={{
            position: 'absolute',
            background: 'white',
            top: -10,
            left: 10,
            paddingLeft: 10,
            paddingRight: 10,
          }}
          htmlFor="datePicker">
          {/* Heure limite */}
          {t('GroupOrderPage.Heure limite')}
        </label>

        <input
          type="time"
          value={dateTime.toISOString().split('T')[1].substring(0, 5)} // Extract time in HH:mm format
          onChange={handleTimeChange}
          style={{
            height: 46,
            border: '1px solid #6C727F',
            borderRadius: 8,
            paddingLeft: 20,
            paddingRight: 10,
            width: '100%',
            boxSizing: 'border-box',
          }}
        />
      </div>
    </div>
  );
};

export default DateTimePicker;
