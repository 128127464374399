import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
const DownArrowSVG = (props) => {
  const {style, fill = '#1D1F21', ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 19"
      fill="none"
      style={style}
      {...restProps}>
      <Path
        fill={fill}
        fillRule="evenodd"
        d="M2.778 6.653a.844.844 0 0 1 1.194 0L9 11.682l5.028-5.029a.844.844 0 1 1 1.194 1.194l-5.625 5.625a.844.844 0 0 1-1.194 0L2.778 7.847a.844.844 0 0 1 0-1.194Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
export default DownArrowSVG;
