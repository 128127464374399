import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
const HomeSVG = (props) => {
  const {style, stroke, ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 25 26"
      style={style}
      {...restProps}>
      <Path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20.833 20.292v-8.855a1.042 1.042 0 0 0-.416-.833l-7.292-5.469a1.042 1.042 0 0 0-1.25 0l-7.292 5.47a1.04 1.04 0 0 0-.416.832v8.855a1.042 1.042 0 0 0 1.041 1.041h4.167a1.041 1.041 0 0 0 1.042-1.041v-3.125a1.042 1.042 0 0 1 1.041-1.042h2.084a1.042 1.042 0 0 1 1.041 1.042v3.125a1.041 1.041 0 0 0 1.042 1.041h4.167a1.042 1.042 0 0 0 1.041-1.041Z"
      />
    </Svg>
  );
};
export default HomeSVG;
