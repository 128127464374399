import React, {useEffect, useMemo, useState} from 'react';
import Colors from '../../theme/colors';
import styles from './MontantChoixStyles';
import ButtonA from '../../components/buttonA/ButtonA';
import {db} from '../../services/firebase.js';

import TitleA from '../../components/titleA/TitleA';
import {
  SafeAreaView,
  StatusBar,
  Text,
  View,
  Platform,
  TextInput,
} from 'react-native';
import GroupMontantSVG from '../../components/svgs/GroupMontantSVG';
import {AuthContext} from '../../contexts/context.js';
import InfoModal from '../../components/modals/InfoModal.js';
const MontantChoix = ({navigation}) => {
  const {user} = React.useContext(AuthContext);
  const FAIL_ICON =
    Platform.OS === 'ios'
      ? 'ios-close-circle-outline'
      : 'md-close-circle-outline';
  const [montant, setMontant] = React.useState('');
  const [listProduct, setListProduct] = React.useState([]);
  const [groupeTableData, setGroupeTableData] = React.useState();
  const [payedAlready, setPayedAlready] = React.useState(0);
  const [infoModal, setInfoModal] = useState({
    infoModalTitle: 'Amount',
    infoModalMessage: 'Your Amount Must be less or equal to TotalPrice',
    infoModalIcon: FAIL_ICON,
    infoModalVisible: false,
  });
  const handelChangeMontant = (text) => {
    const numericValue = text.replace(/[^0-9]/g, '');
    setMontant(numericValue);
    if (numericValue > totalPrice) {
      setInfoModal({
        infoModalTitle: 'Amount',
        infoModalMessage: 'Your Amount Must be less or equal to TotalPrice',
        infoModalVisible: true,
        infoModalIcon: FAIL_ICON,
      });
    } else {
      setInfoModal({
        infoModalVisible: false,
      });
    }
  };
  const totalPrice = useMemo(() => {
    let total = 0.0;
    listProduct.forEach((product) => {
      let {price, isFree} = product;
      const {quantity, iscomposite, composition} = product;
      var compositionprice = iscomposite
        ? composition.reduce(function (a, b) {
            let price = b.quantity > 0 ? b.quantity * b.price : b.price;
            return a + price;
          }, 0)
        : 0;
      total += ((isFree ? 0 : price) + compositionprice) * quantity;
    });
    return total - payedAlready;
  }, [listProduct, payedAlready]);

  const checkUserTableGroupeData = async () => {
    return db
      .collection('users')
      .doc(user.uid)
      .get()
      .then(async (data) => {
        const userData = data.data();
        return userData.activeGroupedTable;
      });
  };
  const aggregatedProducts = (data) =>
    data.reduce((acc, obj) => {
      obj.products.forEach((product) => {
        const foundIndex = acc.findIndex((item) => item.id === product.id);
        if (foundIndex !== -1) {
          acc[foundIndex].quantity += product.quantity;
        } else {
          acc.push({...product});
        }
      });
      return acc;
    }, []);

  const queryFn = async () => {
    const tableGroupeData = await checkUserTableGroupeData();
    if (tableGroupeData) {
      db.collection('groupedorders')
        .doc(tableGroupeData)
        .onSnapshot(async (groupedordersDoc) => {
          const groupedordersData = groupedordersDoc.data();
          setGroupeTableData({...groupedordersData, id: groupedordersDoc.id});
          setListProduct(aggregatedProducts(groupedordersData?.users));
          const payedAmount = groupedordersData?.users?.reduce(
            (acc, curr) => (curr.totalPayedPrice || 0) + acc,
            0,
          );
          console.log('currrentUserData', payedAmount);
          setPayedAlready(payedAmount);
        });
      // unsubscribeListener.current()
    }
  };
  useEffect(() => {
    queryFn();
  }, []);

  const handelConfirm = async () => {
    const usersData = groupeTableData.users.map((item) => {
      if (item.userId === user.uid) {
        return {
          ...item,
          totalPayedPrice: Number(montant),
        };
      }
      return item;
    });

    const isReady = Boolean(totalPrice - Number(montant) === 0);
    await db
      .collection('groupedorders')
      .doc(groupeTableData?.id)
      .set(
        {users: JSON.parse(JSON.stringify(usersData)), isReady},
        {merge: true},
      );
    navigation.navigate('Bill');
  };
  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
      {/* <ScreenTitleA
        style={{height: 60}}
        title={'Payer le montant de votre choix'}
      /> */}
      <View style={styles.container}>
        <View style={styles.containerText}>
          <TitleA> Total à partager </TitleA>
          <TitleA>{totalPrice} €</TitleA>
        </View>
      </View>
      <View style={{paddingHorizontal: 24}}>
        <View style={[styles.separator, {marginVertical: 16}]} />
      </View>
      <View style={{paddingHorizontal: 24, paddingTop: 16}}>
        <Text style={{paddingBottom: 16}}>
          {' '}
          Entrer le montant de votre choix
        </Text>
        <View style={styles.inputContainer}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <TextInput
              placeholderTextColor="#CAD2CC"
              onChangeText={handelChangeMontant}
              underlineColorAndroid="transparent"
              borderBottomColor="transparent"
              keyboardType="numeric"
              caretHidden={true}
              style={styles.textInput}
              value={montant}
              placeholder="•••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••"
            />
            <GroupMontantSVG />
          </View>
        </View>
      </View>
      <View style={{paddingVertical: 16, paddingHorizontal: 24}}>
        <View style={styles.containerScreenPayment}>
          <View style={styles.containerPayement}>
            <TitleA> Votre paiement </TitleA>
            <TitleA style={[{color: 'var(--Dark-Green, #2FBF86)'}]}>
              {montant} €
            </TitleA>
          </View>
        </View>
        <View style={{paddingVertical: 10}}>
          <ButtonA
            style={{
              backgroundColor: Colors.darkGreen,
              height: 65,
            }}
            disabled={Number(montant) > totalPrice}
            onPress={() => handelConfirm()}>
            {' '}
            Confirmer
          </ButtonA>
        </View>
        {Number(montant) > totalPrice && (
          <InfoModal
            title={infoModal.infoModalTitle}
            message={infoModal.infoModalMessage}
            iconName={infoModal.infoModalIcon}
            onButtonPress={() =>
              setInfoModal((prev) => ({...prev, infoModalVisible: false}))
            }
            onRequestClose={() =>
              setInfoModal((prev) => ({...prev, infoModalVisible: false}))
            }
            buttonTitle={'Ok'}
            visible={infoModal.infoModalVisible}
          />
        )}
      </View>
    </SafeAreaView>
  );
};
export default MontantChoix;
