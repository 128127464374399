import React from "react"
import Svg, {Path} from 'react-native-svg';

const ShareIconSVG = () => {
return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
  <Path d="M10.0383 28.2851V34.0723L16.0064 32.6426L20.5 37L24.9936 32.6426L30.9617 34.0723V28.2851L37 26.7872L33.9809 21L37 15.2128L30.9617 13.7149V7.92766L24.9936 9.35745L20.5 5L16.0064 9.35745L10.0383 7.92766V13.7149L4 15.2128L7.01915 21L4 26.7872L10.0383 28.2851ZM9.68723 19.7064L8.35319 17.0511L10.7404 16.5064C11.4426 16.3702 11.9926 16.0411 12.3904 15.5191C12.7883 14.9972 12.9872 14.3957 12.9872 13.7149V11.6723L15.234 12.2851H16.0064C16.8021 12.2851 17.5043 11.9901 18.1128 11.4L20.5 9.08511L22.8872 11.4C23.4957 11.9901 24.1979 12.2851 24.9936 12.2851C25.2745 12.2851 25.5319 12.2397 25.766 12.1489L28.0128 11.6723V13.7149C28.0128 14.3957 28.2117 14.9972 28.6096 15.5191C29.0074 16.0411 29.5574 16.3702 30.2596 16.5064L32.6468 17.0511L31.3128 19.7064C30.8447 20.5688 30.8447 21.4312 31.3128 22.2936L32.6468 24.9489L30.2596 25.4936C29.5574 25.6298 29.0074 25.9589 28.6096 26.4809C28.2117 27.0028 28.0128 27.6043 28.0128 28.2851V30.3277L25.766 29.7149H24.9936C24.1979 29.7149 23.4957 30.0099 22.8872 30.6L20.5 32.9149L18.1128 30.6C17.5043 30.0099 16.8021 29.7149 16.0064 29.7149C15.7255 29.7149 15.4681 29.7603 15.234 29.8511L12.9872 30.3277V28.2851C12.9872 27.6043 12.7883 27.0028 12.3904 26.4809C11.9926 25.9589 11.4426 25.6298 10.7404 25.4936L8.35319 24.9489L9.68723 22.2936C10.1553 21.4312 10.1553 20.5688 9.68723 19.7064ZM19.0255 25.9702L26.0468 19.0936C26.7021 18.4582 26.7021 17.7773 26.0468 17.0511C25.3447 16.461 24.6426 16.461 23.9404 17.0511L19.0255 21.8851L17.0596 19.9787C16.3574 19.3887 15.6553 19.3887 14.9532 19.9787C14.3447 20.6596 14.3447 21.3404 14.9532 22.0213L19.0255 25.9702Z" fill="#2FBF86"/>
</Svg>
)
}
export default ShareIconSVG