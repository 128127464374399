import React from 'react';
import Svg, {Path} from 'react-native-svg';

const VectorSvg = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="63"
      height="3"
      viewBox="0 0 63 3"
      fill="none">
      <Path
        d="M61.2676 1.39429H56.7096H5.82554H1.26758"
        stroke="#604640"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
export default VectorSvg;
