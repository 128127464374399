import {
  SafeAreaView,
  StatusBar,
  Text,
  View,
  ScrollView,
  TextInput,
  Platform,
  ActivityIndicator,
} from 'react-native';
import React, {useMemo, useState} from 'react';
import TitleA from '../../components/titleA/TitleA';
import ButtonBackground from '../../components/buttonAddition/buttonBackground';
import IconShareSVG from '../../components/svgs/IconShareSVG';
import MaterialSymbolSVG from '../../components/svgs/MaterialSymbolSVG';
import ButtonA from '../../components/buttonA/ButtonA';
import Colors from '../../theme/colors';
import LogoResto from '../../../assets/images/logoresto.png';
import styles from './restaurantBillStyle';
import CtaSVG from '../../components/svgs/IconCtaSVG';
import GroupSVG from '../../components/svgs/GroupSVG';
import Majesticons from '../../components/svgs/MajesticonsSVG';
import ShareIconSVG from '../../components/svgs/ShareIconSVG';

import RadioIcon from '../../components/svgs/RatioAdditionSvg';
import ButtonAddition from '../../components/buttonAddition/buttonAddition';
import {AuthContext} from '../../contexts/context';
import ModalA from '../../components/modalA/ModalA';
import {RadioButton} from 'react-native-paper';
import BodyA from '../../components/bodyA/BodyA';
import SubheadingA from '../../components/subheadingA/SubheadingA';
import {db, FUNCTIONS} from '../../services/firebase';
import {loadStripe} from '@stripe/stripe-js';
import {httpsCallable} from 'firebase/functions';
import getEnvVars from '../../config';
import InfoModal from '../../components/modals/InfoModal';
import AppLoading from 'expo-app-loading';
const {paiement} = getEnvVars();
import {useTranslation} from 'react-i18next';

const stripePromise = loadStripe(paiement.publishableKey);
const FAIL_ICON =
  Platform.OS === 'ios'
    ? 'ios-close-circle-outline'
    : 'md-close-circle-outline';

const PriceSection = ({handleModalDismiss, setChecked, checked}) => {
  const {t} = useTranslation();
  const [checkedValue, setCheckedValue] = useState(checked);
  return (
    <View>
      <TitleA style={{marginBottom: 10, paddingHorizontal: 10}}>
        {/* Choose how to pay grouped table */}
        {t('RestaurantBill.Choisissez comment payer la table groupée')}
      </TitleA>
      <SubheadingA style={{marginBottom: 10, paddingHorizontal: 10}}>
        {/* You can pay the whole order or let other people pay for the items they
        choose */}
        {t(
          'RestaurantBill.Vous pouvez payer la totalité de la commande ou laisser d autres personnes payer les articles de leur choix',
        )}
      </SubheadingA>
      <View style={styles.checkBox}>
        <RadioButton
          value="chooseArticle"
          color={Colors.darkGreen}
          status={checkedValue === 'chooseArticle' ? 'checked' : 'unchecked'}
          onPress={() => setCheckedValue('chooseArticle')}
        />
        <BodyA
          onPress={() => setCheckedValue('chooseArticle')}
          style={{color: Colors.normalGray}}>
          {/* Choose the item to pay */}
          {t('RestaurantBill.Choisissez l article à payer')}
        </BodyA>
      </View>
      <View style={styles.checkBox}>
        <RadioButton
          value="shared"
          color={Colors.darkGreen}
          status={checkedValue === 'shared' ? 'checked' : 'unchecked'}
          onPress={() => setCheckedValue('shared')}
        />
        <BodyA
          onPress={() => setCheckedValue('shared')}
          style={{color: Colors.normalGray}}>
          Deviser en parts égales
        </BodyA>
      </View>
      <View style={styles.checkBox}>
        <RadioButton
          value="amount"
          color={Colors.darkGreen}
          status={checkedValue === 'amount' ? 'checked' : 'unchecked'}
          onPress={() => setCheckedValue('amount')}
        />
        <BodyA
          onPress={() => setCheckedValue('amount')}
          style={{color: Colors.normalGray}}>
          Payer le montant de votre choix
        </BodyA>
      </View>
      <ButtonA
        onPress={() => {
          setChecked(checkedValue);
          handleModalDismiss(checkedValue);
        }}
        style={{marginVertical: 20}}>
        Ok
      </ButtonA>
      {/* <ButtonA
        // style={styles.cancelButton}
        // color={Colors.darkGreen}
        onPress={handleModalDismiss}>
        Annuler
      </ButtonA> */}
    </View>
  );
};

const Addition = ({navigation}) => {
  //state
  const [selectedRatio, setSelectedRatio] = React.useState(1);
  const [checkedCard, setCheckedCard] = React.useState(true);
  const [addITProduct, setAddITProduct] = React.useState([]);
  const [groupeTableData, setGroupeTableData] = React.useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [checked, setChecked] = useState('chooseArticle');
  const [store, setStore] = useState();
  const [listProduct, setListProduct] = React.useState([]);
  const [clientName, setClientName] = useState('');
  const [error, setError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [PourboireInput, setPourboireInput] = React.useState('');
  const [MontantInput, setMontantInput] = React.useState('');
  const [TotalMontantChoix, setTotalMontantChoix] = React.useState();
  const [formDataCard, setFormDataCard] = React.useState({
    prenom: '',
    nom: '',
    carteBancaire: '',
  });
  const [payedAlready, setPayedAlready] = React.useState(0);
  const [currentUserData, setCurrentUserData] = React.useState();

  const [infoModal, setInfoModal] = useState({
    infoModalTitle: '',
    infoModalMessage: '',
    infoModalIcon: FAIL_ICON,
    infoModalVisible: false,
  });
  const [showBorder, setShowBorder] = React.useState(false);

  //context
  const {user} = React.useContext(AuthContext);

  const handelCheckedCard = () => {
    setCheckedCard(!checkedCard);
  };
  const {t} = useTranslation();
  const handleToggle = (id) => {
    setSelectedRatio(id);
    if (id > 1) {
      if (id === 2 || id === 3) {
        const rt = ratios.find((rt) => rt.id === id);
        // const currrentUserData = groupedordersData?.users?.find(
        //   (usr) => usr.userId === user.uid,
        // );
        let usersData = groupeTableData?.users;
        const indexUser = usersData?.findIndex((e) => e.userId == user.uid);
        if (usersData && indexUser !== -1) {
          usersData[indexUser].tips = rt?.amount;
        }
        db.collection('groupedorders')
          .doc(groupeTableData?.id)
          .set(
            {users: JSON.parse(JSON.stringify(usersData ?? []))},
            {merge: true},
          )
          .catch((err) => console.log('Error', err));
      }
    }
  };

  const handleMontantChange = (text) => {
    if (/^\d+$/.test(text) || text === '') {
      setMontantInput(text);
    }
  };

  const handleNumberChange = (text) => {
    if (/^\d+$/.test(text) || text === '') {
      setPourboireInput(text);
      let usersData = groupeTableData?.users;
      const indexUser = usersData?.findIndex((e) => e.userId == user.uid);
      usersData[indexUser].tips = text;

      db.collection('groupedorders')
        .doc(groupeTableData.id)
        .set({users: JSON.parse(JSON.stringify(usersData))}, {merge: true})
        .catch((err) => console.log('Error', err));
    }
  };

  const handleChange = (field, value) => {
    setFormDataCard((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handleButtonClick = () => {
    setShowBorder(true);
  };

  const handleModalClose = () => {
    setShowBorder(false);
  };

  const ratios = [
    {id: 1, label: 'Non'},
    {id: 2, label: '5.00 €', amount: 5},
    {id: 3, label: '10.00 €', amount: 10},
    {
      id: 4,
      label: /*'Leave the amount of your choice'*/ t(
        'RestaurantBill.Laissez le montant de votre choix',
      ),
    },
  ];
  const ratiosModal = [
    {id: 1, label: 'Payer pour tout le monde'},
    {id: 2, label: 'Deviser en parts égales'},
    {id: 3, label: 'Payer le montant de votre choix'},
  ];

  const handlePayementType = async (checkedValue) => {
    if (checkedValue) {
      // Push it to Firestore
      await db.collection('groupedorders').doc(groupeTableData?.id).set(
        {
          payementType: checkedValue,
        },
        {merge: true},
      );
    }
    setModalVisible(false);
  };
  const handleModalDismiss = async () => {
    setModalVisible(false);
  };

  const totalPrice = useMemo(() => {
    let total = 0.0;
    listProduct?.forEach((product) => {
      let {price, isFree} = product;
      const {quantity, iscomposite, composition} = product;
      var compositionprice = iscomposite
        ? composition.reduce(function (a, b) {
            let price = b.quantity > 0 ? b.quantity * b.price : b.price;
            return a + price;
          }, 0)
        : 0;
      total += ((isFree ? 0 : price) + compositionprice) * quantity;
    });
    return total - payedAlready;
  }, [listProduct, payedAlready]);

  const totalPayedPrice = React.useMemo(() => {
    let total = 0.0;
    if (groupeTableData?.payementType === 'shared') {
      return currentUserData?.totalPayedPrice || 0;
    }
    if (groupeTableData?.payementType === 'amount') {
      return currentUserData?.totalPayedPrice || 0;
    }

    addITProduct?.forEach((product) => {
      let {price, quantity} = product;
      total += price * quantity;
    });
    return total;
  }, [addITProduct, currentUserData]);

  const checkUserTableGroupeData = async () => {
    return db
      .collection('users')
      .doc(user.uid)
      .get()
      .then(async (data) => {
        const userData = data.data();
        setClientName(userData.displayName);
        return userData.activeGroupedTable;
      });
  };

  const aggregatedProducts = (data) =>
    data.reduce((acc, obj) => {
      obj.products.forEach((product) => {
        const foundIndex = acc.findIndex((item) => item.id === product.id);
        if (foundIndex !== -1) {
          acc[foundIndex].quantity += product.quantity;
        } else {
          acc.push({...product});
        }
      });
      return acc;
    }, []);

  const queryFn = async () => {
    const tableGroupeData = await checkUserTableGroupeData();
    if (tableGroupeData) {
      db.collection('groupedorders')
        .doc(tableGroupeData)
        .onSnapshot(async (groupedordersDoc) => {
          const groupedordersData = groupedordersDoc.data();
          await db
            .collection('organisers')
            .doc(groupedordersData?.storeid)
            .get()
            .then(async (docGO) => {
              const storesData = docGO.data();
              if (storesData?.type !== 'Restaurant') return;

              setStore(storesData);
            });
          setGroupeTableData({...groupedordersData, id: groupedordersDoc.id});
          if (!groupedordersData.payementType) {
            setModalVisible(true);
          }
          const currrentUserData = groupedordersData?.users?.find(
            (usr) => usr.userId === user.uid,
          );
          const payedAmount = groupedordersData?.users
            ?.filter((usr) => usr.userId !== user.uid)
            .reduce((acc, curr) => (curr.totalPayedPrice || 0) + acc, 0);
          setPayedAlready(payedAmount);

          setListProduct(aggregatedProducts(groupedordersData.users));

          if (currrentUserData?.addITProduct)
            setAddITProduct(currrentUserData?.addITProduct);
          setCurrentUserData(currrentUserData);
        });
      // unsubscribeListener.current()
    }
  };

  React.useEffect(() => {
    queryFn();
  }, []);

  const getTableGroupeData = async (id) => {
    return db
      .collection('groupedorders')
      .doc(id)
      .get()
      .then(async (data) => {
        const groupedordersData = data.data();
        return groupedordersData;
      });
  };
  // `${t('EventBPage.Vous avez été invité à partager une commande avec')}`;
  const paymentText = useMemo(
    () =>
      groupeTableData?.isReady
        ? `${t('RestaurantBill.Payer')} ${totalPayedPrice} €`
        : totalPayedPrice > 0
        ? `${t('RestaurantBill.Payer')} ${totalPayedPrice} €`
        : groupeTableData?.payementType
        ? /*'Choose Articles'*/ t('RestaurantBill.Choisissez des articles')
        : /*'Choose payment mode'*/ t(
            'RestaurantBill.Choisissez le mode de paiement',
          ),
    [totalPayedPrice, groupeTableData],
  );

  const createcheckoutsession = async (prices, tier, newid, tips, allPayed) => {
    const dbuser = await db.collection('users').doc(user.uid).get();
    const docuser = dbuser.data();

    let options = {
      prices: prices,
      tier: tier,
      newid: newid,
      customer: docuser.customerId,
      clientid: user.uid,
      tips: Number(tips),
      allPayed: allPayed,
      gtid: groupeTableData.id,
      accountid: groupeTableData.storeid,
      mode: 'payment',
    };
    options.successurl = `Command?id=${newid}`;

    const callCreateCheckoutSession = httpsCallable(
      FUNCTIONS,
      'createCheckoutSession',
    );
    const result = await callCreateCheckoutSession(options);
    const callDeleteUSerFromGP = httpsCallable(
      FUNCTIONS,
      'removeGroupOrderFromUser',
    );
    await callDeleteUSerFromGP({userid: user.uid});
    return result;
  };

  const handlePaymentFood = async () => {
    try {
      let usersData = groupeTableData?.users;
      const currentUserData = usersData?.find((e) => e.userId == user.uid);
      const count = usersData.reduce((count, obj) => {
        return obj['payed'] === true ? count + 1 : count;
      }, 0);

      const allPayed =
        groupeTableData?.payementType == 'amount'
          ? totalPrice - currentUserData.totalPayedPrice === 0
          : count + 1 === usersData.length;

      setShowLoading(true);
      const stripe = await stripePromise;
      let tier = 'food';
      let newid = groupeTableData.newid;
      let tips = currentUserData?.tips || 0;
      // let payementType = groupeTableData?.payementType;
      var reducedPrices =
        groupeTableData?.payementType == 'shared' ||
        groupeTableData?.payementType == 'amount'
          ? [
              {
                quantity: 1,
                price_data: {
                  currency: 'eur',
                  product_data: {
                    name: currentUserData.products[0].name,
                    images: [currentUserData.products[0].photo],
                  },
                  unit_amount: (currentUserData.totalPayedPrice * 100).toFixed(
                    0,
                  ),
                },
              },
            ]
          : currentUserData.addITProduct.reduce(function (filtered, product) {
              var compositionprice = product.iscomposite
                ? product.composition.reduce(function (a, b) {
                    return a + b.price;
                  }, 0)
                : 0;

              if (!product.isFree) {
                // let finalPrice = product.price;
                let finalPrice = product.MontantChoixAmout
                  ? product.MontantChoixAmout
                  : product.price;
                filtered.push({
                  // quantity: product.quantity,
                  quantity: product.MontantChoixAmout ? 1 : product.quantity,
                  price_data: {
                    currency: 'eur',
                    product_data: {
                      name: product.name,
                      images: product.photo ? [product.photo] : [],
                    },
                    unit_amount: (
                      (finalPrice + compositionprice) *
                      100
                    ).toFixed(0),
                  },
                });
              }

              return filtered;
            }, []);

      const checkoutsession = await createcheckoutsession(
        reducedPrices,
        tier,
        newid,
        tips,
        allPayed,
      );

      const result = await stripe.redirectToCheckout({
        sessionId: checkoutsession.data.session.id,
      });

      if (result.data) {
        db.collection('groupedorders')
          .doc(groupeTableData.id)
          .set({payed: true}, {merge: true})
          .catch((err) => console.log('Error', err));
      }
      setShowLoading(false);
    } catch (err) {
      setShowLoading(false);
    }
  };

  const onHandlePayment = async () => {
    if (!groupeTableData?.isReady && totalPayedPrice === 0) {
      if (groupeTableData?.payementType) {
        let distination;
        if (groupeTableData?.payementType === 'chooseArticle') {
          distination = 'chooseArticle';
        }
        if (groupeTableData?.payementType === 'shared') {
          distination = 'shared';
        }
        if (groupeTableData?.payementType === 'amount') {
          distination = 'amount';
        }
        navigation.navigate(distination);
      } else {
        setModalVisible(true);
      }
    } else if (groupeTableData.newid) {
      if (selectedRatio === 4 && !PourboireInput) {
        setError(true);
        setInfoModal({
          infoModalTitle: /*'Add Tips'*/ t(
            'RestaurantBill.Ajouter des conseils',
          ),
          infoModalMessage: /*'Add tips input'*/ t(
            'RestaurantBill.Ajouter des entrées de conseils',
          ),
          infoModalIcon: FAIL_ICON,
          infoModalVisible: true,
        });
        return;
      }

      // add loading indicator
      handlePaymentFood();
    }
  };

  if (showLoading) {
    return (
      <SafeAreaView style={styles.screenContainer}>
        <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <ActivityIndicator size="large" color="green" />
        </View>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        // contentContainerStyle={{paddingBottom: 40}}
        // style={styles.container}
      >
        <View>
          <img
            src={store?.coverImage}
            style={{position: 'relative', width: '100%', height: 170}}
          />
          <img
            src={LogoResto}
            style={{
              position: 'absolute',
              transform: 'translate(-50%, 50%)',
              left: '50%',
              bottom: 0,
            }}
          />
        </View>
        <View style={{marginTop: 16}}>
          <Text style={{textAlign: 'center', paddingTop: 60}}> Table 1 </Text>
        </View>
        <View style={styles.content}>
          <View>
            <View style={{paddingVertical: 16}}>
              <ButtonAddition
                style={{
                  backgroundColor: Colors.blue,
                }}
                onPress={() => navigation.navigate('Cart')}>
                <View
                  style={{flexDirection: 'row', alignItems: 'center', gap: 10}}>
                  <MaterialSymbolSVG />{' '}
                  <Text
                    style={[
                      styles.titleText,
                      {color: Colors.background},
                      ,
                      {textTransform: 'capitalize'},
                    ]}>
                    {' '}
                    {/* See the map */}
                    {t('RestaurantBill.Voir la carte')}
                  </Text>
                </View>
              </ButtonAddition>
            </View>
            <View style={{paddingVertical: 10}}>
              <ButtonBackground>
                <View style={styles.btnSimple}>
                  <Text style={[styles.titleText, {color: Colors.darkGray}]}>
                    {/* Your bill */}
                    {t('RestaurantBill.Votre facture')}
                  </Text>
                  <Text style={[styles.titleText, {color: Colors.normalGray}]}>
                    {' '}
                    {totalPayedPrice} €
                  </Text>
                </View>
              </ButtonBackground>
            </View>
          </View>
          <View style={{marginTop: 16}}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <Text>
                {/* Share the bill with other guests */}
                {t('RestaurantBill.Partagez la facture avec d autres clients')}
              </Text>
              <IconShareSVG />
            </View>
          </View>

          <View style={styles.separator} />
          <View style={{paddingVertical: 16}}>
            {addITProduct?.map((product, index) => (
              <View key={index} style={styles.additionContent}>
                <View style={styles.textAddition}>
                  {' '}
                  {product.quantity} <Text>{product.name}</Text>{' '}
                </View>
                <View style={{color: Colors.darkGreen}}>
                  {' '}
                  {product.price} €
                </View>
              </View>
            ))}
            <ButtonBackground style={{}}>
              <View style={styles.btnBackground}>
                <View>
                  <Text style={[styles.titleText, {color: Colors.darkGray}]}>
                    Total
                  </Text>{' '}
                </View>
                <View>
                  {' '}
                  <Text style={[styles.titleText, {color: Colors.darkGreen}]}>
                    {' '}
                    {totalPayedPrice} €
                  </Text>{' '}
                </View>
              </View>
            </ButtonBackground>
          </View>

          {/* <View style={styles.separator} />

          <View style={{paddingTop: 10}}>
            <View style={{paddingVertical: 16}}>
              <ButtonAddition
                style={{
                  backgroundColor: Colors.blue,
                }}>
                <View style={styles.textBtn}>
                  <AddUserSvg />{' '}
                  <Text
                    style={[
                      styles.titleText,
                      {color: Colors.background},
                      ,
                      {textTransform: 'capitalize'},
                    ]}>
                    {' '}
                    Partager l’addition
                  </Text>
                </View>
              </ButtonAddition>
            </View>
            <View style={{paddingVertical: 10}}>
              <ButtonAddition
                style={{
                  backgroundColor: Colors.darkGreen,
                }}
                onPress={handleButtonClick}>
                <View style={styles.textBtn}>
                  <CtaSVG />{' '}
                  <Text
                    style={[
                      styles.titleText,
                      {color: Colors.background},
                      ,
                      {textTransform: 'capitalize'},
                    ]}>
                    {' '}
                    Payer toute l’addition
                  </Text>
                </View>
              </ButtonAddition>
            </View>
            <Modal
              visible={showBorder}
              animationType="slide"
              transparent={true}
              presentationStyle="overFullScreen">
              <View style={styles.modalContainer}>
                <View style={styles.modalContent}>
                  <View style={styles.border}>
                    <View style={{paddingVertical: 10}}>
                      <TitleA> Choisissez comment payer</TitleA>
                      <Text
                        style={{
                          paddingTop: 15,
                          paddingBottom: 11,
                          color: '#000',
                        }}>
                        Vous pouvez payer l’ensemble de commande ou laisser les
                        autres personnes régler les articles qu’elles
                        choisissent{' '}
                      </Text>
                      <View style={{marginTop: 10}}>
                        {ratiosModal.map((ratio) => (
                          <View key={ratio.id} style={styles.RatioAddition}>
                            <RadioIcon
                              checked={selectedRatio === ratio.id}
                              onClick={() => handleToggle(ratio.id)}
                            />
                            <Text
                              style={{
                                opacity: selectedRatio === ratio.id ? 1 : 0.5,
                              }}>
                              {ratio.label}
                            </Text>
                          </View>
                        ))}
                      </View>
                      <View
                        style={[styles.frameTextInput, {marginVertical: 17}]}>
                        <Text style={styles.borderTextInputRatio}>
                          Montant à payer
                        </Text>

                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}>
                          <TextInput
                            placeholderTextColor="#77838F"
                            underlineColorAndroid="transparent"
                            borderBottomColor="transparent"
                            maxLength={20}
                            onChangeText={handleMontantChange}
                            style={styles.textInput}
                            value={MontantInput}
                            placeholder="•••••••••••••••••••••••••••••••••••••••"
                          />
                          <GroupSVG />
                        </View>
                      </View>
                      <View style={{paddingVertical: 10}}>
                        <ButtonA>ok</ButtonA>
                      </View>
                      <ButtonBackground
                        style={{textAlign: 'center'}}
                        onPress={handleModalClose}>
                        <Text
                          style={[
                            styles.titleText,
                            {color: Colors.darkGreen},
                            {textTransform: 'capitalize'},
                          ]}>
                          Annuler
                        </Text>
                      </ButtonBackground>
                    </View>
                  </View>
                </View>
              </View>
            </Modal>
          </View>

          <View style={styles.separator} /> */}

          <View style={{paddingVertical: 10}}>
            <Text style={{paddingVertical: 10}}>
              {' '}
              {/* Leave a tip for the waiter ? */}
              {t('RestaurantBill.Laisser un pourboire au serveur ?')}
            </Text>
            {/* <View style = {{marginTop : 10}}>
   <View style = {styles.RatioAddition}> <RatioSvG/> <Text> Non</Text></View>
   <View style = {styles.RatioAddition}> <RatioSvG/> <Text> 5.00 €</Text></View>
   <View style = {styles.RatioAddition}> <RatioSvG/> <Text> 10.00 €</Text></View>
   <View style = {styles.RatioAddition}> <RatioCheckedSVG/> <Text>Laisser le montant de votre choix</Text></View>
</View>  */}
            <View style={{marginTop: 10}}>
              {ratios.map((ratio) => (
                <View key={ratio.id} style={styles.RatioAddition}>
                  <RadioIcon
                    checked={selectedRatio === ratio.id}
                    onClick={() => handleToggle(ratio.id)}
                  />
                  <Text style={{opacity: selectedRatio === ratio.id ? 0.5 : 1}}>
                    {ratio.label}
                  </Text>
                </View>
              ))}
            </View>

            {selectedRatio === 4 && (
              <View style={[styles.frameTextInput, {marginTop: 17}]}>
                <Text style={styles.borderText}>Tip</Text>

                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <TextInput
                    placeholderTextColor="#77838F"
                    underlineColorAndroid="transparent"
                    borderBottomColor="transparent"
                    maxLength={20}
                    style={[
                      styles.textInput,
                      {
                        width: '80%',
                        borderColor: error
                          ? Colors.red + ' !important'
                          : '#D2D5DA' + ' !important',
                      },
                    ]}
                    value={PourboireInput}
                    onChangeText={handleNumberChange}
                    keyboardType="numeric"
                    placeholder="•••••••••••••••••••••••••••••••••••••••"
                  />
                  <GroupSVG />
                </View>
              </View>
            )}
          </View>

          <View style={styles.separator} />

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Text>
              {/* Do you have a promo code? */}
              {t('RestaurantBill.Avez-vous un code de promotion?')}
            </Text>
            <ShareIconSVG />
          </View>

          <View style={styles.separator} />

          <View style={{paddingVertical: 10}}>
            <TitleA>
              {/* Select your payment method */}
              {t('RestaurantBill.Sélectionnez votre méthode de paiement')}
            </TitleA>
          </View>
          <View style={{marginTop: 20}}>
            <View style={styles.rowPaypel}>
              <Majesticons />
              <Text>
                {/* All transactions are encrypted and secure */}
                {t(
                  'RestaurantBill.Toutes les transactions sont cryptées et sécurisées',
                )}
              </Text>
            </View>
          </View>
          {/* //payermen paypael design */}
          {/* <View style={styles.containerPaypal}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              {' '}
              <Text> Paiment Paypel</Text>
              <View style={styles.paypalContainer}>
                <View>
                  <VectorPaypalSVG
                    style={{position: 'absolute', top: -3, left: -3}}
                  />
                  <VectorPaypalSVG />
                </View>
              </View>
            </View>
            <View style={styles.rowPaypel}>
              {' '}
              <CardOutlineSVG /> <Text>Payer avec mon compte PayPal</Text>{' '}
            </View>
            <View style={{paddingHorizontal: 10, paddingTop: 10}}>
              <ButtonAddition
                style={{
                  backgroundColor: Colors.darkGreen,
                }}>
                <View style={styles.textBtn}>
                  <CtaSVG />{' '}
                  <Text
                    style={[
                      styles.titleText,
                      {color: Colors.background},
                      ,
                      {textTransform: 'capitalize'},
                    ]}>
                    {' '}
                    Paiment PayPal (84.00 €)
                  </Text>
                </View>
              </ButtonAddition>
            </View>
          </View> */}
          <View style={{paddingHorizontal: 10, paddingVertical: 10}}>
            <ButtonAddition
              onPress={onHandlePayment}
              style={{
                backgroundColor: Colors.darkGreen,
              }}>
              <View style={styles.textBtn}>
                <CtaSVG />{' '}
                <Text
                  style={[
                    styles.titleText,
                    {color: Colors.background},
                    ,
                    {textTransform: 'capitalize'},
                  ]}>
                  {' '}
                  {paymentText}
                </Text>
              </View>
            </ButtonAddition>
          </View>
          {!groupeTableData?.isReady && Boolean(totalPayedPrice > 0) && (
            <View style={{paddingHorizontal: 10, paddingVertical: 10}}>
              <ButtonAddition
                // onPress={() => navigation.navigate('ArticlePayer')}
                onPress={() =>
                  navigation.navigate(groupeTableData.payementType)
                }
                style={{
                  backgroundColor: Colors.darkGreen,
                }}>
                <View style={styles.textBtn}>
                  <CtaSVG />{' '}
                  <Text
                    style={[
                      styles.titleText,
                      {color: Colors.background},
                      ,
                      {textTransform: 'capitalize'},
                    ]}>
                    {' '}
                    {/* Choose Articles */}
                    {t('RestaurantBill.Choisissez des articles')}
                  </Text>
                </View>
              </ButtonAddition>
            </View>
          )}
          {/* <View style={styles.containerBancaire}>
            <View style={styles.PaiementCarte}>
              <Text>Carte Bancaire</Text>
              <View style={styles.cardRowPayement}>
                <View style={styles.masterCardContainer}>
                  <MasterCardSVG />
                </View>
                <View style={styles.VisaCardContainer}>
                  {' '}
                  <Text style={styles.TextVisaCard}> Visa</Text>
                  <VectorVisaSVG />
                </View>
              </View>
            </View>
            <View style={styles.rouwPaymentInput}>
              <View style={styles.framePaymentInput}>
                <Text style={styles.borderText}>Prénom</Text>
                <TextInput
                  placeholderTextColor="#77838F"
                  underlineColorAndroid="transparent"
                  borderBottomColor="transparent"
                  onChangeText={(text) => handleChange('prenom', text)}
                  style={[styles.textInput, {width: '100%'}]}
                  placeholder="••••••••••••"
                />{' '}
              </View>
              <View style={styles.framePaymentInput}>
                <Text style={styles.borderText}>Nom</Text>
                <TextInput
                  placeholderTextColor="#77838F"
                  underlineColorAndroid="transparent"
                  borderBottomColor="transparent"
                  onChangeText={(text) => handleChange('nom', text)}
                  style={[styles.textInput, {width: '100%'}]}
                  value={formDataCard.nom}
                  placeholder="••••••••••••"
                />{' '}
              </View>
            </View>
            <View style={{marginVertical: 30}}>
              <View style={styles.frameTextInput}>
                <Text style={styles.borderText}>Numéro de la carte</Text>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <TextInput
                    placeholderTextColor="#77838F"
                    underlineColorAndroid="transparent"
                    borderBottomColor="transparent"
                    onChangeText={(text) => handleChange('carteBancaire', text)}
                    maxLength={20}
                    style={[styles.textInput, {width: '80%'}]}
                    value={formDataCard.carteBancaire}
                    placeholder="••••  ••••  ••••  ••••"
                  />
                  <View style={styles.masterCardContainerInput}>
                    <MasterCardSVG />
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.frameDateContainer}>
              <View style={styles.frameDateInput}>
                <Text style={styles.borderText}>Mois</Text>

                <View style={styles.rowDateCard}>
                  <Text style={{flex: 1}}> Select</Text>
                  <DuotoneSVG />
                </View>
              </View>
              <View style={styles.frameDateInput}>
                <Text style={styles.borderText}>Année</Text>

                <View style={styles.rowDateCard}>
                  <Text style={{flex: 1}}> Select</Text>
                  <DuotoneSVG />
                </View>
              </View>

              <View style={styles.frameDateInput}>
                <Text style={styles.borderText}>CVC</Text>
                <View style={styles.rowDateCard}>
                  <TextInput
                    placeholderTextColor="#77838F"
                    underlineColorAndroid="transparent"
                    borderBottomColor="transparent"
                    style={[styles.textInput, {width: '80%'}]}
                    value={''}
                    placeholder="•••"
                  />
                  <WarningText />
                </View>
              </View>
            </View>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <CheckedNoteSVG
                Checked={checkedCard}
                onPress={handelCheckedCard}
              />
              <Text> Enregistre Ma Carte</Text>
            </View>
            <View style={{paddingHorizontal: 10, paddingVertical: 10}}>
              <ButtonAddition
                style={{
                  backgroundColor: Colors.darkGreen,
                }}>
                <View style={styles.textBtn}>
                  <CtaSVG />{' '}
                  <Text
                    style={[
                      styles.titleText,
                      {color: Colors.background},
                      ,
                      {textTransform: 'capitalize'},
                    ]}>
                    {' '}
                    Payer (84.00 €)
                  </Text>
                </View>
              </ButtonAddition>
            </View>
          </View> */}
        </View>
        <ModalA visible={modalVisible} onDismiss={handleModalDismiss}>
          <PriceSection
            handleModalDismiss={handlePayementType}
            setChecked={setChecked}
            checked={checked}
          />
        </ModalA>
        <InfoModal
          title={infoModal.infoModalTitle.toUpperCase()}
          message={infoModal.infoModalMessage}
          iconName={infoModal.infoModalIcon}
          onButtonPress={() =>
            setInfoModal((prev) => ({...prev, infoModalVisible: false}))
          }
          onRequestClose={() =>
            setInfoModal((prev) => ({...prev, infoModalVisible: false}))
          }
          buttonTitle={'Ok'.toUpperCase()}
          visible={infoModal.infoModalVisible}
        />
      </ScrollView>
    </SafeAreaView>
  );
};
export default Addition;
