import * as React from 'react';
import Svg, {Circle, Rect} from 'react-native-svg';

const SearchIconSVG = (props) => {
  const {style, fill = '#2FBF86', stroke = '#2FBF86', ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      style={style}
      fill="none"
      {...restProps}>
      <Circle cx={6.5} cy={6.5} r={5.5} stroke={stroke} strokeWidth={2} />
      <Rect
        width={6.538}
        height={2}
        x={10.414}
        y={9}
        fill={fill}
        rx={1}
        transform="rotate(45 10.414 9)"
      />
    </Svg>
  );
};
export default SearchIconSVG;
