import React from 'react';
import Svg, {Path} from 'react-native-svg';

const Majesticons = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none">
      <Path
        d="M7.16634 8.33333H5.49967C5.05765 8.33333 4.63372 8.50893 4.32116 8.82149C4.0086 9.13405 3.83301 9.55797 3.83301 10V15.8333C3.83301 16.2754 4.0086 16.6993 4.32116 17.0118C4.63372 17.3244 5.05765 17.5 5.49967 17.5H15.4997C15.9417 17.5 16.3656 17.3244 16.6782 17.0118C16.9907 16.6993 17.1663 16.2754 17.1663 15.8333V10C17.1663 9.55797 16.9907 9.13405 16.6782 8.82149C16.3656 8.50893 15.9417 8.33333 15.4997 8.33333H13.833M7.16634 8.33333V5.83333C7.16634 4.94928 7.51753 4.10143 8.14265 3.47631C8.76777 2.85119 9.61562 2.5 10.4997 2.5C11.3837 2.5 12.2316 2.85119 12.8567 3.47631C13.4818 4.10143 13.833 4.94928 13.833 5.83333V8.33333M7.16634 8.33333H13.833M10.4997 11.6667V14.1667"
        stroke="#77838F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
export default Majesticons;
