import React, {useState, useEffect, useRef} from 'react';
import styles from './EventsStyles.js';
import Colors from '../../theme/colors';
import {
  FlatList,
  SafeAreaView,
  StatusBar,
  Text,
  Animated,
  View,
  ScrollView,
  ActivityIndicator,
} from 'react-native';
// Import redux
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  addProduct,
  setStore,
  setLastId,
  setTierMode,
} from '../../reducers/cartactions';
// Import Firebase
import firebase from 'firebase/compat/app';
import {db} from '../../services/firebase';
import {collection, query, where, getDocs} from 'firebase/firestore';
// import cities array
import {countries} from '../../config/countries';
import HeaderA from '../../components/headerA/HeaderA';
import TitleA from '../../components/titleA/TitleA';
import BodyA from '../../components/bodyA/BodyA';
import SearchBarA from '../../components/searchBarA/searchBarA';
import CalendarA from '../../components/calendarA/CalendarA';
import EventCardA from '../../components/eventCardA/EventCardA';
import ScreenTitleA from '../../components/screenTitleA/ScreenTitleA';
import TouchableItem from '../../components/TouchableItem';
// import SVG
import DownArrowSVG from '../../components/svgs/DownArrowSVG';
import FavoriteSVG from '../../components/svgs/FavoriteSVG';
import LocalSVG from '../../components/svgs/LocalSVG';
import {useContext} from 'react';
import FavoritesEventsContext from '../../contexts/favoritesEventsContext.js';
import SubheadingA from '../../components/subheadingA/SubheadingA.js';
import {getAllServicesDataEvent} from './servicesDatasEvent.js';
import {useNavigation} from '@react-navigation/native';
import {useTranslation} from 'react-i18next';

// Events
const Events = () => {
  const {t} = useTranslation();
  const [scrollY] = useState(new Animated.Value(0));
  // const handleScrollImage = Animated.event(
  //   [{nativeEvent: {contentOffset: {y: scrollY}}}],
  //   {useNativeDriver: false},
  // );

  // // Vous pouvez ajuster cette logique en fonction de votre mise en page et de la position souhaitée
  // const blurRadius = scrollY.interpolate({
  //   inputRange: [0, 20], // Changer cela en fonction de la hauteur de votre image
  //   outputRange: [1, 0],
  //   extrapolate: 'clamp',
  // });
  // const scrollY = useRef(new Animated.Value(0)).current;

  // const blurRadius = scrollY?.interpolate({
  //   inputRange: [0, 100], // ajuste selon la position de défilement où tu veux appliquer le flou
  //   outputRange: [0, 10], // ajuste la valeur du flou
  //   extrapolate: 'clamp',
  // });
  const navigation = useNavigation();
  const [isLoading, setIsLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date()); // Initialize with today's date

  const [monthData, setMonthData] = useState('');

  const favoritesContext = useContext(FavoritesEventsContext);
  const [showFavorites, setShowFavorites] = useState(false);

  const [events, setEvents] = useState([]);

  const [search, setSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const [selectCityDisplay, setSelectCityDisplay] = useState(false);

  const [searchCity, setSearchCity] = useState(false);
  const [searchCityQuery, setSearchCityQuery] = useState('');

  const [noMatchingEvents, setNoMatchingEvents] = useState(false);
  const [selectDateMonth, setSelectedDateMonth] = React.useState('');
  const [scroll, setScroll] = React.useState(false);
  const servicesDataEvent = getAllServicesDataEvent(t);

  const renderServices = ({item}) => {
    return (
      <TouchableItem
        onPress={() => {
          navigation.navigate(item.routeName);
        }}>
        <View style={styles.item}>
          <View style={styles.icon}>{item.icon}</View>
          <View style={styles.title}>
            <SubheadingA style={{color: Colors.darkGray}}>
              {item.title}
            </SubheadingA>
          </View>
        </View>
        <View
          style={
            item.title === 'Événements' || item.title === 'Events'
              ? styles.borderTitle
              : null
          }
        />
      </TouchableItem>
    );
  };

  const handleScroll = (event) => {
    const offsetY = event.nativeEvent.contentOffset.y;

    if (offsetY > 0) {
      setScroll(true);
      /*  if you want 2 logique when stop affich */

      // clearTimeout(scrollTimer);
      // scrollTimer = setTimeout(() => {
      //   console.log("Resetting scroll state");
      //   setScroll(false);
      // }, 2000);
    } else {
      setScroll(false);
    }
  };

  const queryFn = async () => {
    try {
      setIsLoading(true);

      // Calculate the start and end timestamps for the selected day
      const selectedDayStart = new Date(selectedDate);
      selectedDayStart.setHours(0, 0, 0, 0);
      const selectedDayEnd = new Date(selectedDate);
      selectedDayEnd.setHours(23, 59, 59, 999);
      const selectedDayStartTimestamp = new firebase.firestore.Timestamp(
        Math.floor(selectedDayStart.getTime() / 1000), // Corrected
        0,
      );
      const selectedDayEndTimestamp = new firebase.firestore.Timestamp(
        Math.floor(selectedDayEnd.getTime() / 1000), // Corrected
        0,
      );

      let qAllEvents = db
        .collection('events')
        .where('creationStatus', '==', 'published');

      if (!monthData) {
        qAllEvents = qAllEvents
          .where('startDate', '>=', selectedDayStartTimestamp)
          .where('startDate', '<=', selectedDayEndTimestamp);
      } else {
        qAllEvents = qAllEvents
          .where('startDate', '>=', selectedDate.firstDay)
          .where('startDate', '<=', selectedDate.lastDay);
      }

      const qSearchedEvents = qAllEvents.where(
        'title',
        '==',
        searchQuery.trim(),
      );

      const qSearchedByCityEvents = qAllEvents.where(
        'location',
        '==',
        searchCityQuery.trim(),
      );

      const eventSnapshots =
        searchQuery.trim() !== ''
          ? await qSearchedEvents.get()
          : searchCityQuery.trim() !== ''
          ? await qSearchedByCityEvents.get()
          : await qAllEvents.get();

      const eventsData = [];

      for (const doc of eventSnapshots.docs) {
        const docdata = doc.data();
        const startDate = docdata.startDate?.toDate();
        const formattedDate = `${startDate
          .getDate()
          .toString()
          .padStart(2, '0')} ${getMonthName(
          startDate.getMonth(),
        )} ${startDate.getFullYear()} at ${startDate
          .getHours()
          .toString()
          .padStart(2, '0')}:${startDate
          .getMinutes()
          .toString()
          .padStart(2, '0')}`;

        const qTickets = db
          .collection('events')
          .doc(doc.id)
          .collection('tickets');
        const ticketSnapshots = await qTickets.get();
        const tickets = ticketSnapshots.docs.map((ticketDoc) =>
          ticketDoc.data(),
        );
        const price = tickets.length > 0 ? tickets[0].price : 'N/A';

        const eventData = {
          id: doc.id,
          //auditoriumid: params.id,
          description: docdata.description,
          summary: docdata.summary,
          dateDisplay: formattedDate,
          title: docdata.title,
          imageUri: docdata.coverImage,
          address: docdata.address,
          location: docdata.location,
          price: price, // Price from the first ticket in the tickets sub-collection
        };

        eventsData.push(eventData);
      }

      setEvents(eventsData);
      setIsLoading(false);
      setSearchQuery('');

      setSearchCityQuery('');

      if (eventsData.length === 0 || (searchCity && eventsData.length === 0)) {
        setNoMatchingEvents(true);
      } else {
        setNoMatchingEvents(false);
      }

      if (selectCityDisplay) {
        setSelectCityDisplay(false);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await queryFn(selectedDate);
      } catch (error) {
        console.log('error', error);
      }
    };

    fetchData();
  }, [search, searchCity, selectedDate, monthData]);

  // Function to get the month name
  const getMonthName = (monthIndex) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return months[monthIndex];
  };

  // const keyExtractor = (item, index) => item.key.toString();

  // const eventKeyExtractor = (item, index) => item.id.toString();
  const eventKeyExtractor = (item, index) =>
    item && item.id ? item.id.toString() : index.toString();

  const renderShowItem = ({item, index}) => {
    if (!item || !item.id) {
      return null; // If item or item.id is not defined, return null to skip rendering
    }
    return (
      <EventCardA
        key={index}
        eventId={item.id}
        imageUri={item.imageUri}
        name={item.title}
        description={item.summary}
        date={item.dateDisplay}
        address={item.address}
        location={item.location}
        price={item.price}
        // handleScrollImage={handleScrollImage}
        // isScrolledPastImage={isScrolledPastImage}
        // blurRadius={blurRadius}
      />
    );
  };

  const handleSelectCityDisplay = () => {
    setSelectCityDisplay(true);
  };

  // Function to toggle between displaying all events and favorite events
  const handleShowFavorites = () => {
    setShowFavorites((prevState) => !prevState);
  };

  // Function to get only the favorite events
  const getFavoriteEvents = () => {
    return events.filter((event) => favoritesContext.eventIsFavorite(event.id));
  };

  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
      {!selectCityDisplay && (
        <>
          <View style={styles.headSection}>
            <HeaderA scroll={scroll} />

            {scroll ? (
              <View> {''} </View>
            ) : (
              <SearchBarA
                placeholder /*"Rechercher un événement"*/={t(
                  'events.Rechercher un événement',
                )}
                style={{marginTop: -14}}
                value={searchQuery}
                onChangeText={(text) => {
                  setSearchQuery(text);
                }}
                onSubmitEditing={() => {
                  if (searchQuery.trim() !== '') {
                    queryFn(!search);
                  }
                }}
                onPress={() => {
                  if (searchQuery.trim() !== '') {
                    queryFn(!search);
                  }
                }}
              />
            )}

            {/* <SearchBarA
              placeholder="Search for an event"
              style={{marginTop: -14}}
              value={searchQuery}
              onChangeText={(text) => {
                setSearchQuery(text);
              }}
              onPress={() => {
                if (searchQuery.trim() !== '') {
                  queryFn(!search);
                }
              }}
            /> */}

            {scroll ? (
              <View> {''} </View>
            ) : (
              <View>
                <FlatList
                  horizontal
                  data={servicesDataEvent}
                  showsHorizontalScrollIndicator={false}
                  // alwaysBounceHorizontal={false}
                  keyExtractor={(item) => item.id}
                  renderItem={renderServices}
                  ItemSeparatorComponent={() => (
                    <View
                      style={{
                        marginHorizontal: 20,
                      }}
                    />
                  )}
                  contentContainerStyle={{
                    paddingHorizontal: 25,
                    paddingBottom: 12,
                  }}
                />
              </View>
            )}

            <View style={styles.selectCityBtnContainer}>
              {/* <TouchableItem
                onPress={handleSelectCityDisplay}
                style={styles.selectCityTouchableItem}>
                <TitleA>Select a city</TitleA>
                <DownArrowSVG style={styles.downArrow} />
              </TouchableItem> */}

              <TouchableItem onPress={handleShowFavorites}>
                <FavoriteSVG
                  fill={showFavorites ? Colors.darkGreen : Colors.lightGray}
                  style={styles.favorite}
                />
              </TouchableItem>
            </View>
            <CalendarA
              setSelectedDate={setSelectedDate}
              setMonthData={setMonthData}
            />
          </View>
          <ScrollView
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            onScroll={handleScroll}
            // onScroll={handleScrollImage}
            // onScroll={(event) => {
            //   handleScroll(event);
            //   handleScrollImage(event);
            // }}
            contentContainerStyle={{justifyContent: 'center'}}
            style={styles.container}>
            <View>
              {/* <TitleA
                style={{
                  paddingVertical: 20,
                  marginHorizontal: 25,
                  borderTopWidth: 1,
                  borderColor: Colors.lightGray,
                }}>
                Today
              </TitleA> */}

              {isLoading ? (
                <View style={{paddingTop: 50}}>
                  <ActivityIndicator size="large" color={Colors.darkGreen} />
                </View>
              ) : showFavorites ? (
                getFavoriteEvents().length === 0 ? ( // Display a message if no favorite events
                  <SubheadingA
                    style={{
                      paddingVertical: 20,
                      marginHorizontal: 25,
                      marginTop: 80,
                      color: Colors.red,
                      textAlign: 'center',
                    }}>
                    {/* Aucun événement favori trouvé. */}
                    {t('events.Aucun événement favori trouvé.')}
                  </SubheadingA>
                ) : (
                  <FlatList
                    data={getFavoriteEvents()}
                    showsHorizontalScrollIndicator={false}
                    alwaysBounceHorizontal={false}
                    keyExtractor={eventKeyExtractor}
                    renderItem={renderShowItem}
                    onScroll={Animated.event(
                      [{nativeEvent: {contentOffset: {y: scrollY}}}],
                      {useNativeDriver: false},
                    )}
                    contentContainerStyle={styles.contentContainerStyle}
                  />
                )
              ) : noMatchingEvents ? (
                <SubheadingA
                  style={{
                    paddingVertical: 20,
                    marginHorizontal: 25,
                    marginTop: 80,
                    color: Colors.red,
                    textAlign: 'center',
                  }}>
                  {/* Aucun événement correspondant trouvé.{' '} */}
                  {t('events.Aucun événement correspondant trouvé.')}
                </SubheadingA>
              ) : (
                <FlatList
                  data={events}
                  showsHorizontalScrollIndicator={false}
                  alwaysBounceHorizontal={false}
                  keyExtractor={eventKeyExtractor}
                  renderItem={renderShowItem}
                  contentContainerStyle={styles.contentContainerStyle}
                />
              )}
            </View>
          </ScrollView>
        </>
      )}
      {selectCityDisplay && (
        <>
          <ScreenTitleA
            onPress={() => {
              setSelectCityDisplay(false);
            }}
            style={{height: 60}}
            title={'Rechercher une ville'}
          />

          <SearchBarA
            value={searchCityQuery}
            placeholder="Enter city name"
            style={{marginHorizontal: 25, marginTop: 27}}
            onChangeText={(text) => {
              setSearchCityQuery(text);
            }}
            onPress={() => {
              if (searchCityQuery.trim() !== '') {
                queryFn(!searchCity);
              }
            }}
          />

          <ScrollView
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            style={{...styles.container, marginVertical: 0}}>
            {countries.map((country) => (
              <View key={country.name} style={styles.selectCityContainer}>
                <View style={styles.country}>
                  <View>{country.flagComponent}</View>
                  <View>
                    <TitleA
                      style={{
                        fontSize: 20,
                        lineHeight: 30,
                        letterSpacing: 0,
                      }}>
                      {country.name}
                    </TitleA>
                  </View>
                </View>

                {country.cities.map((city) => (
                  <View style={styles.city} key={city}>
                    <View>
                      <LocalSVG style={styles.locationIcon} />
                    </View>
                    <View>
                      <BodyA
                        style={{
                          fontFamily: 'SemiBold',
                          color: Colors.normalGray,
                        }}>
                        {city}
                      </BodyA>
                    </View>
                  </View>
                ))}
              </View>
            ))}
          </ScrollView>
        </>
      )}
    </SafeAreaView>
  );
};

const mapStateToProps = (state) => {
  const {carts, store, prefs} = state;
  return {carts, store, prefs};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addProduct,
      setStore,
      setLastId,
      setTierMode,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Events);
