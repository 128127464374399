/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

// import node modules
import React, {Component, Fragment} from 'react';
import {  
  StyleSheet,  
} from 'react-native';


import OrdersList from './OrdersAList';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addProduct, setStore, setLastId, setTierMode } from '../../reducers/cartactions';

// import colors
import Colors from '../../theme/colors';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

const Tab = createMaterialTopTabNavigator();

// OrdersB Styles
const styles = StyleSheet.create({
  topArea: {flex: 0, backgroundColor: Colors.primaryColor},
  container: {
    flex: 1,
    backgroundColor: '#efefef',
  },
  productsContainer: {
    paddingVertical: 8,
  },
});

// OrdersB
function OrdersC(props) {
  
 
  if (props.prefs.tierMode && props.prefs.tierMode === "event") {
    return (
      <Tab.Navigator>
        <Tab.Screen name="Billets à venir">
            {(props) => <OrdersList  {...props} tabtype="active" />}
        </Tab.Screen>
        <Tab.Screen name="Billets passés">
            {(props) => <OrdersList  {...props} tabtype="old" />}
        </Tab.Screen>        
      </Tab.Navigator>
    );
  } else {
    return (
      <Tab.Navigator>
        <Tab.Screen name="Commandes actives">
            {(props) => <OrdersList  {...props} tabtype="active" />}
        </Tab.Screen>
        <Tab.Screen name="Anciennes commandes">
            {(props) => <OrdersList  {...props} tabtype="old" />}
        </Tab.Screen>        
      </Tab.Navigator>
    );
  }
 
  
  
}


const mapStateToProps = (state) => {
  const { carts, store, prefs } = state
  return { carts, store, prefs }
};


const mapDispatchToProps = dispatch => (
  bindActionCreators({
    addProduct,
    setStore,
    setLastId,
    setTierMode
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(OrdersC);