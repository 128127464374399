import carbonara from '../../../assets/images/pizzaCarbonara.jpg';
import burger from '../../../assets/images/burgerAndFries.jpg';
import tacos from '../../../assets/images/tacos.png';
import salad from '../../../assets/images/salad.jpg';
import grillades from '../../../assets/images/grillades.png';

export const promotionCardData = [
  {
    id: '1',
    image: carbonara,
    description: 'Pizza Carbonara 35cm',
    title: 'Pizza',
    review: '3.7 (500 avis)',
    price: '5.99',
    rate: '-10%',
  },
  {
    id: '2',
    image: salad,
    description: 'Salade César',
    title: 'Salad',
    review: '3.7 (500 avis)',
    price: '5.99',
    rate: '-10%',
  },
];
export const fastfoodCardData = [
  {
    id: '1',
    image: burger,
    description: 'Burger & Fries',
    category: 'popular',
    title: 'Humburger',
    review: '3.7 (500 avis)',
    location: '1 Bd de Bonne Nouvelle, 75002 Paris',
    time: '10:00 - 23:00 - ',
    status: 'Open',
    rate: 'NEW',
  },
  {
    id: '2',
    image: tacos,
    description: 'Fantastyk',
    title: 'Tacos',
    review: '3.7 (500 avis)',
    location: '92 Av. du Dr Arnold Netter, 75012 Paris',
    time: '18:00 - 23:00 - ',
    status: 'Closed',
  },
];
export const turkishFoodCardData = [
  {
    id: '1',
    image: grillades,
    description: 'Grillades',
    title: 'Grillades',
    review: '3.7 (500 avis)',
    location: 'Juste à côté de ponts, 175 Av. de Flandre, 75019 Paris',
    time: '10:00 - 23:00 - ',
    status: 'Open',
    rate: 'NEW',
  },
];
