import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';

const FranceFlagSVG = (props) => {
  const {style, ...restProps} = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      style={style}
      {...restProps}>
      <G clipPath="url(#a)">
        <Path
          fill="#428BC1"
          d="M.713 12c0 4.913 3.15 9.075 7.5 10.613V1.387C3.863 2.925.713 7.087.713 12Z"
        />
        <Path
          fill="#ED4C5C"
          d="M23.212 12c0-4.913-3.112-9.075-7.5-10.613v21.226c4.388-1.538 7.5-5.7 7.5-10.613Z"
        />
        <Path
          fill="#fff"
          d="M8.213 22.613c1.162.412 2.437.637 3.75.637 1.312 0 2.587-.225 3.75-.637V1.387a11.086 11.086 0 0 0-3.75-.637c-1.35 0-2.588.225-3.75.637v21.226Z"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h24v24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
export default FranceFlagSVG;
