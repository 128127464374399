import React, {useState, useEffect} from 'react';
import styles from './RestaurantOrderDetailsStyles';
import Colors from '../../theme/colors';
import {SafeAreaView, ScrollView, StatusBar, Text, View} from 'react-native';
import TitleBigA from '../../components/titleBigA/TitleBigA';
import SubheadingA from '../../components/subheadingA/SubheadingA';
import TitleA from '../../components/titleA/TitleA';
import TouchableItem from '../../components/TouchableItem';
import TrashSVG from '../../components/svgs/TrashSVG';
import MinusSVG2 from '../../components/svgs/MinusSVG2';
import PlusSVG2 from '../../components/svgs/PlusSVG2';
import TimeSVG from '../../components/svgs/TimeSVG';
import BodyA from '../../components/bodyA/BodyA';
import PromoCodeSVG from '../../components/svgs/PromoCodeSVG';
import AddUserSVG from '../../components/svgs/AddUserSVG';
import ButtonA from '../../components/buttonA/ButtonA';
import CartSVG from '../../components/svgs/CartSVG';
import RemoveUserSVG from '../../components/svgs/RemoveUserSVG';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
  addProduct,
  addPromoCode,
  remPromoCode,
} from '../../reducers/cartactions';

const RestaurantOrderDetails = ({cart, addProduct}) => {
  const {t} = useTranslation();
  const [quantity, setQuantity] = useState(1);
  const [showGroupOrderDetails, setShowGroupOrderDetails] = useState(false);
  const [promoCodeDiscount, setPromoCodeDiscount] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalAfterDiscount, setTotalAfterDiscount] = useState(0);

  const handlePlus = (item) => {
    addProduct({id: item.id, quantity: 1, price: item.price});
  };
  const handleMinus = (item) => {
    addProduct({id: item.id, quantity: -1, price: item.price});
  };

  const updateTotalAmount = (promoCodeDiscount) => {
    const {products} = cart;
    let total = 0.0;
    let totalAfterDiscount = 0.0;

    products.forEach((product) => {
      let {price} = product;
      const {quantity, iscomposite, composition} = product;
      var compositionprice = iscomposite
        ? composition.reduce(function (a, b) {
            return a + b.price;
          }, 0)
        : 0;
      total += (price + compositionprice) * quantity;
      const finalPrice = price;
      if (promoCodeDiscount) {
        const discountFound = promoCodeDiscount?.products.some(
          (e) => e == product.id,
        );
        if (discountFound) {
          totalAfterDiscount +=
            (finalPrice -
              price * promoCodeDiscount?.discount * 0.01 +
              compositionprice) *
            quantity;
        } else {
          totalAfterDiscount += (finalPrice + compositionprice) * quantity;
        }
      }
    });
    // If price with discount is same than without > discounts removed
    if (totalAfterDiscount == total) {
      //this.setState({promoCodeDiscount: null});
      setPromoCodeDiscount(null);
    }
    setTotalPrice(total);
    setTotalAfterDiscount(totalAfterDiscount);
  };

  useEffect(() => {
    updateTotalAmount(promoCodeDiscount);
  }, [cart.products]);

  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        // contentContainerStyle={{paddingBottom: 40}}
        // style={styles.container}
      >
        <View style={styles.wrapper}>
          <View style={styles.header}>
            <TitleBigA style={{paddingBottom: 8}}>
              {/* Your order is ready! */}
              {t('RestaurantOrderDetails.Votre commande est prête !')}
            </TitleBigA>
            <SubheadingA>
              {/* Validate your order and proceed to payment. You can pay the entire
              order or launch a bulk order. */}
              {t('RestaurantOrderDetails.Validez votre commande et')}
            </SubheadingA>
          </View>
          <View>
            {cart.products.map((product) => (
              <>
                <View style={styles.title}>
                  <TitleA>{product?.quantity}</TitleA>
                  <TitleA style={{flexBasis: '70%'}}>{product?.name}</TitleA>
                  <TitleA>{product.price}€</TitleA>
                </View>
                {product?.composition?.length > 0 && (
                  <View style={styles.subDetailsContainer}>
                    {product?.composition.map((composition) => (
                      <>
                        <Text style={{color: Colors.darkGray}}>
                          {composition.category}
                        </Text>
                        {/* {composition.map((option) => ( */}
                        <View style={styles.row}>
                          <Text
                            style={{
                              color: Colors.normalGray,
                              fontFamily: 'Light',
                              paddingRight: 15,
                            }}>
                            1
                          </Text>
                          <Text
                            style={{
                              color: Colors.normalGray,
                              fontFamily: 'Light',
                            }}>
                            {composition.name}
                          </Text>
                        </View>
                        {/* ))} */}
                      </>
                    ))}
                  </View>
                )}
                {/* <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  <Text style={{color: Colors.darkGray}}>Suppléments</Text>
                  <Text style={{color: Colors.darkGray}}>5.49 €</Text>
                </View>
                <View style={styles.subRow}>
                  <Text
                    style={{
                      color: Colors.normalGray,
                      fontFamily: 'Light',
                    }}>
                    1
                  </Text>
                  <Text
                    style={{
                      color: Colors.normalGray,
                      fontFamily: 'Light',
                      flexBasis: '70%',
                    }}>
                    Supplément Saumon cuit
                  </Text>
                  <Text
                    style={{
                      color: Colors.normalGray,
                      fontFamily: 'Light',
                      flexBasis: '15%',
                    }}>
                    3.99 €
                  </Text>
                </View>
                <View style={styles.subRow}>
                  <Text
                    style={{
                      color: Colors.normalGray,
                      fontFamily: 'Light',
                    }}>
                    1
                  </Text>
                  <Text
                    style={{
                      color: Colors.normalGray,
                      fontFamily: 'Light',
                      flexBasis: '70%',
                    }}>
                    Supplément Thon
                  </Text>
                  <Text
                    style={{
                      color: Colors.normalGray,
                      fontFamily: 'Light',
                      flexBasis: '15%',
                    }}>
                    1.50 €
                  </Text>
                </View> */}
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingLeft: 24,
                    paddingVertical: 8,
                  }}>
                  <TouchableItem style={styles.deleteBtn}>
                    <TrashSVG
                      style={{
                        width: 17,
                        height: 17,
                        marginTop: 2,
                        marginRight: 4,
                      }}
                    />
                    <Text style={{color: Colors.red}}>Delete</Text>
                  </TouchableItem>
                  <View>
                    <View style={styles.quantityHandler}>
                      {quantity > 0 && (
                        <TouchableItem
                          onPress={() => handleMinus(product)}
                          style={{marginRight: 10}}>
                          <MinusSVG2
                            stroke={Colors.normalGray}
                            fill={Colors.normalGray}
                          />
                        </TouchableItem>
                      )}

                      {product.quantity > 0 && (
                        <Text
                          style={{
                            color: Colors.normalGray,
                            fontFamily: 'SemiBold',
                          }}>
                          {product.quantity}
                        </Text>
                      )}
                      <TouchableItem
                        style={{marginLeft: 10}}
                        onPress={() => handlePlus(product)}>
                        <PlusSVG2 />
                      </TouchableItem>
                    </View>
                  </View>
                </View>
                <View style={styles.separator} />
              </>
            ))}

            <View style={{paddingVertical: 24}}>
              <View style={styles.total}>
                <TitleBigA style={{color: Colors.darkGray}}>Total</TitleBigA>
                <TitleBigA style={{color: Colors.darkGreen}}>
                  {totalPrice}
                </TitleBigA>
              </View>
              <View style={styles.bodyRow}>
                <TimeSVG style={styles.rowIcon} />
                <BodyA style={{color: Colors.normalGray}}>
                  {/* Order deadline : Vendredi 1 juin, 23:00 */}
                  {t(
                    'RestaurantOrderDetails.Date limite de commande : Vendredi 1 juin 23h00',
                  )}
                </BodyA>
              </View>
              <View style={styles.separator} />
              <View style={styles.promoCode}>
                <SubheadingA>
                  {/* Do you have a promo code? */}
                  {t('RestaurantOrderDetails.Avez-vous un code de promotion?')}
                </SubheadingA>
                <TouchableItem>
                  <PromoCodeSVG
                    style={{
                      width: 42,
                      height: 42,
                    }}
                  />
                </TouchableItem>
              </View>
              <View style={styles.separator} />
              {showGroupOrderDetails && (
                <View style={styles.groupOrder}>
                  <TitleBigA style={{marginBottom: 12}}>
                    {/* Bulk order */}
                    {t('RestaurantOrderDetails.Commande en vrac')}
                  </TitleBigA>
                  <View style={styles.row2}>
                    <SubheadingA style={{color: Colors.black}}>
                      {/* Samuel le Blanc (proprietaire) */}
                      {t(
                        'RestaurantOrderDetails.Samuel le Blanc (proprietaire)',
                      )}
                    </SubheadingA>
                    <TitleA>10.99 €</TitleA>
                  </View>
                  <View style={styles.title}>
                    <Text
                      style={{color: Colors.normalGray, fontFamily: 'Light'}}>
                      1
                    </Text>
                    <Text
                      style={{
                        color: Colors.normalGray,
                        fontFamily: 'Light',
                        flexBasis: '70%',
                      }}>
                      {/* Salade César + Boisson */}
                      {t('RestaurantOrderDetails.Salade César + Boisson')}
                    </Text>
                    <Text
                      style={{color: Colors.normalGray, fontFamily: 'Light'}}>
                      18.99 €
                    </Text>
                  </View>
                  <View style={[styles.separator, {marginVertical: 12}]} />
                  <View>
                    <View style={styles.row2}>
                      <SubheadingA style={{color: Colors.black}}>
                        Frederic Tomas
                      </SubheadingA>
                      <TitleA>17.99 €</TitleA>
                    </View>
                    <View style={[styles.title, {marginBottom: 12}]}>
                      <Text
                        style={{color: Colors.normalGray, fontFamily: 'Light'}}>
                        1
                      </Text>
                      <Text
                        style={{
                          color: Colors.normalGray,
                          fontFamily: 'Light',
                          flexBasis: '70%',
                        }}>
                        {/* Salade Rapide */}
                        {t('RestaurantOrderDetails.Salade Rapide')}
                      </Text>
                      <Text
                        style={{color: Colors.normalGray, fontFamily: 'Light'}}>
                        5.99 €
                      </Text>
                    </View>
                    <View style={styles.title}>
                      <Text
                        style={{color: Colors.normalGray, fontFamily: 'Light'}}>
                        2
                      </Text>
                      <Text
                        style={{
                          color: Colors.normalGray,
                          fontFamily: 'Light',
                          flexBasis: '70%',
                        }}>
                        {/* Hamburgers cheesy */}
                        {t('RestaurantOrderDetails.Hamburgers au fromage')}
                      </Text>
                      <Text
                        style={{color: Colors.normalGray, fontFamily: 'Light'}}>
                        12.00 €
                      </Text>
                    </View>
                    <TouchableItem style={styles.deleteBtn2}>
                      <TrashSVG
                        style={{
                          width: 17,
                          height: 17,
                          marginTop: 2,
                          marginRight: 4,
                        }}
                      />
                      <Text style={{color: Colors.red}}>
                        {/* Remove guest */}
                        {t('RestaurantOrderDetails.Supprimer un invité')}
                      </Text>
                    </TouchableItem>
                  </View>
                </View>
              )}

              <View>
                {!showGroupOrderDetails && (
                  <View style={{paddingVertical: 10}}>
                    <ButtonA
                      onPress={() => setShowGroupOrderDetails(true)}
                      //   disabled={isLoading}
                      style={{
                        backgroundColor: Colors.blue,
                        width: '100%',
                        height: 54,
                        alignSelf: 'center',
                      }}>
                      <View
                        style={{alignItems: 'center', flexDirection: 'row'}}>
                        <AddUserSVG
                          style={{
                            width: 18,
                            height: 18,
                            marginRight: 10,
                          }}
                        />
                        {/* Bulk order */}
                        {t('RestaurantOrderDetails.Commande en vrac')}
                      </View>
                    </ButtonA>
                  </View>
                )}
                {!showGroupOrderDetails && (
                  <View style={{paddingVertical: 10}}>
                    <ButtonA
                      //   onPress={handleNavigation}
                      //   disabled={isLoading}
                      style={{
                        backgroundColor: Colors.darkGray,
                        width: '100%',
                        height: 54,
                        alignSelf: 'center',
                      }}>
                      <View
                        style={{alignItems: 'center', flexDirection: 'row'}}>
                        <PlusSVG2
                          style={{
                            width: 18,
                            height: 18,
                            marginRight: 10,
                          }}
                          fill1={Colors.darkGray}
                          fill={Colors.white}
                          stroke={Colors.white}
                        />
                        {/* Add items */}
                        {t('RestaurantOrderDetails.Ajouter des articles')}
                      </View>
                    </ButtonA>
                  </View>
                )}
                {showGroupOrderDetails && (
                  <View style={{paddingVertical: 10}}>
                    <ButtonA
                      onPress={() => setShowGroupOrderDetails(false)}
                      //   disabled={isLoading}
                      color={Colors.normalGray}
                      style={{
                        backgroundColor: Colors.background,
                        width: '100%',
                        height: 54,
                        alignSelf: 'center',
                        borderWidth: 1,
                        borderColor: Colors.darkGray,
                      }}>
                      <View
                        style={{alignItems: 'center', flexDirection: 'row'}}>
                        <RemoveUserSVG
                          style={{
                            width: 18,
                            height: 18,
                            marginRight: 10,
                          }}
                          //   stroke={Colors.white}
                        />
                        {/* Cancel Bulk Order */}
                        {t(
                          'RestaurantOrderDetails.Annuler la commande groupée',
                        )}
                      </View>
                    </ButtonA>
                  </View>
                )}

                <View style={{paddingVertical: 10}}>
                  <ButtonA
                    //   onPress={handleNavigation}
                    //   disabled={isLoading}
                    style={{
                      backgroundColor: Colors.darkGreen,
                      width: '100%',
                      height: 54,
                      alignSelf: 'center',
                    }}>
                    <View style={{alignItems: 'center', flexDirection: 'row'}}>
                      <CartSVG
                        style={{
                          width: 20,
                          height: 20,
                          marginRight: 10,
                        }}
                        stroke={Colors.white}
                      />
                      {/* Payment (38.00 €) */}
                      {t('RestaurantOrderDetails.Paiement (38,00 €)')}
                    </View>
                  </ButtonA>
                </View>
              </View>
            </View>
          </View>
          {showGroupOrderDetails && (
            <View style={{marginTop: 234}}>
              <ButtonA
                //   onPress={handleNavigation}
                //   disabled={isLoading}
                style={{
                  backgroundColor: Colors.darkGray,
                  width: '100%',
                  height: 74,
                  alignSelf: 'center',
                  borderRadius: 30,
                }}>
                <View style={{position: 'relative', flexDirection: 'row'}}>
                  <View>
                    <Text style={{color: Colors.white, fontSize: 12}}>
                      {/* Show orders */}
                      {t('RestaurantOrderDetails.Afficher les commandes')}
                    </Text>
                    <Text
                      style={{
                        textAlign: 'center',
                        fontFamily: 'SemiBold',
                        fontSize: 16,
                        letterSpacing: 0.64,
                        textTransform: 'capitalize',
                      }}>
                      Resto Montorgueil
                    </Text>
                  </View>

                  <View style={styles.circle}>
                    <SubheadingA
                      style={{fontFamily: 'Bold', color: Colors.white}}>
                      4
                    </SubheadingA>
                  </View>
                </View>
              </ButtonA>
            </View>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const mapStateToProps = (state) => {
  const {cart, store, event} = state;
  return {cart, store, event};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addProduct,
      addPromoCode,
      remPromoCode,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RestaurantOrderDetails);
