/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

// import dependencies
import React, {useState} from 'react';
import {
  I18nManager,
  StyleSheet,
  TextInput,
  View,
  ViewStyle,
  Text,
} from 'react-native';

// import colors
import Colors from '../../theme/colors';

// UnderlineTextInput Config
const isRTL = I18nManager.isRTL;
const INPUT_HEIGHT = 44;
const INPUT_WIDTH = '100%';

// UnderlineTextInput Styles
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 8,
    paddingVertical: 4,
    paddingHorizontal: 4,
    borderBottomWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.2)',
    width: INPUT_WIDTH,
  },
  textInput: {
    flex: 1,
    paddingHorizontal: 0,
    height: INPUT_HEIGHT,
    fontSize: 16,
    color: Colors.primaryText,
    textAlign: isRTL ? 'right' : 'left',
  },
  errorText: {
    color: Colors.error,
    fontSize: 12,
    marginTop: 4,
    textAlign: isRTL ? 'right' : 'left',
  },
});

// UnderlineTextInput Props
type Props = {
  onRef: () => {},
  onChangeText: () => {},
  onFocus: () => {},
  inputFocused: boolean,
  onSubmitEditing: () => {},
  returnKeyType: 'done' | 'go' | 'next' | 'search' | 'send',
  blurOnSubmit: boolean,
  onKeyPress: () => {},
  keyboardType:
    | 'default'
    | 'number-pad'
    | 'decimal-pad'
    | 'numeric'
    | 'email-address'
    | 'phone-pad'
    | 'date',
  autoCapitalize: 'none' | 'sentences' | 'words' | 'characters',
  maxLength: number,
  placeholder: string,
  placeholderTextColor: string,
  value: string,
  inputTextColor: string,
  secureTextEntry: boolean,
  borderColor: string,
  focusedBorderColor: string,
  inputContainerStyle: ViewStyle,
  inputStyle: ViewStyle,
};

// UnderlineTextInput
const UnderlineTextInput = ({
  onRef = () => {},
  onChangeText,
  onFocus,
  inputFocused,
  onSubmitEditing,
  returnKeyType,
  blurOnSubmit,
  onKeyPress,
  keyboardType,
  autoCapitalize = 'none',
  maxLength,
  placeholder,
  placeholderTextColor,
  value,
  inputTextColor,
  secureTextEntry,
  borderColor,
  focusedBorderColor,
  inputContainerStyle,
  inputStyle,
}: Props) => {
  const [error, setError] = useState(null);

  const handleOnChangeText = (text) => {
    let errorMessage = null;

    switch (keyboardType) {
      case 'email-address':
        if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/.test(text)) {
          errorMessage = 'Invalid email address';
        }
        break;

      case 'phone-pad':
        if (!/^\d*$/.test(text)) {
          errorMessage = 'Please enter only digits';
        }
        break;

      case 'numeric':
        if (!/^\d*\.?\d*$/.test(text)) {
          errorMessage = 'Please enter only numeric values';
        }
        break;

      case 'date':
        if (!/^\d{4}-\d{2}-\d{2}$/.test(text)) {
          errorMessage = 'Please enter a valid date in yyyy-mm-dd format';
        }
        break;

      default:
        break;
    }

    setError(errorMessage);
    onChangeText(text);
  };

  return (
    <View
      style={[
        styles.container,
        borderColor && {borderColor},
        inputFocused && {borderColor: focusedBorderColor},
        inputContainerStyle && inputContainerStyle,
      ]}>
      <TextInput
        ref={(r) => onRef(r)}
        onChangeText={handleOnChangeText}
        onFocus={onFocus}
        inputFocused={inputFocused}
        onSubmitEditing={onSubmitEditing}
        returnKeyType={returnKeyType}
        blurOnSubmit={blurOnSubmit}
        onKeyPress={onKeyPress}
        keyboardType={keyboardType}
        autoCapitalize={autoCapitalize}
        maxLength={maxLength}
        placeholder={placeholder}
        placeholderTextColor={placeholderTextColor}
        value={value}
        secureTextEntry={secureTextEntry}
        style={[
          styles.textInput,
          inputTextColor && {color: inputTextColor},
          inputStyle,
        ]}
      />
      {error && <Text style={{color: 'red'}}>{error}</Text>}
    </View>
  );
};

export default UnderlineTextInput;
