import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
const HandDeliverySVG = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    fill="none"
    viewBox="0 0 17 17"
    {...props}>
    <Path
      stroke="#77838F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.333}
      d="m7.167 11.5 2 .667s5-1 5.666-1c.667 0 .667.666 0 1.333-.666.667-3 2.667-5 2.667s-3.333-1-4.666-1H1.833"
    />
    <Path
      stroke="#77838F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.333}
      d="M1.833 10.167C2.5 9.5 3.833 8.5 5.167 8.5c1.333 0 4.5 1.333 5 2s-1 1.667-1 1.667M5.833 6.5V3.833a.667.667 0 0 1 .667-.666h8a.667.667 0 0 1 .667.666v5.334"
    />
    <Path
      stroke="#77838F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.333}
      d="M8.833 3.167h3.334v3H8.833v-3Z"
    />
  </Svg>
);
export default HandDeliverySVG;
