
import {StyleSheet, I18nManager} from 'react-native';
import Colors from "../../theme/colors"


const styles = StyleSheet.create({
    screenContainer : {
        flex : 1 ,
        backgroundColor: Colors.background,

    } , 
    container : {
        paddingHorizontal: 24 , 
      paddingVertical : 16 , 
    } , 
    separator: {
        height: 1,
        backgroundColor: Colors.lightGray,
      },
    containerText :{
        flexDirection : "row" ,
        alignItems : "center" , 
        justifyContent :"space-between"
    } , 
    titleArticle : {
       /* color: "var(--Dark-Grey, #1D1F21)",
    fontFamily: "Bai Jamjuree",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "150%", 
    letterSpacing: 0.64, */
    } , 
    textArticle: {
       /* color: "var(--Dark-Grey, #1D1F21)",
        fontFamily: "Bai Jamjuree",
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "150%", */
    } , 
    containerPayement :{
        flexDirection : "row" ,
        paddingVertical :20 ,
         justifyContent : "space-between"
          ,paddingHorizontal : 24 ,
         alignItems : "center"
    } ,
    containerScreenPayment : {
        borderRadius: 15,
         borderWidth: 1,
          marginBottom: 16
    }
})
export default styles