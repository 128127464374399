import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';

const RemoveUserSVG = (props) => {
  const {style, ...restProps} = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 19 18"
      style={style}
      {...restProps}>
      <G clipPath="url(#a)">
        <Path
          fill="#77838F"
          d="M7.77.108c-2.09 0-3.765 1.435-3.765 4.176 0 1.79.713 3.614 1.795 4.738.423 1.123-.339 1.541-.497 1.6-2.185.803-4.739 2.256-4.739 3.7v.54c0 1.969 3.749 2.424 7.226 2.424.758-.002 1.516-.03 2.272-.086a5.365 5.365 0 0 1-1.47-3.7 5.37 5.37 0 0 1 1.123-3.288C9.612 9.968 9.57 9.584 9.76 9c1.076-1.125 1.774-2.933 1.774-4.717 0-2.74-1.677-4.175-3.764-4.175ZM14 9.13a4.37 4.37 0 1 0 0 8.74 4.37 4.37 0 0 0 0-8.74Zm.606 3.743h1.796v1.254h-4.825v-1.254h3.029Z"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M.5 0h18v18H.5z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
export default RemoveUserSVG;
