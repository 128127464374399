/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

// import node modules
import React, {Component} from 'react';
import {
  SafeAreaView,
  Button,
  LogBox,
  StatusBar,
  StyleSheet,
  Text,
  View,
  ImageBackground,
  ScrollView,
} from 'react-native';
import TouchableItem from '../../components/TouchableItem';
import GradientContainer from '../../components/gradientcontainer/GradientContainer';
import {db} from '../../services/firebase';
// import components
import OrderItem from '../../components/cards/OrderItemA';
import getImgSource from '../../utils/getImgSource.js';
// import colors
import Colors from '../../theme/colors';
import {Heading6, Subtitle2} from '../../components/text/CustomText';
import locales from '../../config/locales';
import {connect} from 'react-redux';
import {setStore} from '../../reducers/cartactions';
import {bindActionCreators} from 'redux';
import {Rating, AirbnbRating} from 'react-native-ratings';
import {AuthContext} from '../../contexts/context';
const imgHolder = require('../../../assets/images/imgholder.png');

LogBox.ignoreLogs(['Setting a timer']);
// OrdersA Styles
const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  container: {
    flex: 1,
    backgroundColor: '#efefef',
  },
  itemContainer: {
    marginVertical: 4,
    backgroundColor: Colors.background,
  },
  item: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
    height: 36,
  },
  productsContainer: {
    paddingVertical: 8,
  },
  productImg: {
    width: '100%',
    height: 236,
    resizeMode: 'cover',
  },
  bottomOverlay: {flex: 1},
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 16,
    paddingHorizontal: 16,
    paddingBottom: 12,
  },
  titleText: {
    fontWeight: '700',
  },
  bottomButtonContainer: {
    width: '100%',
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
});

// OrdersA
class RatingA extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      preptime: 1,
      store: {},
      rating: 1,
      loading: false,
    };
  }

  componentDidMount = () => {
    console.log('this.props.store');
    //console.log(this.props.store);
    this.setState({loading: true});
    console.log(this.props.route);

    db.collection('organisers')
      .doc(this.props.route.params.id)
      .get()
      .then(
        (doc) => {
          var store = doc.data();

          store.id = doc.id;
          //console.log(store);
          this.setState({store: store, loading: false});
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        },
      );
  };

  goBack = () => {
    const {navigation} = this.props;
    navigation.goBack();
  };

  navigateTo = (screen) => () => {
    console.log(screen);
    const {navigation} = this.props;
    navigation.navigate(screen);
  };

  keyExtractor = (item) => item.orderNumber.toString();

  renderItem = ({item, index}) => (
    <OrderItem
      key={index}
      activeOpacity={0.8}
      orderNumber={item.orderNumber}
      orderDate={item.orderDate}
      orderItems={item.orderItems}
      orderStatus={item.orderStatus}
      onPress={this.navigateTo('Product')}
    />
  );
  ratingCompleted = (rating) => {
    console.log('Rating is: ' + rating);
    this.setState({rating: rating});
  };

  onRatingSubmit = () => {
    const {user} = this.context;

    db.collection('organisers')
      .doc(this.props.route.params.id)
      .collection('ratings')
      .doc(user.uid)
      .set({value: this.state.rating})
      .then(() => {
        const {navigation} = this.props;
        navigation.navigate('Home');
        //alert('submitted');
      })
      .catch((e) => console.log(e));
  };

  renderRestos = () => {
    if (this.state.store.image) {
      return (
        <ImageBackground
          defaultSource={imgHolder}
          source={getImgSource(this.state.store.image)}
          style={styles.productImg}>
          <GradientContainer
            colors={[Colors.black, 'transparent']}
            start={{x: 0, y: 1}}
            end={{x: 0, y: 0.6}}
            containerStyle={styles.bottomOverlay}
          />
        </ImageBackground>
      );
    }
    return <></>;
  };

  render() {
    const {orders} = this.state;
    if (this.state.loading) {
      return <Text>loading</Text>;
    }
    return (
      <SafeAreaView style={styles.screenContainer}>
        <StatusBar
          backgroundColor={Colors.statusBarColor}
          barStyle="dark-content"
        />

        <View style={styles.container}>
          <ScrollView>
            <View style={styles.header}>{this.renderRestos()}</View>
            <View style={styles.titleContainer}>
              <Heading6 style={styles.titleText}>
                Qu'avez-vous pensé du restaurant?
              </Heading6>
            </View>
            <View style={styles.container}>
              <View style={styles.pv8}>
                <Rating
                  showRating
                  onFinishRating={this.ratingCompleted}
                  style={{paddingVertical: 10}}
                />
              </View>
            </View>
            <View>
              <View style={styles.bottomButtonContainer}>
                <Button
                  color={Colors.primaryColor}
                  onPress={this.onRatingSubmit}
                  title="Soumettre ma note"
                />
              </View>
              <View style={styles.bottomButtonContainer}>
                <Button
                  color={Colors.primaryColor}
                  onPress={this.navigateTo('Home')}
                  title="Passer une nouvelle commande"
                />
              </View>
            </View>
          </ScrollView>
        </View>
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state) => {
  const {store} = state;
  return {store};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setStore,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RatingA);
