import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const EventsIconSVG = ({props}) => {
  const {...restProps} = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={75}
      height={68}
      fill="none"
      viewBox="0 0 75 68"
      {...restProps}>
      <Path
        fill="#000"
        d="M36.5 68C16.342 68 0 66.433 0 64.5S16.342 61 36.5 61 73 62.567 73 64.5 56.658 68 36.5 68Z"
        opacity={0.1}
      />
      <Path
        fill="#F1F2F2"
        d="M62.955 29.1 24.92 25.371l-2.665 27.99L60.29 57.09l2.665-27.99Z"
      />
      <Path
        fill="#844F02"
        d="m62.952 29.1-2.665 27.986-38.033-3.729 2.665-27.986L62.952 29.1Zm.886-.727-39.651-3.887-2.82 29.601 39.655 3.888 2.82-29.605-.004.003Z"
      />
      <Path
        fill="#E6E7E8"
        d="m24.175 33.395 8.053-6.834-7.333-.719-.72 7.553Z"
        style={{
          mixBlendMode: 'multiply',
        }}
      />
      <Path
        fill="#F15A29"
        d="m67.07 17.524-43.98-4.302-1.13 11.826 43.982 4.303 1.128-11.827Z"
      />
      <Path
        fill="#844F02"
        d="m67.07 17.524-1.128 11.831-43.982-4.303 1.129-11.831 43.982 4.303Zm.078-.806-43.982-4.303a.81.81 0 0 0-.886.726l-1.129 11.832a.814.814 0 0 0 .732.885l43.982 4.303a.81.81 0 0 0 .886-.726l1.128-11.832a.814.814 0 0 0-.731-.885Z"
      />
      <Path
        fill="#F1F2F2"
        d="M31.853 22.653a2.687 2.687 0 0 0 2.944-2.415 2.705 2.705 0 0 0-2.434-2.943 2.687 2.687 0 0 0-2.944 2.416 2.705 2.705 0 0 0 2.434 2.942Z"
      />
      <Path
        fill="#844F02"
        d="M31.815 23.055a3.113 3.113 0 0 1-2.798-3.384 3.093 3.093 0 0 1 3.384-2.777 3.113 3.113 0 0 1 2.798 3.383 3.093 3.093 0 0 1-3.384 2.777Zm.51-5.358a2.283 2.283 0 0 0-2.499 2.05 2.298 2.298 0 0 0 2.066 2.498 2.283 2.283 0 0 0 2.499-2.05 2.298 2.298 0 0 0-2.066-2.498Z"
      />
      <Path
        fill="#F1F2F2"
        d="M44.27 23.862a2.687 2.687 0 0 0 2.944-2.415 2.705 2.705 0 0 0-2.433-2.943 2.687 2.687 0 0 0-2.944 2.415 2.705 2.705 0 0 0 2.433 2.943Z"
      />
      <Path
        fill="#844F02"
        d="M44.232 24.263a3.113 3.113 0 0 1-2.798-3.383 3.093 3.093 0 0 1 3.385-2.777 3.113 3.113 0 0 1 2.798 3.383 3.093 3.093 0 0 1-3.385 2.777Zm.51-5.358a2.283 2.283 0 0 0-2.498 2.05 2.298 2.298 0 0 0 2.065 2.498 2.283 2.283 0 0 0 2.499-2.05 2.298 2.298 0 0 0-2.066-2.498Z"
      />
      <Path
        fill="#F1F2F2"
        d="M56.688 25.07a2.687 2.687 0 0 0 2.944-2.415 2.705 2.705 0 0 0-2.434-2.942 2.687 2.687 0 0 0-2.944 2.415 2.705 2.705 0 0 0 2.434 2.943Z"
      />
      <Path
        fill="#844F02"
        d="M56.65 25.472a3.113 3.113 0 0 1-2.798-3.383 3.092 3.092 0 0 1 3.384-2.777 3.113 3.113 0 0 1 2.798 3.383 3.092 3.092 0 0 1-3.384 2.777Zm.51-5.358a2.283 2.283 0 0 0-2.499 2.05 2.298 2.298 0 0 0 2.066 2.498 2.283 2.283 0 0 0 2.498-2.05 2.298 2.298 0 0 0-2.065-2.498Z"
      />
      <Path
        fill="#C49A6C"
        d="m33.534 10.475-1.02-.1a.835.835 0 0 0-.916.75l-.752 7.884a.84.84 0 0 0 .756.914l1.02.1a.835.835 0 0 0 .916-.75l.752-7.884a.84.84 0 0 0-.756-.914Z"
      />
      <Path
        fill="#844F02"
        d="M32.073 20.374a1.762 1.762 0 0 1-1.585-1.915l.655-6.867a1.75 1.75 0 0 1 1.917-1.572 1.762 1.762 0 0 1 1.584 1.915l-.654 6.866a1.75 1.75 0 0 1-1.917 1.573Zm.91-9.547a.94.94 0 0 0-1.03.845l-.655 6.866a.946.946 0 0 0 1.882.184l.654-6.866a.946.946 0 0 0-.85-1.03Z"
      />
      <Path
        fill="#C49A6C"
        d="m45.951 11.684-1.02-.1a.835.835 0 0 0-.915.75l-.753 7.884a.84.84 0 0 0 .757.913l1.02.1a.835.835 0 0 0 .915-.75l.752-7.883a.84.84 0 0 0-.756-.914Z"
      />
      <Path
        fill="#844F02"
        d="M44.49 21.583a1.762 1.762 0 0 1-1.584-1.915l.655-6.867a1.75 1.75 0 0 1 1.916-1.572 1.762 1.762 0 0 1 1.585 1.915l-.655 6.866a1.75 1.75 0 0 1-1.917 1.573Zm.91-9.547a.94.94 0 0 0-1.03.844l-.654 6.867a.946.946 0 0 0 1.881.184l.655-6.866a.946.946 0 0 0-.852-1.03Z"
      />
      <Path
        fill="#C49A6C"
        d="m58.369 12.892-1.021-.1a.835.835 0 0 0-.915.75l-.752 7.884a.84.84 0 0 0 .756.914l1.02.1a.835.835 0 0 0 .916-.75l.752-7.884a.84.84 0 0 0-.756-.914Z"
      />
      <Path
        fill="#844F02"
        d="M56.907 22.791a1.762 1.762 0 0 1-1.584-1.915l.655-6.866a1.75 1.75 0 0 1 1.917-1.572 1.762 1.762 0 0 1 1.584 1.915l-.655 6.866a1.75 1.75 0 0 1-1.916 1.572Zm.91-9.547a.94.94 0 0 0-1.03.845l-.654 6.867a.946.946 0 0 0 1.881.184l.655-6.867a.946.946 0 0 0-.851-1.029Z"
      />
      <Path
        fill="#F15A29"
        d="m32.796 34.82-2.58-.253-.245 2.57 2.58.254.245-2.57Z"
      />
      <Path
        fill="#BCBEC0"
        d="m36.666 35.197-2.58-.253-.244 2.57 2.58.254.245-2.57ZM40.538 35.574l-2.58-.253-.245 2.57 2.58.253.245-2.57ZM44.412 35.951l-2.58-.253-.245 2.57 2.58.254.245-2.57ZM48.283 36.328l-2.58-.253-.245 2.57 2.58.253.245-2.57ZM52.154 36.705l-2.58-.253-.245 2.57 2.58.253.245-2.57Z"
      />
      <Path
        fill="#F15A29"
        d="m56.025 37.082-2.58-.253-.245 2.57 2.58.253.245-2.57Z"
      />
      <Path
        fill="#BCBEC0"
        d="m32.981 32.88-2.578-.253-.076.805 2.578.253.076-.805ZM36.852 33.257l-2.578-.253-.077.805 2.579.253.076-.805ZM40.723 33.633l-2.578-.252-.077.805 2.579.252.076-.805ZM44.598 34.01l-2.578-.252-.077.805 2.578.253.077-.806ZM48.469 34.387l-2.578-.252-.077.805 2.578.253.077-.806ZM52.34 34.764l-2.578-.252-.077.805 2.578.252.077-.805Z"
      />
      <Path
        fill="#F15A29"
        d="m56.21 35.141-2.577-.252-.077.805 2.578.252.077-.805Z"
      />
      <Path
        fill="#BCBEC0"
        d="m32.468 38.24-2.58-.252-.245 2.57 2.58.253.245-2.57ZM36.339 38.617l-2.58-.252-.245 2.57 2.58.253.245-2.57ZM40.21 38.994l-2.58-.253-.245 2.57 2.58.254.244-2.57ZM44.084 39.371l-2.58-.253-.245 2.571 2.58.253.245-2.57ZM47.955 39.748l-2.58-.253-.245 2.57 2.58.254.245-2.57ZM51.826 40.125l-2.58-.253-.245 2.57 2.58.254.245-2.57Z"
      />
      <Path
        fill="#F15A29"
        d="m55.697 40.502-2.58-.253-.245 2.57 2.58.253.245-2.57Z"
      />
      <Path
        fill="#BCBEC0"
        d="m32.14 41.66-2.58-.252-.245 2.57 2.58.253.244-2.57ZM36.01 42.038l-2.58-.253-.244 2.57 2.58.253.244-2.57ZM39.881 42.414l-2.58-.253-.244 2.571 2.58.253.244-2.57ZM43.756 42.792l-2.58-.253-.245 2.57 2.58.253.245-2.57ZM47.627 43.169l-2.58-.253-.245 2.57 2.58.253.245-2.57ZM51.498 43.545l-2.58-.253-.245 2.57 2.58.254.245-2.57Z"
      />
      <Path
        fill="#F15A29"
        d="m55.369 43.922-2.58-.253-.245 2.57 2.58.254.245-2.57Z"
      />
      <Path
        fill="#BCBEC0"
        d="m31.812 45.077-2.58-.252-.245 2.57 2.58.253.245-2.57ZM35.683 45.454l-2.58-.253-.245 2.57 2.58.254.245-2.57ZM39.554 45.831l-2.58-.253-.245 2.57 2.58.253.245-2.57ZM43.428 46.208l-2.58-.253-.244 2.57 2.58.254.244-2.57ZM47.3 46.585l-2.58-.253-.245 2.57 2.58.254.244-2.57ZM51.17 46.962l-2.58-.253-.245 2.57 2.58.253.245-2.57Z"
      />
      <Path
        fill="#F15A29"
        d="m55.041 47.339-2.58-.253-.245 2.57 2.58.253.245-2.57Z"
      />
      <Path
        fill="#BCBEC0"
        d="m31.474 48.599-2.58-.253-.245 2.57 2.58.254.245-2.571ZM35.345 48.976l-2.58-.253-.245 2.57 2.58.253.245-2.57ZM39.216 49.352l-2.58-.252-.245 2.57 2.58.253.245-2.57ZM40.796 28.898l.697.068-.186 1.946c-.083.876-.533 1.142-1.212 1.075a1.93 1.93 0 0 1-.502-.122l.13-.553c.095.042.216.076.35.089.295.029.489-.087.535-.565l.184-1.935.004-.003ZM42.574 31.381l-.296.767-.726-.071 1.237-2.985.918.09.664 3.171-.75-.074-.165-.811-.886-.087h.004Zm.834-.444-.13-.673c-.037-.19-.07-.423-.1-.608l-.01-.001c-.066.18-.131.407-.199.579l-.247.636.682.067h.003ZM44.782 32.391l.293-3.077.816.08.535 1.191c.154.344.299.745.404 1.105l.014.002c-.008-.41.02-.827.064-1.29l.086-.9.643.063-.294 3.077-.733-.072-.547-1.25c-.15-.347-.315-.765-.433-1.141l-.015-.002c-.021.414-.052.85-.099 1.348l-.088.926-.642-.063-.005.003Z"
      />
      <Path
        fill="#F1F2F2"
        d="m18.795 40.055 16.992-16.717H.41L17.4 40.055v21.147l-6.794 3.055c0 .618 3.353 1.12 7.49 1.12 4.135 0 7.488-.502 7.488-1.12l-6.793-3.055V40.055h.003Z"
      />
      <Path
        fill="#844F02"
        d="M18.096 65.784c-2.02 0-3.921-.12-5.356-.335-1.806-.269-2.541-.614-2.541-1.192 0-.16.095-.306.24-.371l6.554-2.946V40.226L.12 23.626a.404.404 0 0 1 .284-.695h35.382a.404.404 0 0 1 .284.694L19.2 40.226V60.94l6.554 2.946c.146.065.24.21.24.37 0 .579-.735.924-2.541 1.193-1.435.215-3.335.335-5.356.335Zm-6.688-1.444c.899.287 3.124.63 6.688.63 3.565 0 5.79-.343 6.689-.63l-6.16-2.77a.407.407 0 0 1-.241-.372V40.055c0-.109.043-.214.12-.29l16.29-16.024H1.398l16.286 16.027c.076.076.12.182.12.29v21.148c0 .16-.095.305-.24.37l-6.16 2.771.003-.007Z"
      />
      <Path fill="#B8C698" d="m8.914 29.684 9.182 9.037 9.186-9.037H8.914Z" />
      <Path
        fill="#844F02"
        d="M22.545 28.033a.27.27 0 0 1-.09-.018.25.25 0 0 1-.143-.328l3.605-9.19a.25.25 0 0 1 .328-.141c.13.05.196.2.142.327l-3.609 9.19a.25.25 0 0 1-.236.16h.003Z"
      />
      <Path
        fill="#F15A29"
        d="M24.584 29.702a2.627 2.627 0 0 1-3.579.985 2.62 2.62 0 0 1-.986-3.574 2.627 2.627 0 0 1 3.579-.986 2.62 2.62 0 0 1 .986 3.575Z"
      />
      <Path
        fill="#844F02"
        d="M22.301 25.785a2.62 2.62 0 1 1-.007 5.244 2.62 2.62 0 1 1 .007-5.244Zm0-.509c-1.125 0-2.17.607-2.727 1.586a3.132 3.132 0 0 0 1.176 4.265 3.15 3.15 0 0 0 1.544.408c1.125 0 2.17-.608 2.727-1.586a3.132 3.132 0 0 0-1.176-4.266 3.149 3.149 0 0 0-1.544-.407Z"
      />
      <Path
        fill="#F15A29"
        d="M23.598 26.127a2.632 2.632 0 0 0-.947-.32 2.625 2.625 0 0 1-1.86 4.746 2.627 2.627 0 0 0 3.794-.85 2.62 2.62 0 0 0-.987-3.576Z"
        opacity={0.5}
        style={{
          mixBlendMode: 'multiply',
        }}
      />
      <Path
        fill="#E0ADA6"
        d="M20.922 26.796c.123-.342.95-.694 1.27-.505.091.054.11.152.113.247 0 .13-.022.276-.124.418a.838.838 0 0 1-1.11.23c-.135-.088-.204-.23-.146-.39h-.003Z"
      />
      <Path
        fill="#E6E7E8"
        d="M18.096 38.72 3.77 24.88h9.849l4.478 13.84Z"
        style={{
          mixBlendMode: 'multiply',
        }}
      />
    </Svg>
  );
};

export default EventsIconSVG;
