/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

// import dependencies
import React, {Component} from 'react';
import {
  I18nManager,
  ImageBackground,
  Image,
  Platform,
  SafeAreaView,
  ScrollView,
  StatusBar,
  FlatList,
  Share,
  Text,
  Dimensions,
  View,
  useWindowDimensions,
} from 'react-native';
//import Swiper from 'react-native-swiper';
//import Toast, {DURATION} from 'react-native-easy-toast';
import Button from '../../components/buttons/Button';

// import utils
import getImgSource from '../../utils/getImgSource.js';
import locales from '../../config/locales';
import Divider from '../../components/divider/Divider';
// import components
import {Heading6, Heading5, SmallText} from '../../components/text/CustomText';
import Icon from '../../components/icon/Icon';
import TouchableItem from '../../components/TouchableItem';
import LinkButton from '../../components/buttons/LinkButton';
import {collectionData, docData} from 'rxfire/firestore';
import {map, tap, switchMap} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
// import colors
import Colors from '../../theme/colors';

import DeviceInfo from 'react-native-device-info';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  addProduct,
  emptyCart,
  setEvent,
  setReferral,
} from '../../reducers/cartactions';
import {AuthContext} from '../../contexts/context';
import ContainedButton from '../../components/buttons/ContainedButton';
import {db} from '../../services/firebase';
import firebase from 'firebase/compat/app';
import {MaterialCommunityIcons as MIcon} from '@expo/vector-icons';
import ActionProductCardHorizontal from '../../components/cards/ActionProductCardHorizontal';
import RenderHtml from 'react-native-render-html';

import InputModal from '../../components/modals/InputModal';
import InfoModal from '../../components/modals/InfoModal';
import TwoChoicesModal from '../../components/modals/TwoChoicesModal';
import getEnvVars from '../../config';
import {useTranslation} from 'react-i18next';

const {links} = getEnvVars();
// ProductA Config

const isRTL = I18nManager.isRTL;
const IOS = Platform.OS === 'ios';
const CLOSE_ICON = IOS ? 'ios-close' : 'md-close';
const CALENDAR_ICON = IOS ? 'ios-calendar' : 'md-calendar';
const PLACE_ICON = IOS ? 'store' : 'store';
const PIN_ICON = IOS ? 'ios-location' : 'md-location';
const STORE_ICON = IOS ? 'ios-restaurant' : 'md-restaurant';
const imgHolder = require('../../../assets/images/imgholder.png');
const slide3Img = require('../../../assets/images/20946002.jpg');

const DIVIDER_MARGIN_LEFT = 60;
import styles from './EventAStyles.js';

const deviceWidth = Dimensions.get('window').width;

const CHECKMARK_ICON =
  Platform.OS === 'ios'
    ? 'ios-checkmark-circle-outline'
    : 'md-checkmark-circle-outline';

const FAIL_ICON =
  Platform.OS === 'ios'
    ? 'ios-close-circle-outline'
    : 'md-close-circle-outline';
// EventA
class EventA extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    //let params = this.props.route.params;
    console.log(props);
    this.state = {
      show: undefined,
      audit: {},
      store: {},
      eventid: '',
      baseOs: '',
      isLoading: true,
      // Grouped Orders
      inputModalVisible: false,
      clientName: '',
      waitingForClienNameInStep: null,
      userTotalCart: 0,
      totalPriceShared: 0,
      isInActiveGroupedOrder: false,
      groupedOrderData: null,
      groupedOrderIsOwner: false,
      groupedOrderModalVisible: false,
      infoModalVisible: false,
      infoModalTitle: '',
      infoModalMessage: '',
      friendCartStatus: 'draft',
      showLoading: false,
    };
  }

  componentDidMount = async () => {
    //this.props.emptyCart();
    this.setState({isLoading: true});
    DeviceInfo.getBaseOs().then((baseOs) => {
      // "Windows", "Android" etc
      //console.log(baseOs);
      this.setState({baseOs: baseOs});
    });
    let params = this.props;
    if (window) {
      const queryParameters = new URLSearchParams(window.location.search);
      let ref = queryParameters.get('referralid');
      if (ref) this.props.setReferral(ref);
      console.log('referral', ref);
    }

    //console.log(this.props);
    if ('item' in params) {
      const audRef = db.collection('auditoriums').doc(params.item.auditoriumid);
      audRef.get().then((doc) => {
        var audit = doc.data();
        audit.id = doc.id;
        console.log(audit);
        this.setState({audit: audit});
        this.setState({show: params.item, isLoading: false});
      });
    } else if ('id' in params) {
      console.log('param id', params);
      console.log(this.props);
      if (params.id && params.id.trim() !== this.props.prefs.lastId) {
        this.props.emptyCart();
      }
      const eventRef = db.collection('events').doc(params.id);
      const doc = await eventRef.get();
      if (!doc.exists) {
        this.setState({isLoading: false});
        console.log('No such document exista!');
        this.setState({
          infoModalVisible: true,
          infoModalTitle: 'Evènement non trouvé',
          infoModalMessage: 'Essayez de chercher dans notre liste',
          infoModalIcon: FAIL_ICON,
        });
      } else {
        docData(eventRef, 'id')
          .pipe(
            switchMap((eventdoc) => {
              const ref = db
                .collection('organisers')
                .doc(eventdoc.organisation);
              return docData(ref, 'id').pipe(
                map((org) => ({org, ...eventdoc})),
              );
            }),
            switchMap((eventdoc) => {
              const ref = db
                .collection('events')
                .doc(params.id)
                .collection('tickets');
              return collectionData(ref, 'id').pipe(
                map((tickets) => ({tickets, ...eventdoc})),
              );
            }),
          )
          .subscribe((eventdoc) => {
            //console.log(eventdoc);
            let haspromocode = eventdoc.tickets.some(
              (p) => p.promoCode?.length > 0,
            );
            eventdoc.haspromocode = haspromocode;
            this.props.setEvent(eventdoc);
            this.setState({show: eventdoc, isLoading: false});
          });
      }
    }

    // Grouped Orders
    console.log('init grouped order');
    this.calculateUserOrderTotal();
    await this.handleGroupedOrderInitialStatus();
  };

  // Grouped Orders
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.cart !== this.props.cart) {
      //console.log("Cart updated")
      this.calculateUserOrderTotal();
      if (this.state.isInActiveGroupedOrder) {
        this.addProductsToGroupedOrder(this.props.cart.products);
      }
    }
  }

  goBack = () => {
    const {navigation} = this.props;
    navigation.goBack();
  };

  onPressAdd = (item) => () => {
    if (item) {
      const filteredProduct = this.props.cart.products.filter(
        (product) => item.id === product.id,
      );
      if (filteredProduct.length === 0) {
        const finalname = item.name;
        const soldQuantity = item.soldQuantity;
        let productToAdd = {
          id: item.id,
          name: finalname,
          description: item.description,
          price: item.price,
          photo: item.photo,
          isFree: item.isFree,
          isSellTimeLimit: item.isSellTimeLimit,
          maxQuantityPerOrder: item.maxQuantityPerOrder,
          soldQuantity: soldQuantity || 0,
          initialQuantity: item.initialQuantity,
          quantity: 1,
          total: item.price,
          promoCode: item.promoCode,
          categoryid: item.id,
          tier: 'ticket',
          eventid: item.event,
        };
        this.props.addProduct(productToAdd);
      }
    }
  };

  onPressRemove = (item) => () => {
    let {quantity} = item;
    quantity -= 1;

    const {popularProducts} = this.state;
    const index = popularProducts.indexOf(item);

    if (quantity < 0) {
      return;
    }
    popularProducts[index].quantity = quantity;

    this.setState({
      popularProducts: [...popularProducts],
    });
  };

  keyExtractor = (item, index) => item.id.toString();

  renderShowItem = ({item, index}) => {
    console.log('render item', item);
    let outDated = false;
    let comming = false;
    var today = new Date();

    // Convert the start and end dates to JavaScript Date objects
    var startDate = new Date(item.SellTimeLimit?.start?.seconds * 1000);
    var endDate = new Date(item.SellTimeLimit?.end?.seconds * 1000);

    // Check if today's date is between the start and end dates
    if (endDate <= today) {
      outDated = true;
    } else if (startDate >= today) {
      comming = true;
    }

    return (
      <ActionProductCardHorizontal
        //onPress={this.onPressItem(item)}
        onPressRemove={this.onPressRemove(item)}
        onPressAdd={this.onPressAdd(item)}
        swipeoutDisabled
        key={item.id}
        imageUri={item.photo}
        isFree={item.isFree}
        title={item.name}
        description={item.description}
        rating={item.rating}
        price={item.price}
        isAddDisabled={false}
        quantity={item.quantity}
        discountPercentage={item.discountPercentage}
        label={
          item.initialQuantity === item.soldQuantity
            ? 'soldout'
            : outDated
            ? 'expired'
            : comming
            ? 'comming'
            : item.label
        }
      />
    );
  };

  navigateTo = (screen, name, categorid) => () => {
    const {navigation} = this.props;
    //console.log(this.props);
    navigation.navigate(screen, {title: name, categoryid: categorid});
  };

  renderCartButton = () => {
    //console.log("this.props.cart");
    //console.log(this.props.cart.products);
    let cartText = 'Consulter le panier';
    if (this.state.isInActiveGroupedOrder) {
      cartText = 'Consulter la commande groupée';
    }
    if (this.props.cart.products.length > 0) {
      let total = this.props.cart.products.reduce(function (prev, cur) {
        if (__DEV__) {
          console.log('cur');
          console.log(cur);
        }

        return prev + (cur.price === -1 ? 0 : cur.total);
      }, 0);
      //total = total * 100;
      return (
        <Button
          onPress={this.navigateTo('Panier')}
          color={`#00b970`}
          title={`${locales.financial.currency.symbol} ${total.toFixed(
            2,
          )} - ${cartText}`}
          style={{paddingHorizontal: 50}}
          textStyle={{fontSize: 20}}
        />
      );
    } else {
      return;
    }
  };

  popInfoModal = (value) => {
    this.setState({infoModalVisible: value});
  };

  acceptInfoModal = (value) => {
    this.setState({infoModalVisible: value});
    const {navigation} = this.props;

    navigation.navigate('Events');
  };

  checkClientHasName = async (step, callback) => {
    const {user} = this.context;
    await db
      .collection('users')
      .doc(user.uid)
      .get()
      .then(async (docuser) => {
        const userData = docuser.data();
        if ('displayName' in userData) {
          console.log('User name is : ' + userData.displayName);
          this.setState({clientName: userData.displayName});
          callback();
        } else {
          this.setState({waitingForClienNameInStep: step});
          this.showClientNameInputModal(true);
        }
      });
  };

  showClientNameInputModal = (value) => {
    this.setState({inputModalVisible: value});
  };

  submitClientNameInputModal = async () => {
    const {user} = this.context;
    await db.collection('users').doc(user.uid).set(
      {
        displayName: this.state.clientName,
      },
      {merge: true},
    );
    this.setState({
      inputModalVisible: false,
    });
    if (this.state.waitingForClienNameInStep == 2)
      this.showGroupedOrderModal(true);
    else if (this.state.waitingForClienNameInStep == 1 && this.props.go)
      this.handleAcceptInviteToGroupedOrder(this.props.go, user.uid);
  };
  updateClientName = (value) => {
    this.setState({
      clientName: value,
      isSubmitClientNameInputModalDisabled: !(value.length > 0),
    });
  };

  // Grouped Orders
  // Check if User is in a Grouped Order or comes from an invitation
  handleGroupedOrderInitialStatus = async () => {
    const {user} = this.context;
    console.log('handleGroupedOrderInitialStatus');
    console.log(user);
    const params = this.props;
    await db
      .collection('users')
      .doc(user.uid)
      .get()
      .then(async (docuser) => {
        const userData = docuser.data();
        console.log(userData);
        console.log(params);
        console.log(userData.activeGroupedOrder);
        console.log(docuser.exists);
        console.log(docuser.exists && userData.activeGroupedOrder);
        // Check if there is an active Grouped Order in User
        if (docuser.exists && userData.activeGroupedOrder) {
          // We verify that the user exists in the Grouped Order, and if so, as Owner or Friend
          await this.getUserStatusInGroupedOrder(
            userData.activeGroupedOrder,
            user.uid,
          ).then(async (userRole) => {
            if (userRole == 'owner' || userRole == 'friend') {
              // We check the the Grouped Order has not already been paid (after checkout)
              const statusOrder = await this.handleFinishedGroupedOrder(
                userData.activeGroupedOrder,
              );
              if (statusOrder) {
                console.log('Ending handleGroupedOrderInitialStatus');
              } else {
                // All good, we save the Grouped Order locally
                this.setState({
                  isInActiveGroupedOrder: userData.activeGroupedOrder,
                });
                console.log(
                  'The User is already in an active Grouped Order as : ' +
                    userRole,
                );
                console.log('User ID : ' + user.uid);
                console.log(
                  'Grouped Order ID : ' + userData.activeGroupedOrder,
                );
                // Add products in Store to Grouped Orders
                if (this.props.cart.products.length > 0) {
                  console.log('Adding Cart from Redux Store');
                  await this.addProductsToGroupedOrder(
                    this.props.cart.products,
                  );
                }
                // Setup a snapshot to follow changes
                await this.setupSnaphotGroupedOrder(
                  userData.activeGroupedOrder,
                );
              }
            } else if (userRole == 'nogo' || userRole == 'none') {
              // The Grouped Order does not exist
              console.log('Removing invalid Grouped Order from User');
              // Remove Grouped Order from User
              await db.collection('users').doc(user.uid).set(
                {
                  activeGroupedOrder: null,
                },
                {merge: true},
              );
            } else console.log('Unhandled response.');
          });
          // If the user is not in an active Grouped Order, but came from an URL with Grouped Order invitation (Dynamic Link)
          // Handle case where user is in active Grouped Order but also opened an invitation
        } else if (params && params.go) {
          // We verify that the user exists in the Grouped Order, and if so, as Owner or Friend
          await this.getUserStatusInGroupedOrder(params.go, user.uid).then(
            async (userRole) => {
              if (userRole == 'none') {
                this.checkClientHasName(
                  1,
                  async () =>
                    await this.handleAcceptInviteToGroupedOrder(
                      params.go,
                      user.uid,
                    ),
                );
              } else if (userRole == 'owner' || userRole == 'friend') {
                // This shouldn't happen
                console.log(
                  'Unhandled case: The User is in the Grouped Order but no Grouped Order inthe User',
                );
              } else
                console.log('The Grouped Order from invitation is invalid.');
            },
          );
          // Otherwise, nothing happens
        } else {
          console.log('is not in go');
          this.setState({isInActiveGroupedOrder: false});
        }
      });
  };

  handleAcceptInviteToGroupedOrder = async (groupedOrderId, userId) => {
    // We push the new user
    console.log(
      'User accepted invitation with Grouped Order : ' + groupedOrderId,
    );
    // TODO: The following should be handled in backend
    await this.addFriendToGroupedOrder(userId, groupedOrderId);
    // Add it locally
    this.setState({isInActiveGroupedOrder: groupedOrderId});
    // Setup a snapshot to follow changes
    await this.setupSnaphotGroupedOrder(groupedOrderId);
  };

  // Setup onSnapshot on the Grouped Order document
  setupSnaphotGroupedOrder = async (goId) => {
    this.unsubscribeListener = db
      .collection('groupedorders')
      .doc(goId)
      .onSnapshot(async () => {
        console.log('Received an onSnapshot on groupedOrder in MenuA.js');
        if (this.state.isInActiveGroupedOrder) {
          await this.getLastGroupedOrderInfo(
            this.state.isInActiveGroupedOrder,
          ).then(async (dataGO) => {
            if (!dataGO) return;
            // We check the the Grouped Order has not already been paid (for friends)
            if (dataGO.status === 'payed' && !this.state.groupedOrderIsOwner) {
              console.log('This Grouped Order has been paid by owner');
              this.setState({
                infoModalVisible: true,
                infoModalTitle: 'Félicitations',
                infoModalMessage:
                  'Ce panier a été validé et sera livré sous peu',
              });
              await this.removeOrderFromUser();
              this.props.emptyCart();
              return this.unsubscribeListener();
            }
            // Check if the Friend has been removed from Grouped Order
            const {user} = this.context;
            if (
              dataGO.owner.userId != user.uid &&
              !dataGO.users.some((obj) => obj.userId == user.uid) &&
              this.state.isInActiveGroupedOrder
            ) {
              console.log(
                'MenuA: User is no more a Friend in the Grouped Order',
              );
              await this.handleLeaveGoupedOrder();
            }
          });
        } else {
          // console.log("Unsubscribing Listener")
          // this.unsubscribeListener();
        }
      });
  };

  // Add the current user as a Friend in a Grouped Order (case whenreceives invitation but not owner)
  addFriendToGroupedOrder = async (userId, goId) => {
    // Add Grouped Order to User
    await db.collection('users').doc(userId).set(
      {
        activeGroupedOrder: goId,
      },
      {merge: true},
    );
    // Push the User as a Friend
    const actualUser = {
      userId: userId,
      userName: this.state.clientName,
      acceptedAt: firebase.firestore.Timestamp.fromDate(new Date()),
      products: [...this.props.cart.products],
      totalPrice: this.state.userTotalCart,
      status: 'draft',
    };
    const users = this.state.groupedOrderData.users;
    db.collection('groupedorders')
      .doc(goId)
      .set(
        {
          users: [...users, actualUser],
        },
        {merge: true},
      );
  };

  getLastGroupedOrderInfo = async (goId) => {
    // const { user } = this.context;
    // TODO: This should be handled in backend
    return await db
      .collection('groupedorders')
      .doc(goId)
      .get()
      .then(async (docGO) => {
        const dataGO = docGO.data();
        if (dataGO) {
          // Save it locally
          this.setState({groupedOrderData: dataGO});
          // Recalculate Total
          await this.calculateTotalPriceFriends(dataGO);
          // Check status of Cart for Friend
          if (!this.state.groupedOrderIsOwner)
            this.checkCartStatusforFriends(dataGO);
        } else {
          console.log('The Grouped Order no longer exists');
          this.setState({
            isInActiveGroupedOrder: false,
            groupedOrderData: null,
          });
        }
        return dataGO;
      });
  };
  getUserStatusInGroupedOrder = async (goId, userId) => {
    return await this.getLastGroupedOrderInfo(goId).then((dataGO) => {
      if (dataGO) {
        const {user} = this.context;
        // Check if user is Owner
        if (dataGO.owner.userId == user.uid) {
          this.setState({groupedOrderIsOwner: true});
          return 'owner';
        } else {
          this.setState({groupedOrderIsOwner: false});
          // Check if exists in Friends
          const users = dataGO.users;
          if (users.length > 0 && users.some((obj) => obj.userId === userId)) {
            return 'friend';
          } else {
            return 'none';
          }
        }
      } else return 'nogo';
    });
  };
  handleCreateGroupedOrder = async (paymentType) => {
    const {user} = this.context;
    const products = JSON.parse(JSON.stringify(this.props.cart.products)); // Deep clone needed
    // Remove popup and Show Loader
    this.showGroupedOrderModal(false);
    this.setState({showLoading: true});
    // TODO: The following checks should be handled in backend
    // Add the Grouped Order in groupedorders collection in Firebase
    const groupedOrderParams = {
      owner: {
        userId: user.uid,
        products,
      },
      reservationId: '',
      eventId: this.state.show.id,
      paymentType,
      status: 'draft',
      users: [],
      createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
    };
    console.log(groupedOrderParams);
    // Save it locally
    this.setState({
      groupedOrderData: groupedOrderParams,
      groupedOrderIsOwner: true,
    });
    // Push it to Firestore
    await db
      .collection('groupedorders')
      .add(groupedOrderParams)
      .then(async (docGO) => {
        // Setup a snapshot to follow changes
        await this.setupSnaphotGroupedOrder(docGO.id);
        // Add the active Grouped Order to the User
        await db.collection('users').doc(user.uid).set(
          {
            activeGroupedOrder: docGO.id,
          },
          {merge: true},
        );
        // Save the ID for later use
        this.setState({isInActiveGroupedOrder: docGO.id});
        console.log('User ID : ' + user.uid);
        console.log('Grouped Order ID : ' + docGO.id);
        // TODO: Generate the dynamic link
        const link =
          links.webappurl +
          'shop?go=' +
          docGO.id +
          '&s=' +
          this.state.show.id +
          '&t=event';
        console.log('Link to be shared : ' + link);
        // Trigger the native share
        this.shareGroupedOrder(user.displayName, link, paymentType);
        // this.setState({
        //   infoModalVisible: true,
        //   infoModalTitle: "Partager avec mes amis",
        //   infoModalMessage: "Lien: " + link
        // });
        this.setState({showLoading: false});
      });
  };
  handleFinishedGroupedOrder = async (goId) => {
    return await db
      .collection('groupedorders')
      .doc(goId)
      .get()
      .then(async (docGO) => {
        const dataGO = docGO.data();
        if (!dataGO) return false;
        if (dataGO.status === 'payed') {
          console.log('This Grouped Order has been paid');
          // If owner, we remove data from Cart and local/dbb data from Grouped Order in User
          this.props.emptyCart();
          await this.removeOrderFromUser();
          return true;
        } else return false;
      });
  };
  removeOrderFromUser = async () => {
    const {user} = this.context;
    await db
      .collection('users')
      .doc(user.uid)
      .set(
        {
          activeGroupedOrder: null,
        },
        {merge: true},
      )
      .then(() => {
        console.log('Grouped Order successfully deleted from user');
        this.recycleGroupedOrder();
      });
  };
  handleLeaveGoupedOrder = async () => {
    this.setState({showLoading: true});
    // Remove the Snapshot listener
    this.unsubscribeListener();
    // If the User is not the owner, we remove him
    const {user} = this.context;
    if (!this.state.groupedOrderIsOwner) {
      console.log(
        'The user is not the owner and is asked to leave Grouped Order',
      );
      // TODO: The following should be handled in backend
      await db
        .collection('groupedorders')
        .doc(this.state.isInActiveGroupedOrder)
        .get()
        .then(async (docGO) => {
          const dataGO = docGO.data();
          let users = dataGO.users;
          users = users.filter((usr) => usr.userId != user.uid);
          // TODO: Must check that the Grouped Order still exists
          await db
            .collection('groupedorders')
            .doc(this.state.isInActiveGroupedOrder)
            .set({users}, {merge: true})
            .then(async () => {
              // Remove Grouped Order from current user
              await this.removeOrderFromUser();
            });
        });
      return;
    }
    // If the owner wants to get out
    await this.getLastGroupedOrderInfo(this.state.isInActiveGroupedOrder).then(
      async () => {
        if (
          this.state.groupedOrderData.users &&
          this.state.groupedOrderData.users.length == 0
        ) {
          // Delete the Grouped Order
          await db
            .collection('groupedorders')
            .doc(this.state.isInActiveGroupedOrder)
            .delete()
            .then(async () => {
              // Remove Grouped Order from current user
              await this.removeOrderFromUser();
              this.setState({showLoading: false});
            })
            .catch((error) => {
              this.setState({showLoading: false});
              console.error('Error removing Grouped Order: ', error);
            });
        } else {
          // TODO: Handle case where Grouped Order has users
          this.setState({showLoading: false});
          console.log('You cannot remove a Grouped Order while members are in');
        }
      },
    );
  };
  // Function to recycle the Grouped Order
  recycleGroupedOrder = () => {
    this.setState({
      // userTotalCart: 0,
      totalPriceShared: 0,
      isInActiveGroupedOrder: false,
      groupedOrderData: null,
      groupedOrderIsOwner: false,
      groupedOrderLink: '',
      friendCartStatus: 'draft',
      showLoading: false,
    });
  };
  addProductsToGroupedOrder = async (products) => {
    // TODO: The following should be handled in backend
    if (this.state.groupedOrderIsOwner) {
      let ownerData = this.state.groupedOrderData.owner;
      ownerData.products = [...products];
      await db
        .collection('groupedorders')
        .doc(this.state.isInActiveGroupedOrder)
        .set({owner: JSON.parse(JSON.stringify(ownerData))}, {merge: true});
    } else {
      const {user} = this.context;
      let usersData = this.state.groupedOrderData.users;
      const indexUser = usersData.findIndex((e) => e.userId == user.uid);
      usersData[indexUser].products = [...products];
      // Calculate Total price
      let totalPrice = 0;
      products.forEach((product, i) => {
        totalPrice += parseFloat(product.total);
      });
      usersData[indexUser].totalPrice = totalPrice;
      await db
        .collection('groupedorders')
        .doc(this.state.isInActiveGroupedOrder)
        .set({users: JSON.parse(JSON.stringify(usersData))}, {merge: true});
    }
  };
  calculateUserOrderTotal = () => {
    let total =
      this.props.cart.products.length > 0
        ? this.props.cart.products.reduce(function (prev, cur) {
            return prev + cur.price * cur.quantity;
          }, 0)
        : 0;
    this.setState({userTotalCart: total});
  };
  calculateTotalPriceFriends = async (dataGO) => {
    // Get the amount to be added
    let totalPrice = 0;
    dataGO.users &&
      dataGO.users.forEach((user, i) => {
        totalPrice += parseFloat(user.totalPrice);
        // TODO: Handle what to show depending on what will pay
        // if (user.status == "confirmed") totalConfirmedPrice += user.total;
      });
    this.setState({totalPriceShared: totalPrice});
  };
  checkCartStatusforFriends = (dataGO) => {
    const {user} = this.context;
    const userData =
      dataGO.users[dataGO.users.findIndex((usr) => usr.userId == user.uid)];
    if (userData) {
      this.setState({friendCartStatus: userData.status});
    }
  };

  shareGroupedOrder = async (user, link, paymentType) => {
    console.log(this.state.clientName);
    console.log(link);
    console.log(paymentType);
    try {
      let message =
        'Vous avez été invité à partager une commande avec1 ' +
        this.state.clientName +
        '.';
      message = message.concat(' ', 'Lien pour procéder : ' + link);
      console.log(message);
      const result = await Share.share({
        title: message,
        /*message: "Le règlement de cette commande est " 
                  + (paymentType == 'shared') ? ("pris en charge par chaque personne séparément.") : ("pris en charge totalement par " + user + ".")
                  + "Lien pour procéder : " + link*/
        message: message,
      });
      /*if (result.action === Share.sharedAction) {
        if (result.activityType) {
          console.log("shared with activity type of result.activityType")
        } else {
          console.log("shared")
        }
      } else if (result.action === Share.dismissedAction) {
        console.log("dismissed")
      }*/
    } catch (error) {
      alert(error.message);
    }
  };

  renderGroupedOrderButton = () => {
    if (this.state.isInActiveGroupedOrder) {
      return (
        <ContainedButton
          onPress={this.navigateTo('Panier')}
          color={Colors.surface}
          iconName="people"
          iconColor="#00b970"
          title="Afficher la commande groupée"
          titleColor="#00b970"
        />
      );
    } else {
      return (
        <ContainedButton
          onPress={() =>
            this.checkClientHasName(2, () => this.showGroupedOrderModal(true))
          }
          color={Colors.surface}
          iconName="person-add"
          iconColor="#00b970"
          title="Commande groupée"
          titleColor="#00b970"
        />
      );
    }
  };

  showGroupedOrderModal = (value) => {
    this.setState({groupedOrderModalVisible: value});
  };

  render() {
    const {
      show,
      store,
      validStore,
      isLoading,
      baseOs,
      showLoading,
      groupedOrderModalVisible,
      infoModalVisible,
      isInActiveGroupedOrder,
      inputModalVisible,
    } = this.state;
    console.log('show.tickets', show?.tickets);

    if (__DEV__) {
      const {user} = this.context;
      console.log(user.uid);
    }

    if (isLoading) {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}></View>
      );
    }
    if (show === undefined) {
      console.log('hi');
      return (
        <SafeAreaView style={styles.screenContainer}>
          <StatusBar
            backgroundColor={Colors.statusBarColor}
            barStyle="dark-content"
          />
          {baseOs === 'Android' && (
            <AppBanner
              appInfo={appInfo}
              position="top"
              onClose={() => {
                console.log('onClose called');
              }}
              hidden={false}
              style={{
                zIndex: 5,
              }}
            />
          )}
          {baseOs.toLowerCase() === 'ios' && (
            <AppBanner
              appInfo={appInfoIos}
              position="top"
              onClose={() => {
                console.log('onClose called');
              }}
              hidden={false}
              style={{
                zIndex: 10,
              }}
            />
          )}

          <View style={styles.slide}>
            <Image source={slide3Img} style={styles.slideImg} />
            <Heading6 style={styles.title}>
              Avez vous bien scanné un Qr Code Instadrink?.
            </Heading6>
          </View>
          <InfoModal
            title={this.state.infoModalTitle.toUpperCase()}
            message={this.state.infoModalMessage}
            iconName={this.state.infoModalIcon}
            onButtonPress={() => this.acceptInfoModal(false)}
            onRequestClose={() => this.popInfoModal(false)}
            buttonTitle={'Ok'.toUpperCase()}
            visible={infoModalVisible}
          />
        </SafeAreaView>
      );
    }
    const source = {
      html: show.description,
    };

    var images = [];
    if (show) {
      images = [show.coverImage];
    }

    return (
      <SafeAreaView style={styles.screenContainer}>
        <StatusBar
          backgroundColor={Colors.statusBarColor}
          barStyle="dark-content"
        />

        <ScrollView>
          {images?.length > 0 && (
            <View style={styles.swiperContainer}>
              <ImageBackground
                defaultSource={imgHolder}
                source={getImgSource(images[0])}
                style={styles.productImg}></ImageBackground>

              {/*<View
                style={[
                  styles.topButton,
                  styles.right,
                  favorite && styles.favorite,
                ]}>
                <TouchableItem onPress={this.onPressAddToFavorites} borderless>
                  <View style={styles.buttonIconContainer}>
                    <Icon
                      name={FAVORITE_ICON}
                      size={22}
                      color={
                        favorite ? Colors.onSecondaryColor : Colors.secondaryText
                      }
                    />
                  </View>
                </TouchableItem>
                    </View>*/}
            </View>
          )}
          <View style={styles.descriptionContainer}>
            <View style={styles.productTitleContainer}>
              <Heading5 style={styles.productTitle}>{show.title}</Heading5>
            </View>
          </View>
          {
            <View
              style={{
                paddingVertical: 16,
                paddingHorizontal: 16,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}>
              <Text>Organisé par {show.org?.name}</Text>
            </View>
          }
          <Divider />
          <View style={styles.goContainer}>
            {this.renderGroupedOrderButton()}
          </View>

          <View style={{padding: 10, flex: 1, flexDirection: 'row'}}>
            <View style={{flex: 1}}>
              <Icon name={CALENDAR_ICON} size={30} />
            </View>
            <View style={{flex: 6}}>
              <Text style={{fontSize: 18}}>
                {' '}
                {show?.startDate?.toDate().toLocaleDateString()}
              </Text>
            </View>
          </View>
          <View style={{padding: 10, flex: 1, flexDirection: 'row'}}>
            <View style={{flex: 1}}>
              <MIcon
                name={PLACE_ICON}
                style={{
                  alignSelf: 'center',
                  textAlign: 'center',
                }}
                size={30}
              />
            </View>
            <View style={{flex: 6}}>
              <Text style={{fontSize: 18}}> {show.location}</Text>
            </View>
          </View>
          <View style={{padding: 10, flex: 1, flexDirection: 'row'}}>
            <View style={{flex: 1}}>
              <Icon name={PIN_ICON} size={30} />
            </View>
            <View style={{flex: 6}}>
              <Text style={{fontSize: 18}}> {show.address}</Text>
            </View>
          </View>
          <Divider />

          <View style={styles.descriptionContainer}>
            <View style={styles.productTitleContainer}>
              <Heading5 style={styles.productTitle}>A propos</Heading5>
            </View>
          </View>

          <View style={styles.descriptionContainer}>
            <RenderHtml contentWidth={deviceWidth} source={source} />
          </View>
          <View style={styles.container}>
            <FlatList
              data={show.tickets}
              showsHorizontalScrollIndicator={false}
              alwaysBounceHorizontal={false}
              keyExtractor={this.keyExtractor}
              renderItem={this.renderShowItem}
              contentContainerStyle={styles.contentContainerStyle}
            />
          </View>

          <View style={styles.bottomButtonContainer}>
            {this.renderCartButton()}
          </View>
          <TwoChoicesModal
            title="Partage de panier"
            message="Souhaitez vous partager l'addition ou la prendre en charge totalement ?"
            input={(name) => this.handleInputDisplayName(name)}
            iconName={CHECKMARK_ICON}
            visible={groupedOrderModalVisible}
            button1Title={'Partager'}
            onButton1Press={() => this.handleCreateGroupedOrder('shared')}
            button2Title={'Prendre en charge'}
            onButton2Press={() => this.handleCreateGroupedOrder('covered')}
            onRequestClose={() => this.showGroupedOrderModal(false)}
          />
          <InfoModal
            title={this.state.infoModalTitle}
            message={this.state.infoModalMessage}
            iconName={CHECKMARK_ICON}
            visible={infoModalVisible}
            iconColor={Colors.primaryColor}
            buttonTitle="Compris"
            onButtonPress={() => this.showInfoModal(false)}
            onRequestClose={() => this.showInfoModal(false)}
          />
          <InputModal
            title="Commande groupée"
            message="Vous êtes invité à joindre une commande groupée, comment on doit vous appeler?"
            inputDefaultValue={this.state.clientName}
            onChangeText={this.updateClientName}
            onButtonPress={() => this.submitClientNameInputModal(false)}
            onRequestClose={() => this.showClientNameInputModal(false)}
            buttonTitle={'Ok'.toUpperCase()}
            onClosePress={() => this.showClientNameInputModal(false)}
            visible={inputModalVisible}
          />
          <InfoModal
            title={this.state.infoModalTitle.toUpperCase()}
            message={this.state.infoModalMessage}
            iconName={this.state.infoModalIcon}
            onButtonPress={() => this.acceptInfoModal(false)}
            onRequestClose={() => this.popInfoModal(false)}
            buttonTitle={'Ok'.toUpperCase()}
            visible={infoModalVisible}
          />
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state) => {
  const {cart, prefs, event} = state;
  return {cart, prefs, event};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addProduct,
      emptyCart,
      setEvent,
      setReferral,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EventA);
