import React from 'react';
import TabBadgeIcon from '../components/navigation/TabBadgeIcon';
import { connect } from 'react-redux';
const TabOrdIcon = (props) => {
    return (
      <TabBadgeIcon
        name={`receipt`}
        badgeCount={props.count}
        size={props.size}
        {...props}
      />
    )
  }
  const mapStateToProps = (state) => {
    const { cart } = state
    return { cart }
  };
  
export default connect(mapStateToProps, null)(TabOrdIcon)