import React from "react"
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';

const GroupSVG = () => {
return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
    <Path d="M6.5 13C9.81371 13 12.5 10.3137 12.5 7C12.5 3.68629 9.81371 1 6.5 1C3.18629 1 0.5 3.68629 0.5 7C0.5 10.3137 3.18629 13 6.5 13Z" stroke="#77838F" strokeWidth="0.75"/>
    <Path d="M7.67174 4.63199C7.24427 4.3923 6.75944 4.2662 6.26597 4.26636C5.7725 4.26652 5.28776 4.39294 4.86046 4.63291C4.43315 4.87289 4.07833 5.21797 3.83164 5.63349C3.58495 6.049 3.45508 6.52032 3.45508 7.00009C3.45508 7.47985 3.58495 7.95117 3.83164 8.36668C4.07833 8.7822 4.43315 9.12728 4.86046 9.36726C5.28776 9.60723 5.7725 9.73365 6.26597 9.73381C6.75944 9.73398 7.24427 9.60787 7.67174 9.36818M2.98535 6.31643H5.32855M2.98535 7.68329H5.32855" stroke="#77838F" strokeWidth="0.75" strokeLinecap="round"/>
  </Svg>
)
}
export default GroupSVG