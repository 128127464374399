/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

// import node modules
import React, {Component} from 'react';
import {
  SafeAreaView,
  StatusBar,
  StyleSheet,
  Text,
  Image,
  View,
  ImageBackground,
  ScrollView,
} from 'react-native';
import {WebView} from 'react-native-webview';
import axios from 'axios';
import getEnvVars from '../../config';
const {paiement, api} = getEnvVars();
import Colors from '../../theme/colors';
import {connect} from 'react-redux';
import {setStore} from '../../reducers/cartactions';
import {bindActionCreators} from 'redux';
import AppLoading from 'expo-app-loading';
const imgHolder = require('../../../assets/images/imgholder.png');
// OrdersA Styles
const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  container: {
    flex: 1,
    backgroundColor: '#efefef',
  },
  itemContainer: {
    marginVertical: 4,
    backgroundColor: Colors.background,
  },
  item: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
    height: 36,
  },
  productsContainer: {
    paddingVertical: 8,
  },
  productImg: {
    width: '100%',
    height: 236,
    resizeMode: 'cover',
  },
  bottomOverlay: {flex: 1},
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 16,
    paddingHorizontal: 16,
    paddingBottom: 12,
  },
  titleText: {
    fontWeight: '700',
  },
  bottomButtonContainer: {
    width: '100%',
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
});

// OrdersA
class Receipt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: {},
      receipt_url: '',
      isLoading: false,
    };
  }

  componentDidMount = () => {
    this.setState({isLoading: true});
    const sessionid = this.props.route.params.sessionid;
    axios({
      method: 'GET',
      url: `${api.receipt.get}?sessionid=${sessionid}`,
    })
      .then(async (response) => {
        console.log('stripe response');
        console.log(response.data);

        //console.log(response.data[0]);

        this.setState({receipt_url: response.data, isLoading: false});
      })
      .catch((e) => {
        this.setState({isLoading: false});
        console.log(e);
      });
  };

  goBack = () => {
    const {navigation} = this.props;
    navigation.goBack();
  };

  navigateTo = (screen) => () => {
    const {navigation} = this.props;
    if (screen === 'Rating') {
      navigation.navigate(screen, {id: this.state.order.store.id});
    }
    navigation.navigate(screen);
  };

  render() {
    const {order} = this.state;
    if (this.state.isLoading) {
      //throw new Error('I crashed!');
      return (
        <AppLoading onFinish={() => console.log('')} onError={console.warn} />
      );
    }
    console.log('this order');
    console.log(order);
    return (
      <SafeAreaView style={styles.screenContainer}>
        <StatusBar
          backgroundColor={Colors.statusBarColor}
          barStyle="dark-content"
        />

        <View style={styles.container}>
          <WebView
            style={styles.container}
            source={{uri: this.state.receipt_url}}
          />
          <iframe src={this.state.receipt_url} height={400} width={300} />
        </View>
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state) => {
  const {store} = state;
  return {store};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setStore,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Receipt);
