import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
const CartSVG = (props) => {
  const {style, stroke, ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 26 25"
      style={style}
      {...restProps}>
      <Path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M5.378 10.448A1.9 1.9 0 0 1 7.272 8.7h11.455a1.9 1.9 0 0 1 1.895 1.748l.763 9.5A1.9 1.9 0 0 1 19.49 22H6.51a1.901 1.901 0 0 1-1.894-2.052l.763-9.5Z"
      />
      <Path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.8 11.55V6.8a3.8 3.8 0 0 0-7.6 0v4.75"
      />
    </Svg>
  );
};
export default CartSVG;
