
export default {
    firebaseConfig : {
        dev: {
            apiKey: "AIzaSyBQ_sZbDCo2BPdXzL4Oj9ALlxMnXB_MMNk",
            authDomain: "instadrink-47f0f.firebaseapp.com",
            projectId: "instadrink-47f0f",
            storageBucket: "instadrink-47f0f.appspot.com",
            messagingSenderId: "138073739996",
            appId: "1:138073739996:web:84d834292c5b2e8614da19",
            measurementId: "G-MVQ7F6WYWQ"
        },
        uat: {
            apiKey: "AIzaSyBQ_sZbDCo2BPdXzL4Oj9ALlxMnXB_MMNk",
            authDomain: "instadrink-47f0f.firebaseapp.com",
            projectId: "instadrink-47f0f",
            storageBucket: "instadrink-47f0f.appspot.com",
            messagingSenderId: "138073739996",
            appId: "1:138073739996:web:84d834292c5b2e8614da19",
            measurementId: "G-MVQ7F6WYWQ"
        },
        hom: {
            apiKey: "AIzaSyBBoMYDHQuqgmebORavxZD0fIPjtMDdE54",
            authDomain: "instadrinkuat.firebaseapp.com",
            projectId: "instadrinkuat",
            storageBucket: "instadrinkuat.appspot.com",
            messagingSenderId: "946898901720",
            appId: "1:946898901720:web:5a09225ffb3dd90d3c95ad",
        },
        prod: {
            apiKey: "AIzaSyDfb1H_l5hEc_IY80ikV7Lwrrt5J4d6qx8",
            authDomain: "instadrink-eecf1.firebaseapp.com",
            projectId: "instadrink-eecf1",
            storageBucket: "instadrink-eecf1.appspot.com",
            messagingSenderId: "256281800493",
            appId: "1:256281800493:web:0053e8e64de88e3625eda4",
            measurementId: "G-4WG2SB2LB6"
        }
        
    }
}