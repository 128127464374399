export const addProduct = (cartIndex) => ({
  type: 'ADD_PRODUCT',
  payload: cartIndex,
});
export const removeProductByID = (cartIndex) => ({
  type: 'REMOVE_PRODUCT_BY_ID',
  payload: cartIndex,
});
export const addCommandeGroupe = (cartIndex) => ({
  type: 'ADD_COMMANDE_GROUPE',
  payload: cartIndex,
});
export const removeCommandeGroupe = (cartIndex) => ({
  type: 'REMOVE_COMMANDE_GROUPE',
  payload: cartIndex,
});

export const addPromoCode = (cartIndex) => ({
  type: 'ADD_PROMOCODE',
  payload: cartIndex,
});

export const remPromoCode = (cartIndex) => ({
  type: 'REM_PROMOCODE',
  payload: cartIndex,
});

export const emptyCart = (cartIndex) => ({
  type: 'EMPTY_CART',
  payload: cartIndex,
});

export const setStore = (store) => ({
  type: 'SET_STORE',
  payload: store,
});

export const setEvent = (store) => ({
  type: 'SET_EVENT',
  payload: store,
});

export const setReferral = (store) => ({
  type: 'SET_REFERRAL',
  payload: store,
});

export const setPickMode = (cartIndex) => ({
  type: 'SET_PICKMODE',
  payload: cartIndex,
});

export const setTierMode = (tierIndex) => ({
  type: 'SET_TIERMODE',
  payload: tierIndex,
});

export const setLastId = (lastIndex) => ({
  type: 'SET_LASTID',
  payload: lastIndex,
});

export const syncCart = (newproducts) => ({
  type: 'SYNC_CART',
  payload: newproducts,
});
