import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {persistStore, persistCombineReducers} from 'redux-persist';

const storePersistConfig = {
  key: 'store',
  storage: AsyncStorage,
  //whitelist: ['cart', 'store']
};

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['cart', 'store', 'events', 'prefs'],
};

const cartPersistConfig = {
  key: 'cart',
  storage: AsyncStorage,
  //whitelist: ['cart', 'store']
};

const INITIAL_STATE = {
  products: [],
};
const INITIAL_EVENT_STATE = {};
const INITIAL_REFERRAL_STATE = {
  referralID: '',
};

const prefsReducer = (
  state = {pickMode: 'inhouse', tierMode: 'food'},
  action,
) => {
  switch (action.type) {
    case 'SET_PICKMODE':
      const pickState = {...state, pickMode: action.payload};
      return pickState;
    case 'SET_TIERMODE':
      const tierState = {...state, tierMode: action.payload};
      return tierState;
    case 'SET_LASTID':
      const lastidState = {...state, lastId: action.payload};
      return lastidState;
    default:
      return state;
  }
};

const storeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_STORE':
      return {...action.payload};
    default:
      return state;
  }
};

const referralIDReducer = (state = INITIAL_REFERRAL_STATE, action) => {
  switch (action.type) {
    case 'SET_REFERRAL':
      const newState = {
        referralID: action.payload,
      };
      return newState;
    default:
      return state;
  }
};

const eventReducer = (state = INITIAL_EVENT_STATE, action) => {
  switch (action.type) {
    case 'SET_EVENT':
      const newState = {
        id: action.payload.id,
        coverImage: action.payload.coverImage,
        title: action.payload.title,
        haspromocode: action.payload.haspromocode,
        address: action.payload.address,
        startDate: action.payload.startDate,
        organisation: action.payload.organisation,
        tickets: action.payload.tickets,
        tickets: action.payload.tickets,
        participantInfo: action.payload.participantInfo ?? [],
        participantInfo: action.payload.participantInfo ?? [],
      };
      return newState;
    default:
      return state;
  }
};
const commandeGroupeReducer = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_COMMANDE_GROUPE':
      return action.payload;
    case 'REMOVE_COMMANDE_GROUPE':
      return {};
    default:
      return state;
  }
};

const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'REMOVE_PRODUCT_BY_ID':
      const newStateData = state.products.filter(
        (obj) => obj.id !== action.payload.id,
      );
      return {...state, products: newStateData};
    case 'ADD_PRODUCT':
      // Pulls current and possible out of previous state
      // We do not want to alter state directly in case
      // another action is altering it at the same time
      //alert('entering here');
      const {products} = state;
      var prdIndex = products?.findIndex((obj) => obj.id == action.payload.id);
      let newproducts = [...products];

      if (prdIndex === -1) {
        newproducts.push(action.payload);
      } else {
        newproducts[prdIndex].quantity =
          newproducts[prdIndex].quantity + action.payload.quantity;
        newproducts[prdIndex].total =
          newproducts[prdIndex].quantity * action.payload.price;
        if (newproducts[prdIndex].quantity <= 0) {
          newproducts.splice(prdIndex, 1);
        }
      }

      // Finally, update the redux state
      const newState = {products: newproducts};
      return newState;
    case 'ADD_PROMOCODE':
      // Pulls current and possible out of previous state
      // We do not want to alter state directly in case
      // another action is altering it at the same time
      //alert('entering here');

      let productsAfterDiscount = state.products;

      if (action.payload) {
        console.log(action.payload);
        productsAfterDiscount.map((product, prdIndex) => {
          let {price} = product;
          const {quantity} = product;

          const discountFound = action.payload.products.some(
            (e) => e == product.id,
          );
          if (discountFound) {
            let priceAfterDiscount =
              price - price * action.payload.discount * 0.01;
            productsAfterDiscount[prdIndex].priceAfterDiscount =
              priceAfterDiscount;
            productsAfterDiscount[prdIndex].hasDiscount = true;
            productsAfterDiscount[prdIndex].total =
              quantity * priceAfterDiscount;
          }
        });
      }

      //const afterDiscountState = { products: productsAfterDiscount };
      return {...state, products: [...productsAfterDiscount]};
    //return afterDiscountState;

    // Finally, update the redux state

    case 'REM_PROMOCODE':
      // Pulls current and possible out of previous state
      // We do not want to alter state directly in case
      // another action is altering it at the same time
      //alert('entering here');
      let productsBeforeDiscount = state.products;
      if (action.payload) {
        console.log(action.payload);

        productsBeforeDiscount.map((product, prdIndex) => {
          let {price} = product;
          const {quantity} = product;
          const discountFound = action.payload.products.some(
            (e) => e == product.id,
          );
          if (discountFound) {
            productsBeforeDiscount[prdIndex].hasDiscount = false;
            productsBeforeDiscount[prdIndex].total = quantity * price;
          }
        });
        // Finally, update the redux state
        const beforeDiscountState = {products: productsBeforeDiscount};
      }

      //return beforeDiscountState;
      return {...state, products: [...productsBeforeDiscount]};

    case 'EMPTY_CART':
      // Pull friend out of friends.possible
      // Note that action.payload === friendIndex
      //console.log("emptying cart");

      // Finally, update the redux state
      const emptyState = {products: []};
      //console.log(emptyState.products);

      return emptyState;

    case 'SYNC_CART':
      // Pull friend out of friends.possible
      // Note that action.payload === friendIndex
      console.log('syncing cart');
      let oldproducts = state.products;
      let allnewproducts = action.payload;
      console.log(oldproducts);
      if (action.payload) {
        console.log(action.payload);
        oldproducts = oldproducts.map((item) => {
          const item2 = allnewproducts.find((i2) => i2.id === item.id);
          return item2 ? {...item, ...item2} : item;
        });
        console.log('updated products');
        console.log(oldproducts);
        return {...state, products: [...oldproducts]};
      }

      // Finally, update the redux state
      //const emptyState1 = {products: []};
      //console.log(emptyState.products);

      return state;

    default:
      return state;
  }
};
const persistCombinedReducers = persistCombineReducers(persistConfig, {
  cart: cartReducer,
  prefs: prefsReducer,
  store: storeReducer,
  event: eventReducer,
  referralID: referralIDReducer,
  commandeGroupe: commandeGroupeReducer,
});

/*export default combineReducers({
  cart: cartReducer,
  store: storeReducer
});*/
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  persistCombinedReducers,
  composeEnhancer(applyMiddleware(thunk)),
);

export const persistor = persistStore(store);
