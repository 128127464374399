import { StyleSheet } from 'react-native';
import Color from 'color';
import Colors from '../../theme/colors';
import Layout from '../../theme/layout';
import {  Platform } from 'react-native';
// CheckoutA Styles
const styles = StyleSheet.create({
    pt16: {paddingTop: 16},
    screenContainer: {
      flex: 1,
      backgroundColor: Colors.background,
    },
    container: {
      flex: 1,
    },
    headerContainer: {
      backgroundColor: Colors.background,
      elevation: 1,
      ...Platform.select({
        ios: {
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderBottomColor: '#a7a7aa',
        },
      }),
    },
    stepIndicator: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      height: 48,
    },
    stepContainer: {
      width: 70,
      justifyContent: 'center',
      alignItems: 'center',
    },
    stepText: {
      fontWeight: '700',
      color: `${Color(Colors.black).alpha(0.56)}`,
    },
    activeStepText: {
      color: Colors.primaryColor,
    },
    line: {
      width: 48,
      height: 2,
      backgroundColor: `${Color(Colors.black).alpha(0.32)}`,
    },
    activeLine: {
      backgroundColor: Colors.primaryColor,
    },
    swiperContainer: {
      flex: 1,
      ...Platform.select({
        android: {
          minHeight: Layout.SCREEN_HEIGHT - 3 * 56,
        },
      }),
    },
    formContainer: {
      flex: 1,
    },
    form: {
      paddingVertical: 24,
      paddingHorizontal: 20,
    },
    overline: {
      color: `${Color(Colors.secondaryText).alpha(0.6)}`,
      textAlign: 'left',
    },
    inputContainerStyle: {
      marginTop: 0,
      marginBottom: 18,
      paddingVertical: 0,
      paddingHorizontal: 0,
    },
    actionButton: {
      color: Colors.accentColor,
      textAlign: 'center',
    },
    buttonContainer: {
      paddingTop: 16,
      paddingHorizontal: 24,
      paddingBottom: 24,
      backgroundColor: Colors.background,
    },
    linkButtonContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 16,
    },
    linkButton: {
      color: Colors.black,
    },
    orderInfo: {
      paddingVertical: 8,
      textAlign: 'left',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    amount: {
      fontWeight: '500',
      fontSize: 20,
      lineHeight: 24,
    },
  });

  
  export default styles;