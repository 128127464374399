import {TouchableOpacity} from 'react-native';
import React from 'react';
import Svg, {Rect} from 'react-native-svg';

const DiminueSVG = (props) => {
  const {onPress, ...restProps} = props;
  return (
    <TouchableOpacity onPress={onPress}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="34"
        height="33"
        viewBox="0 0 34 33"
        fill="none"
        {...restProps}>
        <Rect
          x="0.5"
          y="0.5"
          width="33"
          height="32"
          rx="10.5"
          fill="#F9F9F9"
          stroke="#AFB2B5"
        />
        <Rect x="10" y="16" width="13" height="2" rx="1" fill="#1E2022" />
      </Svg>
    </TouchableOpacity>
  );
};

export default DiminueSVG;
