import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const PromoCodeSVG = (props) => {
  const {style, ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 42 42"
      style={style}
      {...restProps}>
      <Path
        fill="#2FBF86"
        d="M10.038 28.285v5.787l5.968-1.43L20.5 37l4.494-4.357 5.968 1.43v-5.788L37 26.787 33.98 21 37 15.213l-6.038-1.498V7.928l-5.968 1.43L20.5 5l-4.494 4.357-5.968-1.43v5.788L4 15.213 7.02 21 4 26.787l6.038 1.498Zm-.35-8.579-1.335-2.655 2.387-.545c.703-.136 1.253-.465 1.65-.987a2.895 2.895 0 0 0 .597-1.804v-2.043l2.247.613h.772c.796 0 1.498-.295 2.107-.885L20.5 9.085l2.387 2.315c.609.59 1.31.885 2.107.885.28 0 .538-.045.772-.136l2.247-.477v2.043c0 .68.199 1.282.597 1.804.397.522.947.851 1.65.987l2.387.545-1.334 2.655c-.468.863-.468 1.725 0 2.588l1.334 2.655-2.387.545c-.703.136-1.253.465-1.65.987a2.895 2.895 0 0 0-.597 1.804v2.043l-2.247-.613h-.772c-.796 0-1.498.295-2.107.885L20.5 32.915 18.113 30.6c-.609-.59-1.31-.885-2.107-.885-.28 0-.538.045-.772.136l-2.247.477v-2.043c0-.68-.199-1.282-.597-1.804-.397-.522-.947-.851-1.65-.987l-2.387-.545 1.334-2.655c.468-.863.468-1.725 0-2.588Zm9.338 6.264 7.02-6.876c.656-.636.656-1.317 0-2.043-.701-.59-1.403-.59-2.106 0l-4.915 4.834-1.965-1.906c-.703-.59-1.405-.59-2.107 0-.608.68-.608 1.361 0 2.042l4.073 3.95Z"
      />
    </Svg>
  );
};
export default PromoCodeSVG;
