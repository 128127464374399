/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

// import dependencies
import React, {useState, useEffect} from 'react';
import {
  FlatList,
  ImageBackground,
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import Color from 'color';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addProduct, setStore, setLastId, setTierMode } from '../../reducers/cartactions';




import Menu from '../menu/MenuA';
import Event from '../event/EventA';



function HomeD(props)  {
  const [tier, setTier] = useState("temp");
  const [id, setId] = useState();
  const [go, setGo] = useState();
  const {route, navigation} = props;
  const params = route.params;

  useEffect(() => {
    console.log(params);
    console.log(props);
    console.log(props.prefs);
    if (params && "t" in params) {
      props.setTierMode(params.t);  
      setTier(params.t);
      if ("s" in params) {
        setId(params.s);
        props.setLastId(params.s);
      }
      if ("go" in params) {
        setGo(params.go);        
      }
    }        
    else if (props.prefs.tierMode) {
      setTier(props.prefs.tierMode)
      setId(props.prefs.lastId);
    }

    if (params && params.previous) {
      const {navigation} = this.props;
      if (params.previous) {
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [
              { name: 'HomeNavigator' },
              
            ],
          })
        );      
      }
    }
    
    

  }, []);

  if (tier === 'temp') {
    return (
      <></>
      );
  }
 
  if (tier === 'food') {
    return (
      <Menu id={id} navigation={navigation}></Menu>
      );
  }
  else if (tier === 'event') {
    const attributes = {
      id: id,
      navigation: navigation
    }
    if (go) {
      attributes.go = go;
    }
    return (
      
      <Event  { ...attributes }></Event>
      );
  }
    
  
}


const mapStateToProps = (state) => {
  const { carts, store, prefs } = state
  return { carts, store, prefs }
};


const mapDispatchToProps = dispatch => (
  bindActionCreators({
    addProduct,
    setStore,
    setLastId,
    setTierMode
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(HomeD);
