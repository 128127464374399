import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
const TicketSVG = (props) => {
  const {style, stroke, ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 26 25"
      style={style}
      {...restProps}>
      <Path
        stroke={stroke}
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M18.374 7.129a2.28 2.28 0 0 1-.119-3.097.189.189 0 0 0-.01-.257L16.09 1.618a.19.19 0 0 0-.269 0l-3.434 3.435a1.153 1.153 0 0 0-.279.45 1.155 1.155 0 0 1-.73.733c-.17.057-.325.152-.452.28L2.118 15.32a.19.19 0 0 0 0 .269l2.155 2.155a.189.189 0 0 0 .257.01 2.28 2.28 0 0 1 3.215 3.215.189.189 0 0 0 .01.257l2.155 2.155a.19.19 0 0 0 .269 0l8.808-8.808c.127-.127.223-.282.28-.452a1.156 1.156 0 0 1 .73-.732c.17-.057.324-.152.45-.28l3.435-3.434a.19.19 0 0 0 0-.268l-2.155-2.155a.19.19 0 0 0-.257-.01 2.28 2.28 0 0 1-3.096-.114Z"
      />
      <Path
        stroke={stroke}
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="m12.731 6.857-.806-.806m2.956 2.956-.538-.537m2.687 2.687-.537-.538m2.956 2.956-.806-.806"
      />
    </Svg>
  );
};
export default TicketSVG;
