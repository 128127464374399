import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const NoOrdersSVG = (props) => {
  const {style, ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width={161}
      height={160}
      viewBox="0 0 161 160"
      style={style}
      {...restProps}>
      <Path
        fill="#CAD2CC"
        d="M122.667 32.833h-5.917V21h-11.833v11.833H57.583V21H45.75v11.833h-5.917C33.325 32.833 28 38.158 28 44.667V127.5c0 6.508 5.325 11.833 11.833 11.833h82.834c6.508 0 11.833-5.325 11.833-11.833V44.667c0-6.509-5.325-11.834-11.833-11.834Zm0 94.667H39.833V68.333h82.834V127.5Zm-82.834-71V44.667h82.834V56.5H39.833Zm11.834 23.667h59.166V92H51.667V80.167Zm0 23.666h41.416v11.834H51.667v-11.834Z"
      />
    </Svg>
  );
};

export default NoOrdersSVG;
