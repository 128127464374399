import {StyleSheet, I18nManager} from 'react-native';
import Color from 'color';
import Colors from '../../theme/colors';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    paddingHorizontal: 24,
     paddingVertical: 16,
     display: "flex",
    flexDirection: "row"
  },
  containerSceen: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  detailA: {
    justifyContent: 'space-between',
    // paddingVertical: 8,
    flexDirection: 'row',
  },
  cartIcon: {
    width: 28,
    height: 28,
    marginRight: 8,
  },
  title: {
    flexDirection: 'row',
    // justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 8,
  },
  detail: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
  },
  icon: {
    width: 21,
    height: 21,
    marginRight: 10,
    alignSelf: 'flex-start',
  },
  containerTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 24,
    paddingTop: 16,
    gap: 44,
  },
  titleCommandeLivre: {
    color: 'var(--Dark-Grey, #1D1F21)',

    /* Title */
    fontFamily: 'Bai Jamjuree',
    fontSize: 16,
    fontstyle: 'normal',
    fontWeight: 600,
    lineHeight: '150%' /* 24px */,
    letterSpacing: 0.64,
  },
  textTitleCommande: {
    color: 'var(--Dark-Grey, #1D1F21)',

    /* Title */
    fontFamily: 'Bai Jamjuree',
    fontSize: 12,
    fontstyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%' /* 24px */,
  },
  buttonStyle: {
    paddingVertical: 16,
    paddingHorizontal: 50,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#77838F',
    borderWidth: 1,
  },
  textBtnStyle: {
    color: 'var(--Normal-Grey, #77838F)',
    fontFamily: 'Bai Jamjuree',
    fontSize: 16,
    fontstyle: 'normal',
    fontWeight: 600,
    lineHeight: '150%' /* 24px */,
    letterSpacing: 0.64,
    textTransform: 'capitalize',
  },
});
export default styles;
