import React from 'react';
import styles from './ModalAStyles';
import {
  View,
  Modal,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from 'react-native';
import CloseSVG from '../svgs/CloseSVG';

const ModalA = ({visible, onDismiss, children}) => {
  return (
    <Modal visible={visible} transparent animationType="slide">
      <TouchableWithoutFeedback onPress={onDismiss}>
        <View style={styles.modalContainer}>
          <TouchableOpacity activeOpacity={1} style={styles.modalContent}>
            <TouchableOpacity style={styles.closeButton} onPress={onDismiss}>
              <CloseSVG />
            </TouchableOpacity>
            <View style={styles.content}>{children}</View>
          </TouchableOpacity>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

export default ModalA;
