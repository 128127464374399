import React from 'react';
import {Text} from 'react-native';
import styles from './TitleBigAStyles';

const TitleBigA = ({children, style, ...props}) => (
  <Text style={[styles.title, style]} {...props}>
    {children}
  </Text>
);

export default TitleBigA;
