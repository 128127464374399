import {StyleSheet, I18nManager} from 'react-native';
import Colors from '../../theme/colors';

const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  checkBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 15,
    marginBottom: 16,
  },
  content: {
    paddingHorizontal: 24,
    paddingTop: 16,
    // marginBottom : 24 ,
  },
  contentImg: {
    marginTop: 16,
  },
  centerImage: {
    transform: [{translateX: '-50%'}, {translateY: '50%'}],
    position: 'absolute',
    bottom: 0,
    left: '50%',
  },
  titleText: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '150%',
    letterSpacing: 0.64,
    textAlign: 'center',
  },

  separator: {
    height: 1,
    backgroundColor: Colors.lightGray,
    marginVertical: 16,
  },
  additionContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  textAddition: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  RatioAddition: {
    flexDirection: 'row',
    marginBottom: 16,
    gap: 8,
  },
  btnSimple: {
    paddingVertical: 20,
    paddingHorizontal: 15,
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #77838F',
    alignSelf: 'stretch',
    borderRadius: 15,
    width: '100%',
    gap: 8,
    flexDirection: 'row',
  },
  btnBackground: {
    paddingVertical: 20,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',

    border: '1px solid #77838F',
    alignSelf: 'stretch',
    borderRadius: 15,
  },
  frameTextInput: {
    borderWidth: 1,
    borderColor: '#D2D5DA', // Replace with your desired color
    borderRadius: 8,
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)',
    // marginBottom: 8,
    alignSelf: 'stretch',
    position: 'relative',
    paddingVertical: 12.5,
    backgroundColor: Colors.white,
    paddingHorizontal: 16,
  },

  textInput: {
    borderColor: 'transparent',
    fontSize: '14px',
    fontStyle: 'normal',
    outlineColor: 'transparent',
    fontWeight: 300,
    lineHeight: 21,
    lineHeight: '150%',
    letterSpacing: '0.56px',
    borderWidth: 0,
    outlineStyle: 'none',
    textAlignVertical: 'center',
    //  paddingHorizontal : 16 ,
    underlineColorAndroid: 'transparent',
    flex: 1,
    // width: '80%',
    // width : '100%'
  },
  borderText: {
    position: 'absolute',
    backgroundColor: Colors.white,
    color: Colors.normalGray,
    top: -10,
    borderRadius: 5,

    left: 16,
  },
  borderTextInputRatio: {
    position: 'absolute',
    backgroundColor: Colors.white,
    color: Colors.normalGray,
    top: -13,
    borderRadius: 5,

    left: 16,
  },
  containerPaypal: {
    borderRadius: 15,
    border: '1px solid var(--Light-Grey, #CAD2CC)',
    paddingHorizontal: 10,
    paddingVertical: 10,
    marginVertical: 20,
  },
  rowPaypel: {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
  containerBancaire: {
    borderRadius: 15,
    border: '1px solid var(--Light-Grey, #CAD2CC)',
    paddingHorizontal: 10,
  },
  PaiementCarte: {
    paddingTop: 10,
    paddingBottom: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardRowPayement: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 15,
  },
  VectorPaypel: {},
  masterCardContainer: {
    backgroundColor: '#26365F',
    borderRadius: 8, // Ajustez la bordure selon vos besoins
    padding: 6, // Ajustez le rembourrage selon vos besoins
  },
  VisaCardContainer: {
    backgroundColor: '#2666AC',
    position: 'relative',
    borderRadius: 8, // Ajustez la bordure selon vos besoins
    padding: 8, // Ajustez le rembourrage selon vos besoins
  },
  paypalContainer: {
    backgroundColor: '#478FBE',
    borderRadius: 8, // Ajustez la bordure selon vos besoins
    paddingHorizontal: 14,
    paddingTop: 6.5,
    paddingBottom: 5.5,
    position: 'relative',
  },
  TextVisaCard: {
    color: '#FFF',
    fontFamily: 'Century Gothic',
    fontSize: 16,
    fontStyle: 'italic',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  rouwPaymentInput: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textBtn: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },

  framePaymentInput: {
    borderWidth: 1,
    borderColor: 'var(--Light-Grey, #D2D5DA)', // Replace with your desired color
    borderRadius: 8,
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)',
    // marginBottom: 8,
    alignSelf: 'stretch',
    position: 'relative',
    paddingVertical: 12.5,
    backgroundColor: Colors.white,
    paddingHorizontal: 16,
    width: '48%',
  },

  textPayementInput: {
    borderColor: 'transparent',
    fontSize: '14px',
    fontStyle: 'normal',
    outlineColor: 'transparent',
    fontWeight: 300,
    lineHeight: 21,
    lineHeight: '150%' /* 30px */,
    letterSpacing: '0.56px',
    borderWidth: 0,
    textAlignVertical: 'center', // Centrer verticalement le texte

    outlineStyle: 'none',
    underlineColorAndroid: 'transparent',
    flex: 1,
    width: '80%',
  },
  masterCardContainerInput: {
    backgroundColor: '##FFF',
    border: '1px solid #F2F4F7',
    borderRadius: 4, // Ajustez la bordure selon vos besoins
    padding: '4.125px 5.029px 4.376px 5.029px', // Ajustez le rembourrage selon vos besoins
  },
  frameDateContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  frameDateInput: {
    borderWidth: 1,
    borderColor: 'var(--Light-Grey, #D2D5DA)', // Replace with your desired color
    borderRadius: 8,
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)',
    // marginBottom: 8,
    alignSelf: 'stretch',
    position: 'relative',
    paddingVertical: 12.5,
    backgroundColor: Colors.white,
    paddingLeft: 12,
    paddingRight: 8,
    width: '30%',
  },
  rowDateCard: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    justifyContent: 'end',
    // alignItems: 'center',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'end',
    // alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    width: '100%',
    height: 538,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    backgroundColor: '#FFF',
    shadowColor: 'rgba(0, 0, 0, 0.10)',
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 1,
    shadowRadius: 50,
    padding: 20,
  },
  border: {
    flex: 1,
    // Contenu de la bordure
  },
});
export default styles;
