import * as React from 'react';
import Svg, {Defs, ClipPath, Path, G} from 'react-native-svg';
import Colors from '../../theme/colors';

const AgeSVG = (props) => {
  const {style, ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      style={style}
      {...restProps}>
      <Defs>
        <ClipPath id="a">
          <Path d="M0 0h32v32H0z" />
        </ClipPath>
      </Defs>
      <G clipPath="url(#a)" data-name="Group 2130">
        <G data-name="Group 2129">
          <G data-name="Group 2128">
            <Path
              fill={Colors.normalGray}
              d="M26.383 27.266h-.715c-.28-5.1-2.669-9.4-5.982-11.266 3.313-1.867 5.7-6.168 5.982-11.266h.715a2.367 2.367 0 1 0 0-4.733H5.617a2.367 2.367 0 1 0 0 4.733h.715c.28 5.1 2.669 9.4 5.982 11.266-3.313 1.867-5.7 6.168-5.982 11.266h-.715a2.367 2.367 0 1 0 0 4.733h20.766a2.367 2.367 0 1 0 0-4.733ZM5.25 2.366A.367.367 0 0 1 5.617 2h20.766a.367.367 0 1 1 0 .733H5.617a.368.368 0 0 1-.367-.366Zm18.421 2.367a14.886 14.886 0 0 1-1.18 5.047L9.1 8.727a14.67 14.67 0 0 1-.569-2.255H10.5a.5.5 0 1 0 0-1H8.4c-.026-.245-.052-.489-.067-.738ZM9.5 9.76l12.52.986C20.608 13.334 18.436 15 16 15c-2.732 0-5.131-2.1-6.5-5.24ZM16 17c2.995 0 5.59 2.516 6.864 6.173l-3.6-.814c-.29-.065-.583-.141-.878-.217a13.914 13.914 0 0 0-2.132-.433 9.1 9.1 0 0 0-3.455.566c-.448.136-.894.271-1.337.371l-2.328.527C10.41 19.516 13.005 17 16 17Zm-7.2 7.273 2.881-.652c.465-.1.934-.246 1.4-.388a8.086 8.086 0 0 1 3.072-.528 13.265 13.265 0 0 1 1.976.4c.3.078.608.157.907.224l4.151.939a15.516 15.516 0 0 1 .475 2.993H8.329a15.516 15.516 0 0 1 .471-2.988ZM26.383 30H5.617a.367.367 0 1 1 0-.733h20.766a.367.367 0 1 1 0 .733Z"
              data-name="Path 3790"
            />
          </G>
        </G>
      </G>
    </Svg>
  );
};
export default AgeSVG;
