import {StyleSheet, I18nManager} from 'react-native';
import Color from 'color';
import Colors from '../../theme/colors';
const styles = StyleSheet.create({
  containerSceen: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  containerSceenCommande: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  container: {
    padding: 24,
    flex: 1,
  },
  containerA: {
    padding: '0px 7px',
    height: 58,
    alignSelf: 'stretch',
    marginBottom: '30px',
  },
  textAcontainer: {
    color: 'var(--Dark-Green, #2FBF86)',
    fontFamily: 'Bai Jamjuree',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '150%' /* 30px */,
    letterSpacing: '0.4px',
  },

  SvgText: {
    color: 'var(--Dark-Grey, #1D1F21)',

    fontFamily: 'Bai Jamjuree',
    fontsize: 12,
    fontstyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%' /* 18px */,
  },
  SvgTextTitle: {
    color: 'var(--Dark-Grey, #1D1F21)',

    fontFamily: 'Bai Jamjuree',
    fontsize: 16,
    fontstyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%' /* 18px */,
    letterSpacing: 0.64,
  },
  content: {
    alignItems: 'center',

    flexDirection: 'row',
    gap: 19,
  },

  logoContainer: {
    borderRadius: 10,
    border: '1px solid var(--Light-Grey, #CAD2CC)',
    display: 'flex',
    width: 110,
    height: 50,
    padding: 7,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 5, // Ajoutez une marge entre les logos si nécessaire
  },

  suivieCommandeContainer: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    paddingVertical: 10,
    paddingHorizontal: 24,
  },
  TextCommandeA: {
    color: 'var(--Dark-Grey, #1D1F21)',

    /* Title */
    fontFamily: 'Bai Jamjuree',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '150%' /* 24px */,
    letterSpacing: 0.64,
  },

  TextCommandeB: {
    color: 'var(--Dark-Grey, #1D1F21)',
    fontFamily: 'Bai Jamjuree',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '150%' /* 24px */,
    letterSpacing: 0.64,
  },
  TextCommandeC: {
    color: 'var(--Dark-Grey, #1D1F21)',

    /* Title */
    fontFamily: 'Bai Jamjuree',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%' /* 24px */,
  },
  suivieCommandeContainer2: {
    background: '#F7F6F0',

    paddingHorizontal: 24,
  },
  commandeA: {
    paddingVertical: 8,
    alignItems: 'flex-start',
  },
  detailA: {
    justifyContent: 'space-between',
    // paddingVertical: 8,
    flexDirection: 'row',
  },
  textMapingCommande: {
    flexDirection: 'row',
    gap: 7,
  },

  textDetailA: {
    color: 'var(--Dark-Grey, #1D1F21)',
    fontFamily: 'Bai Jamjuree',
    fontSize: '16px',
    fontStyle: 'normal',

    fontWeight: 600,
    lineHeight: '150%' /* 30px */,
  },
  titleTextDetaileContainer: {
    color: 'var(--Dark-Grey, #1D1F21)',
    fontFamily: 'Bai Jamjuree',
    fontSize: '14px',
    fontStyle: 'normal',

    fontWeight: 300,
    lineHeight: '150%' /* 30px */,
  },
  allDetailesA: {
    paddingLeft: 15,
  },
  wrapper: {
    paddingHorizontal: 24,
  },
  descriptionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  description: {
    padding: 16,
    flexShrink: 1,
  },
  title: {
    flexDirection: 'row',
    // justifyContent: 'space-between',
    alignItems: 'center',
    // paddingVertical: 8,
  },
  cartIcon: {
    width: 28,
    height: 28,
    marginRight: 8,
  },
  detail: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
  },
  icon: {
    width: 21,
    height: 21,
    marginRight: 10,
    alignSelf: 'flex-start',
  },
  backIcon: {
    width: 35,
    height: 21,
    transform: [{scaleX: -1}],
  },
  separator: {
    height: 1,
    backgroundColor: Colors.lightGray,
    marginVertical: 16,
  },

  textTitleCommande: {
    color: 'var(--Dark-Grey, #1D1F21)',
    fontFamily: 'Bai Jamjuree',
    fontSize: '14px',
    fontStyle: 'normal',

    fontWeight: 300,
    lineHeight: '150%' /* 30px */,
  },
  detaileText: {
    color: 'var(--Normal-Grey, #77838F)',
    fontFamily: 'Bai Jamjuree',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '150%' /* 30px */,
  },
  detaileCommande: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  DetaileCommande: {
    color: 'var(--Normal-Grey, #77838F)',
    fontFamily: 'Bai Jamjuree',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 300,
  },
});
export default styles;
