import React, {useState, useEffect, useContext} from 'react';
import styles from './RestaurantItemPageStyles';
import Colors from '../../theme/colors';
import {
  ActivityIndicator,
  TextInput,
  Image,
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  View,
} from 'react-native';
import salad from '../../../assets/images/saladCesar2.png';
import getImgSource from '../../utils/getImgSource';
import TitleA from '../../components/titleA/TitleA';
import SubheadingA from '../../components/subheadingA/SubheadingA';
import {Checkbox, RadioButton} from 'react-native-paper';
import PlusSVG2 from '../../components/svgs/PlusSVG2';
import PlusSVG from '../../components/svgs/PlusSVG';
import MinusSVG2 from '../../components/svgs/MinusSVG2';
import TouchableItem from '../../components/TouchableItem';
import MinusSVG from '../../components/svgs/MinusSVG';
import TitleBigA from '../../components/titleBigA/TitleBigA';
import ButtonA from '../../components/buttonA/ButtonA';
import AddUserSVG from '../../components/svgs/AddUserSVG';
import CartSVG from '../../components/svgs/CartSVG';
import locales from '../../config/locales';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {addProduct, emptyCart} from '../../reducers/cartactions';
import {AuthContext} from '../../contexts/context.js';
import ModalA from '../../components/modalA/ModalA';
import {db} from '../../services/firebase.js';
import {useTranslation} from 'react-i18next';

const NameSection = ({handleModalDismiss, setClientName, clientName}) => {
  const {t} = useTranslation();

  const {user} = useContext(AuthContext);
  const [name, setName] = useState(clientName);

  const ChangeClientName = async () => {
    await db
      .collection('users')
      .doc(user.uid)
      .set(
        {
          displayName: name,
        },
        {merge: true},
      )
      .then(() => {
        setClientName(name);
        handleModalDismiss();
      });
  };

  return (
    <View>
      <TitleA style={{marginBottom: 10, paddingHorizontal: 10}}>
        {/* Order wording */}
        {t('RestaurantItemPage.Libellé de la commande')}
      </TitleA>
      <SubheadingA style={{marginBottom: 10, paddingHorizontal: 10}}>
        {/* Change bulk order name */}
        {t('RestaurantItemPage.Changer le nom de la commande groupée')}
      </SubheadingA>
      <TextInput
        onChange={(e) => setName(e.target.value)}
        value={name}
        placeholder={t('RestaurantItemPage.Indiquez le nom...')}
        maxLength={50}
        style={styles.textInput}
      />
      <ButtonA
        onPress={() => {
          ChangeClientName();
        }}
        disabled={!name}
        style={{marginVertical: 20}}>
        {t('RestaurantItemPage.Changer')}
      </ButtonA>

      <ButtonA
        style={styles.cancelButton}
        color={Colors.darkGreen}
        onPress={handleModalDismiss}>
        {t('RestaurantItemPage.Annuler')}
      </ButtonA>
    </View>
  );
};

const OptionalCheckedListPicker = ({compo, index, onPress}) => {
  const {t} = useTranslation();

  return (
    <View key={index}>
      <View style={{paddingHorizontal: 24}}>
        <TitleA>{compo.caption}</TitleA>
        {compo.required && (
          <SubheadingA style={{color: Colors.darkGreen, marginTop: 8}}>
            {t('RestaurantItemPage.Obligatoire')}
          </SubheadingA>
        )}
      </View>
      <View style={styles.baseChoiceContainer}>
        {compo.options.map((opt) => (
          <>
            <View style={styles.baseChoiceRow}>
              <View>
                <Text>{opt.name}</Text>
              </View>
              <View>
                <Checkbox
                  status={opt.picked ? 'checked' : 'unchecked'}
                  onPress={() => onPress(compo.name, opt, compo.type)}
                  color={Colors.darkGreen}
                />
              </View>
            </View>
            <View style={styles.separator} />
          </>
        ))}
      </View>
    </View>
  );
};

const OptionalAmountListPicker = ({compo, index, onPress}) => {
  const {t} = useTranslation();

  return (
    <>
      <View key={index} style={{paddingHorizontal: 24}}>
        <TitleA>{compo.caption}</TitleA>
        {compo.required && (
          <SubheadingA style={{color: Colors.darkGreen, marginTop: 8}}>
            {t('RestaurantItemPage.Obligatoire')}
          </SubheadingA>
        )}
      </View>
      <View style={styles.baseChoiceContainer}>
        {compo.options.map((opt) => (
          <>
            <View style={styles.baseChoiceRow}>
              <View>
                <Text>{opt.name}</Text>
              </View>
              <View style={styles.quantityHandler}>
                {opt?.quantity > 0 && (
                  <TouchableItem
                    onPress={() =>
                      onPress(
                        compo.name,
                        {...opt, quantity: opt.quantity - 1},
                        compo.type,
                      )
                    }
                    style={{marginRight: 10}}>
                    <MinusSVG2 />
                  </TouchableItem>
                )}

                {opt?.quantity > 0 && (
                  <Text
                    style={{color: Colors.normalGray, fontFamily: 'SemiBold'}}>
                    {opt?.quantity}
                  </Text>
                )}
                <TouchableItem
                  style={{marginHorizontal: 10}}
                  onPress={() =>
                    onPress(
                      compo.name,
                      {...opt, quantity: opt.quantity + 1},
                      compo.type,
                    )
                  }>
                  <PlusSVG2
                    stroke={opt?.quantity == 0 ? '#77838F' : Colors.darkGreen}
                    fill={opt?.quantity == 0 ? '#77838F' : Colors.darkGreen}
                  />
                </TouchableItem>
              </View>
            </View>
            <View style={styles.separator} />
          </>
        ))}
      </View>
    </>
  );
};

const OptionalRadioListPicker = ({compo, index, onPress}) => {
  const {t} = useTranslation();

  return (
    <View key={index}>
      <View style={{paddingHorizontal: 24}}>
        <TitleA>{compo.caption}</TitleA>
        {compo.required && (
          <SubheadingA style={{color: Colors.darkGreen, marginTop: 8}}>
            {t('RestaurantItemPage.Obligatoire')}
          </SubheadingA>
        )}
      </View>
      <View style={styles.baseChoiceContainer}>
        {compo.options.map((opt) => (
          <>
            <View style={styles.baseChoiceRow}>
              <View>
                <Text>{opt.name}</Text>
              </View>

              <View>
                <RadioButton
                  value="checked"
                  color={Colors.darkGreen}
                  status={opt.picked ? 'checked' : 'unchecked'}
                  onPress={() => onPress(compo.name, opt, compo.type)}
                />
              </View>
            </View>
            <View style={styles.separator} />
          </>
        ))}
      </View>
    </View>
  );
};

const OptionalExtraListPicker = () => {
  const [supplementQuantity, setSupplementQuantity] = useState(0);
  const {t} = useTranslation();

  const handleIncrease = () => {
    setSupplementQuantity(supplementQuantity + 1);
  };
  const handleDecrease = () => {
    setSupplementQuantity(supplementQuantity - 1);
  };
  return (
    <>
      <View style={{paddingHorizontal: 24}}>
        <TitleA>
          {t('RestaurantItemPage.Choisissez vos suppléments (max 2)')}
        </TitleA>
        <SubheadingA style={{color: Colors.red, marginTop: 8}}>
          {t('RestaurantItemPage.Extra payant')}
        </SubheadingA>
      </View>
      <View style={[styles.baseChoiceContainer, {marginBottom: 146}]}>
        <View style={styles.baseChoiceRow}>
          <View style={{flexBasis: '60%'}}>
            <Text>{t('RestaurantItemPage.Supplément de saumon')}</Text>
          </View>
          <View>
            <Text
              style={{
                color:
                  supplementQuantity > 0 ? Colors.darkGreen : Colors.darkGray,
                fontFamily: supplementQuantity > 0 ? 'SemiBold' : 'Regular',
              }}>
              3.00 €
            </Text>
          </View>
          <View style={styles.quantityHandler}>
            {supplementQuantity > 0 && (
              <Text
                style={{
                  color: Colors.normalGray,
                  fontFamily: 'SemiBold',
                  marginRight: 10,
                }}>
                {supplementQuantity}
              </Text>
            )}
            <View>
              {supplementQuantity == 0 ? (
                <TouchableItem
                  style={{marginHorizontal: 10}}
                  onPress={handleIncrease}>
                  <PlusSVG2 />
                </TouchableItem>
              ) : (
                <TouchableItem
                  onPress={handleDecrease}
                  style={{marginRight: 10}}>
                  <MinusSVG2 fill={Colors.red} stroke={Colors.red} />
                </TouchableItem>
              )}
            </View>
          </View>
        </View>
        <View style={[styles.separator, {marginVertical: 8}]} />
        <View style={styles.baseChoiceRow}>
          <View style={{flexBasis: '60%'}}>
            <Text>{t('RestaurantItemPage.Supplément de saumon cuit')}</Text>
          </View>
          <View>
            <Text
              style={{
                color:
                  supplementQuantity > 0 ? Colors.darkGreen : Colors.darkGray,
                fontFamily: supplementQuantity > 0 ? 'SemiBold' : 'Regular',
              }}>
              3.99 €
            </Text>
          </View>
          <View style={styles.quantityHandler}>
            {supplementQuantity > 0 && (
              <Text
                style={{
                  color: Colors.normalGray,
                  fontFamily: 'SemiBold',
                  marginRight: 10,
                }}>
                {supplementQuantity}
              </Text>
            )}
            <View>
              {supplementQuantity == 0 ? (
                <TouchableItem
                  style={{marginHorizontal: 10}}
                  onPress={handleIncrease}>
                  <PlusSVG2 />
                </TouchableItem>
              ) : (
                <TouchableItem
                  onPress={handleDecrease}
                  style={{marginRight: 10}}>
                  <MinusSVG2 fill={Colors.red} stroke={Colors.red} />
                </TouchableItem>
              )}
            </View>
          </View>
        </View>
        <View style={[styles.separator, {marginVertical: 8}]} />
        <View style={styles.baseChoiceRow}>
          <View style={{flexBasis: '60%'}}>
            <Text>{t('RestaurantItemPage.Supplément de thon')}</Text>
          </View>
          <View>
            <Text
              style={{
                color:
                  supplementQuantity > 0 ? Colors.darkGreen : Colors.darkGray,
                fontFamily: supplementQuantity > 0 ? 'SemiBold' : 'Regular',
              }}>
              1.50 €
            </Text>
          </View>
          <View style={styles.quantityHandler}>
            {supplementQuantity > 0 && (
              <Text
                style={{
                  color: Colors.normalGray,
                  fontFamily: 'SemiBold',
                  marginRight: 10,
                }}>
                {supplementQuantity}
              </Text>
            )}
            <View>
              {supplementQuantity == 0 ? (
                <TouchableItem
                  style={{marginHorizontal: 10}}
                  onPress={handleIncrease}>
                  <PlusSVG2 />
                </TouchableItem>
              ) : (
                <TouchableItem
                  onPress={handleDecrease}
                  style={{marginRight: 10}}>
                  <MinusSVG2 fill={Colors.red} stroke={Colors.red} />
                </TouchableItem>
              )}
            </View>
          </View>
        </View>
        <View style={[styles.separator, {marginVertical: 8}]} />
        <View style={styles.baseChoiceRow}>
          <View style={{flexBasis: '60%'}}>
            <Text>{t('RestaurantItemPage.Shrimp Supplement')}</Text>
          </View>
          <View>
            <Text
              style={{
                color:
                  supplementQuantity > 0 ? Colors.darkGreen : Colors.darkGray,
                fontFamily: supplementQuantity > 0 ? 'SemiBold' : 'Regular',
              }}>
              7.00 €
            </Text>
          </View>
          <View style={styles.quantityHandler}>
            {supplementQuantity > 0 && (
              <Text
                style={{
                  color: Colors.normalGray,
                  fontFamily: 'SemiBold',
                  marginRight: 10,
                }}>
                {supplementQuantity}
              </Text>
            )}
            <View>
              {supplementQuantity == 0 ? (
                <TouchableItem
                  style={{marginHorizontal: 10}}
                  onPress={handleIncrease}>
                  <PlusSVG2 />
                </TouchableItem>
              ) : (
                <TouchableItem
                  onPress={handleDecrease}
                  style={{marginRight: 10}}>
                  <MinusSVG2 fill={Colors.red} stroke={Colors.red} />
                </TouchableItem>
              )}
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

const RestaurantItemPage = ({
  navigation,
  route,
  addProduct,
  cart,
  emptyCart,
}) => {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [finalProduct, setFinalProduct] = useState({});
  const [sizes, setSizes] = useState([]);
  const [hasSizes, setHasSizes] = useState(false);
  const [composition, setComposition] = useState([]);
  //Component state
  const [modalVisible, setModalVisible] = useState(false);
  const [radioBtnChecked, setRadioBtnChecked] = useState('unchecked');
  const [isLoading, setIsLoading] = useState(false);
  const [isIntableGroupedData, setIsIntableGroupedData] = useState(false);
  const {user} = useContext(AuthContext);
  const [tableGroupedData, setTableGroupedData] = useState();
  const [clientName, setClientName] = useState('');

  const checkUserTableGroupeData = async () => {
    return db
      .collection('users')
      .doc(user.uid)
      .get()
      .then(async (data) => {
        const userData = data.data();
        if (userData && 'displayName' in userData) {
          setClientName(userData.displayName);
        }
        return userData.activeGroupedTable;
      });
  };

  const queryFunction = async () => {
    setLoading(true);
    const {params} = route;
    let productData = params.product;
    let cartProduct;
    const activeGroupedTable = await checkUserTableGroupeData();

    if (activeGroupedTable) {
      setIsIntableGroupedData(activeGroupedTable);
      cartProduct = await db
        .collection('groupedorders')
        .doc(activeGroupedTable)
        .get()
        .then(async (doc) => {
          const docData = doc.data();
          setTableGroupedData({id: doc.id, ...docData});
          let userData = docData.users.filter((obj) => obj.userId == user.uid);
          if (userData.length > 0) {
            const product = userData[0].products.filter(
              (product) => product.id === productData?.id,
            );
            if (product.length > 0) {
              return {
                ...product[0],
                title: product[0].name,
                image: product[0].photo,
              };
            }
          }
        });
    }
    // check confusion between user cart in store and user cart in table grouped $$$$
    else {
      if (cart?.products?.length > 0) {
        const product = cart.products.filter(
          (product) => product.id === productData?.id,
        );

        if (product.length > 0) {
          cartProduct = {
            ...product[0],
            title: product[0].name,
            image: product[0].photo,
          };
        }
      }
    }

    setProduct(productData);

    let {sizes, composition, ...finalProductData} = productData;
    finalProductData.quantity = cartProduct ? cartProduct.quantity : 0;
    finalProductData.composition = cartProduct ? cartProduct.composition : [];
    finalProductData.total = cartProduct
      ? cartProduct.total
      : finalProductData.price * finalProductData.quantity;

    setFinalProduct(finalProductData);

    if (productData.sizes) {
      setHasSizes(true);
      const productSizes = productData.sizes.map((size) => ({
        ...size,
        picked: false,
      }));
      setSizes(productSizes);
    }

    if (productData.iscomposite) {
      const parts = productData?.composition?.map((compo) => ({
        ...compo,
        picked: false,
        options: compo.items.map((element) => ({
          name: element.name,
          picked: cartProduct
            ? cartProduct.composition.some((cp) => cp.name === element.name)
            : false,
          price: element.price,
          quantity: Number(element.quantity) || 0,
        })),
      }));
      setComposition(parts);
    }

    setLoading(false);
  };

  useEffect(() => {
    queryFunction();
  }, [route, cart, user]);

  const onRequiredOptionPicked = async (name, item, type) => {
    try {
      const finalProductData = {...finalProduct};
      var partindex = composition.findIndex((item) => item.name === name);
      var optionindex = composition[partindex].options.findIndex(
        (option) => option.name === item.name,
      );
      var itempicked = composition[partindex].options[optionindex].picked;
      var required = composition[partindex].required
        ? composition[partindex].options.some((element) => element.picked)
          ? false
          : true
        : false;
      //composition[partindex].options[optionindex].picked = true;
      let updatedState;
      if (type === 'checkBox') {
        updatedState = composition[partindex].options.map((option) =>
          option.name === item.name
            ? {...option, picked: required ? true : !itempicked}
            : {...option},
        );
      } else if (type === 'quantityHandler') {
        updatedState = composition[partindex].options.map((option) =>
          option.name === item.name
            ? {
                ...option,
                picked: item.quantity > 0 ? true : false,
                quantity: item.quantity,
              }
            : {...option},
        );
      } else {
        updatedState = composition[partindex].options.map((option) =>
          option.name === item.name
            ? {...option, picked: required ? true : !itempicked}
            : {...option, picked: false},
        );
      }

      composition[partindex].options = updatedState;
      composition[partindex].picked = composition[partindex].options.some(
        (element) => element.picked,
      );

      var finalindex = finalProductData.composition.findIndex(
        (option) => option.category === name && option.name === item.name,
      );

      if (composition[partindex].picked) {
        if (finalindex < 0) {
          finalProductData.composition.push({
            category: name,
            name: item.name,
            price: Number(item.price),
            quantity: Number(item.quantity),
          });
        } else if (finalindex > -1) {
          if (type === 'quantityHandler') {
            if (item.quantity > 0) {
              finalProductData.composition[finalindex].quantity = item.quantity;
            } else {
              finalProductData.composition.splice(finalindex, 1);
            }
          } else {
            finalProductData.composition.splice(finalindex, 1);
          }
        } else {
          finalProductData.composition[finalindex].name = item.name;
        }
      } else {
        if (finalindex > -1) {
          finalProductData.composition.splice(finalindex, 1);
        }
      }

      if (finalProductData.quantity === 0) {
        finalProductData.quantity = 1;
      }
      var compositionprice = finalProductData.composition.reduce(function (
        a,
        b,
      ) {
        let price = b.quantity > 0 ? b.quantity * b.price : b.price;
        return a + price;
      },
      0);

      finalProductData.total =
        (product.price + compositionprice) * finalProductData.quantity;
      finalProductData.price = product.price + compositionprice;
      setFinalProduct(() => {
        return {...finalProductData};
      });
      setComposition(() => {
        return [...composition];
      });
    } catch (err) {
      console.log(err);
    }
  };

  console.log('finalProduct', finalProduct);

  const isReady = () => {
    // if (sizes.length > 0 && sizePicked) {
    //   console.log('sizes');
    //   return true;
    // }

    if (composition.length > 0) {
      console.log('drinks');
      var picked = true;
      composition.forEach((compo) => {
        picked = compo.required ? picked && compo.picked : picked;
      });
      return picked;
    }
    //console.log("neither");
    return false;
  };

  const onPressDecreaseAmount = () => {
    let {quantity} = finalProduct;

    quantity -= 1;
    quantity = quantity < 1 ? 1 : quantity;
    finalProduct.quantity = quantity;

    const total = quantity * finalProduct.price;
    finalProduct.total = total;
    setFinalProduct(() => {
      return {...finalProduct};
    });
  };

  const onPressIncreaseAmount = () => {
    let {quantity} = finalProduct;

    quantity += 1;
    finalProduct.quantity = quantity;

    const total = quantity * finalProduct.price;
    finalProduct.total = total;
    setFinalProduct(() => {
      return {...finalProduct};
    });
  };

  const handleNavigation = async () => {
    const isEmptyRequired = cart?.products?.some(
      (product) =>
        product.tier === 'ticket' || product.storeid !== finalProduct.storeid,
    );
    console.log(
      'cart.products',
      cart.products,
      'isEmptyRequired',
      isEmptyRequired,
      finalProduct,
    );

    if (isEmptyRequired) {
      emptyCart();
    }
    if (!clientName) {
      setModalVisible(true);
      return;
    }
    try {
      let productToAdd = {
        id: finalProduct.id,
        name: finalProduct.title,
        description: finalProduct.description,
        price: finalProduct.price,
        photo: finalProduct.image,
        iscomposite: finalProduct.iscomposite,
        composition: finalProduct.composition,
        quantity: finalProduct.quantity,
        total: finalProduct.total,
        storeid: finalProduct.storeid,
        tier: 'food',
        categoryid: finalProduct.categoryid,
        isalcoholic: finalProduct.isalcoholic,
      };
      if (tableGroupedData) {
        const usersData = tableGroupedData?.users;
        const indexUser = usersData.findIndex((e) => e.userId == user.uid);
        let products = usersData[indexUser].products;
        let productIndex = products.findIndex(
          (product) => finalProduct.id === product.id,
        );
        if (productIndex > -1) {
          products[productIndex] = productToAdd;
        } else {
          products?.push(productToAdd);
        }
        usersData[indexUser].clientName = clientName;
        usersData[indexUser].products = products;
        await db
          .collection('groupedorders')
          .doc(tableGroupedData?.id)
          .set({users: JSON.parse(JSON.stringify(usersData))}, {merge: true})
          .catch((err) => console.log('Error', err));
      } else addProduct(productToAdd);

      navigation?.navigate('Cart');
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleModalDismiss = () => {
    setModalVisible(false);
  };
  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        // contentContainerStyle={{paddingBottom: 40}}
        // style={styles.container}
      >
        <View>
          <Image
            source={getImgSource(product?.image)}
            resizeMode="cover"
            style={styles.itemImage}
          />
        </View>

        <View style={styles.wrapper}>
          <View style={styles.descriptionContainer}>
            {/* <View style={styles.title}> */}
            <TitleA style={{paddingBottom: 6}}>{product?.title}</TitleA>
            <SubheadingA>{product?.description}</SubheadingA>
          </View>
          {composition.length > 0 &&
            composition.map((compo, index) => {
              return compo.type === 'radiobutton' ? (
                <OptionalRadioListPicker
                  key={index}
                  compo={compo}
                  index={index}
                  onPress={onRequiredOptionPicked}
                />
              ) : compo.type === 'checkBox' ? (
                <OptionalCheckedListPicker
                  key={index}
                  compo={compo}
                  index={index}
                  onPress={onRequiredOptionPicked}
                />
              ) : (
                <OptionalAmountListPicker
                  key={index}
                  compo={compo}
                  index={index}
                  onPress={onRequiredOptionPicked}
                />
              );
            })}

          <View style={styles.footer}>
            <View style={styles.quantityItem}>
              <TouchableItem onPress={() => onPressDecreaseAmount()}>
                <MinusSVG style={{width: 34, height: 34, marginRight: 40}} />
              </TouchableItem>
              <TitleBigA>{finalProduct?.quantity}</TitleBigA>
              <TouchableItem onPress={() => onPressIncreaseAmount()}>
                <PlusSVG style={{width: 34, height: 34, marginLeft: 40}} />
              </TouchableItem>
            </View>
            <ButtonA
              onPress={handleNavigation}
              // disabled={!isReady()}
              style={{
                backgroundColor: Colors.darkGreen,
                width: '100%',
                height: 54,
                alignSelf: 'center',
                marginTop: 20,
              }}>
              {isLoading ? (
                <ActivityIndicator size="small" color="green" />
              ) : (
                <>
                  <CartSVG
                    style={{
                      width: 20,
                      height: 20,
                      marginBottom: -2,
                      marginRight: 10,
                    }}
                  />
                  Total ({finalProduct?.total}{' '}
                  {locales.financial.currency.symbol})
                </>
              )}
            </ButtonA>
          </View>
        </View>
        <ModalA visible={modalVisible} onDismiss={handleModalDismiss}>
          <NameSection
            clientName={clientName}
            handleModalDismiss={handleModalDismiss}
            setClientName={setClientName}
          />
        </ModalA>
      </ScrollView>
    </SafeAreaView>
  );
};

const mapStateToProps = (state) => {
  const {cart, store} = state;
  return {cart, store};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      emptyCart,
      addProduct,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantItemPage);
