/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

// import dependencies
import React, {Component, Fragment} from 'react';
import {
  SafeAreaView,
  StatusBar,
  ScrollView,
  View,
  Alert,
  Text,
  Platform,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
  Share,
} from 'react-native';
import remove from 'lodash/remove';
import axios from 'axios';
// import components
import ActionProductCardHorizontal from '../../components/cards/ActionProductCardHorizontal';
import TouchableItem from '../../components/TouchableItem';
import Button from '../../components/buttons/Button';
import {Heading6, Subtitle1} from '../../components/text/CustomText';
import Divider from '../../components/divider/Divider';
import EmptyState from '../../components/emptystate/EmptyState';
import {bindActionCreators} from 'redux';
import {AuthContext} from '../../contexts/context';
import Constants from 'expo-constants';
import {loadStripe} from '@stripe/stripe-js';
import Icon from '../../components/icon/Icon';
// import colors
import Colors from '../../theme/colors';
import {
  addProduct,
  addPromoCode,
  remPromoCode,
} from '../../reducers/cartactions';
import {connect} from 'react-redux';
import locales from '../../config/locales';
import getEnvVars from '../../config';

const {paiement, api, links} = getEnvVars();
import styles from './CartAStyles.js';
import {db} from '../../services/firebase';
import FormModal from '../../components/modals/FormModal';
import InputModal from '../../components/modals/InputModal';
import InfoModal from '../../components/modals/InfoModal';

const IOS = Platform.OS === 'ios';
const PLUS_ICON = IOS ? 'ios-person-remove' : 'md-person-remove';
const ADD_ICON = IOS ? 'ios-person-add' : 'md-person-add';

// CartA Config
const CHECKMARK_ICON =
  Platform.OS === 'ios'
    ? 'ios-checkmark-circle-outline'
    : 'md-checkmark-circle-outline';

const FAIL_ICON =
  Platform.OS === 'ios'
    ? 'ios-close-circle-outline'
    : 'md-close-circle-outline';
const EMPTY_STATE_ICON = 'cart-remove';
const stripePromise = loadStripe(paiement.publishableKey);

// CartA
class CartA extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);

    this.state = {
      isSelected: false,
      total: 0.0,
      totalAfterDiscount: 0.0,
      clientName: '',
      clientEmail: '',
      docUser: {},
      checkoutLoaing: false,
      products: props.cart.products,
      inputModalVisible: false,
      formModalVisible: false,
      isSubmitModalDisabled: true,
      promoCodeValue: '',
      promoCodeDiscount: null, // {discount: 0, productIds: []}
      promoCodeModalVisible: false,
      infoModalVisible: false,
      infoModalIcon: '',
      infoModalTitle: '',
      infoModalMessage: '',
      haspromocode: true,
      // Grouped Orders
      isInActiveGroupedOrder: false,
      groupedOrderIsOwner: false,
      groupedOrderData: null,
      friendCartStatus: 'draft',
      totalPriceShared: 0,
      allProducts: [],
    };
  }

  componentDidMount = async () => {
    // Grouped Orders
    await this.handleGroupedOrderInitialStatus();

    this.updateTotalAmount();
    const unsubscribe = this.props.navigation.addListener('focus', () => {
      // The screen is focused
      // Call any action
      this.updateTotalAmount();
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  };

  // Grouped Orders
  // Check if User is in a Grouped Order or comes from an invitation
  handleGroupedOrderInitialStatus = async () => {
    const {user} = this.context;
    await db
      .collection('users')
      .doc(user.uid)
      .get()
      .then(async (docuser) => {
        const userData = docuser.data();
        // Check if there is an active Grouped Order in User
        if (userData.activeGroupedOrder) {
          // We verify that the user exists in the Grouped Order, and if so, as Owner or Friend
          await this.getUserStatusInGroupedOrder(
            userData.activeGroupedOrder,
            user.uid,
          ).then(async (userRole) => {
            if (userRole == 'owner' || userRole == 'friend') {
              // All good, we save the Grouped Order locally
              this.setState({
                isInActiveGroupedOrder: userData.activeGroupedOrder,
              });
              console.log(
                'The User is already in an active Grouped Order as : ' +
                  userRole,
              );
              console.log('User ID : ' + user.uid);
              console.log('Grouped Order ID : ' + userData.activeGroupedOrder);
              // Setup a snapshot to follow changes
              this.setupSnaphotGroupedOrder(userData.activeGroupedOrder);
            } else {
              this.setState({isInActiveGroupedOrder: false});
              console.log('The user does not handle Grouped Orders');
            }
          });
          // Otherwise, nothing happens
        } else {
          this.setState({isInActiveGroupedOrder: false});
          await this.setupSnaphotUser();
          console.log('The user is not present in an active Grouped Order');
        }
      });
  };

  // Setup onSnapshot on the Grouped Order document
  setupSnaphotGroupedOrder = async (goId) => {
    this.unsubscribeListener = db
      .collection('groupedorders')
      .doc(goId)
      .onSnapshot(async () => {
        console.log('Received an onSnapshot on groupedOrder in CartA.js');
        if (this.state.isInActiveGroupedOrder) {
          await this.getLastGroupedOrderInfo(
            this.state.isInActiveGroupedOrder,
          ).then(async (dataGO) => {
            if (!dataGO) return;
            const {user} = this.context;
            // We check the the Grouped Order has not already been paid (for friends)
            if (dataGO.status === 'payed' && !this.state.groupedOrderIsOwner) {
              this.recycleGroupedOrder();
              await this.setupSnaphotUser();
              await this.unsubscribeListener();
            }
            // Handle if the Friend has been removed from Grouped Order
            if (
              dataGO.owner.userId != user.uid &&
              !dataGO.users.some((obj) => obj.userId == user.uid) &&
              this.state.isInActiveGroupedOrder
            ) {
              console.log(
                'CartA: User is no more a Friend in the Grouped Order',
              );
              this.recycleGroupedOrder();
              await this.setupSnaphotUser();
              await this.unsubscribeListener();
            }
          });
        } else {
          await this.setupSnaphotUser();
          await this.unsubscribeListener();
        }
      });
  };

  // Fix: Setup onSnapshot on the User document to check changes on the Grouped Order field
  setupSnaphotUser = async () => {
    const {user} = this.context;
    this.unsubscribeListenerUser = db
      .collection('users')
      .doc(user.uid)
      .onSnapshot(async () => {
        console.log('Received an onSnapshot on user in CartA.js');
        if (!this.state.isInActiveGroupedOrder) {
          await this.handleGroupedOrderInitialStatus().then(async () => {
            this.unsubscribeListenerUser();
          });
        } else {
          this.unsubscribeListenerUser();
        }
      });
  };

  getLastGroupedOrderInfo = async (goId) => {
    // TODO: This should be handled in backend
    return await db
      .collection('groupedorders')
      .doc(goId)
      .get()
      .then(async (docGO) => {
        const dataGO = docGO.data();
        if (dataGO) {
          // Save it locally
          this.setState({groupedOrderData: dataGO});
          // Recalculate Total
          await this.calculateTotalPriceFriends(dataGO);
          // Check status of Cart for Friend
          if (!this.state.groupedOrderIsOwner)
            this.checkCartStatusforFriends(dataGO);
        } else {
          console.log('The Grouped Order no longer exists');
          this.recycleGroupedOrder();
        }
        console.log('after dataGO');
        console.log(dataGO);
        return dataGO;
      });
  };

  getUserStatusInGroupedOrder = async (goId, userId) => {
    return await this.getLastGroupedOrderInfo(goId).then((dataGO) => {
      if (dataGO) {
        const {user} = this.context;
        // Check if user is Owner
        if (dataGO.owner.userId == user.uid) {
          this.setState({groupedOrderIsOwner: true});
          return 'owner';
        } else {
          this.setState({groupedOrderIsOwner: false});
          // Check if exists in Friends
          const users = dataGO.users;
          if (users.length > 0 && users.some((obj) => obj.userId === userId)) {
            return 'friend';
          } else {
            return 'none';
          }
        }
      } else return 'nogo';
    });
  };

  calculateTotalPriceFriends = async (dataGO) => {
    // Get the amount to be added
    let totalPrice = 0;
    dataGO.users &&
      dataGO.users.forEach((user, i) => {
        totalPrice += parseFloat(user.totalPrice);
        // TODO: Handle what to show depending on what will pay
        // if (user.status == "confirmed") totalConfirmedPrice += user.total;
      });
    this.setState({totalPriceShared: totalPrice});
  };

  removeFriendFromGroupedOrder = async (userId) => {
    this.setState({checkoutLoading: true});
    // TODO: The following should be handled in backend
    // Remove Grouped Order from User
    await db.collection('users').doc(userId).set(
      {
        activeGroupedOrder: null,
      },
      {merge: true},
    );
    // Remove the User from the Grouped Order
    const users = this.state.groupedOrderData.users;
    const updatedUsers = users.filter((user) => user.userId != userId);
    db.collection('groupedorders')
      .doc(this.state.isInActiveGroupedOrder)
      .set(
        {
          users: [...updatedUsers],
        },
        {merge: true},
      );
    this.setState({checkoutLoading: false});
  };

  // Update the value of a Friend's cart
  changeStatusFriendGroupedOrder = async (userId, newStatus) => {
    // TODO: The following should be handled in backend
    // Update the User in the Grouped Order
    let users = this.state.groupedOrderData.users;
    users[users.findIndex((usr) => usr.userId == userId)].status = newStatus;
    db.collection('groupedorders')
      .doc(this.state.isInActiveGroupedOrder)
      .set({users}, {merge: true});
  };
  checkCartStatusforFriends = (dataGO) => {
    const {user} = this.context;
    const userData =
      dataGO.users[dataGO.users.findIndex((usr) => usr.userId == user.uid)];
    if (userData) {
      this.setState({friendCartStatus: userData.status});
    }
  };
  // Check that all Friend's carts have the same status
  checkStatusAllFriendsGroupedOrder = async (status) => {
    if (this.state.groupedOrderData) {
      return !this.state.groupedOrderData.users.some(
        (elt) => elt.status != status,
      );
    }
    return false;
  };

  // Consolidate Owner and Friends Products
  consolidateAllProductsGroupedOrder = async (cartProducts) => {
    const ownerProducts = JSON.parse(JSON.stringify(cartProducts)); // Deep clone needed
    // TODO: The following should be handled in backend
    if (this.state.groupedOrderData) {
      // Get the Friend's products
      const friendsProducts = [];
      this.state.groupedOrderData.users.forEach((elt) =>
        friendsProducts.push(...elt.products),
      );
      // Concatenate both arrays and reduce to increment in case same id
      const allProducts = [...ownerProducts, ...friendsProducts].reduce(
        (accu, current) => {
          let index = accu.findIndex((x) => x.id == current.id);
          if (index == -1) accu.push(current);
          else {
            accu[index].quantity += current.quantity;
            accu[index].total += current.total;
          }
          return accu;
        },
        [],
      );
      this.setState({allProducts});
      return allProducts;
    } else return null;
  };

  // Function to recycle the Grouped Order
  recycleGroupedOrder = () => {
    this.setState({
      totalPriceShared: 0,
      isInActiveGroupedOrder: false,
      groupedOrderData: null,
      showLoading: false,
      friendCartStatus: 'draft',
    });
  };

  renderConfirmFriendGroupedOrderButton = (friendGroupedOrder) => {
    switch (friendGroupedOrder.status) {
      // The friend has not yet confirmed or paid his command
      // > We just remove the friend from the Grouped Order
      case 'draft':
        return (
          <Fragment>
            <View style={styles.actionContainer}>
              <TouchableItem
                onPress={() =>
                  this.removeFriendFromGroupedOrder(friendGroupedOrder.userId)
                }
                borderless>
                <View style={styles.iconContainer}>
                  <Icon
                    name={PLUS_ICON}
                    size={30}
                    color={Colors.onPrimaryColor}
                  />
                </View>
              </TouchableItem>
            </View>

            {/*<Button
                    title="Retirer invitation"
                    buttonStyle={{width: 150, backgroundColor: "red"}} 
    onPress={()=>this.removeFriendFromGroupedOrder(friendGroupedOrder.userId)}/>*/}
          </Fragment>
        );
      // The friend has confirmed his command
      // > We can either remove the friend or confirm his command
      case 'prevalidated':
        return (
          <View>
            <View style={styles.actionContainer}>
              <TouchableItem
                onPress={() =>
                  this.removeFriendFromGroupedOrder(friendGroupedOrder.userId)
                }
                borderless>
                <View style={styles.iconContainer}>
                  <Icon
                    name={PLUS_ICON}
                    size={30}
                    color={Colors.onPrimaryColor}
                  />
                </View>
              </TouchableItem>
            </View>
            <View style={styles.posititveActionContainer}>
              <TouchableItem
                onPress={() =>
                  this.changeStatusFriendGroupedOrder(
                    friendGroupedOrder.userId,
                    'validated',
                  )
                }
                borderless>
                <View style={styles.iconContainer}>
                  <Icon
                    name={CHECKMARK_ICON}
                    size={30}
                    color={Colors.onPrimaryColor}
                  />
                </View>
              </TouchableItem>
            </View>

            {/*<Button
                  title="Retirer invitation"
                  buttonStyle={{width: 150, backgroundColor: "red"}} 
  onPress={()=>this.removeFriendFromGroupedOrder(friendGroupedOrder.userId)}/>
                 <Button
                  title="Valider la commande"
                  buttonStyle={{width: 150}} 
                  onPress={()=>this.changeStatusFriendGroupedOrder(friendGroupedOrder.userId, "validated")}/>*/}
          </View>
        );
      // We have confirmed the user's choice (prevalidated > validated)
      // > we can unconfirm (validated > prevalidated)
      case 'validated':
        return (
          <Fragment>
            <View style={styles.actionContainer}>
              <TouchableItem
                onPress={() =>
                  this.changeStatusFriendGroupedOrder(
                    friendGroupedOrder.userId,
                    'prevalidated',
                  )
                }
                borderless>
                <View style={styles.iconContainer}>
                  <Icon
                    name={FAIL_ICON}
                    size={30}
                    color={Colors.onPrimaryColor}
                  />
                </View>
              </TouchableItem>
            </View>
            {/*<Button
                  title="Annuler la validation"
                  buttonStyle={{width: 150, backgroundColor: "red"}} 
                onPress={()=>this.changeStatusFriendGroupedOrder(friendGroupedOrder.userId, "prevalidated")}/>*/}
          </Fragment>
        );
      // The user has paid
      // No action possible
      case 'paid':
        return (
          <Fragment>
            <Button
              title="Panier Payé"
              outlined
              disabled
              buttonStyle={{width: 150}}
            />
          </Fragment>
        );
      default:
        console.log('Got in default in renderConfirmFriendGroupedOrderButton');
        return null;
    }
  };

  handleLeaveGoupedOrder = async () => {
    this.setState({showLoading: true});
    // Remove the Snapshot listener
    this.unsubscribeListener();
    // If the User is not the owner, we remove him
    const {user} = this.context;
    if (this.state.groupedOrderIsOwner) {
      db.collection('groupedorders')
        .doc(this.state.isInActiveGroupedOrder)
        .set({status: 'canceled'}, {merge: true})
        .then(async () => {
          // Remove Grouped Order from current user
          await this.removeOrderFromUser();
        });
    }
    if (!this.state.groupedOrderIsOwner) {
      console.log(
        'The user is not the owner and is asked to leave Grouped Order',
      );
      // TODO: The following should be handled in backend
      await db
        .collection('groupedorders')
        .doc(this.state.isInActiveGroupedOrder)
        .get()
        .then(async (docGO) => {
          const dataGO = docGO.data();
          let users = dataGO.users;
          users = users.filter((usr) => usr.userId != user.uid);
          // TODO: Must check that the Grouped Order still exists
          await db
            .collection('groupedorders')
            .doc(this.state.isInActiveGroupedOrder)
            .set({users}, {merge: true})
            .then(async () => {
              // Remove Grouped Order from current user
              await this.removeOrderFromUser();
            });
        });
      return;
    }
  };

  removeOrderFromUser = async () => {
    const {user} = this.context;
    await db
      .collection('users')
      .doc(user.uid)
      .set(
        {
          activeGroupedOrder: null,
        },
        {merge: true},
      )
      .then(() => {
        console.log('Grouped Order successfully deleted from user');
        this.recycleGroupedOrder();
      });
  };

  shareGroupedOrder = async () => {
    const {navigation, store} = this.props;
    const {user} = this.context;
    try {
      const dbuser = await db.collection('users').doc(user.uid).get();
      const link =
        links.webappurl +
        'menu?go=' +
        this.state.isInActiveGroupedOrder +
        '&s=' +
        store.id;
      console.log('Link to be shared : ' + link);
      let message =
        'Vous avez été invité à partager une commande avec ' +
        dbuser.displayName +
        '.';
      message = message.concat(' ', 'Lien pour procéder : ' + link);
      console.log(message);
      const result = await Share.share({
        title: message,
        /*message: "Le règlement de cette commande est " 
                  + (paymentType == 'shared') ? ("pris en charge par chaque personne séparément.") : ("pris en charge totalement par " + user + ".")
                  + "Lien pour procéder : " + link*/
        message: message,
      });
      /*if (result.action === Share.sharedAction) {
        if (result.activityType) {
          console.log("shared with activity type of result.activityType")
        } else {
          console.log("shared")
        }
      } else if (result.action === Share.dismissedAction) {
        console.log("dismissed")
      }*/
    } catch (error) {
      alert(error.message);
    }
  };

  renderGroupedOrderManageButtons = () => (
    <View style={{flexDirection: 'row'}}>
      <View style={{flex: 1}}>
        <TouchableOpacity onPress={() => this.shareGroupedOrder()}>
          <View style={{alignItems: 'center', margin: 10}}>
            <Icon name={ADD_ICON} size={22} color={Colors.secondaryText} />
          </View>
        </TouchableOpacity>
      </View>
      <View style={{flex: 2}}>{this.renderGroupedOrderLeaveButton()}</View>
    </View>
  );

  renderGroupedOrderLeaveButton = () => (
    <Button
      onPress={() => this.handleLeaveGoupedOrder()}
      color={'#fff'}
      disabled={
        this.state.groupedOrderIsOwner &&
        this.state.groupedOrderData.users &&
        this.state.groupedOrderData.users.length > 0
      }
      title={
        <View style={{alignItems: 'center'}}>
          <Text style={{textAlign: 'center', fontWeight: 'bold'}}>
            {this.state.groupedOrderIsOwner ? 'Annuler' : 'Quitter'} le panier
            partagé
          </Text>
        </View>
      }
      titleColor={Colors.overlayColor}
    />
  );

  updateClientName = (value) => {
    //console.log(value);
    this.setState({
      clientName: value,
    });
    var isActive = value.length > 0 && this.state.clientEmail.length > 0;
    this.setState({
      isSubmitModalDisabled: !isActive,
    });
  };
  updateClientEmail = (value) => {
    //console.log(value);
    //console.log(this.state.clientName);
    this.setState({
      clientEmail: value,
    });
    var isActive = this.state.clientName.length > 0 && value.length > 0;
    //console.log(isActive);
    this.setState({
      isSubmitModalDisabled: !isActive,
    });
  };

  showInputModal = (value) => () => {
    console.log(this.state.clientName);
    this.setState({
      inputModalVisible: value,
    });
  };

  showFormModal = (value) => () => {
    //console.log(this.state.clientName);
    this.setState({
      formModalVisible: value,
    });
  };

  submitInputModal = (value) => async () => {
    console.log(this.state.clientName);
    this.setState({
      inputModalVisible: value,
    });
    const {user} = this.context;
    await db.collection('users').doc(user.uid).set(
      {
        displayName: this.state.clientName,
      },
      {merge: true},
    );
    const {products} = this.props.cart;
    this.pay(products);
  };

  submitFormModal = (value) => async () => {
    console.log(this.state.clientName);
    console.log(this.state.clientEmail);
    this.setState({
      formModalVisible: value,
    });
    const {user} = this.context;
    //alert(user.uid);
    let params = {
      displayName: this.state.clientName,
      email: this.state.clientEmail,
    };
    if (this.state.clientPhone) {
      params.phone = this.state.clientPhone;
    }
    await db.collection('users').doc(user.uid).set(params, {merge: true});
    if (
      !this.state.isInActiveGroupedOrder ||
      (this.state.isInActiveGroupedOrder &&
        this.state.groupedOrderData?.paymentType == 'shared')
    ) {
      const {products} = this.props.cart;
      console.log(products);
      this.pay(products);
    } else {
      this.pay(this.state.allProducts);
    }
  };

  navigateTo = (screen, tier) => {
    console.log(screen);
    const {navigation} = this.props;
    navigation.navigate(screen, {tier: tier});
  };
  checkandpay = async (products) => {
    const {user} = this.context;
    const dbuser = await db.collection('users').doc(user.uid).get();
    let isTicket = products.some((p) => p.tier === 'ticket');
    const docuser = dbuser.data();
    this.setState({docUser: docuser});
    console.log('isTicket');
    console.log(isTicket);
    if (isTicket) {
      const {navigation, store} = this.props;
      let options = {
        products: products,
        promoCodeDiscount: this.state.promoCodeDiscount,
        shouldCreateOrder: this.state.shouldCreateOrder,
      };
      if (this.state.isInActiveGroupedOrder) {
        options.goid = this.state.isInActiveGroupedOrder;
      }
      navigation.navigate('TicketCoordinates', options);

      //const {navigation} = this.props;
      //navigation.navigate('TicketCoordinates', {products: products});
    } else {
      console.log(docuser);
      if ('displayName' in docuser && docuser.displayName !== '') {
        this.setState({clientName: docuser.displayName});
        //console.log(this.props.store);
        this.pay(products);
      } else {
        this.setState({
          inputModalVisible: true,
        });
      }
    }
  };

  pay = async (products) => {
    var tier = 'food';
    const promoCodeDiscount = this.state.promoCodeDiscount;

    const items = products.map((product) => {
      tier = product.tier === 'ticket' ? 'ticket' : 'food';
      console.log('product');
      console.log(product);

      var compositionprice = product.iscomposite
        ? product.composition.reduce(function (a, b) {
            return a + b.price;
          }, 0)
        : 0;
      let finalPrice = product.isFree
        ? 0
        : promoCodeDiscount && product.hasDiscount
        ? product.priceAfterDiscount
        : product.price;
      let fields = {
        quantity: product.quantity,
        name: product.name,
        photo: product.photo,
        id: product.id,
        isFree: product.isFree,
        isalcoholic: product.isalcoholic,
        tier: product.tier,
        eventid: this.props.event.id,
        categoryid: product.categoryid,
        iscomposite: product.iscomposite,
        composition:
          product.composition !== undefined
            ? JSON.stringify(product.composition)
            : '',
        price: (finalPrice + compositionprice).toFixed(0),
        total: ((finalPrice + compositionprice) * product.quantity).toFixed(0),
      };
      if (product.accessTimeThreshold) {
        fields.accessTimeThreshold = product.accessTimeThreshold;
      }
      if (product.accessTimeLimit) {
        fields.accessTimeLimit = product.accessTimeLimit;
      }
      return fields;
    });

    const {store} = this.props;
    const {user} = this.context;
    const version = Constants.manifest.version;

    var reducedPrices = products.reduce(function (filtered, product) {
      var compositionprice = product.iscomposite
        ? product.composition.reduce(function (a, b) {
            return a + b.price;
          }, 0)
        : 0;
      console.log('products');
      console.log(products);
      if (!product.isFree) {
        let finalPrice =
          promoCodeDiscount && product.hasDiscount
            ? product.priceAfterDiscount
            : product.price;
        filtered.push({
          quantity: product.quantity,
          price_data: {
            currency: 'eur',
            product_data: {
              name: product.name,
              images: product.photo ? [product.photo] : [],
            },
            unit_amount: ((finalPrice + compositionprice) * 100).toFixed(0),
          },
        });
      }
      return filtered;
    }, []);

    var status = 'preordered';
    if (reducedPrices.length == 0) {
      status = tier === 'ticket' ? 'confirmed' : 'ordered';
    }
    let newid = '';
    if (this.state.shouldCreateOrder) {
      this.setState({checkoutLoading: true});
      const options = {
        items: items,
        status: status,
        clientid: user.uid,
        displayName: this.state.clientName,
        email: this.state.clientEmail,
        appversion: version,
        pickmode: 'inhouse',
        origin: 'webapp',
      };
      if (this.state.isInActiveGroupedOrder) {
        options.goid = this.state.isInActiveGroupedOrder;
      }
      if (tier === 'ticket') {
        options.event = this.props.event;
        newid = await axios({
          method: 'POST',
          url: `${api.reservations.precreate}`,
          data: options,
        });
      } else {
        options.storeid = store.id;
        newid = await axios({
          method: 'POST',
          url: `${api.orders.precreate}`,
          data: options,
        });
      }
      this.setState({checkoutLoading: true});
    }

    console.log('newid');
    console.log(newid);

    console.log('prices');
    console.log(reducedPrices);
    //console.log(showid);

    if (reducedPrices.length > 0) {
      const stripe = await stripePromise;
      this.setState({checkoutLoading: true});
      const checkoutsession = await this.createcheckoutsession(
        this.state.docUser.customerId,
        reducedPrices,
        tier,
        newid.data,
      );
      const result = await stripe.redirectToCheckout({
        sessionId: checkoutsession.data.id,
      });
    } else {
      //CheckoutSuccess
      this.setState({checkoutLoading: false});
      this.navigateTo('CheckoutSuccess', tier);
    }
  };

  createcheckoutsession = async (custid, prices, tier, newid) => {
    const {navigation, store} = this.props;
    const {user} = this.context;
    const version = Constants.manifest.version;
    //console.log(api.checkout.create);
    let options = {
      prices: prices,
      tier: tier,
      newid: newid,
      customer: custid,
      savepm: this.state.isSelected,
      accountid: store.accountid,
      mode: 'payment',
    };
    if (this.state.isInActiveGroupedOrder) {
      options.goid = this.state.isInActiveGroupedOrder;
      options.shouldCreateOrder = this.state.shouldCreateOrder;
    }
    const checkoutsession = await axios({
      method: 'POST',
      url: `${api.checkout.create}`,
      data: options,
    });
    return checkoutsession;
  };

  swipeoutOnPressRemove = (item) => () => {
    let {products} = this.state;
    const index = products.indexOf(item);

    products = remove(products, (n) => products.indexOf(n) !== index);

    this.setState(
      {
        products,
      },
      () => {
        this.updateTotalAmount();
      },
    );
  };

  onPressRemove = (item) => () => {
    let {quantity} = item;
    quantity -= 1;

    let {products} = this.state;
    const index = products.indexOf(item);

    let productToAdd = {
      id: item.id,
      quantity: -1,
      total: item.price,
    };
    this.props.addProduct(productToAdd);

    if (quantity === 0) {
      products = remove(products, (n) => products.indexOf(n) !== index);
    } else {
      products[index].quantity = quantity;
    }

    this.setState(
      {
        products: [...products],
      },
      () => {
        this.updateTotalAmount();
      },
    );
  };

  onPressAdd = (item) => () => {
    const {quantity} = item;
    const {products} = this.state;

    let productToAdd = {
      id: item.id,
      quantity: 1,
      price: item.price,
    };
    this.props.addProduct(productToAdd);

    const index = products.indexOf(item);
    //products[index].quantity = quantity + 1;

    this.setState(
      {
        products: [...products],
      },
      () => {
        this.updateTotalAmount();
      },
    );
    //console.log(this.state.products);
  };

  updateTotalAmount = () => {
    const {products} = this.props.cart;
    let total = 0.0;
    let totalAfterDiscount = 0.0;

    products.forEach((product) => {
      let {price, isFree} = product;
      const {quantity, iscomposite, composition} = product;
      var compositionprice = iscomposite
        ? composition.reduce(function (a, b) {
            return a + b.price;
          }, 0)
        : 0;
      total += ((isFree ? 0 : price) + compositionprice) * quantity;
      const finalPrice = isFree ? 0 : price;
      if (this.state.promoCodeDiscount) {
        const discountFound = this.state.promoCodeDiscount.products.some(
          (e) => e == product.id,
        );
        if (discountFound) {
          totalAfterDiscount +=
            (finalPrice -
              price * this.state.promoCodeDiscount.discount * 0.01 +
              compositionprice) *
            quantity;
        } else {
          totalAfterDiscount += (finalPrice + compositionprice) * quantity;
        }
      }
    });
    // If price with discount is same than without > discounts removed
    if (totalAfterDiscount == total) {
      this.setState({promoCodeDiscount: null});
    }
    this.setState({
      total,
      totalAfterDiscount,
    });
  };

  keyExtractor = (item) => item.id.toString();

  renderProductItem = ({item}) => {
    console.log('item', item);
    const {event} = this.props;
    console.log('event', event);
    const tickets = event.tickets;
    const filterTicket = tickets?.filter((ticket) => ticket.id === item.id);
    console.log('filterTicket', filterTicket);

    let outDated = false;
    let comming = false;
    var today = new Date();

    // Convert the start and end dates to JavaScript Date objects
    var startDate = new Date(
      filterTicket[0]?.SellTimeLimit?.start?.seconds * 1000,
    );
    var endDate = new Date(filterTicket[0]?.SellTimeLimit?.end?.seconds * 1000);

    // Check if today's date is between the start and end dates
    if (endDate <= today) {
      outDated = true;
    } else if (startDate >= today) {
      comming = true;
    }
    return (
      <ActionProductCardHorizontal
        key={item.id}
        //onPress={this.navigateTo('Product')}
        onPressRemove={this.onPressRemove(item)}
        onPressAdd={this.onPressAdd(item)}
        imageUri={item.photo}
        title={item.name}
        description={item.description}
        rating={item.rating}
        price={item.price}
        quantity={item.quantity}
        isAddDisabled={
          item.maxQuantityPerOrder <=
          item.initialQuantity - (filterTicket[0]?.soldQuantity ?? 0)
            ? !Boolean(item.maxQuantityPerOrder >= item.quantity)
            : !Boolean(
                item.initialQuantity - (filterTicket[0]?.soldQuantity ?? 0) >=
                  item.quantity,
              )
        }
        isFree={item.isFree}
        composition={item.composition}
        discountPercentage={
          this.state.promoCodeDiscount &&
          this.state.promoCodeDiscount.products.some((e) => e == item.id)
            ? this.state.promoCodeDiscount.discount
            : null
        }
        label={
          item.initialQuantity === item?.soldQuantity
            ? 'soldout'
            : outDated
            ? 'expired'
            : comming
            ? 'comming'
            : item.label
        }
        swipeoutOnPressRemove={this.swipeoutOnPressRemove(item)}
      />
    );
  };

  // Promo Code
  popPromoCodeModal = (value) => {
    this.setState({promoCodeModalVisible: value});
  };
  updatePromoCode = (value) => {
    this.setState({promoCodeValue: value});
  };
  submitPromoCode = async () => {
    this.popPromoCodeModal(false);
    this.setState({checkoutLoading: true});
    // TODO: The following comparision should be server side
    const {event} = this.props;
    console.log(event.tickets);
    let promoCodeFound = false;
    let promoProducts = [];
    let promoDiscount = 0;
    try {
      event.tickets.forEach((ticket) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        ticket.promoCode.map((promoCode) => {
          if (promoCode.discount > 0) {
            console.log(
              'promoCode',
              this.state.promoCodeValue,
              promoCode.code,
              promoCodeFound,
            );
            if (promoCode.code == this.state.promoCodeValue) {
              promoCodeFound = true;
              promoProducts.push(ticket.id);
              promoDiscount = promoCode.discount;
            }
          }
        });
      });
      console.log(this.state.promoCodeDiscount);
      if (promoCodeFound) {
        this.setState(
          {
            promoCodeDiscount: {
              discount: promoDiscount,
              products: promoProducts,
            },
          },
          () => {
            this.updateTotalAmount();
            this.props.addPromoCode(this.state.promoCodeDiscount);
            this.setState({
              checkoutLoading: false,
              infoModalVisible: true,
              infoModalTitle: 'Code promo validé !',
              infoModalMessage:
                'Ce code promo vous permet de profiter de réductions sur votre panier.',
              infoModalIcon: CHECKMARK_ICON,
            });
          },
        );
      } else {
        this.setState({
          checkoutLoading: false,
          promoCodeModalVisible: false,
          infoModalVisible: true,
          infoModalTitle: 'Code promo non valide',
          infoModalMessage: 'Veuillez revoir le code promo soumis',
          infoModalIcon: FAIL_ICON,
        });
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      this.setState({promoCodeModalVisible: false, checkoutLoading: false});
      console.log('Experiment completed');
    }

    // const dbStore = await db.collection('organisers').doc(store.id).get();
    // const docStore = dbStore.data();
    /*await db.collection('products').where("storeid","==",store.id).get()
      .then((querySnapshot) => {
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          const docProduct = doc.data();
          if (docProduct.promoCodes) {
            const promoCodeFound = docProduct.promoCodes.find(e => e.code == this.state.promoCodeValue);
            if (promoCodeFound) {
              const oldDiscountProducts = this.state.promoCodeDiscount ? this.state.promoCodeDiscount.products : [];
              this.setState({promoCodeDiscount: {discount: promoCodeFound.discount, products: [...oldDiscountProducts, doc.id]}})
              this.updateTotalAmount();
            }
          }
        });
      })
      .then(()=>{        
        if (this.state.promoCodeDiscount) {
          this.props.addPromoCode(this.state.promoCodeDiscount);
          this.setState({
            checkoutLoading: false,
            infoModalVisible: true,
            infoModalTitle: "Code promo validé !",
            infoModalMessage: "Ce code promo vous permet de profiter de réductions sur votre panier.",
            infoModalIcon:CHECKMARK_ICON
          });
        } else {
          this.setState({
            checkoutLoading: false,
            promoCodeModalVisible: false,
            infoModalVisible: true,
            infoModalTitle: "Code promo non valide",
            infoModalMessage: "Veuillez revoir le code promo soumis",
            infoModalIcon:FAIL_ICON,
          });
        };
      })
      .catch(function(error) {
        console.log("Error getting documents (products): ", error);
      })
      .finally(() => {
        this.setState({ promoCodeModalVisible: false, checkoutLoading: false });
        console.log('Experiment completed');
      });*/
  };
  removeDiscounts = () => {
    const promoCodeDiscount = this.state.promoCodeDiscount;
    this.setState({promoCodeDiscount: null});
    this.updateTotalAmount();
    console.log(promoCodeDiscount);
    if (promoCodeDiscount) {
      this.props.remPromoCode(promoCodeDiscount);
    }
  };
  popInfoModal = (value) => {
    this.setState({infoModalVisible: value});
  };

  // Grouped Order
  checkAlcoholAndCheckout = async (orderType) => {
    const {products} = this.props.cart;
    if (__DEV__) {
      console.log(products);
    }

    if (products.filter((e) => e.isalcoholic === true).length > 0) {
      Alert.alert(
        "Contrôle d'age",
        'Votre panier contient une boisson alcoolisée. Avez-vous plus de 18 ans?',
        [
          {
            text: 'Non',
            onPress: () => {
              return;
            },
            style: 'cancel',
          },
          {
            text: 'Oui',
            onPress: async () => {
              await this.handleCheckout(orderType, products);
            },
          },
        ],
        {cancelable: false},
      );
    } else {
      await this.handleCheckout(orderType, products);
    }
  };
  handleCheckout = async (orderType, products) => {
    const {user} = this.context;
    this.setState({checkoutLoading: true});
    switch (orderType) {
      // User is NOT in Grouped Order > Normal Behavior
      case 0:
        // Check and pay with creating order
        this.setState({shouldCreateOrder: true});
        this.checkandpay(products);
        break;
      // User is Owner and has decided to Share Grouped Order > PAYS FOR ONLY HIM and START ORDER
      case 1:
        // Verify that all status in Grouped Order (users.status) are paid, otherwise, popup to confirm discard of those that are draft
        const paid = await this.checkStatusAllFriendsGroupedOrder('paid');
        if (!paid) {
          this.setState({
            infoModalTitle: 'Certains paniers non payés',
            infoModalMessage:
              'Tous les paniers de vos amis doivent être payés afin de continuer. Vous pouvez sinon annuler les invitations de ceux qui ne sont pas payés afin de pouvoir procéder.',
            infoModalIcon: FAIL_ICON,
            infoModalVisible: true,
          });
          break;
        }
        // Consolidate all products
        const allProducts1 = await this.consolidateAllProductsGroupedOrder(
          products,
        );
        // Check and pay with creating order
        this.setState({shouldCreateOrder: true});
        this.checkandpay(allProducts1);
        // TODO after checkout: Add to Order a map "groupedOrder: {paymentType: "shared", groupedOrderId}")
        // TODO after checkout: Callback Stripe: Change status of Order and Grouped Order (status) to Completed and add orderId to GroupedOrder
        break;
      // User is Owner and has decided to cover Grouped Order > PAYS FOR ALL and START ORDER
      case 2:
        // Verify that all status in Grouped Order (users.status) are validated, otherwise, popup to confirm discard of those that are draft
        const valid = await this.checkStatusAllFriendsGroupedOrder('validated');
        if (!valid) {
          this.setState({
            infoModalTitle: 'Validez tous les paniers',
            infoModalMessage:
              'Tous les paniers de vos amis doivent être validés afin de continuer. Vous pouvez sinon annuler les invitations de ceux qui ne sont pas validés afin de pouvoir procéder.',
            infoModalIcon: FAIL_ICON,
            infoModalVisible: true,
          });
          break;
        }
        // Consolidate all products
        const allProducts2 = await this.consolidateAllProductsGroupedOrder(
          products,
        );
        console.log(allProducts2);
        // Check and pay with creating order
        this.setState({shouldCreateOrder: true});
        this.checkandpay(allProducts2);
        // TODO after checkout: Add to Order a map "groupedOrder: {paymentType: "covered", groupedOrderId}")
        // TODO after checkout: Callback Stripe: Change status of Order and Grouped Order (status) to Completed
        break;
      // User is NOT Owner and Grouped Order is Shared > PAYS FOR ONLY HIM and WAITS
      case 3:
        // Check and pay without creating order
        this.setState({shouldCreateOrder: false});
        this.checkandpay(products);
        // TODO after checkout: Callback that changes status in Grouped Order (users.status) to paid
        this.changeStatusFriendGroupedOrder(user.uid, 'paid');
        // TODO: Wait for OnSnapshot to see if Owner completes Order
        break;
      // User is NOT Owner and Grouped Order is Covered > DOES NOT PAY and WAITS
      case 4:
        // Change status in Grouped Order (users.status) to prevalidated
        this.changeStatusFriendGroupedOrder(user.uid, 'prevalidated');
        // TODO: Wait for OnSnapshot to see if Owner validates and completes Order
        break;
      default:
        break;
    }
    this.setState({checkoutLoading: false});
  };
  renderPaymentButton = () => {
    let action = 0;
    let message = '';

    const {event} = this.props;
    const {products} = this.props.cart;

    const soldoutProducts = this.props.cart.products.filter((product) => {
      const filtredProduct = event?.tickets?.filter(
        (ticket) => ticket.id === product.id,
      );
      return (
        filtredProduct?.length > 0 &&
        product.initialQuantity === filtredProduct[0]?.soldQuantity
      );
    });

    const outdatedProducts = this.props.cart.products.filter((product) => {
      const filtredProduct = event.tickets.filter(
        (ticket) => ticket.id === product.id,
      );
      var today = new Date();

      var startDate = new Date(
        filtredProduct[0]?.SellTimeLimit?.start?.seconds * 1000,
      );
      var endDate = new Date(
        filtredProduct[0]?.SellTimeLimit?.end?.seconds * 1000,
      );

      console.log('startDate', startDate);
      if (!product.isSellTimeLimit) {
        return false;
      } else {
        if (today >= startDate && today <= endDate) {
          console.log('false passed');
          return false;
        } else {
          console.log('true passed');
          return true;
        }
      }
    });

    console.log('outdatedProducts', outdatedProducts);

    if (products.length > 0) {
      if (soldoutProducts.length === 0 && outdatedProducts.length === 0) {
        if (this.state.isInActiveGroupedOrder && this.state.groupedOrderData) {
          if (this.state.groupedOrderIsOwner) {
            if (this.state.groupedOrderData.paymentType == 'shared') {
              // User is Owner and has decided to Share Grouped Order > PAYS FOR ONLY HIM and START ORDER
              action = 1;
              message = 'Payment (of your basket only) and launch of the Order';
            } else {
              // User is Owner and has decided to cover Grouped Order > PAYS FOR ALL and START ORDER
              action = 2;
              message =
                'Payment (of your basket and that of your validated friends) and launch of the Order';
            }
          } else {
            const status = this.state.friendCartStatus;
            if (this.state.groupedOrderData.paymentType == 'shared') {
              // User is NOT Owner and Grouped Order is Shared > PAYS FOR ONLY HIM and WAITS
              action = 3;
              message = 'Payment (from your cart only)';
            } else {
              // User is NOT Owner and Grouped Order is Covered > DOES NOT PAY and WAITS
              if (status == 'prevalidated') {
                action = -1;
                message = 'Waiting for validation';
              } else if (status == 'validated') {
                action = -1;
                message = 'Basket validated';
              } else if (status == 'draft' || !status) {
                action = this.props.cart.products.length == 0 ? -1 : 4;
                message = 'Confirm my cart';
              }
            }
          }
        } else {
          // User is NOT in Grouped Order > Normal Behavior
          action = 0;
          message = 'Payment';
        }
      } else {
        action = -1;
        message = 'Payment (You have sold out products)';
      }
    } else {
      action = -1;
      message = 'Payment';
    }

    return (
      <Button
        onPress={async () => await this.checkAlcoholAndCheckout(action)}
        disabled={action == -1}
        title={message}
      />
    );
  };

  render() {
    const {
      total,
      totalAfterDiscount,
      inputModalVisible,
      formModalVisible,
      promoCodeModalVisible,
      isSubmitModalDisabled,
      groupedOrderData,
      infoModalVisible,
      promoCodeDiscount,
      isInActiveGroupedOrder,
      totalPriceShared,
      groupedOrderIsOwner,
    } = this.state;

    const {store} = this.props;
    const {products} = this.props.cart;
    const {event} = this.props;
    if (__DEV__) {
      console.log('cart products', products);
    }
    if (this.state.checkoutLoading) {
      return (
        <View style={{paddingTop: 50}}>
          <ActivityIndicator size="large" color="green" />
        </View>
      );
    }
    return (
      <SafeAreaView style={styles.container}>
        <StatusBar
          backgroundColor={Colors.statusBarColor}
          barStyle="dark-content"
        />

        <View style={styles.titleContainer}>
          <Heading6 style={styles.titleText}>Total</Heading6>
          {products.length > 0 && (
            <View style={styles.inline}>
              {promoCodeDiscount ? (
                <Heading6 style={styles.subTotalDiscountPriceText}>
                  {`${locales.financial.currency.symbol} ${parseFloat(
                    Math.round(
                      (totalAfterDiscount +
                        (groupedOrderIsOwner ? totalPriceShared : 0)) *
                        100,
                    ) / 100,
                  ).toFixed(2)}`}
                </Heading6>
              ) : (
                <Heading6 style={styles.subTotalPriceText}>
                  {`${locales.financial.currency.symbol} ${parseFloat(
                    Math.round(
                      (total + (groupedOrderIsOwner ? totalPriceShared : 0)) *
                        100,
                    ) / 100,
                  ).toFixed(2)}`}
                </Heading6>
              )}
            </View>
          )}
        </View>

        {/* Grouped Orders */}
        {isInActiveGroupedOrder && groupedOrderIsOwner && (
          <>
            {this.renderGroupedOrderManageButtons()}
            <View style={styles.secondTitleContainer}>
              <View style={{flexDirection: 'row'}}>
                <Subtitle1 style={styles.titleText}>My Cart</Subtitle1>
              </View>
              <View style={styles.promoCodeContainer}>
                <View style={styles.promoCodeContent}>
                  <Heading6 style={styles.subTotalPriceText}>
                    {`${locales.financial.currency.symbol} ${parseFloat(
                      Math.round(total * 100) / 100,
                    ).toFixed(2)}`}
                  </Heading6>
                </View>
              </View>
            </View>
            {products.length === 0 && (
              <View style={{flexDirection: 'row'}}>
                <EmptyState
                  title=""
                  message="It looks like you haven't added anything to your cart yet"
                />
              </View>
            )}
          </>
        )}
        {isInActiveGroupedOrder && !groupedOrderIsOwner && (
          <>{this.renderGroupedOrderLeaveButton()}</>
        )}

        {/* Promo Code */}
        {promoCodeDiscount && (
          <View style={styles.promoCodeContainer}>
            <View style={styles.promoCodeContent}>
              <Heading6
                style={[
                  styles.subTotalPriceText,
                  promoCodeDiscount
                    ? {
                        textDecorationLine: 'line-through',
                        textDecorationStyle: 'solid',
                        color: Colors.primaryText,
                      }
                    : null,
                ]}>
                {`${locales.financial.currency.symbol} ${parseFloat(
                  Math.round(total * 100) / 100,
                ).toFixed(2)}`}
              </Heading6>
            </View>
          </View>
        )}
        {event.haspromocode && products.length > 0 && (
          <View style={styles.promoCodeContainer}>
            {promoCodeDiscount ? (
              <Button
                buttonStyle={{textAlign: 'center'}}
                outlined
                titleColor="red"
                borderColor="transparent"
                onPress={() => this.removeDiscounts()}
                title={
                  'Cancel promo code \n(discount applied' +
                  promoCodeDiscount.discount +
                  '%)'
                }
              />
            ) : (
              <Button
                outlined
                borderColor="transparent"
                onPress={() => this.popPromoCodeModal(true)}
                title="You have a promo code ?"
              />
            )}
          </View>
        )}

        {!isInActiveGroupedOrder && products.length === 0 ? (
          <EmptyState
            showIcon
            iconName={EMPTY_STATE_ICON}
            title="Votre panier est vide"
            message="Il semble que vous n’avez encore rien ajouté à votre panier"
          />
        ) : (
          <Fragment>
            <View style={styles.flex1}>
              <FlatList
                data={products}
                keyExtractor={this.keyExtractor}
                renderItem={this.renderProductItem}
                contentContainerStyle={styles.productList}
              />
            </View>

            <Divider />

            {/*<View>
              <View style={styles.bottomButtonContainer}>              
                <Button
                  onPress={this.handleCheckout}
                  title="Paiement"
                />
              </View>
        </View>*/}
          </Fragment>
        )}
        {/* Grouped Orders */}
        <Divider />
        {isInActiveGroupedOrder && groupedOrderIsOwner && (
          <View>
            <View style={styles.secondTitleContainer}>
              <Subtitle1 style={styles.titleText}>
                Baskets from your friends
              </Subtitle1>
              <View style={styles.promoCodeContainer}>
                <View style={styles.promoCodeContent}>
                  <Heading6 style={styles.subTotalPriceText}>
                    {`${locales.financial.currency.symbol} ${parseFloat(
                      Math.round(totalPriceShared * 100) / 100,
                    ).toFixed(2)}`}
                  </Heading6>
                </View>
              </View>
            </View>
            {groupedOrderData.users.length == 0 ? (
              <Fragment>
                {/* TODO: Ask to share again */}
                <EmptyState
                  showIcon
                  iconName={EMPTY_STATE_ICON}
                  title="Aucun panier d'ami"
                  message="Il semble que vos amis n'ont pas encore accepté votre demande de partage de panier"
                />
              </Fragment>
            ) : (
              <ScrollView>
                {groupedOrderData.users.map((elt) => (
                  <ScrollView
                    key={elt.userId}
                    contentContainerStyle={{
                      margin: 16,
                      padding: 10,
                    }}>
                    {elt.products.length > 0 ? (
                      <ScrollView style={styles.flex1}>
                        <View
                          style={[
                            styles.friendCart,
                            elt.status == 'validated' || elt.status == 'paid'
                              ? {backgroundColor: '#adf0d5'}
                              : null,
                          ]}>
                          <Text style={{fontWeight: '500', fontSize: 16}}>
                            Name:{' '}
                            <Text style={{fontWeight: '700'}}>
                              {elt.userName}
                            </Text>
                          </Text>
                          <Text style={{fontWeight: '500', fontSize: 16}}>
                            Order status:{' '}
                            <Text
                              style={{
                                fontWeight: '700',
                                textTransform: 'uppercase',
                              }}>
                              {elt.status}
                            </Text>
                          </Text>
                          <Text style={{fontWeight: '500', fontSize: 16}}>
                            Total:{' '}
                            <Text style={{fontWeight: '700', fontSize: 18}}>
                              {locales.financial.currency.symbol}{' '}
                              {elt.totalPrice}
                            </Text>
                          </Text>
                        </View>
                        <View>
                          {this.renderConfirmFriendGroupedOrderButton(elt)}
                        </View>
                        {elt.products.length > 0 && (
                          <View style={styles.flex1}>
                            <FlatList
                              data={elt.products}
                              keyExtractor={this.keyExtractor}
                              renderItem={(items) =>
                                this.renderProductItem(items, true)
                              }
                              contentContainerStyle={styles.productList}
                            />
                          </View>
                        )}
                      </ScrollView>
                    ) : (
                      <View style={styles.flex1}>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}>
                          <Text style={{fontWeight: '500', fontSize: 16}}>
                            {elt.userName}
                          </Text>
                          <Text>No orders received yet</Text>
                        </View>

                        <View
                          style={[
                            styles.flex1,
                            {alignItems: 'center', padding: 5},
                          ]}>
                          <View style={{flex: 2}}>
                            <Button
                              onPress={() => this.handleLeaveGoupedOrder()}
                              color={'#fff'}
                              disabled={
                                this.state.groupedOrderIsOwner &&
                                this.state.groupedOrderData.users &&
                                this.state.groupedOrderData.users.length > 0
                              }
                              title={
                                <View style={{alignItems: 'center'}}>
                                  <Text
                                    style={{
                                      textAlign: 'center',
                                      fontWeight: 'bold',
                                    }}>
                                    Delete person
                                  </Text>
                                </View>
                              }
                              titleColor={Colors.overlayColor}
                            />
                          </View>
                        </View>
                      </View>
                    )}
                  </ScrollView>
                ))}
              </ScrollView>
            )}
          </View>
        )}
        <Divider />
        <InputModal
          title="Code Promo"
          message="Please enter the promo code you received."
          onChangeText={this.updatePromoCode}
          onButtonPress={this.submitPromoCode}
          onRequestClose={() => this.popPromoCodeModal(false)}
          onClosePress={() => this.popPromoCodeModal(false)}
          buttonTitle={'Ok'.toUpperCase()}
          visible={promoCodeModalVisible}
        />
        <InfoModal
          title={this.state.infoModalTitle.toUpperCase()}
          message={this.state.infoModalMessage}
          iconName={this.state.infoModalIcon}
          onButtonPress={() => this.popInfoModal(false)}
          onRequestClose={() => this.popInfoModal(false)}
          buttonTitle={'Ok'.toUpperCase()}
          visible={infoModalVisible}
        />
        <FormModal
          title="Your first and last name"
          message="Your contact details"
          inputDefaultValue={this.state.clientName}
          emailDefaultValue={this.state.clientEmail}
          //inputPlaceholder="E-mail address"
          //inputKeyboardType="email-address"
          onChangeText={this.updateClientName}
          onChangeEmailText={this.updateClientEmail}
          onButtonPress={this.submitFormModal(false)}
          onRequestClose={this.showFormModal(false)}
          buttonTitle={'Ok'.toUpperCase()}
          onClosePress={this.showFormModal(false)}
          visible={formModalVisible}
          disabled={isSubmitModalDisabled}
        />
        <View style={styles.bottomButtonContainer}>
          {/* Grouped Order */}
          {this.renderPaymentButton()}
        </View>
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state) => {
  const {cart, store, event} = state;
  return {cart, store, event};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addProduct,
      addPromoCode,
      remPromoCode,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CartA);
