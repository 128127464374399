import React, { Component } from 'react';
import {
    SafeAreaView,
    StatusBar,
    StyleSheet,
    Text,
    TouchableWithoutFeedback,
    View,
  } from 'react-native';
  import {
    Caption,
    Heading5,
    Subtitle1,
    Subtitle2,
  } from '../../components/text/CustomText';
  import Colors from '../../theme/colors';
  import Layout from '../../theme/layout';
import isEmptyOrUndefined from '../../utils/stringHelper.js'; 
import { db } from "../../services/firebase";
import firebase from "firebase/compat/app";

  const PLACEHOLDER_TEXT_COLOR = 'rgba(0, 0, 0, 0.4)';
  const INPUT_TEXT_COLOR = 'rgba(0, 0, 0, 0.87)';
  const INPUT_BORDER_COLOR = 'rgba(0, 0, 0, 0.2)';
  const INPUT_FOCUSED_BORDER_COLOR = '#000';
  
// SignInA Styles
const styles = StyleSheet.create({
    screenContainer: {
      flex: 1,
      backgroundColor: Colors.background,
    },
    contentContainerStyle: {flex: 1},
    content: {
      flex: 1,
      justifyContent: 'space-between',
    },
    form: {
      paddingHorizontal: Layout.LARGE_PADDING,
    },
    inputContainer: {marginBottom: 7},
    buttonContainer: {paddingTop: 23},
    forgotPassword: {paddingVertical: 23},
    forgotPasswordText: {
      fontWeight: '300',
      fontSize: 13,
      color: Colors.secondaryText,
      textAlign: 'center',
    },
    separator: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    line: {
      width: 64,
      height: 1,
      backgroundColor: INPUT_BORDER_COLOR,
    },
    orText: {
      top: -2,
      paddingHorizontal: 8,
      color: PLACEHOLDER_TEXT_COLOR,
    },
    buttonsGroup: {
      paddingTop: 23,
    },
    vSpacer: {
      height: 15,
    },
    footer: {
      justifyContent: 'center',
      alignItems: 'center',
      padding: 16,
      width: '100%',
    },
    termsContainer: {
      flexDirection: 'row',
    },
    footerText: {
      fontWeight: '300',
      fontSize: 13,
      color: Colors.primaryText,
    },
    footerLink: {
      fontWeight: '400',
      textDecorationLine: 'underline',
    },
    center: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      },
  });
  

class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }
    
    componentDidCatch(error, errorInfo) {
      // Catch errors in any components below and re-render with error message
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
      console.log("error boundary");
      console.log(error);
      db.collection("errors").add({
        //errorInfo: errorInfo,  
        //stack: error !== null ? error.stack : "error stack", 
        error: !isEmptyOrUndefined(error.message)  ? error.message : "error occured",
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      })
        .then((docRef) => {
            console.log("Document written with ID: ", docRef.id);            
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
        }); 
        // You can also log error messages to an error reporting service here
    }
    
    render() {
      if (this.state.errorInfo) {
        // Error path
        return (
            <SafeAreaView style={styles.screenContainer}>
                <StatusBar
                backgroundColor={Colors.statusBarColor}
                barStyle="dark-content"
                />

                <View style={styles.center}>
                    <Subtitle2>Something went wrong!</Subtitle2>
                    
                    
                </View>


            
            </SafeAreaView>
            
          
        );
      }
      // Normally, just render children
      return this.props.children;
    }  
  }

  export default ErrorBoundary;