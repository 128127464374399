import {StyleSheet} from 'react-native';
import Colors from '../../theme/colors';

const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  container: {
    paddingVertical: 16,
    paddingHorizontal: 25,
  },
  input: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    // backgroundColor: Colors.white,
    height: 60,
    // borderRadius: 15,
    // shadowColor: 'black',
    // shadowOffset: {width: 0, height: 0},
    // shadowOpacity: 0.1,
    // shadowRadius: 10,
    // elevation: 2,
  },

  inputSpacing: {
    paddingLeft: 20,
    paddingRight: 10,
    paddingVertical: 13,
    marginVertical: 20,
  },
  inputB: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    // backgroundColor: Colors.white,
    height: 85,
    // borderRadius: 15,
    // shadowColor: 'black',
    // shadowOffset: {width: 0, height: 0},
    // shadowOpacity: 0.1,
    // shadowRadius: 10,
    // elevation: 2,
  },

  separatorFood: {
    width: '100%',
    height: 1,
    backgroundColor: Colors.lightGray,
    marginVertical: 16,
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: Colors.lightGray,
    // marginTop: 20,
    // marginBottom: 10,

    // Add other styles for the separator
  },
  total: {
    marginVertical: 10,
    paddingVertical: 20,
    paddingHorizontal: 16,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: Colors.lightGray,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  bodyRow: {
    flexDirection: 'row',
    // alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  rowIcon: {
    width: 21,
    height: 21,
    marginRight: 10,
  },
  promoCode: {
    marginVertical: 10,
    paddingVertical: 10,
    paddingHorizontal: 0,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  textInput: {
    width: '100%',
    height: 46,
    borderRadius: 8,
    backgroundColor: Colors.white,
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderWidth: 1,
    borderColor: '#D2D5DA',
    shadowColor: 'black',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.05,
    shadowRadius: 2,
    elevation: 1,
    marginVertical: 10,
    // position: 'relative',
  },
  cancelButton: {
    backgroundColor: 'rgba(0,0,0, 0.0)',
  },
  //
  // seconde design to update
  //
  flex1: {
    flex: 1,
  },
  inline: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 16,
    paddingHorizontal: 16,
    paddingBottom: 14,
  },
  titleText: {
    fontWeight: '700',
    fontSize: 26,
  },
  productList: {
    // spacing = paddingHorizontal + ActionProductCardHorizontal margin = 12 + 4 = 16
    paddingHorizontal: 12,
  },
  subTotalText: {
    top: -2,
    fontWeight: '500',
    color: Colors.onSurface,
  },
  subTotalPriceText: {
    fontWeight: '700',
    color: Colors.primaryColor,
  },
  bottomButtonContainer: {
    width: '100%',
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  checkboxContainer: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  checkbox: {
    alignSelf: 'center',
  },
  label: {
    margin: 8,
  },
  titleContainer: {
    backgroundColor: '#f0f0f0',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 16,
    paddingHorizontal: 16,
  },
  secondTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 16,
    paddingLeft: 16,
  },
  friendCartContainer: {
    margin: 16,
    padding: 12,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.08)',
    borderRadius: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  friendCart: {
    padding: 12,
    marginBottom: 16,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.15)',
    borderRadius: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
  promoCodeContainer: {
    justifyContent: 'flex-end',
    paddingHorizontal: 12,
  },
  promoCodeContent: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
  },
  promoCodeDiscountPriceText: {
    fontWeight: '700',
    color: '#000',
  },
  subTotalDiscountPriceText: {
    fontWeight: '700',
    color: Colors.promo,
  },
  actionContainer: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: Colors.secondaryColor,
    overflow: 'hidden',
  },
  posititveActionContainer: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: Colors.primaryColor,
    overflow: 'hidden',
  },
});

export default styles;
