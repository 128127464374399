import React from 'react';
import Colors from '../../theme/colors';
import styles from './categoriesTabStyles';
import {
  FlatList,
  Image,
  SafeAreaView,
  ScrollView,
  StatusBar,
  View,
} from 'react-native';
import ScreenTitleA from '../../components/screenTitleA/ScreenTitleA';
import BackArrowSVG from '../../components/svgs/BackArrowSVG';
import SearchBarA from '../../components/searchBarA/searchBarA';
import TouchableItem from '../../components/TouchableItem';
import {useNavigation} from '@react-navigation/native';
import {useTranslation} from 'react-i18next';

import TitleA from '../../components/titleA/TitleA';
import {allCategoriesTab} from './allCategoriesTab';
const Separator = ({showSeperator}) => {
  return <View style={styles.separator} />;
};

const CategoriesTab = ({}) => {
  const {t} = useTranslation();
  const navigation = useNavigation();
  const [showSeperator, setShowSeprator] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState('');

  const servicesKeyExtractor = (item) => item.id;

  const renderCategoriesTab = (item, searchTerm, index) => {
    setShowSeprator(true);
    if (
      searchTerm &&
      !item.title.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      setShowSeprator(false);
      return null;
    }
    return (
      <View style={styles.item}>
        <Image source={item?.image} style={styles.image} />
        <View style={styles.title}>
          <TitleA style={{color: Colors.normalGray}}>{item.title}</TitleA>
        </View>
        <TouchableItem style={styles.buttonContainer}>
          <BackArrowSVG fill={Colors.lightGray} style={styles.button} />
        </TouchableItem>
      </View>
    );
  };

  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
      <ScreenTitleA
        style={{height: 60}}
        // title={'Toutes catégories'}

        title={t('CategoriesTab.Toutes catégories')}
        onPress={() => navigation.goBack()}
      />
      <ScrollView style={styles.scrollView}>
        <View style={{marginTop: 30}}>
          <SearchBarA
            placeholder={t('CategoriesTab.Rechercher une catégorie')}
            onChangeText={(text) => setSearchTerm(text)}
            value={searchTerm}
          />
        </View>
        <View style={styles.flatListContainer}>
          <FlatList
            showsVerticalScrollIndicator={false}
            data={allCategoriesTab}
            alwaysBounceHorizontal={false}
            keyExtractor={servicesKeyExtractor}
            renderItem={({item, index}) =>
              renderCategoriesTab(item, searchTerm, index)
            }
            contentContainerStyle={styles.flatList}
            ItemSeparatorComponent={() =>
              showSeperator ? <Separator /> : null
            }
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};
export default CategoriesTab;
