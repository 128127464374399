import React, {useMemo, useEffect} from 'react';
import Colors from '../../theme/colors';
import TitleA from '../../components/titleA/TitleA';
import ButtonA from '../../components/buttonA/ButtonA';
import {SafeAreaView, StatusBar, Text, View} from 'react-native';
import styles from './ArticleDiviserStyle';
import PlusDiviserSVG from '../../components/svgs/plusDiviserSVG';
//import ScreenTitleA from '../../components/screenTitleA/ScreenTitleA';
import DiminueSVG from '../../components/svgs/DiminueSVG';

import {AuthContext} from '../../contexts/context.js';
import {db} from '../../services/firebase.js';

const ArticleDiviser = ({navigation}) => {
  const [quantity, setQuantity] = React.useState(0);
  const [remainQuantity, setRemainQuantity] = React.useState(0);
  //user

  const [listProduct, setListProduct] = React.useState([]);
  const [groupeTableData, setGroupeTableData] = React.useState();
  const [currentUserData, setCurrentUserData] = React.useState();
  const [payedAlready, setPayedAlready] = React.useState(0);
  //context
  const {user} = React.useContext(AuthContext);

  const UsersMaxNumber = useMemo(() => {
    return groupeTableData?.users
      ? groupeTableData?.users?.length - remainQuantity
      : 0;
  }, [groupeTableData, remainQuantity]);

  const totalPrice = useMemo(() => {
    let total = 0.0;
    listProduct.forEach((product) => {
      let {price, isFree} = product;
      const {quantity, iscomposite, composition} = product;
      var compositionprice = iscomposite
        ? composition.reduce(function (a, b) {
            let price = b.quantity > 0 ? b.quantity * b.price : b.price;
            return a + price;
          }, 0)
        : 0;
      total += ((isFree ? 0 : price) + compositionprice) * quantity;
    });
    return total - payedAlready;
  }, [listProduct, payedAlready]);

  const checkUserTableGroupeData = async () => {
    return db
      .collection('users')
      .doc(user.uid)
      .get()
      .then(async (data) => {
        const userData = data.data();
        return userData.activeGroupedTable;
      });
  };

  const aggregatedProducts = (data) =>
    data.reduce((acc, obj) => {
      obj.products.forEach((product) => {
        const foundIndex = acc.findIndex((item) => item.id === product.id);
        if (foundIndex !== -1) {
          acc[foundIndex].quantity += product.quantity;
        } else {
          acc.push({...product});
        }
      });
      return acc;
    }, []);

  const queryFn = async () => {
    const tableGroupeData = await checkUserTableGroupeData();
    if (tableGroupeData) {
      db.collection('groupedorders')
        .doc(tableGroupeData)
        .onSnapshot(async (groupedordersDoc) => {
          const groupedordersData = groupedordersDoc.data();
          setGroupeTableData({...groupedordersData, id: groupedordersDoc.id});
          setListProduct(aggregatedProducts(groupedordersData.users));
          const payedAmount = groupedordersData?.users?.reduce(
            (acc, curr) => (curr.totalPayedPrice || 0) + acc,
            0,
          );
          const quantityRemain = groupedordersData?.users?.reduce(
            (acc, curr) => (curr.quantityPaid || 0) + acc,
            0,
          );
          setRemainQuantity(quantityRemain);

          setPayedAlready(payedAmount);
        });
      // unsubscribeListener.current()
    }
  };

  useEffect(() => {
    queryFn();
  }, []);

  const onHandleConfirm = async () => {
    const payedPrice = (totalPrice * (quantity / UsersMaxNumber)).toFixed(2);

    const usersData = groupeTableData.users.map((item) => {
      if (item.userId === user.uid) {
        return {
          ...item,
          totalPayedPrice: payedPrice,
          quantityPaid: quantity,
        };
      }
      return item;
    });
    const isReady = Boolean(UsersMaxNumber - quantity === 0);

    await db
      .collection('groupedorders')
      .doc(groupeTableData?.id)
      .set(
        {users: JSON.parse(JSON.stringify(usersData)), isReady},
        {merge: true},
      );
    navigation.navigate('Bill');
  };

  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
      <View style={styles.container}>
        <View style={styles.containerText}>
          <TitleA> Total à partager </TitleA>
          <TitleA>{totalPrice} €</TitleA>
        </View>
      </View>
      <View style={{paddingHorizontal: 24}}>
        <View style={[styles.separator, {marginVertical: 16}]} />
      </View>
      <View style={{padding: 24}}>
        <View style={styles.containerText}>
          <Text> Payer pour </Text>
          <View style={{flexDirection: 'row', alignItems: 'center', gap: 14}}>
            <DiminueSVG
              onPress={() => quantity > 1 && setQuantity(() => quantity - 1)}
            />

            <Text>{quantity}</Text>

            {(totalPrice * (quantity / UsersMaxNumber)).toFixed(2) >=
            totalPrice ? (
              <Text> {''}</Text>
            ) : (
              <PlusDiviserSVG onPress={() => setQuantity(() => quantity + 1)} />
            )}
          </View>
          <Text style={styles.textArticle}>personne</Text>
        </View>
        <View style={[styles.separator, {marginVertical: 24}]} />

        <View style={styles.containerText}>
          <Text>Sur</Text>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: 37,
              gap: 14,
            }}>
            {/* TouchableOpacity dans svg   */}

            <Text>{UsersMaxNumber}</Text>
          </View>
          <Text>à table</Text>
        </View>
      </View>
      <View style={{padding: 24}}>
        <View style={styles.containerScreenPayment}>
          <View style={styles.containerPayement}>
            <TitleA>Votre paiement</TitleA>
            <TitleA style={[{color: 'var(--Dark-Green, #2FBF86)'}]}>
              {' '}
              {(totalPrice * (quantity / UsersMaxNumber)).toFixed(2)} €
            </TitleA>
          </View>
        </View>

        <View style={{paddingVertical: 10}}>
          <ButtonA
            style={{
              backgroundColor: Colors.darkGreen,
              width: '100%',
              height: 65,
            }}
            onPress={() => onHandleConfirm()}>
            {' '}
            Confirmer
          </ButtonA>
        </View>
      </View>
    </SafeAreaView>
  );
};
export default ArticleDiviser;
