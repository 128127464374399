import React from 'react';
import Svg, {
  Circle,
  Path,
  Defs,
  Pattern,
  Use,
  Rect,
  G,
  Image,
} from 'react-native-svg';

const ObjectsSvg = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="60"
      viewBox="0 0 50 57"
      fill="none">
      <Path
        d="M41.1767 13.487C39.4606 6.05056 32.8744 0.5 25.002 0.5C17.1295 0.5 10.5392 6.05076 8.82312 13.4877C4.09997 14.2704 0.5 18.4217 0.5 23.4198C0.5 28.9732 4.94597 33.4885 10.442 33.4885C12.596 33.4885 14.5896 32.7937 16.216 31.6132C18.7656 33.2249 21.7755 34.1595 25.002 34.1595C28.2287 34.1595 31.2388 33.2248 33.7885 31.6129C35.4141 32.7897 37.4074 33.4885 39.562 33.4885C45.058 33.4885 49.504 28.9732 49.504 23.4198C49.504 18.4203 45.9019 14.2679 41.1767 13.487Z"
        fill="white"
        stroke="#604640"
      />
      <Path
        d="M39.5619 29.9809C37.3958 29.9809 35.4013 29.2411 33.8097 27.996C31.2767 29.6737 28.253 30.6519 25.0019 30.6519C21.7509 30.6519 18.7272 29.6737 16.1941 27.996C14.6025 29.2371 12.608 29.9809 10.442 29.9809C5.74287 29.9809 1.84559 26.5003 1.12358 21.9403C1.04779 22.4214 1.00391 22.9146 1.00391 23.4199C1.00391 28.7034 5.23228 32.9885 10.4459 32.9885C12.612 32.9885 14.6065 32.2487 16.1981 31.0036C18.7312 32.6813 21.7548 33.6596 25.0059 33.6596C28.257 33.6596 31.2806 32.6813 33.8137 31.0036C35.4053 32.2447 37.3998 32.9885 39.5659 32.9885C44.7795 32.9885 49.0079 28.7034 49.0079 23.4199C49.0079 22.9146 48.96 22.4254 48.8882 21.9403C48.1662 26.4962 44.2689 29.9809 39.5699 29.9809H39.5619Z"
        fill="#DDDBD9"
      />
      <Path
        d="M5.88916 47.924L5.89062 48.0696L5.96997 48.1916L6.38914 47.919C5.96997 48.1916 5.97015 48.1919 5.97035 48.1922L5.97085 48.1929L5.97222 48.195L5.97642 48.2014L5.99074 48.2227C6.00292 48.2407 6.0204 48.2661 6.04328 48.2985C6.08902 48.3632 6.15637 48.4557 6.24612 48.5719C6.4256 48.8045 6.69482 49.1323 7.06025 49.5236C7.79104 50.306 8.9074 51.3429 10.461 52.3777C13.5726 54.4502 18.4231 56.5041 25.4088 56.5041C32.3954 56.5041 37.0125 54.4494 39.8919 52.3667C41.3286 51.3274 42.3267 50.2855 42.9684 49.4982C43.2893 49.1046 43.5211 48.7744 43.6743 48.5398C43.751 48.4225 43.8079 48.329 43.8466 48.2633C43.8659 48.2305 43.8806 48.2046 43.8909 48.1861L43.9031 48.1641L43.9067 48.1574L43.9079 48.1552L43.9084 48.1543C43.9085 48.154 43.9087 48.1536 43.4672 47.919L43.9087 48.1536L43.9672 48.0436V47.919V31.5994V31.0994H43.4672H6.22559H5.72055L5.72561 31.6044L5.88916 47.924Z"
        fill="white"
        stroke="#604640"
      />
      <Path
        d="M43.4677 47.919C41.888 44.9275 33.5908 48.1858 21.7913 45.8169C9.99174 43.448 6.38965 47.919 6.38965 47.919C6.38965 47.919 11.6472 56.004 25.4093 56.004C39.1715 56.004 43.4677 47.919 43.4677 47.919Z"
        fill="#AFAEAE"
      />
      <Path
        d="M7.37109 47.8018C7.37109 47.8018 8.97867 45.0003 15.6723 44.8184C22.3659 44.6324 23.5705 46.6537 31.5805 46.3101C39.5905 45.9665 41.6767 46.0231 42.5543 47.8018"
        stroke="#D6D6D6"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
      />
      <Path
        d="M44.3995 32H5.60045C5.26732 32 5 31.5479 5 31C5 30.4452 5.27144 30 5.60045 30H44.3995C44.7327 30 45 30.4521 45 31C45 31.5548 44.7286 32 44.3995 32Z"
        fill="#DDDBD9"
      />
      <Path
        d="M11.8741 31.4862C11.535 31.4862 11.2598 31.7651 11.2598 32.1088V42.0129C11.2598 42.3565 11.535 42.6355 11.8741 42.6355C12.2131 42.6355 12.4884 42.3565 12.4884 42.0129V32.1088C12.4884 31.7651 12.2131 31.4862 11.8741 31.4862Z"
        fill="#D6D6D6"
      />
      <Path
        d="M18.3604 31.4862C18.0213 31.4862 17.7461 31.7651 17.7461 32.1088V42.0129C17.7461 42.3565 18.0213 42.6355 18.3604 42.6355C18.6995 42.6355 18.9747 42.3565 18.9747 42.0129V32.1088C18.9747 31.7651 18.6995 31.4862 18.3604 31.4862Z"
        fill="#D6D6D6"
      />
      <Path
        d="M24.8467 31.4862C24.5077 31.4862 24.2324 31.7651 24.2324 32.1088V42.0129C24.2324 42.3565 24.5077 42.6355 24.8467 42.6355C25.1858 42.6355 25.461 42.3565 25.461 42.0129V32.1088C25.461 31.7651 25.1858 31.4862 24.8467 31.4862Z"
        fill="#D6D6D6"
      />
      <Path
        d="M31.3321 31.4862C30.993 31.4862 30.7178 31.7651 30.7178 32.1088V42.0129C30.7178 42.3565 30.993 42.6355 31.3321 42.6355C31.6712 42.6355 31.9464 42.3565 31.9464 42.0129V32.1088C31.9464 31.7651 31.6712 31.4862 31.3321 31.4862Z"
        fill="#D6D6D6"
      />
      <Path
        d="M37.8184 31.4862C37.4793 31.4862 37.2041 31.7651 37.2041 32.1088V42.0129C37.2041 42.3565 37.4793 42.6355 37.8184 42.6355C38.1575 42.6355 38.4327 42.3565 38.4327 42.0129V32.1088C38.4327 31.7651 38.1575 31.4862 37.8184 31.4862Z"
        fill="#D6D6D6"
      />
    </Svg>
  );
};
export default ObjectsSvg;
