import React from 'react';
import styles from './PaymentMethodStyles';
import Colors from '../../../theme/colors';
import {SafeAreaView, StatusBar, Text, View} from 'react-native';
import ButtonA from '../../../components/buttonA/ButtonA';
import CartSVG from '../../../components/svgs/CartSVG';
import {useNavigation} from '@react-navigation/native';

const PaymentMethod = () => {
  const navigation = useNavigation();
  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
      <View style={{marginVertical: 40, marginHorizontal: 25}}>
        <ButtonA
          onPress={() => {
            navigation.navigate('CheckoutSuccess');
          }}>
          <CartSVG
            style={{
              width: 18,
              height: 18,
              marginBottom: -2,
              marginRight: 10,
            }}
            stroke={Colors.background}
          />
          Pay (28.00 €)
        </ButtonA>
      </View>
    </SafeAreaView>
  );
};

export default PaymentMethod;
