import * as React from 'react';
import {TouchableOpacity} from 'react-native';
import Svg, {G, Rect} from 'react-native-svg';
const PlusDiviserSVG = (props) => {
  const {onPress, ...restProps} = props;

  return (
    <TouchableOpacity onPress={onPress}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="34"
        height="33"
        viewBox="0 0 34 33"
        fill="none">
        <Rect
          x="0.5"
          y="0.5"
          width="33"
          height="32"
          rx="10.5"
          fill="#F9F9F9"
          stroke="#AFB2B5"
        />
        <Rect x="10" y="15.5" width="13" height="2" rx="1" fill="#1E2022" />
        <Rect
          x="15.5"
          y="23"
          width="13"
          height="2"
          rx="1"
          transform="rotate(-90 15.5 23)"
          fill="#1E2022"
        />
      </Svg>
    </TouchableOpacity>
  );
};
export default PlusDiviserSVG;
