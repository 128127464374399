import React from 'react';

import EventsIconSVG from '../../components/svgs/EventsIconSVG';
import PizzaSVG from '../../components/svgs/PizzaSVG';
import CourseSVG from '../../components/svgs/CourseSVG';
import PlusIconSVG from '../../components/svgs/PlusIconSVG';
// import ShoppingSVG from '../../components/svgs/ShoppingSVG';
// import MarketSVG from '../../components/svgs/MarketSVG';
// import DeliverySVG from '../../components/svgs/DeliverySVG';

export const getServicesData = (t) => [
  {
    id: '1',
    icon: <EventsIconSVG />,
    title: t('ServicesDatasEevent.Événements'),
    routeName: 'Home',
  },
  {
    id: '2',
    icon: <PizzaSVG />,
    title: 'Restaurants',
    routeName: 'Restaurants',
  },
  // {
  //   id: '3',
  //   icon: <CourseSVG />,
  //   title: t('ServicesDatasEevent.Course'),
  //   routeName: 'Course',
  // },
  // {
  //   id: '4',
  //   icon: <PlusIconSVG />,
  //   title: t('ServicesDatasEevent.Plus'),
  //   routeName: 'AllServices',
  // },
  // {
  //   id: '4',
  //   icon: <ShoppingSVG />,
  //   title: 'Shopping',
  //   routeName: 'Shopping',
  // },
  // {
  //   id: '5',
  //   icon: <MarketSVG />,
  //   title: 'Market',
  //   routeName: 'Market',
  // },
  // {
  //   id: '6',
  //   icon: <DeliverySVG />,
  //   title: 'Delivery',
  //   routeName: 'Delivery',
  // },
];
