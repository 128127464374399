import pizzaImage from '../../../assets/images/pizzaImage.png';
import HamburgersImage from '../../../assets/images/hamburgersImage.png';
import Salads from '../../../assets/images/saladsImage.png';
import halal from '../../../assets/images/halaIImage.png';
import Kebab from '../../../assets/images/kabab.jpg';
import sandwichs from '../../../assets/images/sandwichs.jpg';
import pates from '../../../assets/images/pates.jpg';
import grillades from '../../../assets/images/grillades.jpg';
import vegetarien from '../../../assets/images/vegetarien.jpg';
import tacos from '../../../assets/images/tacos.jpg';
import cookies from '../../../assets/images/cookies.jpg';
import gateaux from '../../../assets/images/gateaux.jpg';
import pancakes from '../../../assets/images/pancakes.jpg';
export const allCategoriesTab = [
  {
    id: '1',
    image: pizzaImage,
    title: 'Pizzas',
    routeName: '',
  },
  {
    id: '2',
    image: HamburgersImage,
    title: 'Hamburgers',
    routeName: '',
  },
  {
    id: '3',
    image: Salads,
    title: 'Salads',
    routeName: '',
  },
  {
    id: '4',
    image: halal,
    title: 'Halal',
    routeName: '',
  },
  {
    id: '5',
    image: Kebab,
    title: 'Kebab',
    routeName: '',
  },
  {
    id: '6',
    image: sandwichs,
    title: 'Sandwichs',
    routeName: '',
  },
  {
    id: '7',
    image: pates,
    title: 'Pâtes',
    routeName: '',
  },
  {
    id: '8',
    image: grillades,
    title: 'Grillades',
    routeName: '',
  },
  {
    id: '9',
    image: vegetarien,
    title: 'Végétarien',
    routeName: '',
  },
  {
    id: '10',
    image: tacos,
    title: 'Tacos',
    routeName: '',
  },
  {
    id: '11',
    image: cookies,
    title: 'Cookies',
    routeName: '',
  },
  {
    id: '12',
    image: gateaux,
    title: 'Gâteaux',
    routeName: '',
  },
  {
    id: '13',
    image: pancakes,
    title: 'Pancakes',
    routeName: '',
  },
];
