import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const PhoneSVG = (props) => {
  const {style, ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
      style={style}
      {...restProps}>
      <Path
        stroke="#77838F"
        strokeLinejoin="round"
        strokeWidth={1.25}
        d="M2.333 8.75h9.334m-9.333 0v3.5a.583.583 0 0 0 .583.583h8.166a.583.583 0 0 0 .584-.583v-3.5m-9.334 0v-7a.583.583 0 0 1 .584-.583h8.166a.583.583 0 0 1 .584.583v7"
      />
      <Path
        stroke="#77838F"
        strokeLinecap="round"
        strokeWidth={1.25}
        d="M6.417 10.792h1.167"
      />
    </Svg>
  );
};

export default PhoneSVG;
