import { StyleSheet } from 'react-native';
import Colors from '../../theme/colors';

// CartA Styles
const styles = StyleSheet.create({
    flex1: {
      flex: 1,
    },
    inline: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    container: {
      flex: 1,
      backgroundColor: Colors.background,
    },
    titleContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 16,
      paddingHorizontal: 16,
      paddingBottom: 14,
    },
    titleText: {
      fontWeight: '700',
      fontSize: 26
    },
    productList: {
      // spacing = paddingHorizontal + ActionProductCardHorizontal margin = 12 + 4 = 16
      paddingHorizontal: 12,
    },
    subTotalText: {
      top: -2,
      fontWeight: '500',
      color: Colors.onSurface,
    },
    subTotalPriceText: {
      fontWeight: '700',
      color: Colors.primaryColor,
    },
    bottomButtonContainer: {
      width: '100%',
      paddingVertical: 16,
      paddingHorizontal: 24,
    },
    checkboxContainer: {
      flexDirection: "row",
      marginBottom: 20,
    },
    checkbox: {
      alignSelf: "center",
    },
    label: {
      margin: 8,
    },
    titleContainer: {
      backgroundColor: '#f0f0f0',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingVertical: 16,
      paddingHorizontal: 16,
    },
    secondTitleContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 16,
      paddingLeft: 16,
    },
    friendCartContainer: {
      margin: 16,
      padding: 12,
      borderWidth: 1,
      borderColor: 'rgba(0, 0, 0, 0.08)',
      borderRadius: 4,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    friendCart: {
      padding: 12,
      marginBottom: 16,
      borderWidth: 1,
      borderColor: 'rgba(0, 0, 0, 0.15)',
      borderRadius: 4,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    },
    promoCodeContainer: {
      justifyContent: 'flex-end',
      paddingHorizontal: 12,
    },
    promoCodeContent: {
      flexDirection: 'row',
      alignSelf: 'flex-end',
      alignItems: 'flex-end',
    },
    promoCodeDiscountPriceText: {
      fontWeight: '700',
      color: "#000",
    },
    subTotalDiscountPriceText: {
      fontWeight: '700',
      color: Colors.promo,
    },
    actionContainer: {
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      backgroundColor: Colors.secondaryColor,
      overflow: 'hidden',
    },
    posititveActionContainer: {
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      backgroundColor: Colors.primaryColor,
      overflow: 'hidden',
    },
  });

  
  export default styles;