import React, {useContext, useEffect} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import StripeCheckout from 'react-native-stripe-checkout-webview';
//import StripeCheckout from 'react-stripe-checkout';
import {connect} from 'react-redux';
import {emptyCart} from '../../reducers/cartactions';
import {bindActionCreators} from 'redux';
import {db} from '../../services/firebase';
const STRIPE_PUBLIC_KEY =
  'pk_test_51Iss0qCTdpEbBR7uJaxn7WWXmfWVqliIjTE8zehaxGH0SCI2Q2AkpCGOOrLTjbzwfbHCVLrCxRo5xyuFALXd0MBm00Pfkab9Nj';
import config from '../../config';
import firebase from 'firebase/compat/app';
import {AuthContext} from '../../contexts/context';
function CheckoutC(props) {
  const {user} = useContext(AuthContext);
  const {navigation} = props;

  useEffect(() => {
    (async () => {
      // Update the document title using the browser API
      if (
        'sc_checkout' in props.route.params &&
        props.route.params.sc_checkout == 'success'
      ) {
        let storeid = props.store.id;
        //alert(storeid);
        const storeRef = db.collection('organisers').doc(storeid);
        const increment = firebase.firestore.FieldValue.increment(1);
        await storeRef.update({ordersCount: increment});
        var res = await storeRef.get();
        db.collection('orders')
          .add({
            orderNumber: 100, //res.data().ordersCount.toString(),
            clientid: user.uid,
            storeid: storeid,
            products: props.cart.products,
            status: 'ordered',
            //payment: response.id,
            updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
          })
          .then((docRef) => {
            //console.log("Document written with ID: ", docRef.id);
            props.emptyCart();
            navigation.navigate('Order', {id: docRef.id});
          })
          .catch((error) => {
            navigation.navigate('HomeNavigator');
          });
      }
    })();
  }, []);

  async function handleSuccess(checkoutSessionId) {
    const {navigation} = props;
    let storeid = props.store.id;
    //console.log("checkoutSessionId");
    //console.log(checkoutSessionId);
    if ((props.route.params.mode = 'payment')) {
      const storeRef = db.collection('organisers').doc(storeid);
      const increment = firebase.firestore.FieldValue.increment(1);
      await storeRef.update({ordersCount: increment});
      var res = await storeRef.get();
      db.collection('orders')
        .add({
          orderNumber: res.data().ordersCount.toString(),
          clientid: user.uid,
          storeid: storeid,
          products: props.cart.products,
          status: 'ordered',
          //payment: response.id,
          updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
        })
        .then((docRef) => {
          //console.log("Document written with ID: ", docRef.id);
          props.emptyCart();
          navigation.navigate('Order', {id: docRef.id});
        })
        .catch((error) => {
          alert('error');
          //console.error("Error adding document: ", error);
          navigation.navigate('HomeNavigator');
        });
    }
  }

  return <div>congrats</div>;
  {
    /*<StripeCheckout stripePublicKey={STRIPE_PUBLIC_KEY}
    checkoutSessionInput={{
      sessionId: props.route.params.sessionId,
    }}
    onSuccess={({ checkoutSessionId }) => {
      console.log(`Stripe checkout session succeeded. session id: ${checkoutSessionId}.`);
      handleSuccess(checkoutSessionId);
    }}
    onCancel={() => {
      console.log(`Stripe checkout session cancelled.`);
    }}
  />*/
  }
}

const mapStateToProps = (state) => {
  const {cart, store} = state;
  return {cart, store};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      emptyCart,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutC);
