import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const TrashSVG = (props) => {
  const {style, ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 17 17"
      style={style}
      {...restProps}>
      <Path
        fill="#FF5E75"
        fillRule="evenodd"
        d="M11.278 4.535v-.556a1.111 1.111 0 0 0-1.111-1.11H6.833a1.111 1.111 0 0 0-1.11 1.11v.556H4.055a.556.556 0 1 0 0 1.11h.555v6.112a1.667 1.667 0 0 0 1.667 1.667h4.444a1.666 1.666 0 0 0 1.667-1.667V5.646h.555a.556.556 0 1 0 0-1.111h-1.666Zm-1.111-.556H6.833v.556h3.334v-.556Zm1.11 1.667H5.723v6.111a.556.556 0 0 0 .556.556h4.444a.556.556 0 0 0 .556-.556V5.646Z"
        clipRule="evenodd"
      />
      <Path
        fill="#FF5E75"
        d="M6.833 6.757h1.112v4.444H6.833V6.757Zm2.223 0h1.11v4.444h-1.11V6.757Z"
      />
    </Svg>
  );
};
export default TrashSVG;
