import React from 'react';
import styles from './RestaurantOrderDetailsStyles';
import TitleA from '../titleA/TitleA';
import {Text, View} from 'react-native';
import Colors from '../../theme/colors';

const ListProduct = ({product}) => {
  return (
    <>
      <View style={styles.title}>
        <TitleA style={{color: Colors.normalGray}}>{product?.quantity}</TitleA>
        <TitleA style={{flexBasis: '70%', color: Colors.normalGray}}>
          {product?.name}
        </TitleA>
        <TitleA style={{color: Colors.normalGray}}>
          {product.price * product?.quantity}€
        </TitleA>
      </View>
    </>
  );
};

const RestaurantCart = ({user}) => {
  if (user?.products)
    return (
      <>
        <TitleA>{user.clientName}</TitleA>
        {user?.products.map((product) => (
          <ListProduct product={product} />
        ))}
      </>
    );
  else return null;
};

export default RestaurantCart;
