import React from 'react';
import Colors from '../../theme/colors';
import {Text, TouchableOpacity, StyleSheet, View} from 'react-native';

const styles = StyleSheet.create({
    // button: {
    //   paddingVertical: 20,
    //   paddingHorizontal: 15,
      
    //   border : "1px solid #77838F" ,
    //   alignSelf: 'stretch',
    //   borderRadius: 15,
    // },
   
    //   text : {
    //     backgroundColor: Colors.darkGray, 
    //     fontFamily: "Bai Jamjuree",
    //     fontSize: 16,
    //     fontStyle: 'normal',
    //     fontWeight: 600,
    //     lineHeight: "150%", 
    //     letterSpacing: 0.64,
     
    // }

});

const ButtonBackground = ({
  children,
  style,
  styleViewText = {},
  onPress,
  color,
  disabled,
  ...props
}) => (
  <TouchableOpacity
    activeOpacity={0.8}
    style={[
    //   styles.button,
      style,
      
    ]}
    onPress={onPress}
    disabled={disabled}
    {...props}>
    <View style={[styles.buttonTextContainer, styleViewText]}>
      <Text style={[styles.buttonText, {color: color || Colors.background}]}>
        {children}
      </Text>
    </View>
  </TouchableOpacity>
);

export default ButtonBackground;
