import React from 'react';
import styles from './AllServicesStyles';
import Colors from '../../theme/colors';
import {
  FlatList,
  Image,
  SafeAreaView,
  ScrollView,
  StatusBar,
  View,
} from 'react-native';
import profileImage from '../../../assets/images/profileImage.png';
import SubheadingA from '../../components/subheadingA/SubheadingA';
import BodyA from '../../components/bodyA/BodyA';
import TitleA from '../../components/titleA/TitleA';
import BackArrowSVG from '../../components/svgs/BackArrowSVG';
import TouchableItem from '../../components/TouchableItem';
import {getAllServicesData} from './AllServicesData';
import SearchA from '../search/SearchA';
import SearchBarA from '../../components/searchBarA/searchBarA';
import {useTranslation} from 'react-i18next';

const imgHolder = require('../../../assets/images/imgholder.png');

const Separator = () => {
  return <View style={styles.separator} />;
};
const AllServices = ({navigation}) => {
  const {t} = useTranslation();
  const AllServicesData = getAllServicesData(t);

  const servicesKeyExtractor = (item) => item.id;

  const renderService = ({item}) => {
    console.log('itemm', item);
    return (
      <TouchableItem
        onPress={() => {
          navigation.navigate(item.routeName);
        }}>
        <View style={styles.item}>
          <View style={styles.icon}>{item.icon}</View>
          <View style={styles.title}>
            <TitleA style={{color: Colors.normalGray}}>{item.title}</TitleA>
          </View>
          <View style={styles.buttonContainer}>
            <BackArrowSVG fill={Colors.lightGray} style={styles.button} />
          </View>
        </View>
      </TouchableItem>
    );
  };

  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
      <ScrollView style={styles.scrollView}>
        <View style={{marginTop: 30}}>
          <SearchBarA placeholder={'Rechercher un service'} />
        </View>

        <View style={styles.flatListContainer}>
          <FlatList
            showsVerticalScrollIndicator={false}
            data={AllServicesData}
            alwaysBounceHorizontal={false}
            keyExtractor={servicesKeyExtractor}
            renderItem={renderService}
            contentContainerStyle={styles.flatList}
            ItemSeparatorComponent={Separator}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default AllServices;
