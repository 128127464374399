import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
const ShopSVG = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    fill="none"
    viewBox="0 0 21 21"
    {...props}>
    <Path
      stroke="#77838F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M4.403 8.458A1.52 1.52 0 0 1 5.918 7.06h9.164a1.52 1.52 0 0 1 1.515 1.398l.61 7.6a1.52 1.52 0 0 1-1.514 1.642H5.307a1.52 1.52 0 0 1-1.515-1.642l.61-7.6Z"
    />
    <Path
      stroke="#77838F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13.54 9.34v-3.8a3.04 3.04 0 0 0-6.08 0v3.8"
    />
  </Svg>
);
export default ShopSVG;
