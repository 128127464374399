import React from 'react';
import {
  FlatList,
  Image,
  SafeAreaView,
  ScrollView,
  Text,
  StatusBar,
  TouchableOpacity,
  View,
} from 'react-native';
import Colors from '../../../theme/colors';
import FranceFlagSVG from '../../../components/svgs/FranceFlagSVG';
import TitleA from '../../../components/titleA/TitleA';
import {useTranslation} from 'react-i18next';
import AmericeFlag from '../../../../assets/images/americaFlag.jpg';
import FranceFlag from '../../../../assets/images/franceFlag.jpg';
import flagAmerica from '../../../../assets/images/flagAmerica.jpg';
const ChangeLangue = () => {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <View style={{flex: 1, marginBottom: 5, padding: 25}}>
      <TouchableOpacity
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: '40px',
          gap: 40,
        }}
        onPress={() => changeLanguage('fr')}>
        <FranceFlagSVG style={{width: 50}} />

        <TitleA style={{color: Colors.normalGray}}>Français</TitleA>
      </TouchableOpacity>
      <TouchableOpacity
        style={{flexDirection: 'row', alignItems: 'center', gap: 40}}
        onPress={() => changeLanguage('en')}>
        <Image
          source={flagAmerica}
          alt="f"
          style={{
            width: 50,
            height: 50,
            borderRadius: 25,
            overflow: 'hidden',
          }}
        />
        <TitleA style={{color: Colors.normalGray}}>English</TitleA>
      </TouchableOpacity>
    </View>
  );
};
export default ChangeLangue;
