import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';

function ConfirmedPurchaseSVG(props) {
  const {style, ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 164 170"
      fill="none"
      style={style}
      {...restProps}>
      <G clipPath="url(#a)">
        <Path
          fill="#89E56A"
          d="M25.102 147.223h113.796C154.361 132.414 164 111.662 164 88.653c0-44.925-36.716-81.348-82-81.348S0 43.728 0 88.652c0 23.01 9.64 43.795 25.102 58.571Z"
        />
        <Path
          fill="#65D33C"
          d="M82 160.039c36.97 0 66.939-29.731 66.939-66.406 0-36.675-29.969-66.406-66.939-66.406-36.969 0-66.939 29.73-66.939 66.406 0 36.675 29.97 66.406 66.94 66.406Z"
        />
        <Path
          fill="#47477F"
          d="M128.857 155.424a81.738 81.738 0 0 0 10.04-8.201V13.281C138.897 5.943 132.906 0 125.51 0H38.49c-7.397 0-13.388 5.943-13.388 13.281v133.942c3.112 2.988 6.493 5.711 10.04 8.201h93.715Z"
        />
        <Path
          fill="#3B3868"
          d="M58.571 155.424V9.961c-2.778 0-5.02-2.225-5.02-4.98C53.55 2.224 55.792 0 58.57 0H38.489c-7.396 0-13.387 5.943-13.387 13.281v133.942c3.112 2.988 6.493 5.711 10.04 8.201h23.429Z"
        />
        <Path
          fill="#42C3FC"
          d="M128.857 13.281v142.143C115.57 164.621 99.437 170 82 170c-17.438 0-33.57-5.379-46.857-14.576V13.281c0-1.826 1.506-3.32 3.346-3.32h87.021c1.841 0 3.347 1.494 3.347 3.32Z"
        />
        <Path
          fill="#1AA9E8"
          d="M58.571 9.96c-1.205 0-2.276.665-2.878 1.628a3.307 3.307 0 0 0-.469 1.693v152.27a81.426 81.426 0 0 1-20.081-10.16V13.281c0-1.826 1.506-3.32 3.346-3.32h20.082Z"
        />
        <Path
          fill="#fff"
          d="m55.86 135.9 7.565-3.918c3.983-2.058 8.669-2.091 12.752-.199 6.794 3.154 14.392 4.914 22.357 4.947 29.52.1 53.719-23.806 53.752-53.058.034-29.385-23.997-53.191-53.618-53.191-29.52.033-53.618 24.038-53.484 53.324.034 7.935 1.808 15.44 4.987 22.179a14.25 14.25 0 0 1-.2 12.651l-3.95 7.504a7.205 7.205 0 0 0 1.305 8.466c2.243 2.225 5.724 2.756 8.535 1.295Z"
        />
        <Path
          fill="#FFA24D"
          d="M68.71 54.572A4 4 0 0 1 72.689 51h48.441a3.999 3.999 0 0 1 3.977 3.572l6.234 57.875a4 4 0 0 1-3.977 4.428H66.454a4 4 0 0 1-3.977-4.428l6.234-57.875Z"
        />
        <Path
          fill="#fff"
          d="M96.909 91.574c-8.77 0-15.898-7.072-15.898-15.771V63.02a2.988 2.988 0 0 1 3.012-2.989c1.64 0 3.012 1.328 3.012 2.989v12.783c0 5.412 4.452 9.828 9.907 9.828 5.456 0 9.907-4.416 9.907-9.828V63.02a2.988 2.988 0 0 1 3.012-2.989c1.64 0 3.013 1.328 3.013 2.989v12.783c-.067 8.699-7.196 15.771-15.965 15.771Z"
        />
        <Path
          fill="#32CC77"
          d="M159.555 57.06c0 14.942-12.216 27.061-27.277 27.061-15.062 0-27.278-12.12-27.278-27.06C105 42.118 117.216 30 132.278 30c15.061.033 27.277 12.152 27.277 27.06Z"
        />
        <Path
          fill="#fff"
          d="M129.432 73.463a2.79 2.79 0 0 1-2.443-1.428l-7.43-13.281c-.737-1.328-.268-3.022 1.071-3.752 1.338-.73 3.045-.266 3.782 1.063l4.819 8.632 10.677-21.781c.669-1.361 2.343-1.959 3.715-1.295 1.372.664 1.975 2.324 1.272 3.686l-13.02 26.562a2.836 2.836 0 0 1-2.443 1.56c.067.034.034.034 0 .034Z"
        />
        <Path
          fill="#E9EFFF"
          d="M82 83.639c-.033-22.944 14.627-42.5 35.143-49.938-5.756-2.092-11.982-3.22-18.475-3.22-29.52.033-53.618 24.038-53.484 53.324.034 7.935 1.808 15.44 4.987 22.179a14.25 14.25 0 0 1-.2 12.651l-3.95 7.504a7.205 7.205 0 0 0 1.305 8.466c2.243 2.225 5.724 2.756 8.535 1.295l7.564-3.918c3.983-2.058 8.669-2.091 12.752-.199 6.794 3.155 14.392 4.914 22.357 4.947a53.33 53.33 0 0 0 18.609-3.253c-20.45-7.504-35.11-27.028-35.142-49.838Z"
          style={{
            mixBlendMode: 'multiply',
          }}
        />
        <Path
          fill="#1AA9E8"
          d="M66.939 23.242c1.848 0 3.347-1.486 3.347-3.32 0-1.834-1.499-3.32-3.347-3.32-1.849 0-3.347 1.486-3.347 3.32 0 1.834 1.498 3.32 3.347 3.32ZM97.06 23.242c1.85 0 3.348-1.486 3.348-3.32 0-1.834-1.499-3.32-3.347-3.32-1.849 0-3.347 1.486-3.347 3.32 0 1.834 1.498 3.32 3.347 3.32ZM87.02 23.242H76.98c-1.841 0-3.347-1.494-3.347-3.32 0-1.826 1.506-3.32 3.347-3.32h10.04c1.841 0 3.348 1.494 3.348 3.32 0 1.826-1.507 3.32-3.347 3.32Z"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h164v170H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default ConfirmedPurchaseSVG;
