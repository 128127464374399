import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const DetailsSVG = (props) => {
  const {style, ...restProps} = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
      style={style}
      {...restProps}>
      <Path
        stroke="#2FBF86"
        strokeLinejoin="round"
        d="M10.792 2.333H3.208a.583.583 0 0 0-.583.584v9.333c0 .322.261.583.583.583h7.584a.583.583 0 0 0 .583-.583V2.917a.583.583 0 0 0-.583-.584Z"
      />
      <Path
        stroke="#2FBF86"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.25 1.167v1.75m3.5-1.75v1.75M4.667 5.542h4.666M4.667 7.875h3.5m-3.5 2.333H7"
      />
    </Svg>
  );
};
export default DetailsSVG;
