import React from 'react';
import styles from './ParametersStyles';
import Colors from '../../theme/colors';
import {
  FlatList,
  Image,
  SafeAreaView,
  ScrollView,
  StatusBar,
  Button,
  View,
} from 'react-native';
import profileImage from '../../../assets/images/profileImage.png';
import SubheadingA from '../../components/subheadingA/SubheadingA';
import BodyA from '../../components/bodyA/BodyA';
import TitleA from '../../components/titleA/TitleA';
import BackArrowSVG from '../../components/svgs/BackArrowSVG';
import TouchableItem from '../../components/TouchableItem';
import {getProfileParametersData} from './profileParametersData';
import {useTranslation} from 'react-i18next';

const imgHolder = require('../../../assets/images/imgholder.png');

const Separator = () => {
  return <View style={styles.separator} />;
};

const Parameters = ({navigation}) => {
  const {t, i18n} = useTranslation();

  const profileParametersData = getProfileParametersData(t);
  const eventKeyExtractor = (item) => item.id;

  const renderprofileParameters = ({item}) => {
    return (
      <TouchableItem
        onPress={() => {
          navigation.navigate(item.routeName);
        }}>
        <View style={styles.item}>
          <View style={styles.icon}>{item.icon}</View>
          <View style={styles.title}>
            <TitleA style={{color: Colors.normalGray}}>{item.title}</TitleA>
          </View>
          <TouchableItem
            style={styles.buttonContainer}
            onPress={() => {
              navigation.navigate(item.routeName);
            }}>
            <BackArrowSVG fill={Colors.lightGray} style={styles.button} />
          </TouchableItem>
        </View>
      </TouchableItem>
    );
  };

  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
      <ScrollView style={styles.scrollView}>
        <View style={styles.header}>
          <View style={styles.userImageContainer}>
            <Image
              defaultSource={imgHolder}
              source={profileImage}
              style={styles.userImage}
              resizeMode="cover"
            />
          </View>

          <View style={styles.userInfoContainer}>
            <SubheadingA style={{color: Colors.darkGray}}>
              Samuel le Blanc
            </SubheadingA>
            <BodyA style={{color: Colors.normalGray}}>
              Samuel.lb@gmail.com
            </BodyA>
          </View>
        </View>
        <View style={styles.flatListContainer}>
          <FlatList
            data={profileParametersData}
            showsHorizontalScrollIndicator={false}
            alwaysBounceHorizontal={false}
            keyExtractor={eventKeyExtractor}
            renderItem={renderprofileParameters}
            contentContainerStyle={styles.flatList}
            ItemSeparatorComponent={Separator}
          />
        </View>
        {/* <Button title="English" onPress={() => changeLanguage('en')} />
        <Button title="French" onPress={() => changeLanguage('fr')} /> */}
      </ScrollView>
    </SafeAreaView>
  );
};

export default Parameters;
