import React from 'react';
import VectorSvg from './VectorSVG';
import ObjectsSvg from './ObjectsSvg';
import {View} from 'react-native';

const ObjectVectorSvg = () => {
  return (
    <View>
      <ObjectsSvg />
      <VectorSvg />
    </View>
  );
};
export default ObjectVectorSvg;
