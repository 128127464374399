import {StyleSheet} from 'react-native';
import Colors from '../../theme/colors';

const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  suivieCommandeContainer: {
    background: '#F7F6F0',
    paddingHorizontal: 24,
  },

  imageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 25,
    borderBottomWidth: 1,
    borderColor: Colors.lightGray,
  },
  detailA: {
    justifyContent: 'space-between',
    // paddingVertical: 8,
    flexDirection: 'row',
  },
  textDetailA: {
    color: 'var(--Dark-Grey, #1D1F21)',
    fontFamily: 'Bai Jamjuree',
    fontSize: '16px',
    fontStyle: 'normal',

    fontWeight: 600,
    lineHeight: '150%' /* 30px */,
  },
  allDetailesA: {
    paddingLeft: 15,
  },
  titleTextDetaileContainer: {
    color: 'var(--Dark-Grey, #1D1F21)',
    fontFamily: 'Bai Jamjuree',
    fontSize: '14px',
    fontStyle: 'normal',

    fontWeight: 300,
    lineHeight: '150%' /* 30px */,
  },
  textMapingCommande: {
    flexDirection: 'row',
    gap: 7,
  },
  image: {
    width: 170,
    height: 170,
  },
  container: {
    paddingHorizontal: 25,
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderColor: Colors.lightGray,
  },
  bodyRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: 10,
  },
  left: {
    flexDirection: 'row',
    width: '45%',
    alignItems: 'center',
  },
  rowIcon: {
    width: 20,
    height: 20,
    marginRight: 5,
  },
  right: {
    width: '55%',
    textAlign: 'right',
  },
});

export default styles;
