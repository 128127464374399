import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
const PromoFrame = (props) => {
  const {style, fill, ...restProps} = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 36"
      style={style}
      {...restProps}>
      <Path
        // fill="#8D09FE"
        fill={fill}
        d="M0 12a2 2 0 0 1 2-2h31.599a1 1 0 0 1 .824 1.566L30 18l4.423 6.433A1 1 0 0 1 33.599 26H2a2 2 0 0 1-2-2V12Z"
      />
    </Svg>
  );
};
export default PromoFrame;
