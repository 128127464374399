import React, {useEffect, useMemo, useState} from 'react';
import Colors from '../../theme/colors';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  View,
  FlatList,
} from 'react-native';
import TitleA from '../../components/titleA/TitleA';
import styles from './ArticlesPayerStyles';
import PlusSVG2 from '../../components/svgs/PlusSVG2';
import {useNavigation} from '@react-navigation/native';
import ButtonA from '../../components/buttonA/ButtonA';
import {db} from '../../services/firebase.js';
import {AuthContext} from '../../contexts/context.js';
import MinusSVG2 from '../../components/svgs/MinusSVG2';
import TouchableItem from '../../components/TouchableItem';
import BodyA from '../../components/bodyA/BodyA';
import NoOrdersSVG from '../../components/svgs/NoOrdersSVG';

const ArticleItem = ({item, setAddITProduct, addITProduct}) => {
  const [quantity, setQuantity] = useState(0);
  let indexOfProduct = addITProduct.findIndex(
    (product) => product.id === item.id,
  );
  const handleMinus = (quantity) => {
    let productList = [...addITProduct];
    if (quantity > 0 && quantity <= item.quantity) {
      setQuantity(quantity - 1);
      if (indexOfProduct > -1) {
        productList[indexOfProduct] = {
          ...item,
          quantity: productList[indexOfProduct].quantity - 1,
        };

        if (productList[indexOfProduct].quantity === 0) {
          productList = productList.filter((product) => product.id !== item.id);
          return setAddITProduct(() => [...productList]);
        }
        setAddITProduct(() => [...productList]);
      } else {
        productList = [...productList, {...item, quantity: item.quantity - 1}];
        setAddITProduct(() => [...productList]);
      }
    }
  };

  const handlePlus = (quantity) => {
    let productList = [...addITProduct];
    if (quantity < item.quantity) {
      setQuantity(quantity + 1);
      if (indexOfProduct > -1) {
        productList[indexOfProduct] = {
          ...item,
          quantity: productList[indexOfProduct].quantity + 1,
        };
        setAddITProduct(() => [...productList]);
      } else {
        productList = [...productList, {...item, quantity: 1}];
        setAddITProduct(() => [...productList]);
      }
    }
  };

  return (
    <View style={[styles.containerText, {paddingVertical: 10}]}>
      <View style={styles.articleRow}>
        <Text>{item.quantity}</Text>
        <Text>{item.name}</Text>
      </View>
      <Text style={{paddingRight: 54}}>{item.total}€</Text>
      <View style={{flexDirection: 'row', alignItems: 'center', gap: 12}}>
        <TouchableItem onPress={() => handleMinus(quantity)}>
          <MinusSVG2 fill={Colors.darkGreen} stroke={Colors.darkGreen} />
        </TouchableItem>
        <Text style={{paddingHorizontal: 12}}>{quantity}</Text>
        <TouchableItem onPress={() => handlePlus(quantity)}>
          <PlusSVG2 fill={Colors.darkGreen} stroke={Colors.darkGreen} />
        </TouchableItem>
      </View>
    </View>
  );
};

const ArticlesPayer = ({navigation}) => {
  const [listProduct, setListProduct] = React.useState([]);
  const [addITProduct, setAddITProduct] = React.useState([]);
  const [groupeTableData, setGroupeTableData] = React.useState();
  // const [currentUserData, setCurrentUserData] = React.useState();
  let unsubscribeListener = React.useRef(null);
  const {user} = React.useContext(AuthContext);

  const totalPrice = useMemo(() => {
    let total = 0.0;
    listProduct?.forEach((product) => {
      let {price, isFree} = product;
      const {quantity, iscomposite, composition} = product;
      var compositionprice = iscomposite
        ? composition.reduce(function (a, b) {
            let price = b.quantity > 0 ? b.quantity * b.price : b.price;
            return a + price;
          }, 0)
        : 0;
      total += ((isFree ? 0 : price) + compositionprice) * quantity;
    });
    return total;
  }, [listProduct]);

  const totalPayedPrice = useMemo(() => {
    let total = 0.0;
    addITProduct?.forEach((product) => {
      let {price, isFree} = product;
      const {quantity, iscomposite, composition} = product;
      var compositionprice = iscomposite
        ? composition.reduce(function (a, b) {
            let price = b.quantity > 0 ? b.quantity * b.price : b.price;
            return a + price;
          }, 0)
        : 0;
      total += ((isFree ? 0 : price) + compositionprice) * quantity;
    });
    return total;
  }, [addITProduct, addITProduct]);

  const RenderRestaurantItem = ({item}) => {
    return (
      <ArticleItem
        setAddITProduct={setAddITProduct}
        addITProduct={addITProduct}
        item={item}
      />
    );
  };

  const aggregatedSelectProducts = (data) =>
    data.reduce(
      (acc, obj) => {
        obj.addITProduct?.forEach((product) => {
          const foundIndex = acc.findIndex((item) => item.id === product.id);
          if (foundIndex !== -1) {
            acc[foundIndex].quantity += product.quantity;
          } else {
            acc.push({...product});
          }
        });
        return acc;
      },

      [],
    );

  const aggregatedProducts = (data) =>
    data.reduce((acc, obj) => {
      obj.products?.forEach((product) => {
        const foundIndex = acc.findIndex((item) => item.id === product.id);
        if (foundIndex !== -1) {
          acc[foundIndex].quantity += product.quantity;
        } else {
          acc.push({...product});
        }
      });
      return acc;
    }, []);

  const checkUserTableGroupeData = async () => {
    return db
      .collection('users')
      .doc(user.uid)
      .get()
      .then(async (data) => {
        const userData = data.data();
        return userData.activeGroupedTable;
      });
  };

  const queryFn = async () => {
    const tableGroupeData = await checkUserTableGroupeData();
    if (tableGroupeData) {
      db.collection('groupedorders')
        .doc(tableGroupeData)
        .onSnapshot(async (groupedordersDoc) => {
          const groupedordersData = groupedordersDoc.data();
          setGroupeTableData({...groupedordersData, id: groupedordersDoc.id});

          const listSelctedProduct = aggregatedSelectProducts(
            groupedordersData.users,
          );
          let AlistProduct = aggregatedProducts(groupedordersData.users);

          if (listSelctedProduct.length > 0) {
            AlistProduct = AlistProduct.map((p) => {
              const IndexOfSelectedList = listSelctedProduct.findIndex(
                (product) => p.id === product.id,
              );
              if (IndexOfSelectedList > -1) {
                const lSquantitity =
                  listSelctedProduct[IndexOfSelectedList].quantity;
                p.quantity =
                  lSquantitity >= p.quantity ? 0 : p.quantity - lSquantitity;
                return p;
              } else return p;
            });
          }
          let isReady = AlistProduct.every((l) => l.quantity === 0);
          if (isReady) {
            await db
              .collection('groupedorders')
              .doc(tableGroupeData)
              .set({isReady: true}, {merge: true});
          }
          setListProduct(AlistProduct);
        });
    }
  };

  useEffect(() => {
    queryFn();
    // return () => {
    //   unsubscribeListener.current();
    // };
  }, []);

  const onHandleConfirm = async () => {
    let productSelected = [...addITProduct];
    const currentUserData = groupeTableData.users.find(
      (usr) => usr.userId === user.uid,
    );
    if (currentUserData?.addITProduct) {
      let oldSelectedList = currentUserData.addITProduct;
      productSelected = oldSelectedList.map((item) => {
        const indexOf = addITProduct.findIndex((a) => a.id === item.id);
        if (indexOf > -1) {
          item.quantity = item.quantity + addITProduct[indexOf].quantity;
          return item;
        } else return item;
      });
    }
    const usersData = groupeTableData.users.map((item) => {
      if (item.userId === user.uid) {
        return {
          ...item,
          addITProduct: productSelected,
          totalPayedPrice,
        };
      }
      return item;
    });
    await db
      .collection('groupedorders')
      .doc(groupeTableData?.id)
      .set({users: JSON.parse(JSON.stringify(usersData))}, {merge: true});
    navigation.navigate('Bill');
  };

  const eventKeyExtractor = (item, index) =>
    item && item.id ? item.id : index.toString();

  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
      {!groupeTableData ? (
        <View
          style={{
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 128,
          }}>
          <NoOrdersSVG />
          <TitleA
            style={{
              color: Colors.darkGray,
              marginVertical: 26,
            }}>
            No orders
          </TitleA>
          <BodyA
            style={{
              color: Colors.normalGray,
              fontFamily: 'SemiBold',
            }}>
            You don't have any orders yet
          </BodyA>
        </View>
      ) : (
        <ScrollView
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}>
          <View style={styles.container}>
            <View style={styles.containerText}>
              <TitleA> Total à partager </TitleA>
              <TitleA>{totalPrice} €</TitleA>
            </View>
          </View>
          <View style={{paddingHorizontal: 24}}>
            <View style={[styles.separator, {marginVertical: 16}]} />
          </View>
          <View style={{paddingHorizontal: 24, paddingBottom: 16}}>
            <TitleA> {groupeTableData.name} </TitleA>
          </View>

          <View style={styles.screenContainerText}>
            <FlatList
              data={listProduct}
              showsHorizontalScrollIndicator={false}
              alwaysBounceHorizontal={false}
              keyExtractor={eventKeyExtractor}
              renderItem={RenderRestaurantItem}
              // contentContainerStyle={styles.contentContainerStyle}
              ItemSeparatorComponent={() => {
                return <View style={[styles.separator, {marginVertical: 8}]} />;
              }}
            />
          </View>

          <View style={{padding: 24}}>
            <View style={styles.containerScreenPayment}>
              <View style={styles.containerPayement}>
                <TitleA> Votre paiement </TitleA>
                <TitleA
                  style={[
                    styles.titleArticle,
                    {color: 'var(--Dark-Green, #2FBF86)'},
                  ]}>
                  {' '}
                  {totalPayedPrice}€
                </TitleA>
              </View>
            </View>

            <View style={{paddingTop: 16}}>
              <ButtonA
                style={{
                  backgroundColor: Colors.darkGreen,
                  height: 65,
                }}
                disabled={addITProduct.length == 0}
                onPress={() => onHandleConfirm()}>
                {' '}
                Confirmer
              </ButtonA>
            </View>
          </View>
        </ScrollView>
      )}
    </SafeAreaView>
  );
};
export default ArticlesPayer;
