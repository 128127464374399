import React, {useContext, useEffect, useState, useRef} from 'react';
import styles from './TicketCoordinatesBStyles';
import {
  ScrollView,
  StatusBar,
  Text,
  View,
  TextInput,
  Image,
  ActivityIndicator,
} from 'react-native';
import Colors from '../../theme/colors';
import {SafeAreaView} from 'react-native';
import SmartphoneSVG from '../../components/svgs/SmartphoneSVG';
import TitleA from '../../components/titleA/TitleA';
import SubheadingA from '../../components/subheadingA/SubheadingA';
import EmailSVG from '../../components/svgs/EmailSVG';
import PhoneSVG from '../../components/svgs/PhoneSVG';
import InstagramSVG from '../../components/svgs/InstagramSVG';
import BodyA from '../../components/bodyA/BodyA';
import TouchableItem from '../../components/TouchableItem';
import CheckboxSVG from '../../components/svgs/CheckboxSVG';
import checkbox from '../../../assets/images/Checkbox.png';
import BackArrowSVG from '../../components/svgs/BackArrowSVG';
import ButtonA from '../../components/buttonA/ButtonA';
import CartSVG from '../../components/svgs/CartSVG';
import DropDownInput from '../../components/dropDownInput/DropDownInput';
import {useNavigation} from '@react-navigation/native';

import firebase from 'firebase/compat';
import {db, FUNCTIONS} from '../../services/firebase';
import {httpsCallable} from 'firebase/functions';
// contexts
import {AuthContext} from '../../contexts/context';
import Constants from 'expo-constants';
import {loadStripe} from '@stripe/stripe-js';
import {setReferral, emptyCart} from '../../reducers/cartactions';
import {connect, useSelector} from 'react-redux';
import {bindActionCreators} from 'redux';
// import Swiper from 'react-native-swiper';
import Swiper from 'react-native-swiper/src';

import getEnvVars from '../../config';
import GenderSVG from '../../components/svgs/GenderSVG';
import TimeSVG from '../../components/svgs/TimeSVG';
import CalendarSVG from '../../components/svgs/CalendarSVG';
import AgeSVG from '../../components/svgs/AgeSVG';
import {useMemo} from 'react';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

const {paiement, api} = getEnvVars();
const stripePromise = loadStripe(paiement.publishableKey);

// const genders = [
//   'Male' ,
//   'Female' ,
//   'Other',
// ];
// t('RestaurantBill.Choisissez comment payer la table groupée')
const accounts = [
  'Account 1',
  'Account 2',
  'Account 3',
  'Account 4',
  'Account 5',
];

const checkboxLabels = [
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida eget ultricies pharetra scelerisque duis cursus.',
  'Save my details',
];

function isEmail(emailAdress) {
  let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  if (emailAdress.match(regex)) return true;
  else return false;
}

const TicketCoordinatesB = (props) => {
  const navigation = useNavigation();
  const {user} = useContext(AuthContext);
  // console.log('user', user ? true : false);

  const {route, emptyCart} = props;

  const [event, setEvent] = useState([]);
  const swiperRef = useRef(null);

  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const {referralID} = useSelector((state) => state.referralID);
  const [listCoordinate, setListCoordinate] = useState([]);

  const createEmptyParticipant = (participantInfo) => {
    const participant = {
      name: '',
      nameFocused: false,
      email: '',
      emailFocused: false,

      errors: {},
    };

    participantInfo?.map((p) => {
      participant[p.field] = '';
      participant[`${p.field}Focused`] = false;
    });

    return participant;
  };

  // ----------------Age array---------------
  const ages = [];
  for (let age = 18; age <= 65; age++) {
    ages.push(`${age} years old`);
  }
  // --------------------------------------

  // Function to get the month name
  const getMonthName = (monthIndex) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return months[monthIndex];
  };

  useEffect(() => {
    const queryFn = async (eventId) => {
      try {
        await db
          .collection('events')
          .doc(eventId)
          .get()
          .then(async (eventDoc) => {
            const docdata = eventDoc.data();
            if (docdata) {
              let listProducts = props?.route?.params?.options.products;
              const isMultiple = listProducts.some((lp) => lp.isMultiple);

              const list = isMultiple
                ? listProducts?.flatMap((product) => {
                    const isMultiple = product?.isMultiple || false;
                    const quantity = product?.quantity || 1;

                    return Array.from({length: quantity}, (_, index) => {
                      const participants = Array.from(
                        {length: product?.personsNumber || 1},
                        () => createEmptyParticipant(docdata.participantInfo),
                      );

                      const obj = {
                        id: `${product.id}-${index + 1}`,
                        idProduct: product.id,
                        order: index + 1,
                        isMultiple,
                        personsNumber: product?.personsNumber || null,
                        participants,
                      };

                      if (isMultiple) {
                        obj.societyInfo = {
                          name: '',
                          address: '',
                          codePostal: '',
                          siret: '',
                          nameFocused: false,
                          addressFocused: false,
                          postalCodeFocused: false,
                          siretFocused: false,
                          errors: {},
                        };
                      }

                      return obj;
                    });
                  })
                : [
                    {
                      id: listProducts[0].id,
                      isMultiple: false,
                      participants: Array.from({length: 1}, () =>
                        createEmptyParticipant(docdata.participantInfo),
                      ),
                    },
                  ];

              setListCoordinate(list);

              setEvent(docdata);
            } else setEvent(null);
          });
      } catch (error) {
        console.log('error', error);
      }
    };

    if (route?.params?.eventId) queryFn(route?.params?.eventId);
  }, [route, user, props.route]);

  const handleSocietyChange = (id, field, value) => {
    setListCoordinate(
      listCoordinate.map((item) => {
        if (item.id === id) {
          const updatedSocietyInfo = {
            ...item.societyInfo,
            [field]: value,
          };
          return {...item, societyInfo: updatedSocietyInfo};
        }
        return item;
      }),
    );
  };

  const handleChange = (id, index, field, value) => {
    setListCoordinate(
      listCoordinate.map((item) => {
        if (item.id === id) {
          const updatedParticipants = [...item.participants];
          updatedParticipants[index] = {
            ...updatedParticipants[index],
            [field]: value,
          };
          return {...item, participants: updatedParticipants};
        }
        return item;
      }),
    );
  };

  const handleFocusChange = (id, index, field, focused) => {
    if (index !== undefined) {
      setListCoordinate(
        listCoordinate.map((item) => {
          if (item.id === id) {
            const updatedParticipants = [...item.participants];
            updatedParticipants[index] = {
              ...updatedParticipants[index],
              [`${field}Focused`]: focused,
            };
            return {...item, participants: updatedParticipants};
          }
          return item;
        }),
      );
    }
  };

  const handleSocietyFocusChange = (id, index, field, focused) => {
    setListCoordinate(
      listCoordinate.map((item) => {
        if (item.id === id) {
          const updatedSocietyInfo = {
            ...item.societyInfo,
            [`${field}Focused`]: focused,
          };
          return {...item, societyInfo: updatedSocietyInfo};
        }
        return item;
      }),
    );
  };

  const validateFields = () => {
    const newParticipants = listCoordinate?.map((lc) => {
      // Validate participants
      const participantsData = lc.participants.map((participant, index) => {
        let isNotEmpty = Object.keys(participant)
          .filter((key) => fieldsOfCoordinates.includes(key))
          .some((key) => Boolean(participant[key]));

        if (index === 0 || (index > 0 && isNotEmpty)) {
          const participantErrors = Object.fromEntries(
            Object.keys(participant)
              .filter((field) => {
                if (fieldsOfCoordinates.includes(field)) {
                  if (field === 'email' && !isEmail(participant[field].trim()))
                    return true;
                  if (field === 'name' && !participant[field].trim())
                    return true;
                  if (
                    checkIfRequired(field, event.participantInfo) &&
                    !participant[field].trim()
                  )
                    return true;
                  return false;
                }
              })
              .map((field) => [field, true]),
          );
          return {...participant, errors: participantErrors};
        } else return {...participant, errors: {}};
      });

      const societyInfoErrors = lc.societyInfo
        ? Object.fromEntries(
            Object.keys(lc.societyInfo)
              .filter(
                (field) =>
                  fieldsSociety.includes(field) && !lc.societyInfo[field],
              )
              .map((field) => [field, true]),
          )
        : {};

      const societyInfoData = lc.societyInfo
        ? {...lc.societyInfo, errors: societyInfoErrors}
        : null;

      return {
        ...lc,
        participants: participantsData,
        societyInfo: societyInfoData,
      };
    });

    setListCoordinate(newParticipants);

    return (
      newParticipants.every((p) =>
        p.participants.every((p) => Object.keys(p.errors).length === 0),
      ) &&
      newParticipants.every(
        (p) => !p.societyInfo || Object.keys(p.societyInfo.errors).length === 0,
      )
    );
  };

  const filterFields = (obj, fieldArray) => {
    const result = {};
    for (const key of fieldArray) {
      if (obj.hasOwnProperty(key)) {
        result[key] = obj[key];
      }
    }
    return result;
  };

  const handleFinalizePress = async () => {
    if (validateFields()) {
      try {
        let isMultiple = listCoordinate.some((lc) => lc.isMultiple);
        let userinfos = {
          name: '',
          email: '',
        };

        if (!isMultiple) {
          userinfos = listCoordinate[0].participants.map((att) =>
            filterFields(att, fieldsOfCoordinates),
          )[0];
          await db
            .collection('users')
            .doc(user.uid)
            .set(userinfos, {merge: true});
        }

        const {options} = props.route.params;

        var tier = 'food';

        const {products, promoCodeDiscount, shouldCreateOrder, goid} = options;
        const ownerid = products[0]?.ownerid;
        const items = products.map((product) => {
          tier = product.tier === 'ticket' ? 'ticket' : 'food';

          var compositionprice = product.iscomposite
            ? product.composition.reduce(function (a, b) {
                return a + b.price;
              }, 0)
            : 0;
          // let finalPrice = product.isFree
          //   ? 0
          //   : promoCodeDiscount && product.hasDiscount
          //   ? product.priceAfterDiscount
          //   : product.price;
          let finalPrice = product.isFree
            ? 0
            : promoCodeDiscount && product.hasDiscount
            ? product?.quantity > product?.promoCode[0]?.maxSoldTicket
              ? (product.priceAfterDiscount *
                  product?.promoCode[0]?.maxSoldTicket +
                  product?.price.toFixed(2) *
                    (product.quantity - product?.promoCode[0]?.maxSoldTicket)) /
                product.quantity
              : product.priceAfterDiscount
            : product.price;

          let fields = {
            quantity: product.quantity,
            name: product.name,
            photo: product.photo,
            id: product.id,
            isFree: product.isFree,
            isalcoholic: product.isalcoholic,
            tier: product.tier,
            eventid: event.id,
            categoryid: product.categoryid,
            iscomposite: product.iscomposite,
            composition:
              product.composition !== undefined
                ? JSON.stringify(product.composition)
                : '',
            price: Number((finalPrice + compositionprice).toFixed(0)),
            // total: Number(
            //   ((finalPrice + compositionprice) * product.quantity).toFixed(0),
            // ),
            total: product.MontantChoixAmout
              ? Number(
                  (product.MontantChoixAmout + compositionprice).toFixed(0),
                )
              : Number(
                  ((finalPrice + compositionprice) * product.quantity).toFixed(
                    0,
                  ),
                ),
          };

          let attendees = listCoordinate.filter(
            (lc) => lc.idProduct === product.id,
          );
          let isMultiple = listCoordinate.some((lc) => lc.isMultiple);
          if (isMultiple) {
            fields.attendees = attendees.map((att) => {
              let obj = {};
              obj.participants = att.participants
                .map((att) => filterFields(att, fieldsOfCoordinates))
                .filter((participant, index) => {
                  if (index === 0) return true;
                  else {
                    let isEmpty = Object.keys(participant).every(
                      (key) => !Boolean(participant[key]),
                    );
                    if (isEmpty) return false;
                    else return true;
                  }
                });
              obj.index = att.order;
              if (att.isMultiple) {
                obj.companyinfos = filterFields(att.societyInfo, fieldsSociety);
              }
              obj.hasMultiPersonsTickets = att.isMultiple;
              return obj;
            });
          }

          if (product.isAccessTimeThreshold) {
            fields.accessTimeThreshold = product.accessTimeThreshold;
            fields.isAccessTimeThreshold = product.isAccessTimeThreshold;
          }
          if (product.isAccessTimeLimit) {
            fields.accessTimeLimit = product.accessTimeLimit;
            fields.isAccessTimeLimit = product.isAccessTimeLimit;
          }
          if (product.isTvaActive) {
            fields.isTvaActive = product.isTvaActive;
            fields.tvaRate = product.tvaRate;
            fields.priceHt = product.priceHt;
          }

          if (product?.products?.length > 0) {
            fields.products = product.products;
            fields.description = product.products
              ?.map((p) => `${p.product}`)
              .join(', ');
          }
          const filteredValues = {};

          for (const key in fields) {
            if (fields[key] !== undefined) {
              filteredValues[key] = fields[key];
            }
          }
          return filteredValues;
        });

        const {store} = props;
        const version = Constants.manifest.version;

        var reducedPrices = products.reduce(function (filtered, product) {
          var compositionprice = product.iscomposite
            ? product.composition.reduce(function (a, b) {
                return a + b.price;
              }, 0)
            : 0;
          if (!product.isFree) {
            let finalPrice = product.isFree
              ? 0
              : promoCodeDiscount && product.hasDiscount
              ? product?.quantity > product?.promoCode[0]?.maxSoldTicket
                ? (product.priceAfterDiscount *
                    product?.promoCode[0]?.maxSoldTicket +
                    product?.price.toFixed(2) *
                      (product.quantity -
                        product?.promoCode[0]?.maxSoldTicket)) /
                  product.quantity
                : product.priceAfterDiscount
              : product.price;
            filtered.push({
              quantity: product.quantity,
              price_data: {
                currency: 'eur',
                product_data: {
                  name: product.name,
                  images: product.photo ? [product.photo] : [],
                },
                unit_amount: Number(
                  ((finalPrice + compositionprice) * 100).toFixed(0),
                ),
              },
            });
          }
          return filtered;
        }, []);

        var status = 'preordered';
        if (reducedPrices.length == 0) {
          status = tier === 'ticket' ? 'confirmed' : 'ordered';
        }
        let newid = '';
        if (shouldCreateOrder) {
          setCheckoutLoading(true);
          const options = {
            email: userinfos.email,
            items: items,
            status: status,
            clientid: user.uid,
            displayName: userinfos.name,
            appversion: version,
            pickmode: 'inhouse',
            origin: 'webapp',
            userinfos: userinfos,
            paymentinfos: '',
            ownerid: ownerid,
          };
          if (promoCodeDiscount) {
            options.promocode = {
              promoCodeValue: promoCodeDiscount.promoCodeValue,
              promoDiscount: promoCodeDiscount.discount,
            };
          }
          if (goid) {
            options.goid = goid;
          }
          if (referralID !== '') {
            options.referralid = referralID;
          }
          if (tier === 'ticket') {
            options.event = {
              id: event.id,
              title: event.title,
              coverImage: event.coverImage,
              address: event.address,
              startDate: event.startDate,
              ownerid: event?.ownerid || '',
            };

            try {
              const precreateReservtion = httpsCallable(
                FUNCTIONS,
                'precreateReservtion',
              );
              const result = await precreateReservtion(options);

              if (!result.data?.haserror) {
                newid = result.data?.reservation;
              }
              if (newid) {
                setReferral('');
              }
            } catch (error) {
              console.log(error);
            }
          } else {
            options.storeid = store.id;
            const preCreateOrder = httpsCallable(FUNCTIONS, 'precreateOrder');
            newid = await preCreateOrder(options);
          }
          setCheckoutLoading(true);
        }

        if (reducedPrices.length > 0) {
          const stripe = await stripePromise;
          setCheckoutLoading(true);
          const checkoutsession = await createcheckoutsession(
            reducedPrices,
            tier,
            newid,
          );
          const result = await stripe.redirectToCheckout({
            sessionId: checkoutsession.data.session.id,
          });
        } else {
          //CheckoutSuccess
          emptyCart();
          setCheckoutLoading(false);
          navigation.navigate('CheckoutSuccess', tier);
        }
      } catch (err) {
        console.log('finalise error', err);
        navigation.navigate('CheckoutFailed');
      }
    } else return;
  };

  const createcheckoutsession = async (prices, tier, newid) => {
    const {store} = props;
    const {params} = props.route;
    const dbuser = await db.collection('users').doc(user.uid).get();
    const docuser = dbuser.data();

    let options = {
      prices: prices,
      tier: tier,
      newid: newid,
      customer: docuser.customerId,
      clientid: user.uid,
      // savepm: this.state.isSelected,
      accountid: store.accountid,
      mode: 'payment',
    };
    const {shouldCreateOrder, goid} = params.options;
    if (goid) {
      options.goid = goid;
      options.shouldCreateOrder = shouldCreateOrder;
    }
    if (goid && !shouldCreateOrder) {
      options.successurl = 'Cart';
    }

    const callCreateCheckoutSession = httpsCallable(
      FUNCTIONS,
      'createCheckoutSession',
    );
    const result = await callCreateCheckoutSession(options);
    return result;
  };

  const fieldsOfCoordinates = useMemo(() => {
    let baseField = ['name', 'email'];
    if (event?.participantInfo)
      event?.participantInfo?.map((field) => baseField.push(field.field));
    return baseField;
  }, [event]);

  const fieldsSociety = ['name', 'address', 'codePostal', 'siret'];

  const checkIfInclude = useCallback((fieldName, participantInfo) => {
    return participantInfo?.some((field) => field.field === fieldName);
  }, []);
  const checkIfRequired = useCallback((fieldName, participantInfo) => {
    return participantInfo?.some(
      (field) => field.field === fieldName && field.required,
    );
  }, []);

  const {t} = useTranslation();
  // t('AllServicesData.Événements')
  const genders = [
    /*'Male'*/ t('TicketCoordinatesBPage.Mâle'),
    /*'Female'*/ t('TicketCoordinatesBPage.Femelle'),
    t('TicketCoordinatesBPage.Autre'),
  ];

  const previousSlide = () => {
    swiperRef?.current?.scrollBy(-1, true);
    // scrollToTop();
  };

  const nextSlide = () => {
    swiperRef.current.scrollBy(1, true);
    // scrollToTop();
  };

  if (checkoutLoading) {
    return (
      <View style={{paddingTop: 50}}>
        <ActivityIndicator size="large" color="green" />
      </View>
    );
  }

  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />

      <ScrollView
        showsVerticalScrollIndicator={false}
        style={styles.scrollView}>
        <View style={styles.imageContainer}>
          <SmartphoneSVG style={styles.image} />
        </View>

        <View style={styles.container}>
          <View style={styles.firstRow}>
            <TitleA>
              {' '}
              {t(
                'TicketCoordinatesBPage.Veuillez entrer vos coordonnées pour les billets',
              )}
            </TitleA>
          </View>
          <Swiper
            ref={swiperRef}
            // loop
            loop={false}
            showsPagination={false}
            scrollEnabled={true}
            height="100%"
            style={{
              height: 'auto',
            }}>
            {listCoordinate.map((lc, index) => (
              <View
                // showsVerticalScrollIndicator={false}
                style={styles.scrollView}
                key={index}>
                {listCoordinate.length > 1 && (
                  <View style={styles.QRCodeSection}>
                    <View style={styles.ticketIndex}>
                      <TitleA>{` ${index + 1}/${
                        listCoordinate.length
                      }`}</TitleA>
                    </View>
                    <TouchableItem
                      style={{
                        position: 'absolute',
                        // top: 172,
                        left: 30,
                      }}
                      onPress={previousSlide}>
                      <BackArrowSVG
                        style={{height: 20, width: 31, padding: 7}}
                        fill={Colors.lightGray}
                      />
                    </TouchableItem>
                    <TouchableItem
                      style={{
                        position: 'absolute',
                        // top: 172,
                        right: 30,
                      }}
                      onPress={nextSlide}>
                      <BackArrowSVG
                        style={{
                          transform: [{scaleX: -1}],
                          height: 20,
                          width: 31,
                          padding: 7,
                        }}
                        fill={Colors.lightGray}
                      />
                    </TouchableItem>
                  </View>
                )}
                <View>
                  {lc.isMultiple && (
                    <>
                      <TitleA style={{marginBottom: 5, marginTop: 5}}>
                        {t('companyInfo')}
                      </TitleA>
                      <View style={styles.inputsContainer}>
                        {Object.keys(lc.societyInfo)
                          .filter((v) => fieldsSociety.includes(v))
                          .map((key) => (
                            <View style={{marginBottom: 15}}>
                              <SubheadingA style={styles.label}>
                                {t(`companyTranslation.${key}`)}
                                <Text style={{color: Colors.red}}> *</Text>
                              </SubheadingA>
                              <TextInput
                                placeholder="••••••••••••••••••••••••••••"
                                placeholderTextColor={Colors.lightGray}
                                maxLength={50}
                                style={[
                                  styles.textInput,
                                  lc.societyInfo?.errors[key] &&
                                    styles.errorBorder,
                                ]}
                                value={lc.societyInfo[key]}
                                onChangeText={(text) =>
                                  handleSocietyChange(lc.id, key, text)
                                }
                                onFocus={() =>
                                  handleSocietyFocusChange(
                                    lc.id,
                                    undefined,
                                    key,
                                    true,
                                  )
                                }
                                onBlur={() =>
                                  handleSocietyFocusChange(
                                    lc.id,
                                    undefined,
                                    key,
                                    false,
                                  )
                                }
                                isFocused={lc.societyInfo[`${key}Focused`]}
                              />
                            </View>
                          ))}
                      </View>
                    </>
                  )}
                  {lc.participants.map((participant, index) => (
                    <View style={{marginBottom: 20}}>
                      <TitleA style={{marginBottom: 20, marginTop: 10}}>
                        {t('participant')} {index + 1}
                      </TitleA>
                      {Object.keys(participant)
                        .filter((v) => fieldsOfCoordinates.includes(v))
                        .map((key) => (
                          <View
                            style={{
                              position: 'relative',
                              marginBottom: 15,
                            }}>
                            <SubheadingA style={styles.label}>
                              {t(`${key}`)}
                              {checkIfRequired(key, event.participantInfo) && (
                                <Text style={{color: Colors.red}}> *</Text>
                              )}
                            </SubheadingA>
                            {key === 'gender' ? (
                              <View style={{position: 'relative'}}>
                                <DropDownInput
                                  label=/*"Gender"*/ {t(
                                    'TicketCoordinatesBPage.Genre',
                                  )}
                                  placeholder=/*"Gender"*/ {t(
                                    'TicketCoordinatesBPage.Genre',
                                  )}
                                  icon={<GenderSVG />} // Replace with your icon component
                                  dataArray={genders}
                                  value={participant[key]}
                                  style={[
                                    participant?.errors[key] &&
                                      styles.errorBorder,
                                  ]}
                                  onChangeText={(text) =>
                                    handleChange(lc.id, index, key, text)
                                  }
                                  // isFocused={genderFocused}
                                  // onFocus={handleGenderFocus}
                                />
                              </View>
                            ) : (
                              <TextInput
                                placeholder="••••••••••••••••••••••••••••"
                                keyboardType={
                                  key === 'age'
                                    ? 'numeric'
                                    : key === 'phone'
                                    ? 'phone-pad'
                                    : key === 'birthday'
                                    ? 'birthday'
                                    : 'default'
                                }
                                placeholderTextColor={Colors.lightGray}
                                maxLength={50}
                                style={[
                                  styles.textInput,
                                  {paddingLeft: 44},
                                  participant?.errors[key] &&
                                    styles.errorBorder,
                                ]}
                                value={participant[key]}
                                onChangeText={(text) =>
                                  handleChange(lc.id, index, key, text)
                                }
                                onFocus={() =>
                                  handleFocusChange(lc.id, index, key, true)
                                }
                                onBlur={() =>
                                  handleFocusChange(lc.id, index, key, false)
                                }
                                isFocused={participant[`${key}Focused`]}
                              />
                            )}

                            {key === 'email' && (
                              <EmailSVG style={styles.inputLeftIcon} />
                            )}
                            {key === 'birthday' && (
                              <CalendarSVG style={styles.inputLeftIcon} />
                            )}
                            {key === 'age' && (
                              <AgeSVG style={styles.inputLeftIcon} />
                            )}
                            {key === 'phone' && (
                              <PhoneSVG style={styles.inputLeftIcon} />
                            )}
                          </View>
                        ))}
                    </View>
                  ))}
                </View>
              </View>
            ))}
          </Swiper>
          <View style={styles.buttonContainer}>
            <ButtonA onPress={handleFinalizePress} disabled={isDisabled}>
              <CartSVG
                style={{
                  width: 18,
                  height: 18,
                  marginBottom: -2,
                  marginRight: 10,
                }}
                stroke={Colors.background}
              />
              {t('TicketCoordinatesBPage.Finalisation du paiement')}
            </ButtonA>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const mapStateToProps = (state) => {
  const {cart, store, event} = state;
  return {cart, store, event};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setReferral,
      emptyCart,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TicketCoordinatesB);
