import { StyleSheet, I18nManager } from 'react-native';
import Color from 'color';
import Colors from '../../theme/colors';
const isRTL = I18nManager.isRTL;
// HomeA Styles
const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  headSection: {
    backgroundColor: Colors.background,
  },
  container: {
    marginVertical: 10,
    flex: 1,

    flexDirection: 'column',
  },
  selectCityBtnContainer: {
    // // alignItems: 'center',
    // justifyContent: 'space-between',
    // flexDirection: 'row',
    // marginHorizontal: 32,
    // paddingVertical: 30,
    // marginTop: -38,
    paddingRight: 17,
    paddingBottom: 10,
  },
  selectCityTouchableItem: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  downArrow: {
    paddingLeft: 8,
    width: 18,
    height: 18,
  },
  favorite: {
    marginLeft: 'auto',
    width: 30,
    height: 27,
  },
  contentContainerStyle: {
    //paddingVertical: 10,
    // paddingHorizontal: 16,
  },
  cardImg: { borderRadius: 4 },
  card: {
    marginVertical: 6,
    height: 400,
    resizeMode: 'cover',
  },
  cardOverlay: {
    flex: 1,
    borderRadius: 4,
    backgroundColor: `${Color(Colors.overlayColor).alpha(0.1)}`,
    overflow: 'hidden',
  },
  cardContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderRadius: 4,
  },
  cardTitle: {
    padding: 18,
    fontWeight: '700',
    fontSize: 18,
    color: Colors.white,
    textShadowColor: 'rgba(0, 0, 0, 0.88)',
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 10,
  },
  cardDate: {
    padding: 16,
    fontWeight: '700',
    fontSize: 18,
    color: Colors.white,
    textShadowColor: 'rgba(0, 0, 0, 0.88)',
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 10,
  },
  swiperContainer: {
    // flex: 1,
  },
  slide: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 24,
  },
  sectionTitle: {
    height: 30,
  },
  selectCityContainer: {
    // backgroundColor: 'yellow',
  },
  country: {
    alignItems: 'center',
    paddingHorizontal: 32,
    paddingVertical: 16,
    flexDirection: 'row',
    marginBottom: 10,
  },
  // flagIcon: {
  //   width: 24,
  //   height: 24,
  //   marginRight: 24,
  // },
  city: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 60,
    paddingVertical: 14,
    marginBottom: 10,

    // backgroundColor: 'red',
  },
  locationIcon: {
    width: 16,
    height: 16,
    marginRight: 24,
  },
  icon: {

    width: 73,
    height: 73,
  },
  borderTitle: {
    marginTop: 5,
    height: 4,
    width: '100%',
    backgroundColor: Colors.green
  },
  item: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

  },
});

export default styles;
