import React from 'react';

import Svg, {Path} from 'react-native-svg';

const LineSvg = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="69"
      height="59"
      viewBox="0 0 69 59"
      fill="none">
      <Path
        d="M34.5 59L37.3867 54L31.6132 54L34.5 59ZM34 -2.18556e-08L34 54.5L35 54.5L35 2.18556e-08L34 -2.18556e-08Z"
        fill="#CAD2CC"
      />
    </Svg>
  );
};
export default LineSvg;
