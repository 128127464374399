import {StyleSheet} from 'react-native';
import Colors from '../../theme/colors';
import Layout from '../../theme/layout';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 25,
    paddingRight: 10,
  },
  col: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
    width: 42,
  },
  row1: {
    paddingHorizontal: 6,
    paddingVertical: 3,
  },
});

export default styles;
