import {StyleSheet, I18nManager} from 'react-native';
import Color from 'color';
import Colors from '../../theme/colors';
const isRTL = I18nManager.isRTL;
// HomeA Styles
const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  itemImage: {
    height: 185,
    aspectRatio: 1 / 1,
  },
  cancelButton: {
    backgroundColor: 'rgba(0,0,0, 0.0)',
  },
  textInput: {
    width: '100%',
    height: 46,
    borderRadius: 8,
    backgroundColor: Colors.white,
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderWidth: 1,
    borderColor: '#D2D5DA',
    shadowColor: 'black',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.05,
    shadowRadius: 2,
    elevation: 1,
    marginVertical: 10,
    // position: 'relative',
  },
  wrapper: {
    // paddingHorizontal: 24,
  },
  descriptionContainer: {
    paddingHorizontal: 24,

    marginVertical: 16,
  },
  baseChoiceContainer: {
    paddingHorizontal: 24,
    marginVertical: 16,
    backgroundColor: Colors.white,
  },
  baseChoiceRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 10,
  },
  separator: {
    height: 1,
    backgroundColor: Colors.lightGray,
  },
  quantityHandler: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footer: {
    backgroundColor: Colors.white,
    paddingVertical: 16,
    paddingHorizontal: 24,
  },

  quantityItem: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  review: {
    flexDirection: 'row',
    // justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 16,
  },
  detail: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
  },
  icon: {
    width: 21,
    height: 21,
    marginRight: 10,
  },
  description: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  backIcon: {
    width: 35,
    height: 21,
    transform: [{scaleX: -1}],
  },

  favIcon: {
    width: 26,
    height: 26,
  },
  elipse: {
    backgroundColor: 'rgba(119, 131, 143, 0.10)',
    borderRadius: 40,
    padding: 4,
    flexDirection: 'row',
    marginVertical: 16,
  },
  deliveryElipse: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 40,
  },
  takeAwayElipse: {
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 40,
  },

  container: {
    marginVertical: 10,
    flex: 1,

    flexDirection: 'column',
  },
  selectCityBtnContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGray,
    marginHorizontal: 24,
    paddingBottom: 30,
  },
  selectCityTouchableItem: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  downArrow: {
    paddingLeft: 8,
    width: 18,
    height: 18,
  },
  favorite: {
    marginLeft: 'auto',
    width: 30,
    height: 27,
  },
  contentContainerStyle: {
    //paddingVertical: 10,
    // paddingHorizontal: 16,
  },
  categoriesContainer: {
    marginTop: 16,
    paddingHorizontal: 24,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 24,
  },
  showAllButton: {
    color: Colors.darkGreen,
    marginLeft: 16,
  },
  CategoryImage: {
    width: 74,
    aspectRatio: 1 / 1,
  },
  cardImg: {borderRadius: 4},
  card: {
    marginVertical: 6,
    height: 400,
    resizeMode: 'cover',
  },
  cardOverlay: {
    flex: 1,
    borderRadius: 4,
    backgroundColor: `${Color(Colors.overlayColor).alpha(0.1)}`,
    overflow: 'hidden',
  },
  cardContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderRadius: 4,
  },
  cardTitle: {
    padding: 18,
    fontWeight: '700',
    fontSize: 18,
    color: Colors.white,
    textShadowColor: 'rgba(0, 0, 0, 0.88)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 10,
  },
  cardDate: {
    padding: 16,
    fontWeight: '700',
    fontSize: 18,
    color: Colors.white,
    textShadowColor: 'rgba(0, 0, 0, 0.88)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 10,
  },
  swiperContainer: {
    // flex: 1,
  },
  slide: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 24,
  },
  sectionTitle: {
    height: 30,
  },
  selectCityContainer: {
    // backgroundColor: 'yellow',
  },
  country: {
    alignItems: 'center',
    paddingHorizontal: 32,
    paddingVertical: 16,
    flexDirection: 'row',
    marginBottom: 10,
  },
  // flagIcon: {
  //   width: 24,
  //   height: 24,
  //   marginRight: 24,
  // },
  city: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 60,
    paddingVertical: 14,
    marginBottom: 10,

    // backgroundColor: 'red',
  },
  locationIcon: {
    width: 16,
    height: 16,
    marginRight: 24,
  },

  // new styles

  item: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // height: 46,
    // marginVertical: 10,
    // marginBottom: 24,
    // marginTop: 25,
  },

  freeDeliveryContainer: {
    backgroundColor: '#8D09FE',
    borderRadius: 30,
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 999,
    top: 155,
    marginRight: 24,
    // flex: 1,
  },
  announceIcon: {
    width: 33,
    height: 37,
    marginRight: 16,
  },
  closeButton: {
    width: 30,
    height: 30,
    backgroundColor: '#8D09FE',
    borderRadius: '50%',
    borderWidth: 2,
    borderColor: Colors.white,
    alignItems: 'center',
    position: 'absolute',
    right: -20,
    top: -20,
  },
});

export default styles;
