import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const CloseSVG = () => {
  // const {style, ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 12 11"
      style={{width: 15, height: 15}}
      // {...restProps}
    >
      <Path
        fill="#3C3C43"
        fillOpacity={0.6}
        d="M1.049 9.181a.859.859 0 0 0 .006 1.187c.33.324.883.317 1.187.013L6 6.623l3.751 3.751a.853.853 0 0 0 1.188-.006.853.853 0 0 0 .006-1.187L7.193 5.43l3.752-3.758a.847.847 0 0 0-.007-1.187.853.853 0 0 0-1.187-.007L6 4.23 2.242.478a.853.853 0 0 0-1.187.007c-.324.324-.317.882-.006 1.187L4.8 5.43 1.05 9.18Z"
      />
    </Svg>
  );
};

export default CloseSVG;
