import { StyleSheet } from 'react-native';
import Color from 'color';
import Colors from '../../theme/colors';


// TicketCoordinatesA Styles
const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: Colors.background,
    },
    avatarSection: {
      justifyContent: 'center',
      alignItems: 'center',
      paddingVertical: 20,
    },
    whiteCircle: {
      marginTop: -18,
      justifyContent: 'center',
      alignItems: 'center',
      width: 40,
      height: 40,
      borderRadius: 20,
      backgroundColor: Colors.white,
    },
    cameraButtonContainer: {
      width: 34,
      height: 34,
      borderRadius: 17,
      backgroundColor: Colors.primaryColor,
      overflow: 'hidden',
    },
    cameraButton: {
      justifyContent: 'center',
      alignItems: 'center',
      width: 34,
      height: 34,
    },
    editForm: {
      paddingHorizontal: 20,
    },
    overline: {
      color: `${Color(Colors.accentColor)}`,
      textAlign: 'center',
      fontSize: 20,
      paddingHorizontal: 10,
      paddingBottom: 20
    },
    label: {
      color: Colors.primaryText,
      textAlign: 'left',
    },
    inputContainerStyle: {
      marginTop: 0,
      marginBottom: 17,
      paddingVertical: 0,
      paddingHorizontal: 0,
    },
    bottomButtonContainer: {
      width: '100%',
      paddingVertical: 16,
      paddingHorizontal: 24,
    },
  });

  export default styles;