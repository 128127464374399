import * as React from 'react';
import Svg, {Rect} from 'react-native-svg';
const MinusSVG = (props) => {
  const {style, ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 34 34"
      style={style}
      {...restProps}>
      <Rect
        width={33}
        height={32}
        x={0.5}
        y={1}
        fill="#F9F9F9"
        stroke="#AFB2B5"
        rx={10.5}
      />
      <Rect width={13} height={2} x={10} y={16.5} fill="#1E2022" rx={1} />
    </Svg>
  );
};
export default MinusSVG;
