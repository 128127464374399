import {StyleSheet} from 'react-native';
import Colors from '../../theme/colors';

const styles = StyleSheet.create({
  title: {
    fontFamily: 'SemiBold',
    fontSize: 20,
    lineHeight: 30,
    letterSpacing: 0.4,
    color: Colors.black,
  },
});

export default styles;
