import {StyleSheet} from 'react-native';
import Colors from '../../theme/colors';

const styles = StyleSheet.create({
  title: {
    fontFamily: 'SemiBold',
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 0.64,
    color: Colors.darkGray,
  },
});

export default styles;
