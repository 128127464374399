import React from 'react';
import {Text} from 'react-native';
import styles from './BodyAStyles';

const BodyA = ({children, style, ...props}) => (
  <Text style={[styles.body, style]} {...props}>
    {children}
  </Text>
);

export default BodyA;
