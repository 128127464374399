/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

// import dependencies
import React, {Component} from 'react';
import {
  FlatList,
  ImageBackground,
  SafeAreaView,
  StatusBar,
  Text,
  ScrollView,
  View,
  Image,
  Platform,
  Share,
} from 'react-native';
import Button from '../../components/buttons/Button';
//import Notification from '../../notifications/Notification'
import SectionList from 'react-native-tabs-section-list';
// import utils
import getImgSource from '../../utils/getImgSource.js';
import GradientContainer from '../../components/gradientcontainer/GradientContainer';
// import components
import ActionProductCard from '../../components/cards/ActionProductCard';
import ActionProductCardHorizontal from '../../components/cards/ActionProductCardHorizontal';
import {Heading6} from '../../components/text/CustomText';
import TouchableItem from '../../components/TouchableItem';

import ContainedButton from '../../components/buttons/ContainedButton';

import InputModal from '../../components/modals/InputModal';
import InfoModal from '../../components/modals/InfoModal';
import TwoChoicesModal from '../../components/modals/TwoChoicesModal';
import {collectionGroup} from 'firebase/firestore';

import AsyncStorage from '@react-native-async-storage/async-storage';
// import colors
import Colors from '../../theme/colors';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {addProduct, setStore, emptyCart} from '../../reducers/cartactions';
import {db} from '../../services/firebase';
import {CommonActions} from '@react-navigation/native';
import firebase from 'firebase/compat/app';
import {AuthContext} from '../../contexts/context';
import {collectionData, docData} from 'rxfire/firestore';
import {map, tap} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import AppLoading from 'expo-app-loading';
import styles from './MenuAStyles.js';
import {Ionicons as Icon} from '@expo/vector-icons';
import locales from '../../config/locales';
import isEmptyOrUndefined from '../../utils/stringHelper.js';
import DeviceInfo from 'react-native-device-info';
// HomeA Config
const imgHolder = require('../../../assets/images/imgholder.png');

const slide3Img = require('../../../assets/images/20946002.jpg');
const closedImg = require('../../../assets/images/closed.jpg');
import AppBanner from 'smart-app-banner-react';

const appInfo = {
  imageUrl: require('../../../assets/images/instadrink_green.png'),
  name: 'Instadrink',
  publisher: 'Instadrink',
  infoText: 'Free - In Google Play',
  linkButtonText: 'Installer',
  linkUrl:
    'https://play.google.com/store/apps/details?id=com.food.instadrink&hl=en',
};

const appInfoIos = {
  imageUrl: require('../../../assets/images/instadrink_green.png'),
  name: 'Instadrink',
  publisher: 'Instadrink',
  infoText: 'Free - In Apple Store',
  linkButtonText: 'Installer',
  linkUrl: 'https://apps.apple.com/us/app/instadrink/id1588653074',
};

import getEnvVars from '../../config';
const {links} = getEnvVars();

const CHECKMARK_ICON =
  Platform.OS === 'ios'
    ? 'ios-checkmark-circle-outline'
    : 'md-checkmark-circle-outline';

const FAIL_ICON =
  Platform.OS === 'ios'
    ? 'ios-close-circle-outline'
    : 'md-close-circle-outline';

class MenuA extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      store: {},
      validStore: true,
      routes: [],
      baseOs: '',
      // Grouped Orders
      inputModalVisible: false,
      clientName: '',
      waitingForClienNameInStep: null,
      userTotalCart: 0,
      totalPriceShared: 0,
      isInActiveGroupedOrder: false,
      groupedOrderData: null,
      groupedOrderIsOwner: false,
      groupedOrderModalVisible: false,
      infoModalVisible: false,
      infoModalTitle: '',
      infoModalMessage: '',
      friendCartStatus: 'draft',
      showLoading: false,
    };
  }

  componentDidMount = () => {
    //alert(Platform.OS);
    //this.registerForPushNotificationsAsync();
    //Notifications.addNotificationReceivedListener(this._handleNotification);
    //Notifications.addNotificationResponseReceivedListener(this._handleNotificationResponse);
    DeviceInfo.getBaseOs().then((baseOs) => {
      // "Windows", "Android" etc
      console.log(baseOs);
      this.setState({baseOs: baseOs});
    });
    let storeid = this.props.store.id;
    let params = this.props;
    if (__DEV__) {
      console.log('menu this.props');
      console.log(this.props);
    }

    if (params && params.id) {
      console.log('deeplink from outside');
      storeid = params.id.trim();
      console.log(storeid);
      if (storeid !== this.props.store.id) {
        this.props.emptyCart();
      }
    } else {
      console.log('qrcd from inside');
      //console.log(this.props.store);
      storeid = this.props.store.id;
    }

    if (params && params.previous) {
      const {navigation} = this.props;
      if (params.previous) {
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{name: 'Menu'}],
          }),
        );
      }
    }

    if (!isEmptyOrUndefined(storeid)) {
      console.log('non empty store id ');
      //console.log(this.props.route);
      //console.log(this.props.store);
      this.setState({isLoading: true});

      let storeDoc = db.collection('organisers').doc(storeid);

      // let storeDoc = db
      //   .collection('organisers')
      //   .where('type', '==', 'Restaurant')
      //   .where('id', '==', storeid);

      // let catDoc = db
      //   .collection('productcategories')
      //   .where('storeid', '==', storeid);
      let catDoc = db
        .collectionGroup('categories')
        .where('orgId', '==', params.id);
      // let docProds = db.collection('products').where('storeid', '==', storeid);

      let docProds = db
        .collectionGroup('products')
        .where('orgId', '==', params.id);
      const store$ = docData(storeDoc, 'id');
      // const store$ = collectionData(storeDoc, 'id');

      const categories$ = collectionData(catDoc, 'id');
      const products$ = collectionData(docProds, 'id');

      combineLatest([store$, products$, categories$])
        .pipe(
          map(([store, products, categories]) => ({
            products,
            categories,
            ...store,
          })),
        )
        .subscribe((store) => {
          if (store.type === 'Restaurant') {
            store.categories.map((category) => {
              category.data = store.products.filter((obj) => {
                return obj.categoryid === category.id;
              });
            });
            if (__DEV__) {
              console.log('store');
              console.log(store);
            }
            store.categories = store.categories.sort((a, b) =>
              a.order < b.order ? -1 : b.order < a.order ? 1 : 0,
            );

            this.setState({store, isLoading: false});

            this.props.setStore(store);
          }
        });
    } else {
      console.log('empty store id ');
      this.setState({validStore: false});
    }
  };

  // Grouped Orders
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.cart !== this.props.cart) {
      //console.log("Cart updated")
      this.calculateUserOrderTotal();
      if (this.state.isInActiveGroupedOrder) {
        this.addProductsToGroupedOrder(this.props.cart.products);
      }
    }
  }

  componentWillUnmount() {
    //this.catobserver();
    //this.prdobserver();
  }

  checkClientHasName = async (step, callback) => {
    const {user} = this.context;
    await db
      .collection('users')
      .doc(user.uid)
      .get()
      .then(async (docuser) => {
        const userData = docuser.data();
        if ('displayName' in userData) {
          console.log('User name is : ' + userData.displayName);
          this.setState({clientName: userData.displayName});
          callback();
        } else {
          this.setState({waitingForClienNameInStep: step});
          this.showClientNameInputModal(true);
        }
      });
  };

  showClientNameInputModal = (value) => {
    this.setState({inputModalVisible: value});
  };

  submitClientNameInputModal = async () => {
    const {user} = this.context;
    await db.collection('users').doc(user.uid).set(
      {
        displayName: this.state.clientName,
      },
      {merge: true},
    );
    this.setState({
      inputModalVisible: false,
    });
    if (this.state.waitingForClienNameInStep == 2)
      this.showGroupedOrderModal(true);
    else if (this.state.waitingForClienNameInStep == 1 && this.props.go)
      this.handleAcceptInviteToGroupedOrder(this.props.go, user.uid);
  };

  updateClientName = (value) => {
    this.setState({
      clientName: value,
      isSubmitClientNameInputModalDisabled: !(value.length > 0),
    });
  };

  // Grouped Orders
  // Check if User is in a Grouped Order or comes from an invitation
  handleGroupedOrderInitialStatus = async () => {
    const {user} = this.context;
    console.log('handleGroupedOrderInitialStatus');
    console.log(user);
    const params = this.props;
    await db
      .collection('users')
      .doc(user.uid)
      .get()
      .then(async (docuser) => {
        const userData = docuser.data();
        console.log(userData);
        console.log(params);
        console.log(userData.activeGroupedOrder);
        console.log(docuser.exists);
        console.log(docuser.exists && userData.activeGroupedOrder);
        // Check if there is an active Grouped Order in User
        if (docuser.exists && userData.activeGroupedOrder) {
          // We verify that the user exists in the Grouped Order, and if so, as Owner or Friend
          await this.getUserStatusInGroupedOrder(
            userData.activeGroupedOrder,
            user.uid,
          ).then(async (userRole) => {
            if (userRole == 'owner' || userRole == 'friend') {
              // We check the the Grouped Order has not already been paid (after checkout)
              const statusOrder = await this.handleFinishedGroupedOrder(
                userData.activeGroupedOrder,
              );
              if (statusOrder) {
                console.log('Ending handleGroupedOrderInitialStatus');
              } else {
                // All good, we save the Grouped Order locally
                this.setState({
                  isInActiveGroupedOrder: userData.activeGroupedOrder,
                });
                console.log(
                  'The User is already in an active Grouped Order as : ' +
                    userRole,
                );
                console.log('User ID : ' + user.uid);
                console.log(
                  'Grouped Order ID : ' + userData.activeGroupedOrder,
                );
                // Add products in Store to Grouped Orders
                if (this.props.cart.products.length > 0) {
                  console.log('Adding Cart from Redux Store');
                  await this.addProductsToGroupedOrder(
                    this.props.cart.products,
                  );
                }
                // Setup a snapshot to follow changes
                await this.setupSnaphotGroupedOrder(
                  userData.activeGroupedOrder,
                );
              }
            } else if (userRole == 'nogo' || userRole == 'none') {
              // The Grouped Order does not exist
              console.log('Removing invalid Grouped Order from User');
              // Remove Grouped Order from User
              await db.collection('users').doc(user.uid).set(
                {
                  activeGroupedOrder: null,
                },
                {merge: true},
              );
            } else console.log('Unhandled response.');
          });
          // If the user is not in an active Grouped Order, but came from an URL with Grouped Order invitation (Dynamic Link)
          // Handle case where user is in active Grouped Order but also opened an invitation
        } else if (params && params.go) {
          // We verify that the user exists in the Grouped Order, and if so, as Owner or Friend
          await this.getUserStatusInGroupedOrder(params.go, user.uid).then(
            async (userRole) => {
              if (userRole == 'none') {
                this.checkClientHasName(
                  1,
                  async () =>
                    await this.handleAcceptInviteToGroupedOrder(
                      params.go,
                      user.uid,
                    ),
                );
              } else if (userRole == 'owner' || userRole == 'friend') {
                // This shouldn't happen
                console.log(
                  'Unhandled case: The User is in the Grouped Order but no Grouped Order inthe User',
                );
              } else
                console.log('The Grouped Order from invitation is invalid.');
            },
          );
          // Otherwise, nothing happens
        } else {
          console.log('is not in go');
          this.setState({isInActiveGroupedOrder: false});
        }
      });
  };

  handleAcceptInviteToGroupedOrder = async (groupedOrderId, userId) => {
    // We push the new user
    console.log(
      'User accepted invitation with Grouped Order : ' + groupedOrderId,
    );
    // TODO: The following should be handled in backend
    await this.addFriendToGroupedOrder(userId, groupedOrderId);
    // Add it locally
    this.setState({isInActiveGroupedOrder: groupedOrderId});
    // Setup a snapshot to follow changes
    await this.setupSnaphotGroupedOrder(groupedOrderId);
  };

  // Setup onSnapshot on the Grouped Order document
  setupSnaphotGroupedOrder = async (goId) => {
    this.unsubscribeListener = db
      .collection('groupedorders')
      .doc(goId)
      .onSnapshot(async () => {
        console.log('Received an onSnapshot on groupedOrder in MenuA.js');
        if (this.state.isInActiveGroupedOrder) {
          await this.getLastGroupedOrderInfo(
            this.state.isInActiveGroupedOrder,
          ).then(async (dataGO) => {
            if (!dataGO) return;
            // We check the the Grouped Order has not already been paid (for friends)
            if (dataGO.status === 'payed' && !this.state.groupedOrderIsOwner) {
              console.log('This Grouped Order has been paid by owner');
              this.setState({
                infoModalVisible: true,
                infoModalTitle: 'Félicitations',
                infoModalMessage:
                  'Ce panier a été validé et sera livré sous peu',
              });
              await this.removeOrderFromUser();
              this.props.emptyCart();
              return this.unsubscribeListener();
            }
            // Check if the Friend has been removed from Grouped Order
            const {user} = this.context;
            if (
              dataGO.owner.userId != user.uid &&
              !dataGO.users.some((obj) => obj.userId == user.uid) &&
              this.state.isInActiveGroupedOrder
            ) {
              console.log(
                'MenuA: User is no more a Friend in the Grouped Order',
              );
              await this.handleLeaveGoupedOrder();
            }
          });
        } else {
          // console.log("Unsubscribing Listener")
          // this.unsubscribeListener();
        }
      });
  };

  // Add the current user as a Friend in a Grouped Order (case whenreceives invitation but not owner)
  addFriendToGroupedOrder = async (userId, goId) => {
    // Add Grouped Order to User
    await db.collection('users').doc(userId).set(
      {
        activeGroupedOrder: goId,
      },
      {merge: true},
    );
    // Push the User as a Friend
    const actualUser = {
      userId: userId,
      userName: this.state.clientName,
      acceptedAt: firebase.firestore.Timestamp.fromDate(new Date()),
      products: [...this.props.cart.products],
      totalPrice: this.state.userTotalCart,
      status: 'draft',
    };
    const users = this.state.groupedOrderData.users;
    db.collection('groupedorders')
      .doc(goId)
      .set(
        {
          users: [...users, actualUser],
        },
        {merge: true},
      );
  };

  getLastGroupedOrderInfo = async (goId) => {
    // const { user } = this.context;
    // TODO: This should be handled in backend
    return await db
      .collection('groupedorders')
      .doc(goId)
      .get()
      .then(async (docGO) => {
        const dataGO = docGO.data();
        if (dataGO) {
          // Save it locally
          this.setState({groupedOrderData: dataGO});
          // Recalculate Total
          await this.calculateTotalPriceFriends(dataGO);
          // Check status of Cart for Friend
          if (!this.state.groupedOrderIsOwner)
            this.checkCartStatusforFriends(dataGO);
        } else {
          console.log('The Grouped Order no longer exists');
          this.setState({
            isInActiveGroupedOrder: false,
            groupedOrderData: null,
          });
        }
        return dataGO;
      });
  };
  getUserStatusInGroupedOrder = async (goId, userId) => {
    return await this.getLastGroupedOrderInfo(goId).then((dataGO) => {
      if (dataGO) {
        const {user} = this.context;
        // Check if user is Owner
        if (dataGO.owner.userId == user.uid) {
          this.setState({groupedOrderIsOwner: true});
          return 'owner';
        } else {
          this.setState({groupedOrderIsOwner: false});
          // Check if exists in Friends
          const users = dataGO.users;
          if (users.length > 0 && users.some((obj) => obj.userId === userId)) {
            return 'friend';
          } else {
            return 'none';
          }
        }
      } else return 'nogo';
    });
  };
  handleCreateGroupedOrder = async (paymentType) => {
    const {user} = this.context;
    const products = JSON.parse(JSON.stringify(this.props.cart.products)); // Deep clone needed
    // Remove popup and Show Loader
    this.showGroupedOrderModal(false);
    this.setState({showLoading: true});
    // TODO: The following checks should be handled in backend
    // Add the Grouped Order in groupedorders collection in Firebase
    const groupedOrderParams = {
      owner: {
        userId: user.uid,
        products,
      },
      orderId: '',
      storeId: this.state.store.id,
      paymentType,
      status: 'draft',
      users: [],
      createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
    };
    console.log(groupedOrderParams);
    // Save it locally
    this.setState({
      groupedOrderData: groupedOrderParams,
      groupedOrderIsOwner: true,
    });
    // Push it to Firestore
    await db
      .collection('groupedorders')
      .add(groupedOrderParams)
      .then(async (docGO) => {
        // Setup a snapshot to follow changes
        await this.setupSnaphotGroupedOrder(docGO.id);
        // Add the active Grouped Order to the User
        await db.collection('users').doc(user.uid).set(
          {
            activeGroupedOrder: docGO.id,
          },
          {merge: true},
        );
        // Save the ID for later use
        this.setState({isInActiveGroupedOrder: docGO.id});
        console.log('User ID : ' + user.uid);
        console.log('Grouped Order ID : ' + docGO.id);
        // TODO: Generate the dynamic link
        const link =
          links.webappurl +
          'shop?go=' +
          docGO.id +
          '&s=' +
          this.state.store.id +
          '&t=food';
        console.log('Link to be shared : ' + link);
        // Trigger the native share
        this.shareGroupedOrder(user.displayName, link, paymentType);
        // this.setState({
        //   infoModalVisible: true,
        //   infoModalTitle: "Partager avec mes amis",
        //   infoModalMessage: "Lien: " + link
        // });
        this.setState({showLoading: false});
      });
  };
  handleFinishedGroupedOrder = async (goId) => {
    return await db
      .collection('groupedorders')
      .doc(goId)
      .get()
      .then(async (docGO) => {
        const dataGO = docGO.data();
        if (!dataGO) return false;
        if (dataGO.status === 'payed') {
          console.log('This Grouped Order has been paid');
          // If owner, we remove data from Cart and local/dbb data from Grouped Order in User
          this.props.emptyCart();
          await this.removeOrderFromUser();
          return true;
        } else return false;
      });
  };
  removeOrderFromUser = async () => {
    const {user} = this.context;
    await db
      .collection('users')
      .doc(user.uid)
      .set(
        {
          activeGroupedOrder: null,
        },
        {merge: true},
      )
      .then(() => {
        console.log('Grouped Order successfully deleted from user');
        this.recycleGroupedOrder();
      });
  };
  handleLeaveGoupedOrder = async () => {
    this.setState({showLoading: true});
    // Remove the Snapshot listener
    this.unsubscribeListener();
    // If the User is not the owner, we remove him
    const {user} = this.context;
    if (!this.state.groupedOrderIsOwner) {
      console.log(
        'The user is not the owner and is asked to leave Grouped Order',
      );
      // TODO: The following should be handled in backend
      await db
        .collection('groupedorders')
        .doc(this.state.isInActiveGroupedOrder)
        .get()
        .then(async (docGO) => {
          const dataGO = docGO.data();
          let users = dataGO.users;
          users = users.filter((usr) => usr.userId != user.uid);
          // TODO: Must check that the Grouped Order still exists
          await db
            .collection('groupedorders')
            .doc(this.state.isInActiveGroupedOrder)
            .set({users}, {merge: true})
            .then(async () => {
              // Remove Grouped Order from current user
              await this.removeOrderFromUser();
            });
        });
      return;
    }
    // If the owner wants to get out
    await this.getLastGroupedOrderInfo(this.state.isInActiveGroupedOrder).then(
      async () => {
        if (
          this.state.groupedOrderData.users &&
          this.state.groupedOrderData.users.length == 0
        ) {
          // Delete the Grouped Order
          await db
            .collection('groupedorders')
            .doc(this.state.isInActiveGroupedOrder)
            .delete()
            .then(async () => {
              // Remove Grouped Order from current user
              await this.removeOrderFromUser();
              this.setState({showLoading: false});
            })
            .catch((error) => {
              this.setState({showLoading: false});
              console.error('Error removing Grouped Order: ', error);
            });
        } else {
          // TODO: Handle case where Grouped Order has users
          this.setState({showLoading: false});
          console.log('You cannot remove a Grouped Order while members are in');
        }
      },
    );
  };
  // Function to recycle the Grouped Order
  recycleGroupedOrder = () => {
    this.setState({
      // userTotalCart: 0,
      totalPriceShared: 0,
      isInActiveGroupedOrder: false,
      groupedOrderData: null,
      groupedOrderIsOwner: false,
      groupedOrderLink: '',
      friendCartStatus: 'draft',
      showLoading: false,
    });
  };
  addProductsToGroupedOrder = async (products) => {
    // TODO: The following should be handled in backend
    if (this.state.groupedOrderIsOwner) {
      let ownerData = this.state.groupedOrderData.owner;
      ownerData.products = [...products];
      await db
        .collection('groupedorders')
        .doc(this.state.isInActiveGroupedOrder)
        .set({owner: JSON.parse(JSON.stringify(ownerData))}, {merge: true});
    } else {
      const {user} = this.context;
      let usersData = this.state.groupedOrderData.users;
      const indexUser = usersData.findIndex((e) => e.userId == user.uid);
      usersData[indexUser].products = [...products];
      // Calculate Total price
      let totalPrice = 0;
      products.forEach((product, i) => {
        totalPrice += parseFloat(product.total);
      });
      usersData[indexUser].totalPrice = totalPrice;
      await db
        .collection('groupedorders')
        .doc(this.state.isInActiveGroupedOrder)
        .set({users: JSON.parse(JSON.stringify(usersData))}, {merge: true});
    }
  };
  calculateUserOrderTotal = () => {
    let total =
      this.props.cart.products.length > 0
        ? this.props.cart.products.reduce(function (prev, cur) {
            return prev + cur.price * cur.quantity;
          }, 0)
        : 0;
    this.setState({userTotalCart: total});
  };
  calculateTotalPriceFriends = async (dataGO) => {
    // Get the amount to be added
    let totalPrice = 0;
    dataGO.users &&
      dataGO.users.forEach((user, i) => {
        totalPrice += parseFloat(user.totalPrice);
        // TODO: Handle what to show depending on what will pay
        // if (user.status == "confirmed") totalConfirmedPrice += user.total;
      });
    this.setState({totalPriceShared: totalPrice});
  };
  checkCartStatusforFriends = (dataGO) => {
    const {user} = this.context;
    const userData =
      dataGO.users[dataGO.users.findIndex((usr) => usr.userId == user.uid)];
    if (userData) {
      this.setState({friendCartStatus: userData.status});
    }
  };

  shareGroupedOrder = async (user, link, paymentType) => {
    console.log(this.state.clientName);
    console.log(link);
    console.log(paymentType);
    try {
      let message =
        'Vous avez été invité à partager une commande avec1 ' +
        this.state.clientName +
        '.';
      message = message.concat(' ', 'Lien pour procéder : ' + link);
      console.log(message);
      const result = await Share.share({
        title: message,
        /*message: "Le règlement de cette commande est " 
                  + (paymentType == 'shared') ? ("pris en charge par chaque personne séparément.") : ("pris en charge totalement par " + user + ".")
                  + "Lien pour procéder : " + link*/
        message: message,
      });
      /*if (result.action === Share.sharedAction) {
        if (result.activityType) {
          console.log("shared with activity type of result.activityType")
        } else {
          console.log("shared")
        }
      } else if (result.action === Share.dismissedAction) {
        console.log("dismissed")
      }*/
    } catch (error) {
      alert(error.message);
    }
  };

  navigateTo = (screen, name, categorid) => () => {
    const {navigation} = this.props;
    navigation.navigate(screen, {title: name, categoryid: categorid});
  };

  navigateToCart = (screen) => () => {
    const {navigation} = this.props;
    navigation.navigate(screen);
  };

  onPressRemove = (item) => () => {
    let {quantity} = item;
    quantity -= 1;

    const {popularProducts} = this.state;
    const index = popularProducts.indexOf(item);

    if (quantity < 0) {
      return;
    }
    popularProducts[index].quantity = quantity;

    this.setState({
      popularProducts: [...popularProducts],
    });
  };

  onPressItem = (item) => () => {
    console.log('pressing item');
    this.navigateToProduct('Product', item);
  };

  onPressAdd = (item) => () => {
    console.log('item adding');
    console.log(item);

    if (item.sizes) {
      console.log('has sizes adding');
      this.navigateToProduct('Product', item);
    } else if (item.iscomposite) {
      console.log('iscomposite adding');
      this.navigateToProduct('Product', item);
    } else {
      let prdcategory = this.state.store.categories.filter((obj) => {
        return obj.id === item.categoryid;
      });
      const finalname =
        (prdcategory.length > 0 ? prdcategory[0].name : '') + ' ' + item.name;
      let productToAdd = {
        id: item.id,
        isalcoholic: item.isalcoholic,
        name: finalname,
        description: '',
        price: item.price,
        photo: item.photo,
        quantity: 1,
        total: item.price,
        tier: item.tier,
        showid: item.showid,
        categoryid: item.categoryid,
      };
      console.log('add product');
      console.log(productToAdd);
      this.props.addProduct(productToAdd);
    }

    //this.toast.show('Produit ajouté au panier');
  };

  keyExtractor = (item, index) => index.toString();

  renderCategoryItem = ({item, index}) => {
    //console.log("category");
    //console.log(item);
    return (
      <ImageBackground
        key={index}
        defaultSource={imgHolder}
        source={getImgSource(item.photo)}
        imageStyle={styles.cardImg}
        style={styles.card}>
        <View style={styles.cardOverlay}>
          <TouchableItem
            onPress={this.navigateTo('Category', item.name, item.id)}
            style={styles.cardContainer}
            // borderless
          >
            <Text style={styles.cardTitle}>{item.name}</Text>
          </TouchableItem>
        </View>
      </ImageBackground>
    );
  };

  renderProductItem = ({item, index}) => (
    <ActionProductCard
      onPress={this.navigateTo('Product')}
      key={index}
      imageUri={item.imageUri}
      title={item.name}
      description={item.description}
      rating={item.rating}
      price={item.price}
      discountPercentage={item.discountPercentage}
      label={item.label}
    />
  );

  navigateToProduct = (screen, item) => {
    console.log('item navigating');
    console.log(item);
    const {navigation} = this.props;
    if (item.sizes || item.iscomposite) {
      console.log('item adding navigating');
      navigation.navigate(screen, {id: item.id, product: item});
    }
  };

  renderCartButton = () => {
    let cartText = 'Consulter le panier';
    if (this.state.isInActiveGroupedOrder) {
      cartText = 'Consulter la commande groupée';
    }
    if (this.props.cart.products.length > 0) {
      let total = this.props.cart.products.reduce(function (prev, cur) {
        return prev + cur.total;
      }, 0);
      //total = total * 100;
      return (
        <Button
          onPress={this.navigateTo('Panier')}
          color={`#00b970`}
          title={`${locales.financial.currency.symbol} ${total.toFixed(
            2,
          )} - ${cartText}`}
          style={{paddingHorizontal: 50}}
          textStyle={{fontSize: 20}}
        />
      );
    } else {
      return;
    }
  };

  indexChanged = (v) => {
    this.setState({index: v});
  };

  renderRestos = () => {
    if (this.state.store.image) {
      return (
        <ImageBackground
          defaultSource={imgHolder}
          source={getImgSource(this.state.store.image)}
          style={styles.productImg}></ImageBackground>
      );
    }
    return <></>;
  };
  renderTabs = () => {
    if (
      this.state.store !== undefined &&
      this.state.store.categories !== undefined
    ) {
      //console.log("entering categories");
      return (
        <SectionList
          onScrollToIndexFailed={() => {}}
          sections={this.state.store.categories}
          keyExtractor={(item) => item.id}
          stickySectionHeadersEnabled={false}
          scrollToLocationOffset={50}
          tabBarStyle={styles.tabBar}
          ItemSeparatorComponent={() => <View style={styles.separator} />}
          renderTab={({name, isActive}) => (
            <View
              style={[
                styles.tabContainer,
                {borderBottomWidth: isActive ? 1 : 0},
              ]}>
              <Text
                style={[
                  styles.tabText,
                  {color: isActive ? '#090909' : '#9e9e9e'},
                ]}>
                {name}
              </Text>
            </View>
          )}
          renderSectionHeader={({section}) => (
            <View>
              <View style={styles.sectionHeaderContainer} />
              <Text style={styles.sectionHeaderText}>{section.name}</Text>
            </View>
          )}
          renderItem={({item}) => (
            <ActionProductCardHorizontal
              onPress={this.onPressItem(item)}
              onPressRemove={this.onPressRemove(item)}
              onPressAdd={this.onPressAdd(item)}
              swipeoutDisabled
              key={item.id}
              imageUri={item.photo}
              title={item.name}
              description={item.description}
              rating={item.rating}
              price={item.hasOwnProperty('price') ? item.price : ''}
              quantity={item.quantity}
              discountPercentage={item.discountPercentage}
              label={item.hs ? 'hs' : item.label}
            />
          )}
        />
      );
    }
    return <></>;
  };

  renderGroupedOrderButton = () => {
    if (this.state.isInActiveGroupedOrder) {
      return (
        <ContainedButton
          onPress={this.navigateTo('Panier')}
          color={Colors.surface}
          iconName="people"
          iconColor="#00b970"
          title="Afficher la commande groupée"
          titleColor="#00b970"
        />
      );
    } else {
      return (
        <ContainedButton
          onPress={() =>
            this.checkClientHasName(2, () => this.showGroupedOrderModal(true))
          }
          color={Colors.surface}
          iconName="person-add"
          iconColor="#00b970"
          title="Commande groupée"
          titleColor="#00b970"
        />
      );
    }
  };

  showGroupedOrderModal = (value) => {
    this.setState({groupedOrderModalVisible: value});
  };

  render() {
    const {user} = this.context;

    const {
      store,
      validStore,
      isLoading,
      baseOs,
      showLoading,
      groupedOrderModalVisible,
      infoModalVisible,
      isInActiveGroupedOrder,
      inputModalVisible,
    } = this.state;

    //const layout = useWindowDimensions();
    if (__DEV__) {
      console.log('this.State');
      console.log(this.state);
    }

    if (isLoading) {
      //throw new Error('I crashed!');
      return (
        <AppLoading onFinish={() => console.log('')} onError={console.warn} />
      );
    }

    if (
      (store.categories !== undefined && store.categories.length == 0) ||
      !validStore
    ) {
      console.log('hi');
      return (
        <SafeAreaView style={styles.screenContainer}>
          <StatusBar
            backgroundColor={Colors.statusBarColor}
            barStyle="dark-content"
          />
          {baseOs === 'Android' && (
            <AppBanner
              appInfo={appInfo}
              position="top"
              onClose={() => {
                console.log('onClose called');
              }}
              hidden={false}
              style={{
                zIndex: 5,
              }}
            />
          )}
          {baseOs.toLowerCase() === 'ios' && (
            <AppBanner
              appInfo={appInfoIos}
              position="top"
              onClose={() => {
                console.log('onClose called');
              }}
              hidden={false}
              style={{
                zIndex: 10,
              }}
            />
          )}

          <View style={styles.slide}>
            <Image source={slide3Img} style={styles.slideImg} />
            <Heading6 style={styles.title}>
              Avez vous bien scanné un Qr Code Instadrink?.
            </Heading6>
          </View>
        </SafeAreaView>
      );
    } else if (validStore && !store.open) {
      console.log('hi');
      return (
        <SafeAreaView style={styles.screenContainer}>
          <StatusBar
            backgroundColor={Colors.statusBarColor}
            barStyle="dark-content"
          />
          {baseOs === 'Android' && (
            <AppBanner
              appInfo={appInfo}
              position="top"
              onClose={() => {
                console.log('onClose called');
              }}
              hidden={false}
              style={{
                zIndex: 5,
              }}
            />
          )}
          {baseOs.toLowerCase() === 'ios' && (
            <AppBanner
              appInfo={appInfoIos}
              position="top"
              onClose={() => {
                console.log('onClose called');
              }}
              hidden={false}
              style={{
                zIndex: 10,
              }}
            />
          )}
          <View style={styles.slide}>
            <Image source={closedImg} style={styles.slideImg} />
            <Heading6 style={styles.title}>
              Désolé nous sommes fermé pour le moment!
            </Heading6>
          </View>
        </SafeAreaView>
      );
    }

    return (
      <SafeAreaView style={styles.screenContainer}>
        <StatusBar
          backgroundColor={Colors.statusBarColor}
          barStyle="dark-content"
        />
        {baseOs === 'Android' && (
          <AppBanner
            appInfo={appInfo}
            position="top"
            onClose={() => {
              console.log('onClose called');
            }}
            hidden={false}
            style={{
              zIndex: 10,
            }}
          />
        )}
        {baseOs.toLowerCase() === 'ios' && (
          <AppBanner
            appInfo={appInfoIos}
            position="top"
            onClose={() => {
              console.log('onClose called');
            }}
            hidden={false}
            style={{
              zIndex: 10,
            }}
          />
        )}
        <View style={styles.container}>
          <View style={styles.header}>{this.renderRestos()}</View>
          <View style={styles.titleContainer}>
            <Text style={styles.titleText}> {store.name}</Text>
          </View>
          <View style={styles.titleContainer}>
            <Text style={styles.titleText}> {store.adresse}</Text>
          </View>
          <View style={styles.goContainer}>
            {this.renderGroupedOrderButton()}
          </View>

          <ScrollView style={{}}>
            {this.renderTabs()}
            <View style={{paddingBottom: 60}}></View>
          </ScrollView>
          <View style={styles.bottomButtonContainer}>
            {this.renderCartButton()}
          </View>
          <TwoChoicesModal
            title="Partage de panier"
            message="Souhaitez vous partager l'addition ou la prendre en charge totalement ?"
            input={(name) => this.handleInputDisplayName(name)}
            iconName={CHECKMARK_ICON}
            visible={groupedOrderModalVisible}
            button1Title={'Partager'}
            onButton1Press={() => this.handleCreateGroupedOrder('shared')}
            button2Title={'Prendre en charge'}
            onButton2Press={() => this.handleCreateGroupedOrder('covered')}
            onRequestClose={() => this.showGroupedOrderModal(false)}
          />
          <InfoModal
            title={this.state.infoModalTitle}
            message={this.state.infoModalMessage}
            iconName={CHECKMARK_ICON}
            visible={infoModalVisible}
            iconColor={Colors.primaryColor}
            buttonTitle="Compris"
            onButtonPress={() => this.showInfoModal(false)}
            onRequestClose={() => this.showInfoModal(false)}
          />
          <InputModal
            title="Commande groupée"
            message="Vous êtes invité à joindre une commande groupée, comment on doit vous appeler?"
            inputDefaultValue={this.state.clientName}
            onChangeText={this.updateClientName}
            onButtonPress={() => this.submitClientNameInputModal(false)}
            onRequestClose={() => this.showClientNameInputModal(false)}
            buttonTitle={'Ok'.toUpperCase()}
            onClosePress={() => this.showClientNameInputModal(false)}
            visible={inputModalVisible}
          />
          <InfoModal
            title={this.state.infoModalTitle.toUpperCase()}
            message={this.state.infoModalMessage}
            iconName={this.state.infoModalIcon}
            onButtonPress={() => this.acceptInfoModal(false)}
            onRequestClose={() => this.popInfoModal(false)}
            buttonTitle={'Ok'.toUpperCase()}
            visible={infoModalVisible}
          />
        </View>
        {/*<Notification />*/}
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state) => {
  const {cart, store} = state;
  return {cart, store};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addProduct,
      setStore,
      emptyCart,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MenuA);
