import {Dimensions, StyleSheet} from 'react-native';
import Colors from '../../theme/colors';

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  modalContent: {
    backgroundColor: Colors.white,
    padding: 25,
    borderTopRightRadius: 30,
    borderTopLeftRadius: 30,
    position: 'relative',
    width: Dimensions.get('window').width,
    // elevation: 4,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  content: {
    fontSize: 16,
    marginBottom: 20,
  },
  closeButton: {
    padding: 15,
    // backgroundColor: 'pink',
    position: 'absolute',
    zIndex: 2,
    top: 8,
    right: 8,
  },
  buttonText: {
    fontSize: 16,
    color: 'blue',
  },
});

export default styles;
