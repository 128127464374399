import React from "react"
import Svg, {Circle, Path,Defs, Pattern, Use, Rect, G, Image} from 'react-native-svg';
const IsolionModeSvgFille = () => {
return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="70" height="68" viewBox="0 0 70 68" fill="none">
  <G clipPath="url(#clip0_1111_14475)">
    <Path d="M70 42.4051C70 52.2175 65.889 61.0803 59.2878 67.3913H10.7122C4.111 61.0754 0 52.2175 0 42.4051C0 23.243 15.668 7.70386 34.9951 7.70386C54.3222 7.70386 69.9951 23.2381 69.9951 42.4002L70 42.4051Z" fill="#DCEFEC"/>
    <Path d="M65 67.3912H60.442H9.55796H5" stroke="#604640" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M50.6637 51.1267C50.6637 51.1267 49.9024 54.1849 47.1126 55.9915C46.4102 56.4541 45.5753 56.8388 44.588 57.0677L40.0645 41.402C40.0645 41.402 41.592 41.5773 43.4289 43.233C43.8955 43.6469 44.3768 44.1582 44.8582 44.7815L50.6588 51.1267H50.6637Z" fill="#C5E74C"/>
    <Path d="M47.1126 55.9915C46.4102 56.4541 45.5753 56.8388 44.588 57.0677L40.0645 41.402C40.0645 41.402 41.592 41.5773 43.4289 43.233L47.1126 55.9915Z" fill="#B8D331"/>
    <Path d="M50.6637 51.1267C50.6637 51.1267 49.9024 54.1849 47.1126 55.9915C46.4102 56.4541 45.5753 56.8388 44.588 57.0677L40.0645 41.402C40.0645 41.402 41.592 41.5773 43.4289 43.233C43.8955 43.6469 44.3768 44.1582 44.8582 44.7815L50.6588 51.1267H50.6637Z" stroke="#604640" stroke-miterlimit="10"/>
    <Path d="M19.7451 51.1267C19.7451 51.1267 20.5064 54.1849 23.2962 55.9915C23.9986 56.4541 24.8335 56.8388 25.8208 57.0677L30.3443 41.402C30.3443 41.402 28.8168 41.5773 26.9799 43.233C26.5133 43.6469 26.032 44.1582 25.5506 44.7815L19.75 51.1267H19.7451Z" fill="#C5E74C"/>
    <Path d="M23.2959 55.9915C23.9983 56.4541 24.8332 56.8388 25.8205 57.0677L30.344 41.402C30.344 41.402 28.8165 41.5773 26.9796 43.233L23.2959 55.9915Z" fill="#B8D331"/>
    <Path d="M19.7451 51.1267C19.7451 51.1267 20.5064 54.1849 23.2962 55.9915C23.9986 56.4541 24.8335 56.8388 25.8208 57.0677L30.3443 41.402C30.3443 41.402 28.8168 41.5773 26.9799 43.233C26.5133 43.6469 26.032 44.1582 25.5506 44.7815L19.75 51.1267H19.7451Z" stroke="#604640" stroke-miterlimit="10"/>
    <Path d="M46.2283 67.3865H23.7676L26.9159 45.7992C27.2352 43.603 28.7725 41.7574 30.909 41.0951C31.5672 40.8906 32.2597 40.7494 32.9375 40.7494H37.0682C37.746 40.7494 38.4336 40.8906 39.0967 41.0951C41.2283 41.7623 42.7656 43.6079 43.0898 45.7992L46.2381 67.3865H46.2283Z" fill="#B8D331"/>
    <Path d="M46.2285 67.3865H26.5723L29.7206 45.7992C30.0398 43.603 31.5772 41.7574 33.7137 41.0951C34.3719 40.8906 35.0644 40.7494 35.7373 40.7494H37.0683C37.7461 40.7494 38.4338 40.8906 39.0968 41.0951C41.2285 41.7623 42.7658 43.6079 43.0899 45.7992L46.2383 67.3865H46.2285Z" fill="#C5E74C"/>
    <Path d="M46.2283 67.3865H23.7676L26.9159 45.7992C27.2352 43.603 28.7725 41.7574 30.909 41.0951C31.5672 40.8906 32.2597 40.7494 32.9375 40.7494H37.0682C37.746 40.7494 38.4336 40.8906 39.0967 41.0951C41.2283 41.7623 42.7656 43.6079 43.0898 45.7992L46.2381 67.3865H46.2283Z" stroke="#604640" stroke-miterlimit="10"/>
    <Path d="M58.7813 44.4159C60.9361 42.5243 61.1364 39.259 59.2286 37.1225C57.3208 34.986 54.0273 34.7874 51.8725 36.679C49.7176 38.5705 49.5173 41.8358 51.4251 43.9723C53.3329 46.1088 56.6264 46.3074 58.7813 44.4159Z" fill="#E5EAF0"/>
    <Path d="M55.0685 44.124C57.3851 44.124 59.263 42.2621 59.263 39.9653C59.263 37.6686 57.3851 35.8066 55.0685 35.8066C52.752 35.8066 50.874 37.6686 50.874 39.9653C50.874 42.2621 52.752 44.124 55.0685 44.124Z" fill="white"/>
    <Path d="M46.1299 38.7577L51.0366 39.1375L50.6191 41.139L46.1299 38.7577Z" fill="white"/>
    <Path d="M55.3293 35.3782C52.9619 35.3782 50.9678 36.9413 50.3293 39.084L46.1299 38.7577L50.1328 40.8809C50.3047 43.5787 52.5641 45.7165 55.3293 45.7165C58.0945 45.7165 60.5405 43.4034 60.5405 40.5498C60.5405 37.6961 58.2075 35.383 55.3293 35.383V35.3782Z" stroke="#604640" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M56.2973 38.4899C55.703 38.9136 55.3248 39.5417 55.3248 39.5417C55.3248 39.5417 54.9466 38.9184 54.3523 38.4899C53.4584 37.852 52.2059 38.5873 52.3287 39.6732C52.4318 40.5839 53.0851 41.8597 55.3248 43.2963C57.5645 41.8646 58.2177 40.5839 58.3208 39.6732C58.4436 38.5873 57.1912 37.852 56.2973 38.4899Z" fill="#CC5251"/>
    <Path d="M37.048 38.1733V41.5091C37.048 42.3126 36.5273 42.9943 35.8053 43.2378C35.6187 43.3011 35.4173 43.3401 35.2061 43.3401C34.1894 43.3401 33.3643 42.522 33.3643 41.5091V38.1733H37.0529H37.048Z" fill="#E5EAF0"/>
    <Path d="M37.0478 38.1733V41.5091C37.0478 42.3126 36.5272 42.9943 35.8052 43.2378C35.0782 42.9943 34.5576 42.3126 34.5576 41.5091V38.1733H37.0478Z" fill="white"/>
    <Path d="M37.048 38.1733V41.5091C37.048 42.3126 36.5273 42.9943 35.8053 43.2378C35.6187 43.3011 35.4173 43.3401 35.2061 43.3401C34.1894 43.3401 33.3643 42.522 33.3643 41.5091V38.1733H37.0529H37.048Z" stroke="#604640" stroke-miterlimit="10"/>
    <Path d="M51.0756 8.59525C51.1051 9.053 51.0756 9.51075 50.9823 9.97337C50.5255 12.2962 49.0619 15.3495 44.5432 16.114L33.7721 13.1143C33.7721 13.1143 30.0246 5.60528 34.4204 1.98712C35.1326 1.40763 35.9578 0.993705 36.8369 0.77944C37.7652 0.550565 38.7525 0.545696 39.6955 0.808658C40.8546 1.12032 42.1022 1.86051 42.8193 3.49184C42.8193 3.49184 45.0933 2.79061 47.4067 3.59898C49.5825 4.36351 50.9725 6.38442 51.0806 8.60012L51.0756 8.59525Z" fill="#815438"/>
    <Path d="M51.0757 8.59499C49.3026 9.65658 46.8272 10.3091 44.0914 10.3091C38.6936 10.3091 34.3174 7.75254 34.3174 4.597C34.3174 3.12636 35.2702 1.78233 36.8321 0.774313C37.7604 0.545439 38.7476 0.540569 39.6907 0.803531C40.8498 1.11519 42.0973 1.85538 42.8144 3.48672C42.8144 3.48672 45.0885 2.78548 47.4019 3.59385C49.5777 4.35839 50.9677 6.3793 51.0757 8.59499Z" fill="#97654A"/>
    <Path d="M51.0756 8.59525C51.1051 9.053 51.0756 9.51075 50.9823 9.97337C50.5255 12.2962 49.0619 15.3495 44.5432 16.114L33.7721 13.1143C33.7721 13.1143 30.0246 5.60528 34.4204 1.98712C35.1326 1.40763 35.9578 0.993705 36.8369 0.77944C37.7652 0.550565 38.7525 0.545696 39.6955 0.808658C40.8546 1.12032 42.1022 1.86051 42.8193 3.49184C42.8193 3.49184 45.0933 2.79061 47.4067 3.59898C49.5825 4.36351 50.9725 6.38442 51.0806 8.60012L51.0756 8.59525Z" stroke="#604640" stroke-miterlimit="10"/>
    <Path d="M21.2522 26.7103C21.2522 26.7103 20.1717 21.042 23.5852 16.0555C26.2571 12.1451 30.8937 10.0268 35.6432 10.3385C37.4163 10.4554 39.3023 10.9375 40.9133 12.1451C40.9133 12.1451 41.0312 12.1305 41.2424 12.1208C47.6668 11.8043 52.7159 17.5505 51.5322 23.8177C51.2473 25.3273 50.712 26.9781 49.7886 28.6582L40.8004 31.2294L25.5302 29.973L21.2522 26.7152V26.7103Z" fill="#815438"/>
    <Path d="M51.5325 23.8127C51.5129 23.9198 51.4883 24.0318 51.4638 24.1438C40.7123 23.4328 42.0188 14.8427 42.0188 14.8427C25.339 12.8803 22.6377 23.8321 22.2054 27.4308L21.2575 26.7101C21.2575 26.7101 20.172 21.0418 23.5905 16.0504C26.2624 12.145 30.899 10.0218 35.6485 10.3334C37.4265 10.4503 39.3076 10.9324 40.9186 12.1352C40.9186 12.1352 41.0365 12.1255 41.2526 12.1109C47.672 11.7943 52.7261 17.5405 51.5375 23.8078L51.5325 23.8127Z" fill="#97654A"/>
    <Path d="M21.2522 26.7103C21.2522 26.7103 20.1717 21.042 23.5852 16.0555C26.2571 12.1451 30.8937 10.0268 35.6432 10.3385C37.4163 10.4554 39.3023 10.9375 40.9133 12.1451C40.9133 12.1451 41.0312 12.1305 41.2424 12.1208C47.6668 11.8043 52.7159 17.5505 51.5322 23.8177C51.2473 25.3273 50.712 26.9781 49.7886 28.6582L40.8004 31.2294L25.5302 29.973L21.2522 26.7152V26.7103Z" stroke="#604640" stroke-miterlimit="10"/>
    <Path d="M27.1319 29.7099L26.6751 31.1513L26.1201 32.9239C23.6103 32.7583 22.0042 32.1934 20.9826 31.4678C18.9835 30.0459 19.735 27.5137 21.4 26.6225C22.181 26.1988 23.1584 26.1404 24.1554 26.7296C25.9531 27.7961 27.127 29.7099 27.127 29.7099H27.1319Z" fill="#E5EAF0"/>
    <Path d="M49.4208 31.4678C48.4041 32.1934 46.7931 32.7582 44.2882 32.9238L43.7283 31.1513L43.2715 29.7098C43.2715 29.7098 44.4503 27.7961 46.243 26.7296C47.2351 26.1404 48.2273 26.1988 49.0033 26.6273C50.6585 27.5234 51.4051 30.0507 49.411 31.4678H49.4208Z" fill="#E5EAF0"/>
    <Path d="M27.1315 29.7099L26.6747 31.1513C24.5087 30.9857 23.0991 30.4793 22.1954 29.8414C20.9773 28.9697 20.8791 27.6451 21.4046 26.6225C22.1855 26.1988 23.1629 26.1404 24.16 26.7296C25.9576 27.7961 27.1315 29.7099 27.1315 29.7099Z" fill="white"/>
    <Path d="M48.2076 29.8413C47.3088 30.4841 45.8992 30.9857 43.7283 31.1513L43.2715 29.7098C43.2715 29.7098 44.4503 27.7961 46.243 26.7296C47.2351 26.1404 48.2273 26.1988 49.0033 26.6273C49.519 27.6548 49.4208 28.9745 48.2027 29.8413H48.2076Z" fill="white"/>
    <Path d="M27.1319 29.7099C27.1319 29.7099 25.9531 27.7912 24.1603 26.7296C21.0611 24.8889 18.0552 29.3787 20.9875 31.4678C22.0042 32.1934 23.6152 32.7583 26.125 32.9238L27.1368 29.7147L27.1319 29.7099Z" stroke="#604640" stroke-miterlimit="10"/>
    <Path d="M43.2754 29.7099C43.2754 29.7099 44.4542 27.7912 46.2469 26.7296C49.3461 24.8889 52.352 29.3787 49.4198 31.4678C48.4031 32.1934 46.7921 32.7583 44.2823 32.9238L43.2705 29.7147L43.2754 29.7099Z" stroke="#604640" stroke-miterlimit="10"/>
    <Path d="M45.629 25.3028V30.752C45.629 35.1931 41.9993 38.7918 37.5199 38.7918H32.8932C28.4138 38.7918 24.7842 35.1931 24.7842 30.752V26.5348C24.7842 25.483 25.074 24.4506 25.6192 23.5448C27.1074 21.0905 30.9335 16.9172 39.7645 16.9172C39.7645 16.9172 38.8903 22.8582 45.629 25.2979V25.3028Z" fill="white"/>
    <Path d="M45.629 25.3028V30.752C45.629 35.1931 41.9993 38.7918 37.5199 38.7918H32.8932C28.4138 38.7918 24.7842 35.1931 24.7842 30.752V26.5348C24.7842 25.483 25.074 24.4506 25.6192 23.5448C27.1074 21.0905 30.9335 16.9172 39.7645 16.9172C39.7645 16.9172 38.8903 22.8582 45.629 25.2979V25.3028Z" fill="white"/>
    <Path d="M26.626 26.54V30.7571C26.626 35.1933 30.2557 38.792 34.7351 38.792H32.8932C28.4138 38.792 24.7842 35.1933 24.7842 30.7571V26.54C24.7842 25.4832 25.0691 24.4509 25.6192 23.55C27.1074 21.0957 30.9335 16.9224 39.7694 16.9224C39.7694 16.9224 39.7694 16.9467 39.7596 16.9857C32.2302 17.5213 28.8412 21.2661 27.461 23.5451C26.9158 24.446 26.626 25.4784 26.626 26.5351V26.54Z" fill="#E5EAF0"/>
    <Path d="M45.629 25.3028V30.752C45.629 35.1931 41.9993 38.7918 37.5199 38.7918H32.8932C28.4138 38.7918 24.7842 35.1931 24.7842 30.752V26.5348C24.7842 25.483 25.074 24.4506 25.6192 23.5448C27.1074 21.0905 30.9335 16.9172 39.7645 16.9172C39.7645 16.9172 38.8903 22.8582 45.629 25.2979V25.3028Z" stroke="#604640" stroke-miterlimit="10"/>
    <Path d="M35.207 34.3848C35.207 34.3848 37.373 34.8425 38.4683 33.1576" stroke="#604640" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M38.0068 32.208C38.0068 32.208 37.9921 33.3524 39.4607 33.6202" stroke="#604640" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M32.2152 28.0981C32.2152 28.0981 31.783 26.9245 30.3783 26.7882C29.6563 26.72 28.9195 26.9635 28.4529 27.5186C28.1681 27.8546 27.947 28.3367 28.0305 28.9941" stroke="#604640" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M38.1924 28.0981C38.1924 28.0981 38.6246 26.9245 40.0293 26.7882C40.7513 26.72 41.4881 26.9635 41.9547 27.5186C42.2395 27.8546 42.4606 28.3367 42.3771 28.9941" stroke="#604640" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M27.1318 25.2639C27.1318 25.2639 27.8931 23.5887 30.3145 23.8907" stroke="#604640" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M43.2764 25.2639C43.2764 25.2639 42.5151 23.5887 40.0986 23.8907" stroke="#604640" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M36.5865 30.0507C36.5865 30.6351 35.9677 31.1075 35.2015 31.1075C34.4353 31.1075 33.8164 30.6351 33.8164 30.0507C33.8164 29.4664 34.4353 28.994 35.2015 28.994C35.9677 28.994 36.5865 29.4664 36.5865 30.0507Z" fill="#E5EAF0"/>
    <Path d="M36.5865 30.0507C36.5865 30.6351 35.9677 31.1075 35.2015 31.1075C34.4353 31.1075 33.8164 30.6351 33.8164 30.0507C33.8164 29.4664 34.4353 28.994 35.2015 28.994C35.9677 28.994 36.5865 29.4664 36.5865 30.0507Z" stroke="#604640" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M42.7514 30.567C43.5299 30.567 44.1611 30.2094 44.1611 29.7684C44.1611 29.3273 43.5299 28.9697 42.7514 28.9697C41.9729 28.9697 41.3418 29.3273 41.3418 29.7684C41.3418 30.2094 41.9729 30.567 42.7514 30.567Z" fill="#CC5251"/>
    <Path d="M27.6577 30.567C28.4362 30.567 29.0673 30.2094 29.0673 29.7684C29.0673 29.3273 28.4362 28.9697 27.6577 28.9697C26.8792 28.9697 26.248 29.3273 26.248 29.7684C26.248 30.2094 26.8792 30.567 27.6577 30.567Z" fill="#CC5251"/>
    <Path d="M40.0095 50.7467V51.5502C40.0095 51.9349 39.6951 52.2466 39.3071 52.2466H31.0949C30.7069 52.2466 30.3926 51.9349 30.3926 51.5502V50.7467C30.3926 50.5276 30.5743 50.3474 30.7953 50.3474H39.6018C39.8228 50.3474 40.0046 50.5276 40.0046 50.7467H40.0095Z" fill="#E8A62C"/>
    <Path d="M40.0101 50.7467V51.5502C40.0101 51.9349 39.6958 52.2466 39.3078 52.2466H32.4807C32.0927 52.2466 31.7783 51.9349 31.7783 51.5502V50.7467C31.7783 50.5276 31.96 50.3474 32.1811 50.3474H39.6074C39.8284 50.3474 40.0101 50.5276 40.0101 50.7467Z" fill="#EFC741"/>
    <Path d="M38.9192 51.0097H38.2316C38.1236 51.0097 38.0352 50.922 38.0352 50.8149C38.0352 50.7078 38.1236 50.6201 38.2316 50.6201H38.9192C39.0273 50.6201 39.1157 50.7078 39.1157 50.8149C39.1157 50.922 39.0273 51.0097 38.9192 51.0097Z" fill="#FFE563"/>
    <Path d="M39.5484 51.0097H39.4846C39.3765 51.0097 39.2881 50.922 39.2881 50.8149C39.2881 50.7078 39.3765 50.6201 39.4846 50.6201H39.5484C39.6565 50.6201 39.7449 50.7078 39.7449 50.8149C39.7449 50.922 39.6565 51.0097 39.5484 51.0097Z" fill="#FFE563"/>
    <Path d="M39.6071 50.3474C39.8281 50.3474 40.0098 50.5276 40.0098 50.7467V51.5502C40.0098 51.9349 39.6955 52.2466 39.3075 52.2466H31.0953C30.7073 52.2466 30.3929 51.9349 30.3929 51.5502V50.7467C30.3929 50.5276 30.5747 50.3474 30.7957 50.3474H39.6022M39.6022 49.13H30.7957C29.8969 49.13 29.165 49.8556 29.165 50.7467V51.5502C29.165 52.6069 30.0295 53.464 31.0953 53.464H39.3075C40.3733 53.464 41.2377 52.6069 41.2377 51.5502V50.7467C41.2377 49.8556 40.5059 49.13 39.6071 49.13H39.6022Z" fill="#604640"/>
    <Path d="M39.7458 49.7875C39.7458 49.8654 39.7163 49.9384 39.677 49.992C39.6328 50.0456 39.5788 50.0797 39.515 50.0797H39.1269C38.9403 50.2744 38.7095 50.3864 38.4541 50.3864C38.292 50.3864 38.1397 50.3426 38.0022 50.2598C37.8057 50.143 37.5896 50.0797 37.3686 50.0797C37.1476 50.0797 36.9364 50.1381 36.735 50.2598C36.5975 50.3426 36.4452 50.3864 36.2831 50.3864C36.1211 50.3864 35.9688 50.3426 35.8313 50.2598C35.6348 50.143 35.4187 50.0797 35.1977 50.0797C34.9767 50.0797 34.7655 50.1381 34.5641 50.2598C34.4266 50.3426 34.2743 50.3864 34.1122 50.3864C33.9501 50.3864 33.7979 50.3426 33.6603 50.2598C33.4639 50.1381 33.2478 50.0797 33.0317 50.0797C32.8156 50.0797 32.5994 50.1381 32.3981 50.2598C32.2605 50.3426 32.1083 50.3864 31.9462 50.3864C31.6908 50.3864 31.46 50.2696 31.2733 50.0797H30.8951C30.7625 50.0797 30.6495 49.9384 30.6545 49.7631C30.6545 49.7047 30.6741 49.6463 30.6987 49.5976C30.7085 49.583 30.7134 49.5683 30.7232 49.5586C30.7674 49.505 30.8214 49.4709 30.8853 49.4709H39.4953C39.5788 49.4709 39.6525 49.5245 39.6967 49.6073C39.7262 49.6609 39.7409 49.7193 39.7409 49.7875H39.7458Z" fill="#B8D331"/>
    <Path d="M39.7012 49.6073C39.7012 49.6073 39.6865 49.6365 39.6767 49.6463C39.6325 49.6998 39.5784 49.7339 39.5146 49.7339H39.1266C38.9399 49.9287 38.7091 50.0456 38.4537 50.0456C38.2916 50.0456 38.1393 49.9969 38.0018 49.919C37.8053 49.7972 37.5892 49.7388 37.3682 49.7388C37.1472 49.7388 36.936 49.7972 36.7346 49.919C36.5971 50.0018 36.4448 50.0505 36.2828 50.0505C36.1207 50.0505 35.9684 50.0018 35.8309 49.9238C35.6344 49.8021 35.4183 49.7437 35.1973 49.7437C34.9763 49.7437 34.7651 49.8021 34.5637 49.9238C34.4262 50.0066 34.2739 50.0553 34.1118 50.0553C33.9497 50.0553 33.7975 50.0066 33.66 49.9238C33.4635 49.8021 33.2474 49.7437 33.0313 49.7437C32.8152 49.7437 32.5991 49.8021 32.3977 49.9238C32.2602 50.0066 32.1079 50.0505 31.9458 50.0505C31.6904 50.0505 31.4596 49.9336 31.2729 49.7388H30.8947C30.8161 49.7388 30.7376 49.6852 30.6934 49.6024C30.7032 49.5878 30.7081 49.5732 30.7179 49.5635C30.7621 49.5099 30.8161 49.4758 30.88 49.4758H39.49C39.5735 49.4758 39.6472 49.5294 39.6914 49.6122L39.7012 49.6073Z" fill="#C5E74C"/>
    <Path d="M39.5048 49.471C39.5883 49.471 39.6619 49.5245 39.7061 49.6073C39.7356 49.6609 39.7503 49.7193 39.7503 49.7875C39.7503 49.8654 39.7209 49.9384 39.6816 49.992C39.6374 50.0456 39.5833 50.0797 39.5195 50.0797H39.1315C38.9448 50.2745 38.714 50.3865 38.4586 50.3865C38.2965 50.3865 38.1442 50.3426 38.0067 50.2598C37.8103 50.143 37.5941 50.0797 37.3731 50.0797C37.1521 50.0797 36.9409 50.1381 36.7395 50.2598C36.602 50.3426 36.4497 50.3865 36.2877 50.3865C36.1256 50.3865 35.9733 50.3426 35.8358 50.2598C35.6393 50.143 35.4232 50.0797 35.2022 50.0797C34.9812 50.0797 34.77 50.1381 34.5686 50.2598C34.4311 50.3426 34.2788 50.3865 34.1167 50.3865C33.9547 50.3865 33.8024 50.3426 33.6649 50.2598C33.4684 50.1381 33.2523 50.0797 33.0362 50.0797C32.8201 50.0797 32.604 50.1381 32.4026 50.2598C32.2651 50.3426 32.1128 50.3865 31.9507 50.3865C31.6953 50.3865 31.4645 50.2696 31.2778 50.0797H30.8997C30.767 50.0797 30.6541 49.9384 30.659 49.7631C30.659 49.7047 30.6786 49.6463 30.7032 49.5976C30.713 49.583 30.7179 49.5683 30.7277 49.5586C30.772 49.505 30.826 49.471 30.8898 49.471H39.4998M39.4998 48.2535H30.8898C30.438 48.2535 30.0205 48.4629 29.7454 48.8282C29.7012 48.8817 29.6668 48.9402 29.6325 48.9986L29.6226 49.0181L29.6128 49.0376C29.5048 49.2421 29.4409 49.4758 29.4311 49.7096V49.7242V49.7388C29.4213 50.1625 29.5833 50.5715 29.8731 50.8686C30.1384 51.1364 30.4871 51.2922 30.8604 51.302C31.1943 51.5016 31.5676 51.6087 31.9556 51.6087C32.3437 51.6087 32.712 51.5065 33.0362 51.3068C33.3653 51.5065 33.7386 51.6087 34.1167 51.6087C34.4949 51.6087 34.8682 51.5065 35.1973 51.3068C35.5264 51.5016 35.8997 51.6087 36.2778 51.6087C36.656 51.6087 37.0293 51.5065 37.3584 51.3068C37.6875 51.5016 38.0608 51.6087 38.4439 51.6087C38.827 51.6087 39.2052 51.5016 39.5391 51.302C39.9763 51.2922 40.3839 51.0828 40.6541 50.7273C40.8505 50.4692 40.9586 50.1625 40.9684 49.8313C40.9733 49.5391 40.9095 49.2664 40.7769 49.0278C40.5313 48.5603 40.0352 48.2584 39.4998 48.2584V48.2535Z" fill="#604640"/>
    <Path d="M30.9627 47.0361H39.445C39.612 47.0361 39.7446 47.1676 39.7446 47.3332V48.3071C39.7446 48.4727 39.612 48.6042 39.445 48.6042H30.9627C30.7957 48.6042 30.6631 48.4727 30.6631 48.3071V47.3381C30.6631 47.1725 30.7957 47.041 30.9627 47.041V47.0361Z" fill="#815438"/>
    <Path d="M32.1316 47.0361H39.445C39.612 47.0361 39.7446 47.1676 39.7446 47.3332V48.3071C39.7446 48.4727 39.612 48.6042 39.445 48.6042H32.1316C31.9646 48.6042 31.832 48.4727 31.832 48.3071V47.3381C31.832 47.1725 31.9646 47.041 32.1316 47.041V47.0361Z" fill="#97654A"/>
    <Path d="M38.7774 47.4695H38.2568C38.1733 47.4695 38.0996 47.4013 38.0996 47.3137C38.0996 47.226 38.1684 47.1578 38.2568 47.1578H38.7774C38.8609 47.1578 38.9346 47.226 38.9346 47.3137C38.9346 47.3965 38.8658 47.4695 38.7774 47.4695Z" fill="#B8816A"/>
    <Path d="M39.2835 47.4695H39.2246C39.1411 47.4695 39.0674 47.4013 39.0674 47.3137C39.0674 47.226 39.1361 47.1578 39.2246 47.1578H39.2835C39.367 47.1578 39.4407 47.226 39.4407 47.3137C39.4407 47.3965 39.3719 47.4695 39.2835 47.4695Z" fill="#B8816A"/>
    <Path d="M39.4454 47.0361C39.6124 47.0361 39.745 47.1676 39.745 47.3332V48.3071C39.745 48.4727 39.6124 48.6042 39.4454 48.6042H30.9631C30.7961 48.6042 30.6634 48.4727 30.6634 48.3071V47.3332C30.6634 47.1676 30.7961 47.0361 30.9631 47.0361H39.4454ZM39.4454 45.8187H30.9631C30.1183 45.8187 29.4355 46.5005 29.4355 47.3332V48.3071C29.4355 49.1398 30.1232 49.8216 30.9631 49.8216H39.4454C40.2902 49.8216 40.9729 49.1447 40.9729 48.3071V47.3332C40.9729 46.4956 40.2853 45.8187 39.4454 45.8187Z" fill="#604640"/>
    <Path d="M40.0786 45.4924C40.0786 45.6044 40.0344 45.9599 39.9607 46.0329C39.8871 46.106 39.7839 46.1498 39.6709 46.1498H30.7417C30.5157 46.1498 30.3291 45.7115 30.3291 45.4924C30.3291 44.4892 30.7368 43.5835 31.3998 42.9261C31.9254 42.405 32.6081 42.0447 33.3694 41.9181C33.5708 41.884 33.777 41.8645 33.9882 41.8645H36.4195C38.3301 41.8645 39.8969 43.3157 40.0639 45.1661C40.0737 45.2733 40.0786 45.3853 40.0786 45.4924Z" fill="#E8A62C"/>
    <Path d="M40.0641 45.1661H32.1663C31.9551 45.1661 31.7832 44.9957 31.7832 44.7863C31.7832 43.8513 32.1663 42.9991 32.7852 42.3856C32.7999 42.3709 32.8195 42.3515 32.8343 42.3369C33.1437 42.0398 33.5563 41.8645 33.9836 41.8645H36.4197C38.3304 41.8645 39.8972 43.3157 40.0641 45.1661Z" fill="#EFC741"/>
    <Path d="M32.0876 44.3676H31.9059C31.847 44.3676 31.7979 44.3189 31.7979 44.2605C31.7979 44.202 31.847 44.1533 31.9059 44.1533H32.0876C32.1466 44.1533 32.1957 44.202 32.1957 44.2605C32.1957 44.3189 32.1466 44.3676 32.0876 44.3676Z" fill="white"/>
    <Path d="M33.7869 43.5348H33.6051C33.5462 43.5348 33.4971 43.4861 33.4971 43.4277C33.4971 43.3693 33.5462 43.3206 33.6051 43.3206H33.7869C33.8458 43.3206 33.8949 43.3693 33.8949 43.4277C33.8949 43.4861 33.8458 43.5348 33.7869 43.5348Z" fill="white"/>
    <Path d="M36.3562 44.9909H36.1745C36.1155 44.9909 36.0664 44.9422 36.0664 44.8837C36.0664 44.8253 36.1155 44.7766 36.1745 44.7766H36.3562C36.4151 44.7766 36.4642 44.8253 36.4642 44.8837C36.4642 44.9422 36.4151 44.9909 36.3562 44.9909Z" fill="white"/>
    <Path d="M34.2927 44.8837H34.111C34.052 44.8837 34.0029 44.835 34.0029 44.7766C34.0029 44.7181 34.052 44.6694 34.111 44.6694H34.2927C34.3517 44.6694 34.4008 44.7181 34.4008 44.7766C34.4008 44.835 34.3517 44.8837 34.2927 44.8837Z" fill="white"/>
    <Path d="M37.2156 44.1289H37.0338C36.9749 44.1289 36.9258 44.0802 36.9258 44.0218C36.9258 43.9634 36.9749 43.9147 37.0338 43.9147H37.2156C37.2745 43.9147 37.3236 43.9634 37.3236 44.0218C37.3236 44.0802 37.2745 44.1289 37.2156 44.1289Z" fill="white"/>
    <Path d="M38.3298 43.4278H38.1481C38.0892 43.4278 38.04 43.3791 38.04 43.3206C38.04 43.2622 38.0892 43.2135 38.1481 43.2135H38.3298C38.3888 43.2135 38.4379 43.2622 38.4379 43.3206C38.4379 43.3791 38.3888 43.4278 38.3298 43.4278Z" fill="white"/>
    <Path d="M38.9343 44.7815H38.7526C38.6936 44.7815 38.6445 44.7328 38.6445 44.6744C38.6445 44.616 38.6936 44.5673 38.7526 44.5673H38.9343C38.9933 44.5673 39.0424 44.616 39.0424 44.6744C39.0424 44.7328 38.9933 44.7815 38.9343 44.7815Z" fill="white"/>
    <Path d="M37.0095 42.6388H36.8278C36.7688 42.6388 36.7197 42.5901 36.7197 42.5317C36.7197 42.4733 36.7688 42.4246 36.8278 42.4246H37.0095C37.0684 42.4246 37.1176 42.4733 37.1176 42.5317C37.1176 42.5901 37.0684 42.6388 37.0095 42.6388Z" fill="white"/>
    <Path d="M35.4812 44.0511H35.2995C35.2405 44.0511 35.1914 44.0024 35.1914 43.9439C35.1914 43.8855 35.2405 43.8368 35.2995 43.8368H35.4812C35.5401 43.8368 35.5892 43.8855 35.5892 43.9439C35.5892 44.0024 35.5401 44.0511 35.4812 44.0511Z" fill="white"/>
    <Path d="M35.2654 42.6924H35.0836C35.0247 42.6924 34.9756 42.6437 34.9756 42.5853C34.9756 42.5268 35.0247 42.4781 35.0836 42.4781H35.2654C35.3243 42.4781 35.3734 42.5268 35.3734 42.5853C35.3734 42.6437 35.3243 42.6924 35.2654 42.6924Z" fill="white"/>
    <Path d="M33.0505 42.7314H32.8688C32.8099 42.7314 32.7607 42.6827 32.7607 42.6242C32.7607 42.5658 32.8099 42.5171 32.8688 42.5171H33.0505C33.1095 42.5171 33.1586 42.5658 33.1586 42.6242C33.1586 42.6827 33.1095 42.7314 33.0505 42.7314Z" fill="white"/>
    <Path d="M36.4198 41.8645C38.3304 41.8645 39.8972 43.3157 40.0642 45.1661C40.0741 45.2733 40.079 45.3853 40.079 45.4924C40.079 45.5995 40.0348 45.9599 39.9611 46.0329C39.8874 46.106 39.7843 46.1498 39.6713 46.1498H30.742C30.5161 46.1498 30.3295 45.7115 30.3295 45.4924C30.3295 44.4893 30.7371 43.5835 31.4002 42.9261C31.9257 42.405 32.6084 42.0447 33.3697 41.9181C33.5711 41.884 33.7774 41.8645 33.9886 41.8645H36.4198ZM36.4198 40.6471H33.9886C33.7086 40.6471 33.4287 40.6714 33.1585 40.7201C32.1664 40.8857 31.2578 41.3532 30.5357 42.069C29.6124 42.9845 29.1016 44.2019 29.1016 45.4973C29.1016 46.2862 29.6713 47.3721 30.742 47.3721H39.6713C40.1035 47.3721 40.5112 47.2065 40.8206 46.9046C41.2725 46.4469 41.3069 45.7115 41.3069 45.4924C41.3069 45.3512 41.302 45.2051 41.2872 45.059C41.0613 42.5414 38.969 40.6471 36.4198 40.6471Z" fill="#604640"/>
    <Path d="M14.7154 60.525C15.2458 61.7765 16.626 62.6433 18.178 62.2683C18.2026 62.2683 18.2272 62.2537 18.2468 62.2489C18.7183 62.1271 19.1505 61.8836 19.5091 61.5525L29.9659 52.2758C32.3676 51.5843 33.3254 50.2695 33.6496 49.0375C33.9492 47.8834 33.5022 46.7877 32.682 46.1546C32.0533 45.6579 31.2036 45.4437 30.3097 45.6871C28.291 46.2374 27.2841 48.3508 27.2841 48.3508L15.6191 57.0627C15.3637 57.2721 15.1525 57.5253 14.9757 57.798C14.9364 57.8564 14.9069 57.9197 14.8725 57.9782C14.3863 58.8352 14.3863 59.7507 14.7154 60.5201V60.525Z" fill="#E5EAF0"/>
    <Path d="M14.7154 60.525C15.3883 61.085 16.3116 61.3529 17.3087 61.1094C17.3333 61.1094 17.3578 61.0948 17.3775 61.0899C17.849 60.9681 18.2812 60.7247 18.6397 60.3935L29.0965 51.1168C31.4983 50.4205 32.456 49.1105 32.7802 47.8785C32.9374 47.2698 32.8932 46.6708 32.682 46.1546C32.0533 45.6579 31.2036 45.4437 30.3097 45.6871C28.291 46.2374 27.2841 48.3508 27.2841 48.3508L15.6191 57.0627C15.3637 57.2721 15.1525 57.5253 14.9757 57.798C14.9364 57.8564 14.9069 57.9197 14.8725 57.9782C14.3863 58.8352 14.3863 59.7507 14.7154 60.5201V60.525Z" fill="white"/>
    <Path d="M14.7154 60.525C15.2458 61.7765 16.626 62.6433 18.178 62.2683C18.2026 62.2683 18.2272 62.2537 18.2468 62.2489C18.7183 62.1271 19.1505 61.8836 19.5091 61.5525L29.9659 52.2758C32.3676 51.5843 33.3254 50.2695 33.6496 49.0375C33.9492 47.8834 33.5022 46.7877 32.682 46.1546C32.0533 45.6579 31.2036 45.4437 30.3097 45.6871C28.291 46.2374 27.2841 48.3508 27.2841 48.3508L15.6191 57.0627C15.3637 57.2721 15.1525 57.5253 14.9757 57.798C14.9364 57.8564 14.9069 57.9197 14.8725 57.9782C14.3863 58.8352 14.3863 59.7507 14.7154 60.5201V60.525Z" stroke="#604640" stroke-miterlimit="10"/>
    <Path d="M55.6927 60.525C55.1622 61.7765 53.7821 62.6433 52.23 62.2683C52.2054 62.2683 52.1809 62.2537 52.1612 62.2489C51.6897 62.1271 51.2575 61.8836 50.899 61.5525L40.4422 52.2758C38.0404 51.5843 37.0827 50.2695 36.7585 49.0375C36.4589 47.8834 36.9058 46.7877 37.7261 46.1546C38.3548 45.6579 39.2045 45.4437 40.0984 45.6871C42.117 46.2374 43.1239 48.3508 43.1239 48.3508L54.7889 57.0627C55.0443 57.2721 55.2555 57.5253 55.4324 57.798C55.4717 57.8564 55.5011 57.9197 55.5355 57.9782C56.0217 58.8352 56.0217 59.7507 55.6927 60.5201V60.525Z" fill="#E5EAF0"/>
    <Path d="M55.6922 60.525C55.0193 61.085 54.0959 61.3529 53.0989 61.1094C53.0743 61.1094 53.0498 61.0948 53.0301 61.0899C52.5586 60.9681 52.1264 60.7247 51.7678 60.3935L41.3111 51.1168C38.9093 50.4205 37.9515 49.1105 37.6274 47.8785C37.4702 47.2698 37.5144 46.6708 37.7256 46.1546C38.3543 45.6579 39.204 45.4437 40.0979 45.6871C42.1166 46.2374 43.1234 48.3508 43.1234 48.3508L54.7885 57.0627C55.0439 57.2721 55.2551 57.5253 55.4319 57.798C55.4712 57.8564 55.5006 57.9197 55.535 57.9782C56.0213 58.8352 56.0213 59.7507 55.6922 60.5201V60.525Z" fill="white"/>
    <Path d="M55.6927 60.525C55.1622 61.7765 53.7821 62.6433 52.23 62.2683C52.2054 62.2683 52.1809 62.2537 52.1612 62.2489C51.6897 62.1271 51.2575 61.8836 50.899 61.5525L40.4422 52.2758C38.0404 51.5843 37.0827 50.2695 36.7585 49.0375C36.4589 47.8834 36.9058 46.7877 37.7261 46.1546C38.3548 45.6579 39.2045 45.4437 40.0984 45.6871C42.117 46.2374 43.1239 48.3508 43.1239 48.3508L54.7889 57.0627C55.0443 57.2721 55.2555 57.5253 55.4324 57.798C55.4717 57.8564 55.5011 57.9197 55.5355 57.9782C56.0217 58.8352 56.0217 59.7507 55.6927 60.5201V60.525Z" stroke="#604640" stroke-miterlimit="10"/>
    <Path d="M14.6414 54.4964H13.502L15.2554 49.8215C15.442 49.3199 15.9234 48.9888 16.4636 48.9888H18.4725C18.7672 48.9888 19.0029 49.2225 19.0029 49.5147C19.0029 49.8069 18.7672 50.0406 18.4725 50.0406H16.4636C16.3654 50.0406 16.2819 50.0991 16.2475 50.1916L14.6414 54.5012V54.4964Z" fill="white"/>
    <Path d="M18.4773 48.9888C18.772 48.9888 19.0077 49.2225 19.0077 49.5147C19.0077 49.8069 18.772 50.0406 18.4773 50.0406H16.4685C16.3702 50.0406 16.2867 50.0991 16.2523 50.1916L14.6463 54.5013H13.5068L15.2602 49.8264C15.4468 49.3248 15.9282 48.9937 16.4685 48.9937H18.4773M18.4773 47.7762H16.4685C15.4223 47.7762 14.4743 48.4288 14.106 49.4027L12.3525 54.0776L13.5019 55.7187H14.6413L15.7907 54.9249L17.1561 51.2629H18.4724C19.44 51.2629 20.2307 50.4789 20.2307 49.5196C20.2307 48.5603 19.44 47.7762 18.4724 47.7762H18.4773Z" fill="#604640"/>
    <Path d="M16.6014 56.8679L16.2576 60.1209L15.958 62.955L15.5651 66.69C15.5258 67.0893 15.1819 67.3961 14.7743 67.3961H10.29C9.88234 67.3961 9.54344 67.0893 9.49924 66.69L9.10631 62.955L8.8067 60.1209L8.46289 56.8679H16.6014Z" fill="#B84536"/>
    <Path d="M16.6018 56.8679L15.5654 66.6852C15.5212 67.0845 15.1823 67.3913 14.7747 67.3913H11.7246C11.3169 67.3913 10.978 67.0845 10.9338 66.6852L9.89746 56.8679H16.6067H16.6018Z" fill="#CC5251"/>
    <Path d="M16.6014 56.8679L15.565 66.6852C15.5208 67.0845 15.1819 67.3913 14.7743 67.3913H10.2949C9.88722 67.3913 9.54832 67.0845 9.50412 66.6852L8.46777 56.8679H16.6063H16.6014Z" stroke="#604640" stroke-miterlimit="10"/>
    <Path d="M16.2575 60.116L15.9579 62.9501H9.10625L8.80664 60.116H16.2575Z" fill="#E5EAF0"/>
    <Path d="M16.2579 60.116L15.9583 62.9501H10.5359L10.2363 60.116H16.2579Z" fill="white"/>
    <Path d="M16.2575 60.116L15.9579 62.9501H9.10625L8.80664 60.116H16.2575Z" stroke="#604640" stroke-miterlimit="10"/>
    <Path d="M17.7654 55.6799C17.7654 56.0062 17.6328 56.3032 17.4167 56.5175C17.2006 56.7318 16.901 56.8681 16.5719 56.8681H8.49235C7.83419 56.8681 7.29883 56.3373 7.29883 55.6799C7.29883 55.3975 7.39706 55.1394 7.56405 54.9397C7.58861 54.9056 7.61808 54.8715 7.64755 54.8423C7.86857 54.6281 8.16327 54.4966 8.49235 54.4966H16.5719C16.9452 54.4966 17.2841 54.667 17.5002 54.9397C17.6623 55.1394 17.7654 55.3975 17.7654 55.6799Z" fill="#E5EAF0"/>
    <Path d="M17.5056 54.9882C17.5056 55.2949 17.3779 55.5774 17.1716 55.7819C16.9702 55.9864 16.6804 56.1131 16.3661 56.1131H8.69418C8.06549 56.1131 7.55469 55.6115 7.55469 54.9882C7.55469 54.9687 7.55469 54.9492 7.55469 54.9346C7.57925 54.9005 7.60872 54.8664 7.63818 54.8372C7.85921 54.6229 8.1539 54.4915 8.48298 54.4915H16.5625C16.9358 54.4915 17.2747 54.6619 17.4908 54.9346C17.4908 54.9492 17.4908 54.9687 17.4908 54.9882H17.5056Z" fill="white"/>
    <Path d="M17.7654 55.6799C17.7654 56.0062 17.6328 56.3032 17.4167 56.5175C17.2006 56.7318 16.901 56.8681 16.5719 56.8681H8.49235C7.83419 56.8681 7.29883 56.3373 7.29883 55.6799C7.29883 55.3975 7.39706 55.1394 7.56405 54.9397C7.58861 54.9056 7.61808 54.8715 7.64755 54.8423C7.86857 54.6281 8.16327 54.4966 8.49235 54.4966H16.5719C16.9452 54.4966 17.2841 54.667 17.5002 54.9397C17.6623 55.1394 17.7654 55.3975 17.7654 55.6799Z" stroke="#604640" stroke-miterlimit="10"/>
  </G>
  <Defs>
    <clipPath id="clip0_1111_14475">
      <Rect width="70" height="68" fill="white"/>
    </clipPath>
  </Defs>
</Svg>
)
}
export default IsolionModeSvgFille