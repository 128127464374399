import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const PizzaSVG = ({props}) => {
  const {...restProps} = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={73}
      height={73}
      fill="none"
      viewBox="0 0 73 73"
      {...props}>
      <Path
        fill="#000"
        d="M36.5 71C16.342 71 0 69.433 0 67.5S16.342 64 36.5 64 73 65.567 73 67.5 56.658 71 36.5 71Z"
        opacity={0.1}
      />
      <Path
        fill="#FFC54B"
        d="m11.44 62.435 16.769-46.048s.015.006.024.006l-.006-.006c-.934-.407-1.12-2.046-.418-3.668.63-1.454 2.083-2.296 2.982-2.183C48.488 15.3 62.29 29.545 66.433 47.5c.144.967-.985 2.168-2.632 2.556-1.702.4-3.269-.078-3.502-1.07l-.137-.14-44.755 17.465c-2.439.953-4.86-1.418-3.964-3.876h-.003Z"
      />
      <Path
        fill="#844F02"
        d="M14.281 67.052c.44 0 .881-.08 1.31-.25L59.933 49.5c.251.477.738.842 1.412 1.045.75.227 1.663.236 2.573.02 1.863-.438 3.224-1.848 3.03-3.144 0-.015-.006-.027-.01-.042a50.41 50.41 0 0 0-12.87-23.674 50.385 50.385 0 0 0-23.139-13.678c-.024-.005-.048-.011-.072-.014-.582-.072-1.295.143-1.952.59a4.502 4.502 0 0 0-1.576 1.903c-.376.866-.534 1.774-.444 2.56.074.66.316 1.18.695 1.517L10.95 62.256a3.527 3.527 0 0 0 .857 3.772 3.527 3.527 0 0 0 2.477 1.021l-.003.003ZM60.16 48.327a.54.54 0 0 0-.19.036L15.212 65.828a2.492 2.492 0 0 1-2.678-.547 2.494 2.494 0 0 1-.606-2.664l16.716-45.902c.015-.02.03-.039.041-.063a.52.52 0 0 0-.08-.624l-.006-.006a.543.543 0 0 0-.167-.116c-.269-.117-.454-.466-.51-.956-.07-.594.065-1.332.364-2.022.25-.579.677-1.096 1.205-1.454.502-.344.944-.442 1.203-.421 17.617 4.754 31.109 18.752 35.222 36.54.078.66-.854 1.627-2.239 1.952-.722.17-1.462.17-2.03-.003-.468-.143-.776-.388-.844-.675a.512.512 0 0 0-.137-.247l-.138-.14a.527.527 0 0 0-.373-.156l.003.003Z"
      />
      <Path
        fill="#fff"
        d="M64.093 48.091a.63.63 0 0 0 .612-.776C60.637 30.343 47.6 16.89 30.683 12.215a.629.629 0 0 0-.334 1.212c16.477 4.555 29.171 17.653 33.132 34.18.069.287.326.481.61.481l.002.003Z"
      />
      <Path
        fill="#844F02"
        d="M14.281 67.052c.44 0 .881-.08 1.31-.25l45.297-17.675-.105-.442a44.085 44.085 0 0 0-11.611-20.983 44.092 44.092 0 0 0-20.838-11.826l-.456-.11-16.928 46.49a3.527 3.527 0 0 0 .857 3.772 3.527 3.527 0 0 0 2.477 1.021l-.003.003Zm45.377-18.57L15.213 65.825a2.492 2.492 0 0 1-2.678-.547 2.494 2.494 0 0 1-.606-2.664l16.608-45.606c15.232 3.918 27.378 16.198 31.12 31.474Z"
      />
      <Path
        fill="#fff"
        d="M28.833 25.764c-2.51-2.383 6.193-5.665 11.793-.86 3.495 2.998-4.847 8.01.66 8.35 8.202.505 3.447 3.297 7.65 3.202 3.468-.08 6.382 5.62 5.815 6.794-1.129 2.33-4.364 2.733-3.896 4.922.469 2.186-2.226 2.135-3.31 1.851-1.084-.287-1.895.38-4.325 1.06-2.433.678-7.45-2.73-8.026.105-.365 1.786-.12 4.763-1.72 4.811-4.817.146-3.29 2.754-5.226 3.22-2.293.555-3.65-.332-5.146.212-1.496.543-5.82 3.497-7.131 3.518-2.985.047 2.403-4.002 1.245-6.92-.475-1.195 1.579-1.777 1.43-3.78-.15-2.005.008-3.812 1.48-4.463 4.483-1.98 6.346-3.3 4.492-5.826-4.937-6.732 9.69-11 4.218-16.19l-.003-.006Z"
        opacity={0.4}
      />
      <Path
        fill="#FF3428"
        d="M25.523 57.453a4.995 4.995 0 0 1-4.994-4.996 4.995 4.995 0 0 1 4.994-4.996 4.995 4.995 0 0 1 4.993 4.996 4.995 4.995 0 0 1-4.993 4.996Z"
      />
      <Path
        fill="#844F02"
        d="M25.523 57.976a5.524 5.524 0 0 0 5.516-5.519 5.524 5.524 0 0 0-5.517-5.519c-1.474 0-2.859.574-3.9 1.616a5.484 5.484 0 0 0-1.616 3.903c0 1.475.573 2.861 1.615 3.904a5.478 5.478 0 0 0 3.902 1.615Zm0-9.992a4.474 4.474 0 0 1 4.468 4.47 4.476 4.476 0 0 1-4.468 4.471 4.476 4.476 0 0 1-4.469-4.47 4.476 4.476 0 0 1 4.469-4.471Z"
      />
      <Path
        fill="#FF3428"
        d="M26.494 39.303a4.994 4.994 0 1 1 9.716-2.312 4.994 4.994 0 1 1-9.716 2.312Z"
      />
      <Path
        fill="#844F02"
        d="M31.352 43.665a5.524 5.524 0 0 0 5.516-5.519 5.524 5.524 0 0 0-5.516-5.519c-1.474 0-2.86.573-3.901 1.616a5.484 5.484 0 0 0-1.615 3.903c0 1.475.573 2.861 1.615 3.903a5.478 5.478 0 0 0 3.901 1.616Zm0-9.993a4.474 4.474 0 0 1 4.468 4.471 4.476 4.476 0 0 1-4.468 4.47 4.476 4.476 0 0 1-4.468-4.47 4.476 4.476 0 0 1 4.468-4.47Z"
      />
      <Path
        fill="#FF3428"
        d="M42.763 46.673a4.998 4.998 0 0 1 0-7.066 4.992 4.992 0 0 1 7.062 0 4.998 4.998 0 0 1 0 7.066 4.992 4.992 0 0 1-7.062 0Z"
      />
      <Path
        fill="#844F02"
        d="M46.294 48.661a5.524 5.524 0 0 0 5.517-5.519 5.524 5.524 0 0 0-5.517-5.519c-1.474 0-2.859.574-3.9 1.616a5.484 5.484 0 0 0-1.616 3.903c0 1.476.574 2.861 1.615 3.904a5.478 5.478 0 0 0 3.901 1.615Zm0-9.992a4.474 4.474 0 0 1 4.469 4.47 4.476 4.476 0 0 1-4.468 4.471 4.476 4.476 0 0 1-4.469-4.47 4.476 4.476 0 0 1 4.468-4.471Z"
      />
      <Path
        fill="#fff"
        d="M30.713 37.56a.788.788 0 1 1 0-1.576.788.788 0 0 1 0 1.577ZM30.671 39.618a.653.653 0 0 0-.794-.462.652.652 0 0 0-.462.794.653.653 0 0 0 .794.463.652.652 0 0 0 .462-.795ZM25.728 49.764a.965.965 0 0 0-1.346-.204.967.967 0 0 0-.203 1.347.966.966 0 0 0 1.346.203.967.967 0 0 0 .203-1.346ZM24.806 52.84a.965.965 0 0 0-1.346-.204.967.967 0 0 0-.203 1.347.965.965 0 0 0 1.346.203.967.967 0 0 0 .203-1.347ZM27.875 52.618a.744.744 0 1 0-1.197.887.744.744 0 1 0 1.197-.887ZM45.303 41.41a.652.652 0 1 0-.606 1.154.652.652 0 0 0 .606-1.154ZM45.921 44.203a.82.82 0 1 0-.764 1.45.82.82 0 0 0 .764-1.45ZM48.807 42.22a.962.962 0 1 0-.895 1.703.962.962 0 0 0 .895-1.703ZM33.973 38.743a.961.961 0 1 0-1.86.493.961.961 0 1 0 1.86-.493Z"
        opacity={0.4}
      />
      <Path
        fill="#fff"
        d="M13.53 64.093a.628.628 0 0 0 .59-.416L29.91 20.04a.626.626 0 1 0-1.179-.427l-15.79 43.641a.626.626 0 0 0 .591.843l-.003-.003Z"
      />
    </Svg>
  );
};

export default PizzaSVG;
