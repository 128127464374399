import React from 'react';
import {View, TouchableOpacity} from 'react-native';
import {useNavigation} from '@react-navigation/native';

import styles from './ScreenTitleAStyles';
import Colors from '../../theme/colors';
import BackArrowSVG from '../svgs/BackArrowSVG';
import TitleA from '../titleA/TitleA';
import TouchableItem from '../TouchableItem';

const ScreenTitleA = ({title, style, onPress, disableBack, ...props}) => {
  return (
    <View style={[styles.container, style]} {...props}>
      {!disableBack && (
        <TouchableItem style={styles.iconContainer} onPress={onPress}>
          <BackArrowSVG style={styles.icon} />
        </TouchableItem>
      )}
      <View style={styles.titleContainer}>
        <TitleA style={styles.title}>{title}</TitleA>
      </View>
    </View>
  );
};

export default ScreenTitleA;
