import React from 'react';
import Svg, {Path} from 'react-native-svg';

const PriceSVG = (props) => {
  const {style, stroke = '#77838F', ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 21"
      fill="none"
      style={style}
      {...restProps}>
      <Path
        stroke={stroke}
        strokeWidth={0.75}
        d="M10.5 17a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13Z"
      />
      <Path
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth={0.75}
        d="M11.77 7.934a3.113 3.113 0 0 0-3.046.001c-.463.26-.848.634-1.115 1.084a2.899 2.899 0 0 0 0 2.961c.267.45.652.824 1.115 1.084a3.115 3.115 0 0 0 3.045.001M6.692 9.76h2.539m-2.539 1.48h2.539"
      />
    </Svg>
  );
};

export default PriceSVG;
