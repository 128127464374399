import React from 'react';
import IsolionModeSvgFille from '../../components/svgs/IsolutionModeFilleSVG';
import IsolutionModeMotoSvg from '../../components/svgs/IsolutionModeMotoSvg';
import IsolutionModeSvg from '../../components/svgs/IsolutionModeSvg';
import ObjectVectorSvg from '../../components/svgs/ObjectVectorSVG';

import styles from './CommandStyle';

export const SvgCommande = [
  {
    id: 1,
    name: 'ordered',
    svg: <IsolutionModeSvg style={{width: 14, height: 14}} />,
    phrases: [
      {text: 'Commande Lancée', style: styles.SvgTextTitle},
      {text: 'Votre commande est lancée', style: styles.SvgText},
    ],
  },
  {
    id: 2,
    name: 'confirmed',
    svg: <ObjectVectorSvg style={{width: 14, height: 14}}/>,
    phrases: [
      {text: 'En cours de préparation', style: styles.SvgTextTitle},
      {text: 'Votre commande a été', style: styles.SvgText},
      {text: 'préparée', style: styles.SvgText},
    ],
  },

  {
    id: 3,
    name: 'validated',
    svg: <IsolutionModeMotoSvg style={{width: 14, height: 14}}/>,
    phrases: [
      {text: 'En cours de livraison', style: styles.TextCommandeB},
      {text: 'Votre commande est en cours', style: styles.SvgText},
      {text: 'livraison', style: styles.SvgText},
    ],
  },

  {
    id: 4,
    name: 'delivered',
    svg: <IsolionModeSvgFille style={{width: 14, height: 14}}/>,
    phrases: [
      {text: 'Commande Livrée', style: styles.SvgTextTitle},
      {text: 'Votre commande est livrée', style: styles.SvgText},
    ],
  },
];


export const DetailsCommande = [
  {
    id: 1,
    titleCommande: 'Base',
    phrases: [
      {text: '1', style: styles.DetaileCommande},
      {text: 'Mâche', style: styles.DetaileCommande},
    ],
  },
  {
    id: 2,
    titleCommande: 'Vos 3 ingrédients',
    phrases: [
      {text: '2', style: styles.DetaileCommande},
      {text: 'Maïs', style: styles.DetaileCommande},
    ],
    Phrases2: [
      {text: '1', style: styles.DetaileCommande},
      {text: 'Carottes', style: styles.DetaileCommande},
    ],
  },
  {
    id: 3,
    titleCommande: 'Votre sauce',
    phrases: [
      {text: '1', style: styles.DetaileCommande},
      {text: 'Sauce Pesto', style: styles.DetaileCommande},
    ],
  },
  {
    id: 3,
    titleCommande: 'Votre Petit plus',
    phrases: [
      {text: '1', style: styles.DetaileCommande},
      {text: 'Graines de sesame', style: styles.DetaileCommande},
    ],
  },
];

export const DetailsCommandeB = [
  {
    id: 1,
    titleCommande: 'Salade',
    phrases: [
      {text: '1', style: styles.DetaileCommande},
      {text: 'Bol de Salade', style: styles.DetaileCommande},
    ],
  },
  {
    id: 2,
    titleCommande: 'Boissons',
    phrases: [
      {text: '2', style: styles.DetaileCommande},
      {text: 'Coca', style: styles.DetaileCommande},
    ],
    Phrases2: [
      {text: '1', style: styles.DetaileCommande},
      {text: 'Jus', style: styles.DetaileCommande},
    ],
  },
];
